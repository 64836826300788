import React from "react";
import PropTypes from "prop-types";

import style from "./style.scss";


// A wrappers that allow to have a list of buttons with spacing between them
const ButtonsWrapper = ({ children }) => (
  <div className={style.wrapper}>
    {children}
  </div>
);

// propTypes for the ButtonsWrapper component
ButtonsWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ButtonsWrapper;
