import React, { Component } from "react";
import { TablePagination } from "@wfp/ui";
import Table from "../Table";
import PropTypes from "prop-types";

import { calculateMinimumRows } from "../../utils/table";


// A ReactTable wrapper with client side pagination
class TableInMemory extends React.Component {
  render() {
    const { data, columns, loading, pageSize, showPagination, subComponent } = this.props;
    return (
      <div>
        <Table
          className="-border -striped"
          pageSizesDisabled
          resizable={false}
          sortable={false}
          loading={loading}
          pageSize={pageSize}
          columns={columns}
          data={data}
          showPagination={showPagination}
          PaginationComponent={TablePagination}
          minRows={calculateMinimumRows(data)}
          noDataText="No record exists"
          subComponent={subComponent}
        />
      </div>
    );
  }
}

// propTypes for the TableInMemory component
TableInMemory.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  pageSize: PropTypes.number.isRequired,
  // Pagination properties, if pagination is handled
  showPagination: PropTypes.bool
};

// defaultProps for TableInMemory component
TableInMemory.defaultProps = {
  pageSize: 20,
  showPagination: true
};

export default TableInMemory;
