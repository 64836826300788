// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_icon_XERTrCXV60Vn4ts7RiKnVg\\=\\= {\n  width: 100%;\n  text-align: center;\n}\n\n.style_text_TDn0dtBBWnBGGhgFKQmq8Q\\=\\= {\n  margin-top: 1rem;\n  width: 100%;\n  text-align: center;\n}", "",{"version":3,"sources":["webpack://src/components/ModalSuccess/style.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,kBAAA;AACF;;AAEA;EACE,gBAAA;EACA,WAAA;EACA,kBAAA;AACF","sourcesContent":[".icon {\n  width: 100%;\n  text-align: center;\n}\n\n.text {\n  margin-top: 1rem;\n  width: 100%;\n  text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "style_icon_XERTrCXV60Vn4ts7RiKnVg==",
	"text": "style_text_TDn0dtBBWnBGGhgFKQmq8Q=="
};
export default ___CSS_LOADER_EXPORT___;
