import React from "react";
import PropTypes from "prop-types";
import { reduxForm, Field } from "redux-form";
import { iconEdit, iconDelete, iconDownloadGlyph } from "@wfp/icons";

import { displayErrorMessage } from "../../../../utils";
import { menu } from "../../../../utils/table";
import Table from "../../../../components/Table";
import Button from "../../../../components/Button";
import { Grid, Row, Col } from "../../../../components/Grid";
import FiltersWrapper from "../../../../components/FiltersWrapper";
import Select from "../../../../components/Select";
import Module from "../../../../components/Module";
import ButtonsWrapper from "../../../../components/ButtonsWrapper";

import style from "./style.scss";

// The list of columns and their properties
export const columns = (
  onClickEdit,
  onClickDelete,
  canChangeImportExportRestriction,
  canDeleteImportExportRestriction
) => [
  {
    Header: "Country",
    accessor: "country.name"
  },
  {
    Header: "Import restrictions",
    accessor: "import_restrictions"
  },
  {
    Header: "Export restrictions",
    accessor: "export_restrictions"
  },
  {
    Header: "Additional information",
    accessor: "additional_information"
  },
  {
    Header: "Updated on",
    accessor: "last_update_date"
  },
  {
    Header: "Edit",
    Cell: props =>
      menu(props.row?.original.id, [
        {
          key: props.row?.original.id,
          icon: iconEdit,
          label: "Edit restriction",
          onClick: onClickEdit,
          visible: canChangeImportExportRestriction
        },
        {
          key: props.row?.original.id + 1,
          icon: iconDelete,
          label: "Delete restriction",
          onClick: onClickDelete,
          visible: canDeleteImportExportRestriction
        }
      ]),
    width: 40,
    show: canChangeImportExportRestriction || canDeleteImportExportRestriction
  }
];

// The ImportExportRestrictionsList component is used to display a list of Import Export Restrictions
const ImportExportRestrictionsList = ({
  importExportRestrictions,
  errorMessageImportExportRestrictions,
  totalItems,
  page,
  onPageChange,
  loadingImportExportRestrictions,
  countries,
  loadingFilters,
  onClickSearch,
  onClickEdit,
  onClickDelete,
  canChangeImportExportRestriction,
  canDeleteImportExportRestriction,
  onClickExport
}) => (
  <>
    {displayErrorMessage(errorMessageImportExportRestrictions)}
    <Module
      title="Import/Export Restrictions"
      filter={
        <ButtonsWrapper>
          <Button icon={iconDownloadGlyph} disabled={!importExportRestrictions.length} onClick={onClickExport}>
            Export
          </Button>
        </ButtonsWrapper>
      }
      table={
        <Table
          loading={loadingImportExportRestrictions}
          columns={columns(
            onClickEdit,
            onClickDelete,
            canChangeImportExportRestriction,
            canDeleteImportExportRestriction
          )}
          data={importExportRestrictions}
          totalItems={totalItems}
          page={page}
          onPageChange={onPageChange}
        />
      }
    >
      <FiltersWrapper>
        <Grid>
          <Row>
            <Col md={4}>
              <Field
                isMulti
                component={Select}
                name="countries"
                id="countries"
                label="Project Country"
                options={countries}
                loading={loadingFilters}
              />
            </Col>
            <Col md={1} className={style.searchButtonWrapper}>
              <Button onClick={onClickSearch}>Find</Button>
            </Col>
          </Row>
        </Grid>
      </FiltersWrapper>
    </Module>
  </>
);

// propTypes for the List component
ImportExportRestrictionsList.propTypes = {
  importExportRestrictions: PropTypes.array.isRequired,
  errorMessageImportExportRestrictions: PropTypes.string.isRequired,
  totalItems: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  loadingImportExportRestrictions: PropTypes.bool.isRequired,
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.any.isRequired
    })
  ),
  loadingFilters: PropTypes.bool.isRequired,
  onClickSearch: PropTypes.func.isRequired,
  onClickEdit: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  canChangeImportExportRestriction: PropTypes.bool.isRequired,
  canDeleteImportExportRestriction: PropTypes.bool.isRequired,
  onClickExport: PropTypes.func.isRequired
};

export default reduxForm({ form: "importExportRestrictionsFilters" })(ImportExportRestrictionsList);
