import React from "react";
import PropTypes from "prop-types";
import { reduxForm, Field } from "redux-form";

import Select from "../../../../components/Select";
import Input from "../../../../components/Input";
import SelectAsync from "../../../../components/SelectAsync";
import { Grid, Row, Col } from "../../../../components/Grid";
import FormWrapper from "../../../../components/FormWrapper";
import Modal from "../../../../components/Modal";
import Loading from "../../../../components/Loading";
import { getErrors, displayErrorMessage } from "../../../../utils";
import { normalizeNumber } from "../../../../utils/reduxForm";


// The IntakeContainerModal component is used to add an Intake/Container
const IntakeContainerModal = ({
  errorObject,
  containerTypes,
  loadPorts,
  isOpen,
  onValidate,
  onCancel,
  errorMessage,
  isLoading,
  specificCommodities,
  loadingRelatedEntities,
  isOnEdit
}) => (
  <Modal
    isOpen={isOpen}
    title="New Intake/Container"
    onValidate={onValidate}
    onCancel={onCancel}
    validateText="Save"
  >
    {displayErrorMessage(errorMessage)}
    <FormWrapper>
      <Grid>
        <Row>
          <Col md={6}>
            <Field
              component={Select}
              name="commodity"
              id="commodity"
              label="Commodity"
              options={specificCommodities}
              smallMenu
              isDisabled={isOnEdit}
              loading={loadingRelatedEntities}
              errorMessage={getErrors(errorObject, "commodity")}
            />
          </Col>
          <Col md={6}>
            <Field
              component={SelectAsync}
              name="load_port"
              id="load_port"
              label="Load port"
              loadOptions={loadPorts}
              smallMenu
              isDisabled={isOnEdit}
              errorMessage={getErrors(errorObject, "load_port")}
            />
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <Field
              component={Select}
              name="container_type"
              id="container_type"
              label="Container type"
              isDisabled={isOnEdit}
              options={containerTypes}
            />
          </Col>
          <Col md={3}>
            <Field
              component={Input}
              name="intake"
              id="intake"
              label="Intake (Mt)"
              normalize={(value) => normalizeNumber(value)}
              errorMessage={getErrors(errorObject, "intake")}
            />
          </Col>
        </Row>
      </Grid>
    </FormWrapper>
    <Loading isVisible={isLoading} />
  </Modal>
);

// propTypes for the Modal component
IntakeContainerModal.propTypes = {
  errorObject: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onValidate: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  loadPorts: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
  containerTypes: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  specificCommodities: PropTypes.array.isRequired,
  loadingRelatedEntities: PropTypes.bool.isRequired,
  isOnEdit: PropTypes.bool.isRequired
};

export default reduxForm({ form: "intakeContainerForm", enableReinitialize: true })(IntakeContainerModal);