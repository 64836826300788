import React from "react";
import PropTypes from "prop-types";
import { fieldInputPropTypes } from "redux-form";

import { displayErrorHelpText } from "../../utils";

import style from "./style.scss";


// A wrapper of the TextArea component form WFP UI Kit
const InputMultiline = ({ id, label, placeholder, errorMessage, maxLength, input: { value, onFocus, onChange } }) => (
  <div className="wfp--form-item">
    {
      label
        ? <label htmlFor={id} className="wfp--label">{label}</label>
        : ""
    }
    <div className={style.wrapper}>
      <textarea
        value={value}
        onFocus={onFocus}
        onChange={onChange}
        id={id}
        placeholder={placeholder}
        className="wfp--text-area"
        rows="4"
        cols="50"
        maxLength={maxLength}
      />
    </div>
    {displayErrorHelpText(errorMessage)}
  </div>
);

// propTypes for the InputMultiline component
InputMultiline.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  input: PropTypes.shape(fieldInputPropTypes).isRequired,
  errorMessage: PropTypes.string,
  maxLength: PropTypes.number
};

// defaultProps for the Input component
InputMultiline.defaultProps = {
  type: "text",
  label: "",
  placeholder: "",
  helperText: "",
  cssClass: "",
  errorMessage: "",
  maxLength: undefined
};

export default InputMultiline;
