import React from "react";
import PropTypes from "prop-types";

import { displayErrorMessage } from "../../../../utils";
import { columns, AdditionalInformation } from "../../../../utils/ipfs";
import Module from "../../../../components/Module";
import Table from "../../../../components/Table";


// The MyIpfList component is used to display a list of IPFs
const MyIpfList = ({
  ipfs,
  errorMessageIpfs,
  totalItems,
  page,
  onPageChange,
  loadingIpfs,
  onClickDetail
}) => (
  <>
    {displayErrorMessage(errorMessageIpfs)}
    <Module
      title="Your IPFs"
      table={
        <Table
          loading={loadingIpfs}
          columns={columns(onClickDetail, true)}
          data={ipfs}
          totalItems={totalItems}
          page={page}
          onPageChange={onPageChange}
          subComponent={{ component: AdditionalInformation, dataKey: "additional_information" }}
        />
      }
    >
    </Module>
  </>
);

// propTypes for the MyIpfList component
MyIpfList.propTypes = {
  ipfs: PropTypes.array.isRequired,
  errorMessageIpfs: PropTypes.string.isRequired,
  totalItems: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  loadingIpfs: PropTypes.bool.isRequired,
  onClickDetail: PropTypes.func.isRequired
};

export default MyIpfList;