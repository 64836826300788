import React from "react";
import PropTypes from "prop-types";

import style from "./style.scss";


// A wrappers that align items on center horizontal
const AlignCenterHorizontal = ({ children }) => (
  <div className={style.wrapper}>
    {children}
  </div>
);

// propTypes for the AlignCenterHorizontal component
AlignCenterHorizontal.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AlignCenterHorizontal;