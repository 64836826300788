import React from "react";
import PropTypes from "prop-types";
import { iconCheckmark } from "@wfp/icons";

import Modal from "../Modal";
import Icon from "../Icon";

import style from "./style.scss";


// A component used tho show a successful message
const ModalSuccess = ({
  isOpen,
  onValidate,
  title,
  text
}) => (
  <Modal
    isOpen={isOpen}
    title={title}
    onValidate={onValidate}
    hideCancelButton
    validateText="Close"
  >
    <div className={style.icon}>
      <Icon
        icon={iconCheckmark}
        width="120"
        height="120"
      />
    </div>
    <div className={style.text}>
      {text}
    </div>
  </Modal>
);

// propTypes for the ModalSuccess component
ModalSuccess.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onValidate: PropTypes.func.isRequired,
  title: PropTypes.string,
  text: PropTypes.string
};

// defaultProps for the ModalSuccess component
ModalSuccess.defaultProps = {
  title: undefined
};

export default ModalSuccess;