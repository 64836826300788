import React from "react";
import PropTypes from "prop-types";
import ReactSelect from "react-select";
import { fieldInputPropTypes } from "redux-form";

import { displayErrorHelpText } from "../../utils";

import style from "./style.scss";


// A wrapper of the ReactSelect with WFP UI Kit styles
const Select = ({
  id,
  label,
  options,
  isMulti,
  loading,
  placeholder,
  smallMenu,
  errorMessage,
  isDisabled,
  input: { value, onFocus, onChange, onInputChange }
}) => (
  <div className="wfp--form-item">
    <label htmlFor={id} className="wfp--label">{label}</label>
    <div className={style.wrapper}>
      <ReactSelect
        value={value}
        onFocus={onFocus}
        onChange={onChange}
        onInputChange={onInputChange}
        className="wfp--react-select-container"
        classNamePrefix="wfp--react-select"
        id={id}
        options={options}
        isMulti={isMulti}
        isLoading={loading}
        placeholder={placeholder}
        maxMenuHeight={smallMenu ? "140" : undefined}
        isDisabled={isDisabled}
        isClearable
      />
    </div>
    {displayErrorHelpText(errorMessage)}
  </div>
);

// propTypes for the Select component
Select.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.any,
    label: PropTypes.any
  })),
  input: PropTypes.shape(fieldInputPropTypes).isRequired,
  isMulti: PropTypes.bool,
  loading: PropTypes.bool,
  placeholder: PropTypes.string,
  smallMenu: PropTypes.bool,
  errorMessage: PropTypes.string,
  isDisabled: PropTypes.bool
};

// defaultProps for the Select component
Select.defaultProps = {
  label: "",
  isMulti: false,
  smallLabel: false,
  loading: false,
  placeholder: "",
  smallMenu: false,
  errorMessage: "",
  isDisabled: false
};

export default Select;
