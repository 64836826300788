// Actions available to be used by the generic errors reducer
export const ERRORS_ = "ERRORS_";


// Action creator to set a a list of errors to be used to display messages in a form
export const errors = element => data => {
  return {
    type: `${ERRORS_}${element}`,
    data
  };
};