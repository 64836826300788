import React from "react";
import PropTypes from "prop-types";
import { iconWfpHumResourcingPos, iconDownload } from "@wfp/icons";

import { displayErrorMessage, formatDateDefault } from "@utils";
import { smallHeader, centeredHeader, twoLineCell, tagCell, alignRightCell } from "@utils/table";
import Table from "@components/Table";
import Icon, { ClockIcon } from "@components/Icon";
import Module from "@components/Module";
import Tag from "@components/Tag";
import IconTooltip from "@components/IconTooltip";
import { Grid, Row, Col } from "@components/Grid";
import MessageWarning from "@components/MessageWarning";
import { stringOrDash, dateToString, isEmpty, formatNumber } from "@utils";
import { DATE_FORMAT_STANDARD, DATE_FORMAT_DEFAULT, DATE_FORMAT_TIMESTAMP, JUSTIFICATION_OPTIONS } from "@constants";

import style from "./style.scss";
import {formatNumberWithDecimalParts} from "../../../../utils";

// The list of columns and their properties
export const columns = () => [
  {
    Header: () => null,
    id: 'expander',
    Cell: props => 
      alignRightCell(<div id="expander" className={props.arrowBox} {...props.row?.getToggleRowExpandedProps()}>
        <div className={props.arrowStyle}></div>
      </div>)
  },
  {
    Header: centeredHeader("Delivery"),
    id: "Delivery",
    columns: [
      {
        Header: smallHeader("Named place"),
        id: "Named place",
        Cell: props => twoLineCell(props.row?.original.incoterm_place_name, props.row?.original.incoterm_place_country)
      },
      {
        Header: smallHeader("Incoterm"),
        id: "Incoterm",
        accessor: "incoterm_code",
        width: 70
      },
      {
        Header: smallHeader("Source"),
        id: "Source",
        Cell: props => tagCell(props.row?.original.source_type.description),
        width: 120
      },
      {
        Header: smallHeader("Packaging"),
        id: "Packaging",
        accessor: "packaging_type.description",
        width: 70
      }
    ]
  },
  {
    Header: centeredHeader("Costs", "US$ / Mt"),
    id: "Costs",
    columns: [
      {
        Header: smallHeader("Commodity price"),
        id: "Commodity price",
        Cell: props => alignRightCell(props.row?.original.commodity_cost),
        width: 70
      },
      {
        Header: smallHeader("Shipping rate"),
        id: "Shipping rate",
        Cell: props => alignRightCell(props.row?.original.ocean_transportation_cost),
        width: 70
      },
      {
        Header: smallHeader("Port/ Handling/ Other"),
        id: "Port/ Handling/ Other",
        Cell: props => alignRightCell(props.row?.original.port_handling_other_cost),
        width: 90
      },
      {
        Header: smallHeader("Landside rate"),
        id: "Landside rate",
        Cell: props => alignRightCell(props.row?.original.overland_transportation_cost),
        width: 70
      },
      {
        Header: smallHeader("Quality & Quantity"),
        id: "Quality & Quantity",
        Cell: props => alignRightCell(props.row?.original.quality_and_quantity),
        width: 70
      }
    ]
  },
  {
    Header: smallHeader("Total costs", "US$ / Mt"),
    id: "Total costs",
    Cell: props => alignRightCell(props.row?.original.total_cost),
    width: 100
  },
  {
    Header: centeredHeader("Lead times", "days"),
    id: "Lead times",
    columns: [
      {
        Header: smallHeader("Technical"),
        id: "Technical",
        Cell: props => alignRightCell(props.row?.original.processing_time),
        width: 60
      },
      {
        Header: smallHeader("Shipping/ Discharge"),
        id: "Shipping/ Discharge",
        Cell: props => alignRightCell(props.row?.original.port_time),
        width: 60
      },
      {
        Header: smallHeader("Landside"),
        id: "Landside",
        Cell: props => alignRightCell(props.row?.original.overland_time),
        width: 60
      }
    ]
  },
  {
    Header: smallHeader("Total time", "days"),
    id: "Total time",
    Cell: props => alignRightCell(props.row?.original.total_time),
    width: 100
  }
];

// Here we generate the grant information tooltip content
export const generateGrantInformation = grant =>
  grant && (
    <div>
      <span className={style.grantTitle}>{`${grant.code} - ${grant.description}`}</span>
      <span className={style.grantLabel}>Remarks</span>
      <span className={style.grantDescription}>{stringOrDash(grant.general_remark)}</span>
      <span className={style.grantLabel}>Purchase restrictions</span>
      <span className={style.grantDescription}>{stringOrDash(grant.purchase_restriction)}</span>
      <span className={style.grantLabel}>Purchase restrictions remarks</span>
      <span className={style.grantDescription}>{stringOrDash(grant.purchase_restriction_remarks)}</span>
      <span className={style.grantLabel}>TOD</span>
      <span className={style.grantDescription}>
        {stringOrDash(grant.tod && dateToString(grant.tod, DATE_FORMAT_STANDARD, DATE_FORMAT_DEFAULT))}
      </span>
    </div>
  );

// A component used to display additional information in a expand section
export const AdditionalInformation = ({ total_ipf_cost, best_time_option, best_cost_option, packaging_note }) => (
  <div className={style.additionalInformation}>
    {best_cost_option ? (
      <Icon icon={iconWfpHumResourcingPos} width="20" height="20" style={{ padding: "0rem 0.4rem 0rem 0rem" }} />
    ) : (
      undefined
    )}
    {best_time_option ? (
      <ClockIcon name="light" width="20" height="20" style={{ padding: "0rem 0.4rem 0rem 0rem" }} />
    ) : (
      undefined
    )}
    <span className={style.totalIpfCost}>US$ {total_ipf_cost}</span>
    <span className={style.totalIpfCostSubtitle}>Total IPF Cost (US$)</span>
    <table>
      <tbody>
        <tr>
          <td>Commodity Notes</td>
          <td>{packaging_note}</td>
        </tr>
      </tbody>
    </table>
  </div>
);

// propTypes for the AdditionalInformation component
AdditionalInformation.propTypes = {
  total_ipf_cost: PropTypes.string,
  best_time_option: PropTypes.bool,
  best_cost_option: PropTypes.bool,
  packaging_note: PropTypes.string
};

// defaultProps for the AdditionalInformation component
AdditionalInformation.defaultProps = {
  total_ipf_cost: "",
  best_time_option: false,
  best_cost_option: false,
  packaging_note: ""
};

// A function to show different messages to user based on mandatory fields
export const showMessage = (valuesAboveIpp, justifications) =>
  valuesAboveIpp.percentage !== "0" ? (
    <>
      <MessageWarning noSpaceAfter>
        The most cost-effective option has not been chosen. Purchase is{" "}
        <span className={style.valuesAboveIpp}>{valuesAboveIpp.percentage}%</span> {" above IPP "}
        <span className={style.valuesAboveIpp}>(US$ {valuesAboveIpp.value})</span>.
      </MessageWarning>
      {justifications && (
        <table className={style.details}>
          <tbody>
            <tr>
              <td>Justification</td>
              <td>
                {justifications.map((justification, index) => {
                  const justificationText = JUSTIFICATION_OPTIONS.find(option => option.value === justification).text;
                  return justifications.length > 1 ? (
                    <span className={style.justification} key={`justification-${justification}`}>
                      {`${index + 1}) ${justificationText}${index + 1 === justifications.length ? "." : ", "}`}
                    </span>
                  ) : (
                    <span key={`justification-${justification}`} className={style.justification}>
                      {justificationText}
                    </span>
                  );
                })}
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </>
  ) : (
    undefined
  );

// A component used to display a list of history items
export const History = ({ items }) => (
  <>
    {items.map((item, index) => (
      <HistoryItem
        key={index}
        action={item.to_state_label}
        author={item.user.full_name}
        date={dateToString(item.timestamp, DATE_FORMAT_TIMESTAMP, DATE_FORMAT_DEFAULT)}
        comments={item.comments}
      />
    ))}
  </>
);

// propTypes for the History component
History.propTypes = {
  items: PropTypes.array
};

// defaultProps for the HistoryItem component
History.defaultProps = {
  items: []
};

// A component used to display a status change to compose the history
export const HistoryItem = ({ action, author, date, comments }) => (
  <div className={style.historyItem}>
    <div className={style.historyItemBalloon} />
    <p className={style.historyItemAction}>
      <span>{action}</span> by {author}
    </p>
    <p className={style.historyItemDate}>on {date}</p>
    {comments && <p className={style.historyItemComments}>{comments}</p>}
  </div>
);

// propTypes for the HistoryItem component
HistoryItem.propTypes = {
  action: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  comments: PropTypes.string
};

// defaultProps for the HistoryItem component
HistoryItem.defaultProps = {
  comment: ""
};

// The Form component allows user to select a specific commodity from a list
const Detail = ({
  ipf,
  importExportRestriction,
  errorMessage,
  chosenPurchaseOptions,
  otherPurchaseOptions,
  valuesAboveIpp
}) => {
  const hasProperty = (obj, property) => (obj && obj.hasOwnProperty(property) ? obj[property] : "–");

  return (
    <>
      <Module>
        {displayErrorMessage(errorMessage)}
        <div className={style.state}>
          <span className={style.stateLabel}>Current status</span>
          <span className={style.stateTag}>
            <Tag>{ipf.state_label}</Tag>
          </span>
        </div>
        <Grid>
          <Row>
            <Col md={7}>
              {ipf.is_gcmf ? (
                <table className={style.details}>
                  <tbody>
                    <tr>
                      <td>Commodity</td>
                      <td>{ipf.commodity && ipf.commodity && ipf.commodity.description}</td>
                    </tr>
                    <tr>
                      <td>Quantity (Mt)</td>
                      <td>{ipf.quantity && formatNumberWithDecimalParts(ipf.quantity)}</td>
                    </tr>
                    <tr>
                      <td>GCMF supply line</td>
                      <td>{ipf.gcmf_zone && ipf.gcmf_zone.name}</td>
                    </tr>
                    <tr>
                      <td>GCMF corridor</td>
                      <td>{ipf.gcmf_subzone && ipf.gcmf_subzone.name}</td>
                    </tr>
                    <tr>
                      <td>WBS element</td>
                      <td>{ipf.gcmf_subzone && ipf.gcmf_subzone.wbs}</td>
                    </tr>
                    <tr>
                      <td>Final destination country</td>
                      <td>
                        {ipf.destination_point && ipf.destination_point.country && ipf.destination_point.country.name}
                      </td>
                    </tr>
                    <tr>
                      <td>Discharge port</td>
                      <td>
                        {ipf.discharge_port && ipf.discharge_port && ipf.discharge_port.name}{" "}
                        <span className={style.rta}>RTA</span>{" "}
                        {(ipf.rta_discharge_port && formatDateDefault(ipf.rta_discharge_port)) || "-"}
                      </td>
                    </tr>
                    <tr>
                      <td>Final destination point</td>
                      <td>
                        {ipf.destination_point && ipf.destination_point && ipf.destination_point.name}{" "}
                        <span className={style.rta}>RTA</span>{" "}
                        {(ipf.rta_destination_point && formatDateDefault(ipf.rta_destination_point)) || "-"}
                      </td>
                    </tr>
                    <tr>
                      <td>Smallholder farmer</td>
                      <td>{ipf.small_holder_farmer ? "Yes" : "No"}</td>
                    </tr>
                    <tr>
                      <td>GMO</td>
                      <td>{ipf.gmo_commodity ? "Yes" : "No"}</td>
                    </tr>
                    <tr>
                      <td>Import restrictions</td>
                      <td>{hasProperty(importExportRestriction, "import_restrictions") || "–"}</td>
                    </tr>
                    <tr>
                      <td>Export restrictions</td>
                      <td>{hasProperty(importExportRestriction, "export_restrictions") || "–"}</td>
                    </tr>
                    <tr>
                      <td>Additional information</td>
                      <td>{hasProperty(importExportRestriction, "additional_information") || "–"}</td>
                    </tr>
                  </tbody>
                </table>
              ) : (
                <table className={style.details}>
                  <tbody>
                    <tr>
                      <td>Commodity</td>
                      <td>{ipf.commodity && ipf.commodity && ipf.commodity.description}</td>
                    </tr>
                    <tr>
                      <td>Quantity (Mt)</td>
                      <td>{ipf.quantity && formatNumberWithDecimalParts(ipf.quantity)}</td>
                    </tr>
                    <tr>
                      <td>Project country</td>
                      <td>{ipf.recipient_country && ipf.recipient_country.name}</td>
                    </tr>
                    <tr>
                      <td>Project CPB</td>
                      <td>{ipf.project && ipf.project.title}</td>
                    </tr>
                    <tr>
                      <td>WBS element</td>
                      <td>{ipf.wbs && ipf.wbs.code}</td>
                    </tr>
                    <tr>
                      <td>Final destination country</td>
                      <td>
                        {ipf.destination_point && ipf.destination_point.country && ipf.destination_point.country.name}
                      </td>
                    </tr>
                    <tr>
                      <td>Discharge port</td>
                      <td>
                        {ipf.discharge_port && ipf.discharge_port && ipf.discharge_port.name}{" "}
                        <span className={style.rta}>RTA</span>{" "}
                        {(ipf.rta_discharge_port && formatDateDefault(ipf.rta_discharge_port)) || "-"}
                      </td>
                    </tr>
                    <tr>
                      <td>Final destination point</td>
                      <td>
                        {ipf.destination_point && ipf.destination_point && ipf.destination_point.name}{" "}
                        <span className={style.rta}>RTA</span>{" "}
                        {(ipf.rta_destination_point && formatDateDefault(ipf.rta_destination_point)) || "-"}
                      </td>
                    </tr>
                    <tr>
                      <td>Grant ID</td>
                      <td>
                        {!isEmpty(ipf.grant) && (
                          <>
                            {ipf.grant.code} <IconTooltip html={generateGrantInformation(ipf.grant)} />
                          </>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Smallholder farmer</td>
                      <td>{ipf.small_holder_farmer ? "Yes" : "No"}</td>
                    </tr>
                    <tr>
                      <td>GMO</td>
                      <td>{ipf.gmo_commodity ? "Yes" : "No"}</td>
                    </tr>
                    <tr>
                      <td>Import restrictions</td>
                      <td>{hasProperty(importExportRestriction, "import_restrictions") || "–"}</td>
                    </tr>
                    <tr>
                      <td>Export restrictions</td>
                      <td>{hasProperty(importExportRestriction, "export_restrictions") || "–"}</td>
                    </tr>
                    <tr>
                      <td>Additional information</td>
                      <td>{hasProperty(importExportRestriction, "additional_information") || "–"}</td>
                    </tr>
                  </tbody>
                </table>
              )}
              <span className={style.attachmentsTitle}>Attachments</span>
              {isEmpty(ipf.attachments) ? (
                <span>No attachment found</span>
              ) : (
                ipf.attachments.map(attachment => (
                  <a
                    className={style.attachmentFile}
                    key={attachment.filename}
                    href={attachment.url}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <Icon icon={iconDownload} width="26" height="26" style={{ padding: "0rem 0.6rem 0rem 0rem" }} />
                    <span>{attachment.filename}</span>
                  </a>
                ))
              )}
            </Col>
            <Col md={5}>
              <History items={ipf.history} />
            </Col>
          </Row>
        </Grid>
      </Module>
      {chosenPurchaseOptions.length ? (
        <Module
          title="Chosen options"
          table={
            <Table
              loading={false}
              columns={columns()}
              data={chosenPurchaseOptions}
              showPagination={false}
              pageSize={chosenPurchaseOptions.length}
              subComponent={{ component: AdditionalInformation, dataKey: "additional_information" }}
              expandAll
            />
          }
        >
          {showMessage(valuesAboveIpp, ipf.justifications)}
        </Module>
      ) : (
        <Module title="Chosen options">No data available</Module>
      )}
      {otherPurchaseOptions.length ? (
        <Module
          title="Other options"
          table={
            <Table
              loading={false}
              columns={columns()}
              data={otherPurchaseOptions}
              showPagination={false}
              pageSize={otherPurchaseOptions.length}
              subComponent={{ component: AdditionalInformation, dataKey: "additional_information" }}
              expandAll
            />
          }
        ></Module>
      ) : (
        <Module title="Other options">No data available</Module>
      )}
    </>
  );
};

// propTypes for the Form component
Detail.propTypes = {
  ipf: PropTypes.any.isRequired,
  importExportRestriction: PropTypes.object.isRequired,
  errorMessage: PropTypes.string.isRequired,
  chosenPurchaseOptions: PropTypes.array.isRequired,
  otherPurchaseOptions: PropTypes.array.isRequired,
  valuesAboveIpp: PropTypes.object.isRequired
};

export default Detail;
