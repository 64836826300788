import React from "react";
import PropTypes from "prop-types";
import { iconWfpHumEmergencyResponsePos } from "@wfp/icons";

import Icon from "../Icon";

import style from "./style.scss";


// A loading message shown over page content
const Loading = ({ children, isVisible }) => (
  <>
    {
      isVisible
        ? <div className={style.wrapper}>
          <div>
            <Icon
              icon={iconWfpHumEmergencyResponsePos}
              width="100"
              height="100"
              style={{ marginBottom: "2rem", transform: "rotate(10deg)" }}
            />
            <div>
              <h4>Please wait</h4>
              {children}
            </div>
          </div>
        </div>
        : undefined
    }
  </>
);

// propTypes for the FormGroup component
Loading.propTypes = {
  children: PropTypes.node.isRequired,
  isVisible: PropTypes.bool.isRequired
};

// defaultProps for the FormGroup component
Loading.defaultProps = {
  children: "Loading..."
};

export default Loading;
