// Actions available to be used by the generic current page reducer
export const ACTIVE_RECORD_ = "ACTIVE_RECORD_";


// Action creator to set the id of current on edit or to delete record
export const activeRecord = (element) => id => {
  return {
    type: `${ACTIVE_RECORD_}${element}`,
    id: id
  };
};