// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_wrapper_z2LjJruDHQeVte0\\+Ucng9Q\\=\\= > * + * {\n  margin-left: 1rem;\n}\n\n.style_wrapper_z2LjJruDHQeVte0\\+Ucng9Q\\=\\= > div > * + * {\n  margin-left: 1rem;\n}", "",{"version":3,"sources":["webpack://src/components/ButtonsWrapper/style.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;;AACA;EACE,iBAAA;AAEF","sourcesContent":[".wrapper > * + * {\n  margin-left: 1rem;\n}\n.wrapper > div > * + * {\n  margin-left: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "style_wrapper_z2LjJruDHQeVte0+Ucng9Q=="
};
export default ___CSS_LOADER_EXPORT___;
