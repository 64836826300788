// Actions available to be used by the generic show hide reducer
export const SHOW_ = "SHOW_";
export const HIDE_ = "HIDE_";

// Action creator to show the element
export const show = (element) => () => {
  return {
    type: `${SHOW_}${element}`
  };
};

// Action creator to hide the element
export const hide = (element) => () => {
  return {
    type: `${HIDE_}${element}`,
  };
};