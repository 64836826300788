import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { reset, change } from "redux-form";

import PageTabs from "@components/PageTabs";
import { adaptOptions, openUrl } from "@utils";
import AdministrationUserList from "./components/AdministrationUserList";
import AdministrationAccessRequestList from "./components/AdministrationAccessRequestList";
import {
  adminCurrentTab,
  userList,
  userListCurrentPage,
  userRolesList,
  userDivisionList,
  countryList,
  userSearchDefaultDetail,
  userSearchDefaultAdd,
  userSearchDefaultDetailReset,
  showUserListFiltersArea,
  hideUserListFiltersArea,
  accessRequestList,
  accessRequestsCurrentPage
} from "@actions";

const API_URL_DOWNLOAD_XLS_USER_LIST = `${process.env.API_BASE_URL}/api/accounts/xls/`;

export const adaptRoles = roles => {
  const roleList = [];
  roles.filter((role) => role.toLowerCase() !== "public").map(role => roleList.push({ label: role, value: role }));
  return roleList;
};

// The main page of the application
export class Administration extends Component {
  // On component mount, Users, my Users and related entities are loaded
  componentDidMount() {
    this.onPageChangeUserList = this.onPageChangeUserList.bind(this);
    this.onClickSearch = this.onClickSearch.bind(this);
    this.onClickDetail = this.onClickDetail.bind(this);
    this.onClickAdminTab = this.onClickAdminTab.bind(this);
    this.onClickAccessRequestsTab = this.onClickAccessRequestsTab.bind(this);
    this.onClickUserRequestDetail = this.onClickUserRequestDetail.bind(this);
    this.onClickResetFiltersForm = this.onClickResetFiltersForm.bind(this);
    this.onClickResetPreset = this.onClickResetPreset.bind(this);
    this.onClickShowHideFiltersArea = this.onClickShowHideFiltersArea.bind(this);
    this.onClickExportUserList = this.onClickExportUserList.bind(this);
    this.onPageChangeAccessRequests = this.onPageChangeAccessRequests.bind(this);
    this.props.countryList();
    this.props.userRolesList();
    this.props.userDivisionList();
    this.props.userSearchDefaultDetail().finally(() => this.filterUserListInitial());
    this.props.showUserListFiltersArea();
    this.filterAccessRequests(0);
  }

  // On component unmount store is reset where needed
  componentWillUnmount() {
    // this.props.adminCurrentTab(0);
    this.props.userListCurrentPage(0);
    this.props.accessRequestsCurrentPage(0);
    this.props.userSearchDefaultDetailReset();
  }

  // A method used to change the current tab
  changeCurrentTab(tab) {
    this.props.adminCurrentTab(tab);
  }

  // A method to filter by props and and given filter
  filterUserListInitial() {
    const { presetName, presetCountries, presetRoles, presetDivisions } = this.props;
    this.props.userListCurrentPage(0);

    this.props.userList(1, presetName, presetCountries, presetRoles, presetDivisions);
  }

  // A method to filter by props and and given filter
  filterUserList(page) {
    const { name, selectedCountries, selectedRoles, selectedDivisions } = this.props;
    this.props.userListCurrentPage(page);
    this.props.userList(page + 1, name, selectedCountries, selectedRoles, selectedDivisions);
  }

  filterAccessRequests(page) {
    this.props.accessRequestsCurrentPage(page);
    this.props.accessRequestList(page + 1);
  }

  // When user select a page we have to perform a request with given parameters
  onPageChangeUserList(page) {
    this.filterUserList(page);
  }

  onPageChangeAccessRequests(page) {
    this.filterAccessRequests(page);
  }

  // When user click search, Users will be filtered and the preset is saved, if user checked
  // the checkbox is set to false after the reset
  onClickSearch() {
    this.filterUserList(0);
    if (this.props.shouldSavePreset) {
      this.props.clearSavePreset();
      this.props.userSearchDefaultAdd(this.props.filterFormValues);
    }
  }

  // When user click reset preset, filters are reset backend side,
  // the checkbox is set to false after the reset,
  // defaults should be deleted from redux store to clear form initial values
  // and, after that, the form should be reset to initial values
  onClickResetPreset() {
    this.props.userSearchDefaultAdd({});
    this.props.userSearchDefaultDetailReset();
    this.props.resetFiltersForm();
  }

  // When user clicks view button it is redirected to the detail page
  onClickDetail(id) {
    this.props.history.push(`/userView/${id}`);
  }

  onClickUserRequestDetail(id) {
    this.props.history.push(`/accessRequestView/${id}`);
  }

  // On click reset defaults should be deleted from redux store to clear form initial values
  // and, after that, the form should be reset to initial values
  onClickResetFiltersForm() {
    this.props.userSearchDefaultDetailReset();
    this.props.resetFiltersForm();
  }

  // When user clicks show/hide all User filters the area is showed/hidden
  onClickShowHideFiltersArea() {
    if (this.props.isVisibleUserListFiltersArea) {
      this.props.hideUserListFiltersArea();
    } else {
      this.props.showUserListFiltersArea();
    }
  }

  onClickAdminTab() {
    this.changeCurrentTab(0);
  }
  onClickAccessRequestsTab() {
    this.changeCurrentTab(1);
  }

  // Data are exported as Excel file based on filters selected by user
  onClickExportUserList() {
    let parameters = [];
    parameters.push({ label: "countries", value: this.props.selectedCountries });
    parameters.push({ label: "roles", value: this.props.selectedRoles });
    parameters.push({ label: "name", value: this.props.name });
    parameters.push({ label: "divisions", value: this.props.selectedDivisions });
    openUrl(API_URL_DOWNLOAD_XLS_USER_LIST, parameters);
    return;
  }

  render() {
    const {
      currentTab,
      isFetchingUserList,
      isFetchingFilters,
      users,
      errorMessageFilters,
      errorMessageUserList,
      totalItems,
      userListPage,
      countries,
      divisions,
      userRoles,
      isLoadingPresets,
      initialFilters,
      isVisibleUserListFiltersArea,
      isFetchingAccessRequests,
      errorMessageAccessRequests,
      accessRequests,
      accessRequestsPage,
      accessRequestsTotalItems
    } = this.props;
    return (
      <>
        <PageTabs
          title="Admin"
          tabs={[
            {
              id: "users",
              label: "Users",
              onClick: this.onClickAdminTab
            },
            {
              id: "access_requests",
              label: "Access Requests",
              onClick: this.onClickAccessRequestsTab
            }
          ]}
          selected={currentTab}
        >
          <AdministrationUserList
            isVisible={currentTab === 0}
            loadingUsers={isFetchingUserList}
            loadingFilters={isFetchingFilters}
            errorMessageUsers={errorMessageUserList}
            errorMessageFilters={errorMessageFilters}
            users={users}
            roles={userRoles}
            countries={countries}
            divisions={divisions}
            totalItems={totalItems}
            page={userListPage}
            onPageChange={this.onPageChangeUserList}
            onClickSearch={this.onClickSearch}
            onClickDetail={this.onClickDetail}
            onClickReset={this.onClickResetFiltersForm}
            initialValues={initialFilters}
            onClickResetPreset={this.onClickResetPreset}
            loadingPresets={isLoadingPresets}
            onClickShowHideFiltersArea={this.onClickShowHideFiltersArea}
            isVisibleFiltersArea={isVisibleUserListFiltersArea}
            onClickExport={this.onClickExportUserList}
          />
          <AdministrationAccessRequestList
            isVisible={currentTab === 1}
            loadingAccessRequests={isFetchingAccessRequests}
            errorMessageAccessRequests={errorMessageAccessRequests}
            accessRequests={accessRequests}
            totalItems={accessRequestsTotalItems}
            page={accessRequestsPage}
            onPageChange={this.onPageChangeAccessRequests}
            onClickDetail={this.onClickUserRequestDetail}
          />
        </PageTabs>
      </>
    );
  }
}

// propTypes for the Home component
Administration.propTypes = {
  name: PropTypes.string.isRequired,
  currentTab: PropTypes.number.isRequired,
  adminCurrentTab: PropTypes.func.isRequired,
  isFetchingUserList: PropTypes.bool.isRequired,
  isFetchingFilters: PropTypes.bool.isRequired,
  users: PropTypes.array.isRequired,
  userList: PropTypes.func.isRequired,
  userRolesList: PropTypes.func.isRequired,
  userDivisionList: PropTypes.func.isRequired,
  userListPage: PropTypes.number.isRequired,
  userListCurrentPage: PropTypes.func.isRequired,
  errorMessageUserList: PropTypes.string.isRequired,
  errorMessageFilters: PropTypes.string.isRequired,
  totalItems: PropTypes.number.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }),
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.any.isRequired
    })
  ),
  userRoles: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.any.isRequired
    })
  ),
  divisions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.any.isRequired
    })
  ),
  countryList: PropTypes.func.isRequired,
  selectedCountries: PropTypes.array.isRequired,
  selectedRoles: PropTypes.array.isRequired,
  selectedDivisions: PropTypes.array.isRequired,
  userSearchDefaultDetail: PropTypes.func.isRequired,
  userSearchDefaultDetailReset: PropTypes.func.isRequired,
  presetCountries: PropTypes.array.isRequired,
  presetRoles: PropTypes.array.isRequired,
  presetDivisions: PropTypes.array.isRequired,
  presetName: PropTypes.string.isRequired,
  shouldSavePreset: PropTypes.bool.isRequired,
  clearSavePreset: PropTypes.func.isRequired,
  userSearchDefaultAdd: PropTypes.func.isRequired,
  resetFiltersForm: PropTypes.func.isRequired,
  isLoadingPresets: PropTypes.bool.isRequired,
  initialFilters: PropTypes.object.isRequired,
  filterFormValues: PropTypes.any.isRequired,
  isVisibleUserListFiltersArea: PropTypes.bool.isRequired,
  hideUserListFiltersArea: PropTypes.func.isRequired,
  showUserListFiltersArea: PropTypes.func.isRequired,
  isFetchingAccessRequests: PropTypes.bool.isRequired,
  errorMessageAccessRequests: PropTypes.string.isRequired,
  accessRequestList: PropTypes.func.isRequired,
  accessRequests: PropTypes.array.isRequired,
  accessRequestsPage: PropTypes.number.isRequired,
  accessRequestsCurrentPage: PropTypes.func.isRequired,
  accessRequestsTotalItems: PropTypes.number.isRequired
};

// Starting from the redux state it gets data related to User list
export const mapStateToProps = state => {
  const {
    form,
    adminCurrentTab,
    userSearchDefaultDetail,
    userSearchDefaultAdd,
    countryList,
    userDivisionList,
    userList,
    userRolesList,
    userListCurrentPage,
    userFiltersArea,
    accessRequestList,
    accessRequestsCurrentPage
  } = state;
  const { userFilters } = form;

  // A funcion to create an array of applied filters
  const createFiltersArray = (filters, property) => {
    const hasProperty = filters && filters.values && filters.values[property];
    let filterArray = [];
    if (hasProperty) filterArray = filters.values[property].map(element => element.value);
    return filterArray;
  };

  //A function that sets search preference
  const setPreferences = (filters, property, defaultValue) => {
    const hasProperty = filters && filters.data && filters.data.preferences && filters.data.preferences[property];
    let preferences = defaultValue;
    if (hasProperty) {
      const preferenceProp = filters.data.preferences[property];
      if (Array.isArray(preferenceProp)) {
        preferences = preferenceProp.map(element => element.value);
      } else if (typeof preferenceProp === "string") {
        preferences = preferenceProp;
      } else if (typeof preferenceProp === "object") {
        preferences = preferenceProp.data && preferenceProp.data.value;
      }
    }
    return preferences;
  };

  return {
    currentTab: adminCurrentTab.number,
    // Related entities properties
    countries: adaptOptions(countryList.data.results, "name", "name"),
    divisions: userDivisionList.data.divisions && userDivisionList.data.divisions.map((item, idx) => ({value: item, key: idx, label: item })),
    userRoles: adaptRoles(userRolesList.data.results),
    isFetchingFilters: countryList.isFetching || userRolesList.isFetching || userDivisionList.isFetching,
    errorMessageFilters: countryList.errorMessage,
    // User List properties
    users: userList.data.results,
    isFetchingUserList: userList.isFetching,
    errorMessageUserList: userList.errorMessage,
    // Table management of all users
    totalItems: userList.data.count || 0,
    userListPage: userListCurrentPage.number,
    // Selected filters management
    isVisibleUserListFiltersArea: userFiltersArea.isVisible,
    filterFormValues: (form && userFilters && { ...userFilters.values, savePreset: false }) || {},
    selectedCountries: createFiltersArray(userFilters, "countries"),
    selectedRoles: createFiltersArray(userFilters, "roles"),
    selectedDivisions: createFiltersArray(userFilters, "divisions"),
    name: (userFilters && userFilters.values && userFilters.values.name) || "",
    // Save preset options, filters form values and user default settings
    shouldSavePreset: (form && userFilters && userFilters.values && userFilters.values.savePreset) || false,
    isLoadingPresets: userSearchDefaultDetail.isFetching || userSearchDefaultAdd.isLoading,
    initialFilters:
      (userSearchDefaultDetail && userSearchDefaultDetail.data && userSearchDefaultDetail.data.preferences) || {},
    presetName: setPreferences(userSearchDefaultDetail, "name", ""),
    presetCountries: setPreferences(userSearchDefaultDetail, "countries", []),
    presetRoles: setPreferences(userSearchDefaultDetail, "roles", []),
    presetDivisions: setPreferences(userSearchDefaultDetail, "divisions", []),
    //User access requests properties
    isFetchingAccessRequests: accessRequestList.isFetching,
    errorMessageAccessRequests: accessRequestList.errorMessage,
    accessRequests: accessRequestList.data.results,
    accessRequestsPage: accessRequestsCurrentPage.number,
    accessRequestsTotalItems: accessRequestList.data.count || 0
  };
};

// Maps functions to dispatch actions
export const mapDispatchToProps = dispatch => {
  return {
    adminCurrentTab: number => dispatch(adminCurrentTab(number)),
    userList: (page, name, countries, roles, divisions) =>
      dispatch(
        userList({
          page,
          name,
          countries,
          roles,
          org_unit_description: divisions
        })
      ),
    userListCurrentPage: number => dispatch(userListCurrentPage(number)),
    countryList: () => dispatch(countryList()),
    userDivisionList: () => dispatch(userDivisionList()),
    userRolesList: () => dispatch(userRolesList()),
    resetFiltersForm: () => dispatch(reset("userFilters")),
    clearSavePreset: () => dispatch(change("userFilters", "savePreset", false)),
    userSearchDefaultDetail: () => dispatch(userSearchDefaultDetail("user")),
    userSearchDefaultAdd: data => dispatch(userSearchDefaultAdd({ search_type: "user", preferences: data })),
    userSearchDefaultDetailReset: () => dispatch(userSearchDefaultDetailReset()),
    showUserListFiltersArea: () => dispatch(showUserListFiltersArea()),
    hideUserListFiltersArea: () => dispatch(hideUserListFiltersArea()),
    accessRequestList: page => dispatch(accessRequestList({ page })),
    accessRequestsCurrentPage: number => dispatch(accessRequestsCurrentPage(number))
  };
};

// The form is added to the redux store with a given name
export default connect(mapStateToProps, mapDispatchToProps)(Administration);
