// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_wrapper_CMefvv7i8oMFhPUl1IqsLA\\=\\= {\n  padding: 2rem;\n  text-align: center;\n}", "",{"version":3,"sources":["webpack://src/containers/NotFound/style.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kBAAA;AACF","sourcesContent":[".wrapper {\n  padding: 2rem;\n  text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "style_wrapper_CMefvv7i8oMFhPUl1IqsLA=="
};
export default ___CSS_LOADER_EXPORT___;
