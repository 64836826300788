import { RESOURCE_ADD_WITH_RESULT_REQUEST_, RESOURCE_ADD_WITH_RESULT_SUCCESS_, RESOURCE_ADD_WITH_RESULT_ERROR_, RESOURCE_ADD_WITH_RESULT_RESET_ } from "../actions/resourceAddWithResult";


// A generic reducer used to add a resource
const resourceAddWithResult = resource => (state = { isLoading: false, data: {}, error: {} }, action) => {
  switch (action.type) {
  case `${RESOURCE_ADD_WITH_RESULT_REQUEST_}${resource}`:
    return { ...state, isLoading: true, data: {}, error: {} };
  case `${RESOURCE_ADD_WITH_RESULT_SUCCESS_}${resource}`:
    return { ...state, isLoading: false, data: action.data, error: {} };
  case `${RESOURCE_ADD_WITH_RESULT_ERROR_}${resource}`:
    return { ...state, isLoading: false, data: {}, error: action.error };
  case `${RESOURCE_ADD_WITH_RESULT_RESET_}${resource}`:
    return { ...state, isLoading: false, data: {}, error: {} };
  default:
    return state;
  }
};

export default resourceAddWithResult;