import React from "react";
import PropTypes from "prop-types";

import Button from "../components/Button";
import { tagCell } from "./table";
import { alignRightCell } from "@utils/table";

import style from "./style.scss";

// A function to render the right button based on some parameters
export const generateButton = (row, isMyIpfList, onClickButton) => {
  const statuses = ["draft", "draft_endorsed", "draft_cleared"];
  if (isMyIpfList && statuses.indexOf(row?.original.status.value) !== -1) {
    return generateEditButton(row?.original.id, onClickButton);
  }
  return generateViewButton(row?.original.id, onClickButton);
};

// A component to render a button inside the list to view the IPF Detail
export const generateViewButton = (id, onClick) => (
  <Button small onClick={() => onClick(id)}>
    View
  </Button>
);

// A component to render a button inside the list to edit the IPF
export const generateEditButton = (id, onClick) => (
  <Button small onClick={() => onClick(id)}>
    Edit
  </Button>
);

// The list of columns and their properties
export const columns = (onClickButton, isMyIpfList) => [
    {
    Header: () => null,
    id: 'expander',
    Cell: props => 
      alignRightCell(<div id="expander" className={props.arrowBox} {...props.row?.getToggleRowExpandedProps()}>
        <div className={props.arrowStyle}></div>
      </div>)
  },
  {
    Header: "Country/Supply line",
    accessor: "country_zone.name",
    width: 160
  },
  {
    Header: "Date",
    accessor: "submission_date",
    width: 90
  },
  {
    Header: "Commodity",
    accessor: "commodity.description",
    width: 150
  },
  {
    Header: "Quantity/Mt",
    accessor: "quantity",
    width: 105
  },
  {
    Header: "Value (US$)",
    accessor: "value",
    width: 110
  },
  {
    Header: "Source",
    Cell: props => tagCell(props.row?.original.source.description),
    width: 120
  },
  {
    Header: "Status",
    Cell: props => props && props.row && props.row.original.is_partial? "Draft": props.row?.original.state_label || null,
    width: 110
  },
  {
    Header: "IPF ID",
    accessor: "ipf_id",
    width: 175
  },
  {
    Header: "Button",
    width: 90,
    Cell: props => generateButton(props.row, isMyIpfList, onClickButton)
  }
];

// A component used to display additional information in a expand section
export const AdditionalInformation = ({ project_cpb, wbs_code }) => (
  <div className={style.additionalInformation}>
    <table>
      <tbody>
        <tr>
          <td>Project/CPB</td>
          <td>{project_cpb}</td>
        </tr>
        <tr>
          <td>WBS element</td>
          <td>{wbs_code}</td>
        </tr>
      </tbody>
    </table>
  </div>
);

// propTypes for the AdditionalInformation component
AdditionalInformation.propTypes = {
  project_cpb: PropTypes.string,
  wbs_code: PropTypes.string
};

// defaultProps for the AdditionalInformation component
AdditionalInformation.defaultProps = {
  project_cpb: "",
  wbs_code: ""
};
