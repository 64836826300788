import React from "react";
import PropTypes from "prop-types";

import { displayErrorMessage } from "@utils";
import Tag from "@components/Tag";
import Module from "@components/Module";
import Link from "@components/Link";
import { Grid, Row, Col } from "@components/Grid";

import style from "./style.scss";

const generateRoleTags = roles => roles.map(role => <Tag key={role.replace(/ /g, "")}>{role}</Tag>);
const generateRequestList = (requests, onClick) =>
  requests.map((request, id) => (
    <li className={style.link} key={request}>
      <Link key={request} onClick={() => onClick(request)}>{`Access Request ${id + 1}`}</Link>
    </li>
  ));

const AdministrationUserDetail = ({ user, errorMessage, onClickAccessRequest }) => {
  return (
    <Module>
      {displayErrorMessage(errorMessage)}
      <Grid>
        <Row>
          <Col md={7}>
            <table className={style.details}>
              <tbody>
                <tr>
                  <td>First Name</td>
                  <td>{user.first_name || "–"}</td>
                </tr>
                <tr>
                  <td>Last Name</td>
                  <td>{user.last_name || "–"}</td>
                </tr>
                <tr>
                  <td>Username/Email Address</td>
                  <td>{user.username}</td>
                </tr>
                <tr>
                  <td>Country</td>
                  <td>{user.country && user.country.name ? user.country.name : "–"}</td>
                </tr>
                <tr>
                  <td>Division</td>
                  <td className={style.wrap}>
                    {user && user.org_unit_description?
                      user.org_unit_description.split(",").map((item, idx) => <Tag key={idx}>{item}</Tag>) :
                      user.divisions && user.divisions.length > 0
                        ? user.divisions.map(division => <Tag key={division.id}>{division.name}</Tag>)
                        : "–"}
                  </td>
                </tr>
                <tr>
                  <td>Roles</td>
                  <td className={style.wrap}>{user.roles ?
                    generateRoleTags(user.roles.filter((role) => role.toLowerCase() !== "public")) : "–"}
                  </td>
                </tr>
                <tr>
                  <td>Access Request</td>
                  <td className={style.wrap}>
                    <ul>
                      {user.user_request && user.user_request.length > 0
                        ? generateRequestList(user.user_request, onClickAccessRequest)
                        : "–"}
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>
      </Grid>
    </Module>
  );
};

AdministrationUserDetail.propTypes = {
  user: PropTypes.any.isRequired,
  errorMessage: PropTypes.string.isRequired,
  onClickAccessRequest: PropTypes.func.isRequired
};

export default AdministrationUserDetail;
