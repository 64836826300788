// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_smallLabel_PmACJ-9HWd3vPq1K0cywww\\=\\= {\n  font-family: \"Open Sans\", sans-serif;\n  font-size: 0.7rem;\n  color: #031c2d;\n  font-weight: 600;\n  display: inline-block;\n  vertical-align: baseline;\n  margin-top: 0.1rem;\n  margin-bottom: 0.6rem;\n}\n\n.style_wrapper_hY5WgipxbahnfgwbUtKt4w\\=\\= {\n  width: 100%;\n}", "",{"version":3,"sources":["webpack://src/components/Select/style.scss"],"names":[],"mappings":"AAAA;EACE,oCAAA;EACA,iBAAA;EACA,cAAA;EACA,gBAAA;EACA,qBAAA;EACA,wBAAA;EACA,kBAAA;EACA,qBAAA;AACF;;AAEA;EACE,WAAA;AACF","sourcesContent":[".smallLabel {\n  font-family: \"Open Sans\",sans-serif;\n  font-size: 0.7rem;\n  color: #031c2d;\n  font-weight: 600;\n  display: inline-block;\n  vertical-align: baseline;\n  margin-top: .1rem;\n  margin-bottom: .6rem;\n}\n\n.wrapper {\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"smallLabel": "style_smallLabel_PmACJ-9HWd3vPq1K0cywww==",
	"wrapper": "style_wrapper_hY5WgipxbahnfgwbUtKt4w=="
};
export default ___CSS_LOADER_EXPORT___;
