import React from "react";
import PropTypes from "prop-types";

import Modal from "../Modal";
import Loading from "../Loading";
import { displayErrorMessage } from "../../utils";


// A component used tho show a confirmation message to delete a Commodity Price
const ModalConfirm = ({
  isOpen,
  onValidate,
  onCancel,
  errorMessage,
  isLoading,
  title,
  text
}) => (
  <Modal
    isOpen={isOpen}
    title={title}
    onValidate={onValidate}
    onCancel={onCancel}
    validateText="Confirm"
  >
    {displayErrorMessage(errorMessage)}
    {text}
    <Loading isVisible={isLoading} />
  </Modal>
);

// propTypes for the Modal component
ModalConfirm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onValidate: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.any.isRequired
};

export default ModalConfirm;