import "react-dates/initialize";

import React, { useState } from "react";
import PropTypes from "prop-types";
import { SingleDatePicker } from "react-dates";

import { displayErrorHelpText } from "@utils";
import { DATE_FORMAT_DEFAULT } from "@constants";

// This function returns a value or undefined
export const valueOrUndefined = value => value || undefined;

// A DatePicker component used to select a single date
const DatePicker = ({
  id,
  label,
  placeholder,
  withPortal,
  errorMessage,
  isOutsideRange,
  input: { value, onChange }
}) => {
  const [focused, setFocused] = useState({ focused: false });
  return (
    <div className="wfp--form-item">
      {label ? (
        <label htmlFor={id} className="wfp--label">
          {label}
        </label>
      ) : (
        ""
      )}
      <SingleDatePicker
        id={id}
        placeholder={placeholder}
        date={focused.focused && isOutsideRange ? undefined : valueOrUndefined(value)}
        onDateChange={onChange}
        focused={focused.focused}
        onFocusChange={setFocused}
        isOutsideRange={isOutsideRange}
        withPortal={valueOrUndefined(withPortal)}
        numberOfMonths={1}
        hideKeyboardShortcutsPanel
        readOnly
        reopenPickerOnClearDate
        showClearDate
        displayFormat={DATE_FORMAT_DEFAULT}
      />
      {displayErrorHelpText(errorMessage)}
    </div>
  );
};

// propTypes for the DatePicker component
DatePicker.propTypes = {
  id: PropTypes.string.isRequired,
  input: PropTypes.any.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  withPortal: PropTypes.bool,
  errorMessage: PropTypes.string,
  isOutsideRange: PropTypes.func
};

// defaultProps for the DatePicker component
DatePicker.defaultProps = {
  placeholder: "",
  label: "",
  withPortal: false,
  errorMessage: "",
  isOutsideRange: () => false
};

export default DatePicker;
