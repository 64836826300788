import { RESOURCE_VERSION_REQUEST_, RESOURCE_VERSION_SUCCESS_, RESOURCE_VERSION_ERROR_ } from "../actions/resourceVersion";


// A generic reducer used to get the version a resource
const resourceVersion = resource => (state = { isFetching: false, version: undefined, errorMessage: "" }, action) => {
  switch (action.type) {
  case `${RESOURCE_VERSION_REQUEST_}${resource}`:
    return { ...state, isFetching: true, version: undefined, errorMessage: "" };
  case `${RESOURCE_VERSION_SUCCESS_}${resource}`:
    return { ...state, isFetching: false, version: action.version, errorMessage: "" };
  case `${RESOURCE_VERSION_ERROR_}${resource}`:
    return { ...state, isFetching: false, version: undefined, errorMessage: action.errorMessage };
  default:
    return state;
  }
};

export default resourceVersion;