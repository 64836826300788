import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { reset } from "redux-form";

import PageStandard from "@components/PageStandard";
import Button from "@components/Button";
import ButtonsWrapper from "@components/ButtonsWrapper";
import ModalSuccess from "@components/ModalSuccess";
import Loading from "@components/Loading";
import AdministrationUserEditModal from "./components/AdministrationUserEditModal";
import AdministrationUserDetail from "./components/AdministrationUserDetail";
import { getStatusErrorMessage, adaptOption, adaptOptions, isEmpty } from "@utils";
import { getUserDescription } from "@utils/users";
import {
  adminUserDetail,
  showAdminUserDetailModal,
  hideAdminUserDetailModal,
  showSuccessAdminUserDetailModal,
  hideSuccessAdminUserDetailModal,
  adminUserDetailUpdate,
  adminUserDetailUpdateReset,
  userRolesList,
  userDivisionList,
  countryList
} from "@actions";

export const adaptRoles = roles => {
  const roleList = [];
  roles.filter((role) => role.toLowerCase() !== "public").map(role => roleList.push({ label: role, value: role }));
  return roleList;
};

export const adaptUserInitialValues = user => {
  const divisionsList = [];
  if (user.divisions && user.divisions.length > 0) {
    user.divisions.map(division => divisionsList.push(division.id));
  }

  return {
    ...user,
    country: user.country && adaptOption(user.country, "id", "name"),
    divisions: divisionsList
  };
};

// A component that shows the User detail page
export class AdministrationUserView extends Component {
  // On component mount, User detail is loaded
  componentDidMount() {
    this.onClickEditUser = this.onClickEditUser.bind(this);
    this.onClickUserRequestDetail = this.onClickUserRequestDetail.bind(this);
    this.onValidateAdminUserDetailModal = this.onValidateAdminUserDetailModal.bind(this);
    this.onCancelAdminUserDetailModal = this.onCancelAdminUserDetailModal.bind(this);
    this.resetAdminUserDetailModal = this.resetAdminUserDetailModal.bind(this);
    this.onValidateSuccessModal = this.onValidateSuccessModal.bind(this);
    this.props.adminUserDetail(this.props.id);
    this.props.userRolesList();
    this.props.countryList();
    this.props.userDivisionList();
  }

  // On component unmount store is reset where needed
  componentWillUnmount() {
    this.resetAdminUserDetailModal();
  }

  onClickEditUser() {
    this.props.showAdminUserDetailModal();
  }

  onClickUserRequestDetail(id) {
    this.props.history.push(`/accessRequestView/${id}`);
  }

  // Close the modal to confirm the action
  onCancelAdminUserDetailModal() {
    this.resetAdminUserDetailModal();
  }

  // When admin clicks submit data is sent to server
  // After success a modal is displayed
  // if it fails, an error message is displayed
  onValidateAdminUserDetailModal() {
    const { id } = this.props;
    const { roles, country } = this.props.adminUserEditFormValues;

    this.props.adminUserDetailUpdate(id, { roles, country: country.value }).then(success => {
      if (success) {
        this.props.adminUserDetail(id);
        this.props.showSuccessModal();
        this.props.hideAdminUserDetailModal();
      }
    });
  }

  // When user click ok on success modal, it is redirected to Admin list page
  // and the page is scrolled to top
  onValidateSuccessModal() {
    this.props.hideSuccessModal();
    this.props.history.push("/administration");
    window.scrollTo(0, 0);
  }

  resetAdminUserDetailModal() {
    this.props.hideAdminUserDetailModal();
    this.props.adminUserDetailUpdateReset();
    this.props.resetAdminUserDetailForm();
  }

  render() {
    const {
      isFetching,
      errorMessage,
      user,
      userInitialData,
      isAdminUserDetailModalOpen,
      isSuccessModalOpen,
      errorMessageUserDetailUpdate,
      errorObjectUserDetailUpdate,
      userRoles,
      countries,
      divisions
    } = this.props;
    return (
      <>
        {isFetching ? (
          <Loading isVisible={isFetching}>Loading...</Loading>
        ) : (
          <PageStandard
            title={getUserDescription(user)}
            additional={
              <ButtonsWrapper>
                <Button onClick={this.onClickEditUser}>Edit</Button>
              </ButtonsWrapper>
            }
          >
            <AdministrationUserDetail
              errorMessage={errorMessage}
              user={user}
              onClickAccessRequest={this.onClickUserRequestDetail}
            />
            <AdministrationUserEditModal
              isLoading={isFetching}
              isOpen={isAdminUserDetailModalOpen}
              isOnEdit
              errorObject={errorObjectUserDetailUpdate}
              errorMessage={errorMessageUserDetailUpdate}
              onValidate={this.onValidateAdminUserDetailModal}
              onCancel={this.onCancelAdminUserDetailModal}
              title={"Edit user"}
              text={`${user.first_name} ${user.last_name}`}
              countries={countries}
              divisions={divisions}
              userRoles={userRoles}
              initialValues={userInitialData}
              org_unit_description={user.org_unit_description}
            />
            <ModalSuccess
              isOpen={isSuccessModalOpen}
              onValidate={this.onValidateSuccessModal}
              text="Record has been updated"
            />
          </PageStandard>
        )}
      </>
    );
  }
}

// propTypes for the AdministrationUserView component
AdministrationUserView.propTypes = {
  id: PropTypes.string.isRequired,
  isFetching: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  user: PropTypes.any.isRequired,
  userInitialData: PropTypes.any.isRequired,
  adminUserDetail: PropTypes.func.isRequired,
  hideAdminUserDetailModal: PropTypes.func.isRequired,
  showAdminUserDetailModal: PropTypes.func.isRequired,
  isAdminUserDetailModalOpen: PropTypes.bool.isRequired,
  isSuccessModalOpen: PropTypes.bool.isRequired,
  showSuccessModal: PropTypes.func.isRequired,
  hideSuccessModal: PropTypes.func.isRequired,
  errorObjectUserDetailUpdate: PropTypes.object.isRequired,
  errorMessageUserDetailUpdate: PropTypes.string.isRequired,
  history: PropTypes.any.isRequired,
  resetAdminUserDetailForm: PropTypes.func.isRequired,
  adminUserDetailUpdate: PropTypes.func.isRequired,
  adminUserDetailUpdateReset: PropTypes.func.isRequired,
  countryList: PropTypes.func.isRequired,
  countries: PropTypes.array.isRequired,
  userRolesList: PropTypes.func.isRequired,
  userRoles: PropTypes.array.isRequired,
  userDivisionList: PropTypes.func.isRequired,
  divisions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.any.isRequired
    })
  ),
  adminUserEditFormValues: PropTypes.any
};

AdministrationUserView.defaultProps = {
  adminUserEditFormValues: undefined
};

// Starting from the redux state it gets data related to an User detail
export const mapStateToProps = (state, ownProps) => {
  const {
    adminUserDetail,
    adminUserDetailUpdate,
    form,
    userRolesList,
    countryList,
    userDivisionList,
    adminUserDetailModal,
    successAdminUserDetailModal
  } = state;
  const { adminUserEditForm } = form;
  return {
    id: ownProps.match.params.id,
    user: adminUserDetail.data,
    userInitialData: adaptUserInitialValues(adminUserDetail.data),
    countries: adaptOptions(countryList.data.results, "id", "name"),
    divisions: userDivisionList.data.divisions,
    userRoles: adaptRoles(userRolesList.data.results),
    errorMessage: adminUserDetail.errorMessage,
    isAdminUserDetailModalOpen: adminUserDetailModal.isVisible,
    isSuccessModalOpen: successAdminUserDetailModal.isVisible,
    errorObjectUserDetailUpdate: adminUserDetailUpdate.error,
    errorMessageUserDetailUpdate:
      (!isEmpty(adminUserDetailUpdate.error) && getStatusErrorMessage(adminUserDetailUpdate.error)) || "",
    // Properties related to loading status
    isFetchingUserView: adminUserDetail.isFetching,
    // Purchase options properties
    isFetching: adminUserDetail.isFetching || !adminUserDetail.data.id,
    adminUserEditFormValues: adminUserEditForm && adminUserEditForm.values
  };
};

// Maps functions to dispatch actions
export const mapDispatchToProps = dispatch => {
  return {
    adminUserDetail: id => dispatch(adminUserDetail(id, "/specific_user/")),
    showAdminUserDetailModal: () => dispatch(showAdminUserDetailModal()),
    hideAdminUserDetailModal: () => dispatch(hideAdminUserDetailModal()),
    showSuccessModal: () => dispatch(showSuccessAdminUserDetailModal()),
    hideSuccessModal: () => dispatch(hideSuccessAdminUserDetailModal()),
    adminUserDetailUpdate: (id, data) => dispatch(adminUserDetailUpdate(id, data, "edit_user/")),
    adminUserDetailUpdateReset: () => dispatch(adminUserDetailUpdateReset()),
    countryList: () => dispatch(countryList()),
    userDivisionList: () => dispatch(userDivisionList()),
    userRolesList: () => dispatch(userRolesList()),
    resetAdminUserDetailForm: () => dispatch(reset("adminUserEditForm"))
  };
};

// The form is added to the redux store with a given name
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdministrationUserView));
