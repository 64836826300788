import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import PageStandard from "@components/PageStandard";
import Button from "@components/Button";
import ButtonsWrapper from "@components/ButtonsWrapper";
import ModalWithComments from "@components/ModalWithComments";
import Loading from "@components/Loading";
import AccessRequestDetail from "./AccessRequestDetail";
import { isEmpty, getStatusErrorMessage } from "@utils";
import { hasPermission } from "@utils/permissions";

import {
  userDetail,
  userDetailReset,
  accessRequestDetail,
  accessRequestDetailUpdate,
  accessRequestDetailUpdateReset,
  accessRequestDetailActiveAction,
  showAccessRequestDetailConfirmModal,
  hideAccessRequestDetailConfirmModal,
  accessRequestActionsList
} from "@actions";
import {reduxForm} from "redux-form";

const ConfirmActionModal = reduxForm({ form: "confirmActionForm", enableReinitialize: true })(ModalWithComments);

// // A function to generate the message of the confirm action model
export const generateConfirmActionMessage = (actions, currentActionCommand) => {
  const action = actions.find(action => action.command === currentActionCommand);
  return (action && `${action.label} this access request?`) || "";
};

export const generateActionsButtons = (actions, onClickAction) => (
  <>
    {actions.sort((a, b) => a.label > b.label? 1 : -1).map(action => (
      <Button key={action.command} onClick={() => onClickAction(action.command)}>
        {action.label}
      </Button>
    ))}
  </>
);

// A component that shows the User Access Request detail page
export class AccessRequestView extends Component {
  // On component mount, User detail is loaded
  componentDidMount() {
    this.onClickAction = this.onClickAction.bind(this);
    this.onValidateConfirmModal = this.onValidateConfirmModal.bind(this);
    this.onCancelConfirmModal = this.onCancelConfirmModal.bind(this);
    this.resetActionConfirmModal = this.resetActionConfirmModal.bind(this);
    this.props.userDetail();
    this.props.accessRequestDetail(this.props.id);
    this.props.accessRequestActionsList(this.props.id);
  }

  // On component unmount store is reset where needed
  componentWillUnmount() {
    this.resetActionConfirmModal();
    this.props.userDetail();
  }

  onClickAction(action) {
    this.props.accessRequestDetailActiveAction(action);
    if(action === "edit") {
      this.props.accessRequestDetailUpdate(this.props.id, action).then(success => {
        if (success) {
          this.props.history.push(`/userAccess/${this.props.id}`);
        }
      });
    } else {
      this.props.showAccessRequestDetailConfirmModal();
    }
  }

  // Close the modal to confirm the action
  onCancelAccessRequestDetailConfirmModal() {
    this.resetAccessRequestDetailConfirmModal();
    this.props.hideAccessRequestDetailConfirmModal();
  }
  // Close the modal to confirm the action
  onCancelConfirmModal() {
    this.resetActionConfirmModal();
  }

  // When user click ok on success modal, it is redirected to Admin list page
  // and the page is scrolled to top
  onValidateConfirmModal() {
    this.props.accessRequestDetailUpdate(
      this.props.id, this.props.activeAction, this.props.comments
    ).then(success => {
      if (success) {
        this.props.userDetailReset();
        this.props.hideConfirmModal();
        this.props.history.push("/");
        window.scrollTo(0, 0);
      }
    });
  }

  resetActionConfirmModal() {
    this.props.hideConfirmModal();
  }

  render() {
    const {
      isFetching,
      errorMessage,
      accessRequest,
      isConfirmModalOpen,
      errorAccessRequestDetailUpdate,
      messageConfirmAction,
      requestActions
    } = this.props;
    return (
      <>
        {isFetching ? (
          <Loading isVisible={isFetching}>Loading...</Loading>
        ) : (
          <PageStandard
            title={"Access Request"}
            additional={
              accessRequest &&
              accessRequest.state !== "cancelled" && (
                <ButtonsWrapper>
                  {generateActionsButtons(requestActions, this.onClickAction)}
                </ButtonsWrapper>
              )
            }
          >
            <AccessRequestDetail errorMessage={errorMessage} accessRequest={accessRequest} />
            <ConfirmActionModal
              isOpen={isConfirmModalOpen}
              errorMessage={errorAccessRequestDetailUpdate}
              onValidate={this.onValidateConfirmModal}
              onCancel={this.onCancelConfirmModal}
              validateText={"Confirm"}
              isLoading={false}
              text={messageConfirmAction}
            >
            </ConfirmActionModal>
          </PageStandard>
        )}
      </>
    );
  }
}

// propTypes for the AccessRequestView component
AccessRequestView.propTypes = {
  id: PropTypes.string.isRequired,
  isFetching: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  accessRequest: PropTypes.any.isRequired,
  accessRequestDetail: PropTypes.func.isRequired,
  hideAccessRequestDetailConfirmModal: PropTypes.func.isRequired,
  showAccessRequestDetailConfirmModal: PropTypes.func.isRequired,
  isConfirmModalOpen: PropTypes.bool.isRequired,
  showConfirmModal: PropTypes.func.isRequired,
  hideConfirmModal: PropTypes.func.isRequired,
  history: PropTypes.any.isRequired,
  accessRequestDetailUpdate: PropTypes.func.isRequired,
  accessRequestDetailUpdateReset: PropTypes.func.isRequired,
  accessRequestDetailActiveAction: PropTypes.func.isRequired,
  accessRequestActionsList: PropTypes.func.isRequired,
  activeAction: PropTypes.string.isRequired,
  errorAccessRequestDetailUpdate: PropTypes.string.isRequired,
  messageConfirmAction: PropTypes.string.isRequired,
  userDetail: PropTypes.func.isRequired,
  userDetailReset: PropTypes.func.isRequired,
  canManageUsers: PropTypes.bool.isRequired,
  comments: PropTypes.string.isRequired,
};

// Starting from the redux state it gets data related to an User detail
export const mapStateToProps = (state, ownProps) => {
  const {
    userDetail,
    accessRequestDetail,
    accessRequestDetailUpdate,
    accessRequestDetailConfirmModal,
    accessRequestDetailActiveAction,
  } = state;

  const actions = (state.accessRequestActionsList && state.accessRequestActionsList.data &&
        state.accessRequestActionsList.data.actions) || [];

  // A function to check if user has given permission
  const checkUserPermission = permission => {
    return (userDetail && userDetail.data && hasPermission(userDetail.data, permission)) || false;
  };

  return {
    id: ownProps.match.params.id,
    canManageUsers: checkUserPermission("auth.user_managment"),
    activeAction: accessRequestDetailActiveAction.command,
    requestActions: actions,
    messageConfirmAction: generateConfirmActionMessage(actions, accessRequestDetailActiveAction.command),
    accessRequest: accessRequestDetail.data,
    isFetching: accessRequestDetail.isFetching || !accessRequestDetail.data.id,
    errorMessage: accessRequestDetail.errorMessage,
    isConfirmModalOpen: accessRequestDetailConfirmModal.isVisible,
    errorAccessRequestDetailUpdate:
      (!isEmpty(accessRequestDetailUpdate.error) && getStatusErrorMessage(accessRequestDetailUpdate.error)) || "",
    comments:
      (state.form.confirmActionForm &&
        state.form.confirmActionForm.values &&
        state.form.confirmActionForm.values.comments) ||
      "",
  };
};

// Maps functions to dispatch actions
export const mapDispatchToProps = dispatch => {
  return {
    userDetail: () => dispatch(userDetail()),
    userDetailReset: () => dispatch(userDetailReset()),
    accessRequestDetail: id => dispatch(accessRequestDetail(id)),
    accessRequestDetailActiveAction: action => dispatch(accessRequestDetailActiveAction(action)),
    accessRequestActionsList: id => dispatch(accessRequestActionsList({}, `${id}/actions/`)),
    showAccessRequestDetailConfirmModal: () => dispatch(showAccessRequestDetailConfirmModal()),
    hideAccessRequestDetailConfirmModal: () => dispatch(hideAccessRequestDetailConfirmModal()),
    showConfirmModal: () => dispatch(showAccessRequestDetailConfirmModal()),
    hideConfirmModal: () => dispatch(hideAccessRequestDetailConfirmModal()),
    accessRequestDetailUpdate: (id, action, comments) =>
      dispatch(accessRequestDetailUpdate(id, {comments}, `perform/${action}/`)),
    accessRequestDetailUpdateReset: () => dispatch(accessRequestDetailUpdateReset())
  };
};

// The form is added to the redux store with a given name
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccessRequestView));
