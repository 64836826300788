import React from "react";
import PropTypes from "prop-types";
import { reduxForm, Field } from "redux-form";
import { iconCloseGlyph, iconDownloadGlyph } from "@wfp/icons";
import { iconFilterAlt } from "@wfp/icons";

import { displayErrorMessage } from "@utils";
import { columns } from "@utils/users";
import ShowHideArea from "@components/ShowHideArea";
import Table from "@components/Table";
import Button from "@components/Button";
import Link from "@components/Link";
import Select from "@components/Select";
import Module from "@components/Module";
import { Grid, Row, Col } from "@components/Grid";
import Input from "@components/Input";
import FiltersWrapper from "@components/FiltersWrapper";
import AlignRight from "@components/AlignRight";
import AlignCenterHorizontal from "@components/AlignCenterHorizontal";
import ButtonsWrapper from "@components/ButtonsWrapper";
import Checkbox from "@components/Checkbox";

import style from "./style.scss";

// The AdministrationUserList component is used to display a list of users in Admin section
const AdministrationUserList = ({
  users,
  errorMessageUsers,
  errorMessageFilters,
  totalItems,
  page,
  onPageChange,
  loadingUsers,
  loadingFilters,
  onClickDetail,
  countries,
  divisions,
  roles,
  onClickSearch,
  onClickReset,
  onClickExport,
  loadingPresets,
  onClickResetPreset,
  isVisibleFiltersArea,
  onClickShowHideFiltersArea,
  isVisible
}) => (
  <ShowHideArea isVisible={isVisible}>
    {displayErrorMessage(errorMessageUsers)}
    {displayErrorMessage(errorMessageFilters)}
    <Module
      title="All Users"
      filter={
        <ButtonsWrapper>
          <Button icon={iconDownloadGlyph} disabled={!users.length} onClick={onClickExport}>
            Export
          </Button>
          <Button onClick={onClickShowHideFiltersArea} icon={isVisibleFiltersArea ? iconCloseGlyph : iconFilterAlt}>
            {isVisibleFiltersArea ? "Hide filters" : "Show filters"}
          </Button>
        </ButtonsWrapper>
      }
      table={
        <Table
          loading={loadingUsers}
          columns={columns(onClickDetail, false)}
          data={users}
          totalItems={totalItems}
          page={page}
          onPageChange={onPageChange}
        />
      }
    >
      {isVisibleFiltersArea ? (
        <>
          <FiltersWrapper>
            <Grid>
              <Row>
                <Col md={3}>
                  <Field
                    component={Input}
                    name="name"
                    id="name"
                    type="search"
                    label="Name"
                    autoCompleteOff
                    placeholder="Type user first or last name"
                  />
                </Col>
                <Col md={3}>
                  <Field
                    isMulti
                    component={Select}
                    name="countries"
                    id="countries"
                    label="Country"
                    options={countries}
                    loading={loadingFilters}
                  />
                </Col>
                <Col md={3}>
                  <Field
                    isMulti
                    component={Select}
                    name="divisions"
                    id="divisions"
                    label="Division"
                    options={divisions}
                    loading={loadingFilters}
                  />
                </Col>
                <Col md={3}>
                  <Field
                    isMulti
                    component={Select}
                    name="roles"
                    id="roles"
                    label="Roles"
                    options={roles}
                    loading={loadingFilters}
                  />
                </Col>
              </Row>
            </Grid>
          </FiltersWrapper>
          <FiltersWrapper>
            <Grid>
              <Row>
                <Col md={3}>
                  <AlignCenterHorizontal>
                    {loadingPresets ? (
                      <span>Loading...</span>
                    ) : (
                      <Field
                        component={Checkbox}
                        name="savePreset"
                        id="savePreset"
                        text="Save this as your default preset"
                        single
                      />
                    )}
                  </AlignCenterHorizontal>
                </Col>
                <Col md={3}>
                  <AlignCenterHorizontal>
                    {loadingPresets ? " " : <Link onClick={onClickResetPreset}>Remove default presets</Link>}
                  </AlignCenterHorizontal>
                </Col>
                <Col md={6} className={style.searchButtonWrapper}>
                  <AlignRight>
                    <ButtonsWrapper>
                      <Link onClick={onClickReset}>Clear filters</Link>
                      <Button onClick={onClickSearch}>Find</Button>
                    </ButtonsWrapper>
                  </AlignRight>
                </Col>
              </Row>
            </Grid>
          </FiltersWrapper>
        </>
      ) : (
        undefined
      )}
    </Module>
  </ShowHideArea>
);

// propTypes for the AdministrationUserList component
AdministrationUserList.propTypes = {
  users: PropTypes.array.isRequired,
  errorMessageUsers: PropTypes.string.isRequired,
  errorMessageFilters: PropTypes.string.isRequired,
  totalItems: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  loadingUsers: PropTypes.bool.isRequired,
  loadingFilters: PropTypes.bool.isRequired,
  onClickDetail: PropTypes.func.isRequired,
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.any.isRequired
    })
  ),
  divisions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.any.isRequired
    })
  ),
  roles: PropTypes.array.isRequired,
  onClickSearch: PropTypes.func.isRequired,
  onClickReset: PropTypes.func.isRequired,
  loadingPresets: PropTypes.bool.isRequired,
  onClickResetPreset: PropTypes.func.isRequired,
  isVisibleFiltersArea: PropTypes.bool.isRequired,
  onClickShowHideFiltersArea: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onClickExport: PropTypes.func.isRequired
};

export default reduxForm({ form: "userFilters", enableReinitialize: true })(AdministrationUserList);
