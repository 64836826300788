import client from "../client";

// Actions available for generic add resource reducer
export const RESOURCE_ADD_WITH_RESULT_REQUEST_ = "RESOURCE_ADD_WITH_RESULT_REQUEST_";
export const RESOURCE_ADD_WITH_RESULT_SUCCESS_ = "RESOURCE_ADD_WITH_RESULT_SUCCESS_";
export const RESOURCE_ADD_WITH_RESULT_ERROR_ = "RESOURCE_ADD_WITH_RESULT_ERROR_";
export const RESOURCE_ADD_WITH_RESULT_RESET_ = "RESOURCE_ADD_WITH_RESULT_RESET_";

// Action creator for start adding a resource
export const resourceAddWithResultRequest = resource => () => {
  return {
    type: `${RESOURCE_ADD_WITH_RESULT_REQUEST_}${resource}`
  };
};

// Action creator for a successful add resource request
export const resourceAddWithResultSuccess = resource => data => {
  return {
    type: `${RESOURCE_ADD_WITH_RESULT_SUCCESS_}${resource}`,
    data
  };
};

// Action creator for a successful list resource request
export const resourceAddWithResultError = resource => error => {
  return {
    type: `${RESOURCE_ADD_WITH_RESULT_ERROR_}${resource}`,
    error
  };
};

// Action creator for resetting the data
export const resourceAddWithResultReset = resource => () => {
  return {
    type: `${RESOURCE_ADD_WITH_RESULT_RESET_}${resource}`,
  };
};

// Here we dispatch the request action and then, after it is resolved, the success action
// or, if fails, the error action
export const resourceAddWithResult = (resource, url) => (data, appendUrl = "") => {
  return dispatch => {
    dispatch(resourceAddWithResultRequest(resource)());
    return client.post(`${url}${appendUrl}`, data)
      .then(result => {
        dispatch(resourceAddWithResultSuccess(resource)(result.data));
        return result.data;
      })
      .catch(error => {
        dispatch(resourceAddWithResultError(resource)(error));
        return {};
      });
  };
};
