import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { userDetail, applicationVersion, showUpdateApplicationBanner } from "../../actions";
import MainNavigation from "../../components/MainNavigation";

import style from "./style.scss";


// The header used in all pages to allow user access to all section of the application
export class Header extends Component {
  // When component mount the user is fetched
  componentDidMount() {
    this.onClickUpdate = this.onClickUpdate.bind(this);
    this.props.userDetail();
  }

  // Every times component receives props a version check is performed
  // if it is the first time that user opens this page, no banner is displayed
  componentDidUpdate(props) {
    if (props.location.pathname !== this.props.location.pathname) {
      this.props.applicationVersion().then(version => {
        if (!localStorage.getItem("version")) {
          localStorage.setItem("version", version);
        } else if (localStorage.getItem("version") !== version) {
          this.props.showUpdateApplicationBanner();
        }
      });
    }
  }

  // On click update, current version is stored and page is refreshed
  onClickUpdate() {
    localStorage.setItem("version", this.props.version);
    window.location.reload(true);
  }

  // A method to navigate to new IPF page
  onClickNewIPF() {
    this.props.history.push({
      pathname: "/IPFDetail"
    });
  }

  render() {
    return (
      <>
        {
          this.props.isUpdateApplicationBannerVisible &&
          <div className={style.banner} onClick={this.onClickUpdate}>
            A new version of the application is available. Click <span>here</span> to reload the page.
          </div>
        }
        <MainNavigation
          onClickNewIPF={this.onClickNewIPF.bind(this)}
          loading={this.props.isFetching}
          user={this.props.currentUser}
        />
      </>
    );
  }
}

// propTypes for the Header component
Header.propTypes = {
  history: PropTypes.any.isRequired,
  location: PropTypes.any.isRequired,
  isFetching: PropTypes.bool.isRequired,
  userDetail: PropTypes.func.isRequired,
  currentUser: PropTypes.any,
  isUpdateApplicationBannerVisible: PropTypes.bool.isRequired,
  applicationVersion: PropTypes.func.isRequired,
  showUpdateApplicationBanner: PropTypes.func.isRequired,
  version: PropTypes.any.isRequired
};

// defaultProps for the Header component
Header.defaultProps = {
  currentUser: undefined
};

// Starting from the redux state it gets data related to logged in user
export const mapStateToProps = (state) => {
  return {
    currentUser: state.userDetail && state.userDetail.data,
    isFetching: !state.userDetail || state.userDetail.isFetching,
    version: state.applicationVersion && state.applicationVersion.version || "",
    isUpdateApplicationBannerVisible: state.updateApplicationBanner && state.updateApplicationBanner.isVisible || false
  };
};

// Maps functions to dispatch actions
export const mapDispatchToProps = dispatch => {
  return {
    userDetail: () => dispatch(userDetail()),
    applicationVersion: () => dispatch(applicationVersion()),
    showUpdateApplicationBanner: () => dispatch(showUpdateApplicationBanner()),
  };
};

// The component needs to use the router to redirect user to the search page
// after entering some text in the search box
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
