// Mapping for status filed, from a code we get a description
export const STATUSES = [
  {
    value: "draft",
    description: "Edited"
  },
  {
    value: "submitted",
    description: "Submitted"
  },
  {
    value: "cleared",
    description: "Cleared"
  },
  {
    value: "cleared_for_app",
    description: "Cleared/To be approved"
  },
  {
    value: "cleared_for_end",
    description: "Cleared/To be endorsed"
  },
  {
    value: "rejected",
    description: "Rejected"
  },
  {
    value: "cancelled",
    description: "Cancelled"
  },
  {
    value: "endorsed",
    description: "Endorsed"
  },
  {
    value: "approved",
    description: "Approved"
  },
  {
    value: "rejected_ae",
    description: "Rejected"
  },
  {
    value: "draft_endorsed",
    description: "Draft (endorsed)"
  },
  {
    value: "requested",
    description: "Purchase Requisition Raised"
  },
  {
    value: "rejected_ac",
    description: "Rejected"
  },
  {
    value: "draft_cleared",
    description: "Draft (cleared)"
  }
];

// Mapping for status filed, from a code we get a description
// and a list of statuses to filter
// this filter is used in home to filter, for example,
// all statuses that are rejected (rejected, rejected_ae, rejected_ac)
export const STATUSES_FILTER = [
  {
    value: "submitted",
    description: "Submitted",
    codes: ["submitted"]
  },
  {
    value: "cleared",
    description: "Cleared",
    codes: ["cleared", "cleared_for_app", "cleared_for_end"]
  },
  {
    value: "rejected",
    description: "Rejected",
    codes: ["rejected", "rejected_ae", "rejected_ac"]
  },
  {
    value: "cancelled",
    description: "Cancelled",
    codes: ["cancelled"]
  },
  {
    value: "endorsed",
    description: "Endorsed",
    codes: ["endorsed"]
  },
  {
    value: "approved",
    description: "Approved",
    codes: ["approved"]
  },
  {
    value: "requested",
    description: "Purchase Requisition Raised",
    codes: ["requested"]
  }
];

// Mapping for packaging type filed, from a code we get a description
export const PRICE_TYPES = [
  {
    value: "loc",
    description: "Local"
  },
  {
    value: "reg",
    description: "Regional"
  },
  {
    value: "int",
    description: "International"
  }
];

// Mapping for container type filed, from a code we get a description
export const CONTAINER_TYPES = [
  {
    value: 1,
    description: "20'"
  },
  {
    value: 2,
    description: "40'"
  }
];

//Mapping for IPF justification options
export const JUSTIFICATION_OPTIONS = [
  {
    id: "justification_0",
    value: 1,
    text: "Purchase restrictions"
  },
  {
    id: "justification_1",
    value: 2,
    text: "Tied contributors"
  },
  {
    id: "justification_2",
    value: 3,
    text: "Recipient country government restrictions"
  },
  {
    id: "justification_3",
    value: 4,
    text: "Small quantity less than truck/rail loads"
  },
  {
    id: "justification_4",
    value: 5,
    text: "Response at the onset of an emergency operation"
  },
  {
    id: "justification_5",
    value: 6,
    text: "Pipeline break"
  },
  {
    id: "justification_6",
    value: 7,
    text: "TOD/TDD expiring"
  },
  {
    id: "justification_7",
    value: 8,
    text: "Capacity building"
  },
  {
    id: "justification_8",
    value: 9,
    text: "Import/export restrictions"
  },
  {
    id: "justification_9",
    value: 10,
    text: "Other restrictions (requires additional comments)"
  },
  {
    id: "justification_10",
    value: 11,
    text: "Only two sources are available"
  },
  {
    id: "justification_11",
    value: 12,
    text: "Emergency"
  },
  {
    id: "justification_12",
    value: 13,
    text: "Not enough time for other sources"
  }
];

export const YES_NO_FILTER = [
  { value: "", label: "All" },
  { value: "True", label: "Yes" },
  { value: "False", label: "No" }
];

// Date and time format
export const DATE_FORMAT_DEFAULT = "DD/MM/YYYY";
export const DATE_FORMAT_DEFAULT_WITH_TIME = "DD/MM/YYYY, hh:mm";
export const DATE_FORMAT_INPUT = "YYYY-MM-DD";
export const DATE_FORMAT_STANDARD = "YYYYMMDD";
export const DATE_FORMAT_DATE_PICKER = "YYYY-MM-DDThh:mm:ss.cccZ";
export const DATE_FORMAT_TIMESTAMP = "DD-MM-YYYY hh:mm:ss UTC";
