import { RESOURCE_DETAIL_REQUEST_, RESOURCE_DETAIL_SUCCESS_, RESOURCE_DETAIL_ERROR_, RESOURCE_DETAIL_RESET_ } from "../actions/resourceDetail";


// A generic reducer used to get the detail a resource
const resourceDetail = resource => (state = { isFetching: false, data: {}, errorMessage: "" }, action) => {
  switch (action.type) {
  case `${RESOURCE_DETAIL_REQUEST_}${resource}`:
    return { ...state, isFetching: true, data: {}, errorMessage: "" };
  case `${RESOURCE_DETAIL_SUCCESS_}${resource}`:
    return { ...state, isFetching: false, data: action.data, errorMessage: "" };
  case `${RESOURCE_DETAIL_ERROR_}${resource}`:
    return { ...state, isFetching: false, data: {}, errorMessage: action.errorMessage };
  case `${RESOURCE_DETAIL_RESET_}${resource}`:
    return { ...state, isFetching: false, data: {}, errorMessage: "" };
  default:
    return state;
  }
};

export default resourceDetail;