import React from "react";
import PropTypes from "prop-types";
import { reduxForm, Field } from "redux-form";
import { iconDownloadGlyph, iconDelete, iconEdit } from "@wfp/icons";

import { displayErrorMessage } from "../../../../utils";
import Module from "../../../../components/Module";
import Table from "../../../../components/Table";
import ShowHideArea from "../../../../components/ShowHideArea";
import FiltersWrapper from "../../../../components/FiltersWrapper";
import { Grid, Row, Col } from "../../../../components/Grid";
import Select from "../../../../components/Select";
import SelectAsync from "../../../../components/SelectAsync";
import Button from "../../../../components/Button";
import ButtonsWrapper from "../../../../components/ButtonsWrapper";
import { menu } from "../../../../utils/table";

import style from "./style.scss";


// The list of columns and their properties
export const columns = (onClickDelete, onClickEdit, canChangeRateContainer, canDeleteRateContainer) => [
  {
    Header: "Load port",
    accessor: "load_port"
  },
  {
    Header: "Discharge port",
    accessor: "discharge_port"
  },
  {
    Header: "Container type",
    accessor: "container_type"
  },
  {
    Header: "Price US$/Container",
    accessor: "price"
  },
  {
    Header: "Valid until",
    accessor: "valid_until"
  },
  {
    Header: "Edit",
    Cell: props => menu(
      props.row?.original.id,
      [
        { key: props.row?.original.id, icon: iconEdit, label: "Edit rate", onClick: onClickEdit, visible: canChangeRateContainer },
        { key: props.row?.original.id + 1, icon: iconDelete, label: "Delete rate", onClick: onClickDelete, visible: canDeleteRateContainer }
      ]
    ),
    width: 40
  }
];

// The RateContainerList component is used to display a list of Rate/Container
const RateContainerList = ({
  rateContainers,
  errorMessageRateContainers,
  totalItems,
  page,
  onPageChange,
  loadingRateContainers,
  isVisible,
  loadPorts,
  dischargePorts,
  containerTypes,
  loadingFilters,
  onClickSearch,
  onClickExport,
  onClickDelete,
  canDeleteRateContainer,
  onClickEdit,
  canChangeRateContainer
}) => (
  <>
    <ShowHideArea isVisible={isVisible}>
      {displayErrorMessage(errorMessageRateContainers)}
      <Module
        title="Rate/Container"
        filter={
          <ButtonsWrapper>
            <Button icon={iconDownloadGlyph} disabled={!rateContainers.length} onClick={onClickExport}>Export</Button>
          </ButtonsWrapper>
        }
        table={
          <Table
            loading={loadingRateContainers}
            columns={columns(onClickDelete, onClickEdit, canChangeRateContainer, canDeleteRateContainer)}
            data={rateContainers}
            totalItems={totalItems}
            page={page}
            onPageChange={onPageChange}
          />
        }
      >
        <FiltersWrapper>
          <Grid>
            <Row>
              <Col md={4}>
                <Field
                  isMulti
                  component={SelectAsync}
                  name="loadPorts"
                  id="loadPorts"
                  label="Load port"
                  loadOptions={loadPorts}
                />
              </Col>
              <Col md={4}>
                <Field
                  isMulti
                  component={SelectAsync}
                  name="dischargePorts"
                  id="dischargePorts"
                  label="Discharge port"
                  loadOptions={dischargePorts}
                />
              </Col>
              <Col md={3}>
                <Field
                  isMulti
                  component={Select}
                  name="containerTypes"
                  id="containerTypes"
                  label="Container type"
                  options={containerTypes}
                  loading={loadingFilters}
                />
              </Col>
              <Col md={1} className={style.searchButtonWrapper}>
                <Button onClick={onClickSearch}>Find</Button>
              </Col>
            </Row>
          </Grid>
        </FiltersWrapper>
      </Module>
    </ShowHideArea>
  </>
);

// propTypes for the List component
RateContainerList.propTypes = {
  rateContainers: PropTypes.array.isRequired,
  errorMessageRateContainers: PropTypes.string.isRequired,
  totalItems: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  loadingRateContainers: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  loadPorts: PropTypes.func.isRequired,
  dischargePorts: PropTypes.func.isRequired,
  containerTypes: PropTypes.array.isRequired,
  loadingFilters: PropTypes.bool.isRequired,
  onClickSearch: PropTypes.func.isRequired,
  onClickExport: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  canDeleteRateContainer: PropTypes.bool.isRequired,
  onClickEdit: PropTypes.func.isRequired,
  canChangeRateContainer: PropTypes.bool.isRequired
};

export default reduxForm({ form: "rateContainersFilters" })(RateContainerList);