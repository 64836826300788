import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";

import Modal from "../Modal";
import Loading from "../Loading";
import FormWrapper from "../FormWrapper";
import InputMultiline from "../InputMultiline";
import Spacer from "../Spacer";
import { displayErrorMessage } from "../../utils";

// A component used to show a confirmation message and text area for additional comments
const ModalWithComments = ({
  isOpen,
  onValidate,
  onCancel,
  errorMessage,
  isLoading,
  text,
  additionalComponent,
  commentsError
}) => (
  <Modal
    isOpen={isOpen}
    onValidate={onValidate}
    onCancel={onCancel}
    validateText="Confirm"
  >
    {displayErrorMessage(errorMessage)}
    {text}
    <Spacer height="2rem" />
    <FormWrapper>
      <Field
        component={InputMultiline}
        name="comments"
        id="comments"
        label="Comments"
        maxLength={200}
        errorMessage={commentsError}
      />
    </FormWrapper>
    {additionalComponent}
    <Loading isVisible={isLoading} />
  </Modal>
);

// propTypes for the Modal component
ModalWithComments.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onValidate: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  text: PropTypes.any.isRequired,
  additionalComponent: PropTypes.any,
  commentsError: PropTypes.string
};

export default ModalWithComments;
