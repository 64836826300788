import React from "react";
import PropTypes from "prop-types";
import { reduxForm, Field } from "redux-form";
import { iconDownloadGlyph, iconDelete, iconEdit } from "@wfp/icons";

import { displayErrorMessage } from "../../../../utils";
import Module from "../../../../components/Module";
import Table from "../../../../components/Table";
import ShowHideArea from "../../../../components/ShowHideArea";
import FiltersWrapper from "../../../../components/FiltersWrapper";
import { Grid, Row, Col } from "../../../../components/Grid";
import Select from "../../../../components/Select";
import SelectAsync from "../../../../components/SelectAsync";
import Button from "../../../../components/Button";
import ButtonsWrapper from "../../../../components/ButtonsWrapper";
import { menu } from "../../../../utils/table";

import style from "./style.scss";


// The list of columns and their properties
export const columns = (onClickDelete, onClickEdit, canChangeIntakeRestriction, canDeleteIntakeRestriction) => [
  {
    Header: "Discharge port",
    accessor: "discharge_port"
  },
  {
    Header: "Container type",
    accessor: "container_type"
  },
  {
    Header: "Intake value (Mt)",
    accessor: "intake"
  },
  {
    Header: "Effective from",
    accessor: "as_of_date"
  },
  {
    Header: "Edit",
    Cell: props => menu(
      props.row?.original.id,
      [
        { key: props.row?.original.id, icon: iconEdit, label: "Edit intake", onClick: onClickEdit, visible: canChangeIntakeRestriction },
        { key: props.row?.original.id + 1, icon: iconDelete, label: "Delete intake", onClick: onClickDelete, visible: canDeleteIntakeRestriction }
      ]
    ),
    width: 40
  }
];


// The IntakeRestrictionList component is used to display a list of Shipping Rates
const IntakeRestrictionList = ({
  intakeRestrictions,
  errorMessageIntakeRestrictions,
  totalItems,
  page,
  onPageChange,
  loadingIntakeRestrictions,
  isVisible,
  dischargePorts,
  containerTypes,
  loadingFilters,
  onClickSearch,
  onClickExport,
  onClickDelete,
  canDeleteIntakeRestriction,
  onClickEdit,
  canChangeIntakeRestriction
}) => (
  <>
    <ShowHideArea isVisible={isVisible}>
      {displayErrorMessage(errorMessageIntakeRestrictions)}
      <Module
        title="Intake/Restriction at discharge"
        filter={
          <ButtonsWrapper>
            <Button icon={iconDownloadGlyph} disabled={!intakeRestrictions.length} onClick={onClickExport}>Export</Button>
          </ButtonsWrapper>
        }
        table={
          <Table
            loading={loadingIntakeRestrictions}
            columns={columns(onClickDelete, onClickEdit, canChangeIntakeRestriction, canDeleteIntakeRestriction)}
            data={intakeRestrictions}
            totalItems={totalItems}
            page={page}
            onPageChange={onPageChange}
          />
        }
      >
        <FiltersWrapper>
          <Grid>
            <Row>
              <Col md={7}>
                <Field
                  isMulti
                  component={SelectAsync}
                  name="dischargePorts"
                  id="dischargePorts"
                  label="Discharge port"
                  loadOptions={dischargePorts}
                />
              </Col>
              <Col md={4}>
                <Field
                  isMulti
                  component={Select}
                  name="containerTypes"
                  id="containerTypes"
                  label="Container type"
                  options={containerTypes}
                  loading={loadingFilters}
                />
              </Col>
              <Col md={1} className={style.searchButtonWrapper}>
                <Button onClick={onClickSearch}>Find</Button>
              </Col>
            </Row>
          </Grid>
        </FiltersWrapper>
      </Module>
    </ShowHideArea>
  </>
);

// propTypes for the List component
IntakeRestrictionList.propTypes = {
  intakeRestrictions: PropTypes.array.isRequired,
  errorMessageIntakeRestrictions: PropTypes.string.isRequired,
  totalItems: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  loadingIntakeRestrictions: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  dischargePorts: PropTypes.func.isRequired,
  containerTypes: PropTypes.array.isRequired,
  loadingFilters: PropTypes.bool.isRequired,
  onClickSearch: PropTypes.func.isRequired,
  onClickExport: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  canDeleteIntakeRestriction: PropTypes.bool.isRequired,
  onClickEdit: PropTypes.func.isRequired,
  canChangeIntakeRestriction: PropTypes.bool.isRequired
};

export default reduxForm({ form: "intakeRestrictionsFilters" })(IntakeRestrictionList);