import React from "react";
import PropTypes from "prop-types";
import { reduxForm, Field } from "redux-form";

import Select from "@components/Select";
// import SelectAsync from "@components/SelectAsync";
import Input from "@components/Input";
import { Grid, Row, Col } from "@components/Grid";
import FormWrapper from "@components/FormWrapper";
import ButtonsWrapper from "@components/ButtonsWrapper";
import Button from "@components/Button";
import Module, { SectionTitle } from "@components/Module";
import Loading from "@components/Loading";
import CheckboxGroup from "@components/CheckboxGroup";

import { getErrors, displayErrorMessage } from "@utils";

import style from "./style.scss";
import SelectAsync from "../../../components/SelectAsync";


export const noOptionsMessageSupervisor = input => {
  const filter = input.inputValue.replace(/^[0]+/g, "");
  return filter.length > 3 ? "Supervisor doesn't exist" : "Start typing...";
};

// The User access form component is used to edit user roles
const UserAccessForm = ({
  errorObject,
  userRoles,
  onValidate,
  onCancel,
  errorMessageUserAccessRequest,
  errorMessageUserDetail,
  user,
  supervisors,
  selectedSupervisor,
  isOnEdit,
  // isLoadingSupervisors,
  isLoading
}) => (
  <Module
    footer={
      <ButtonsWrapper>
        <Button onClick={onCancel} kind={"secondary"}>
          Cancel
        </Button>
        <Button onClick={onValidate}>Submit</Button>
      </ButtonsWrapper>
    }
  >
    {displayErrorMessage(errorMessageUserDetail)}
    {displayErrorMessage(errorMessageUserAccessRequest)}
    <FormWrapper>
      <SectionTitle title="User Information" />
      <Grid>
        <Row>
          <Col md={11}>
            {user && <p className={style.accessFormUserData}>{user.full_name}</p>}
          </Col>
          <Col md={11}>
            {user && <p className={style.accessFormUserData}>{user.username}</p>}
          </Col>
          <Col md={11}>
            {user && <p className={style.accessFormUserData}>{user.position_title}</p>}
          </Col>
          <Col md={11}>
            {user && user.divisions &&
            <p className={style.accessFormUserData}>{user.org_unit_description}</p>
            }
          </Col>
          <Col md={11}>
            {user && user.country && <p className={style.accessFormUserData}>{user.country.value}</p>}
          </Col>
        </Row>
        <br/>
      </Grid>
      <SectionTitle title="User Roles Requested" withSeparator />
      <Grid>
        <Row>
          <Col md={12}>
            <CheckboxGroup name="roles" title="User Roles" options={userRoles} errorMessage={getErrors(errorObject, "roles_to_grant")}/>
          </Col>
        </Row>
      </Grid>
      <SectionTitle title="Approver" withSeparator />
      <Grid>
        <Row>
          <Col md={4}>
            <Field
              component={SelectAsync}
              name="supervisor"
              id="supervisor"
              label="Supervisor"
              loadOptions={supervisors}
              displayKey="value"
              placeholder="Enter last name"
              noOptionsMessage={noOptionsMessageSupervisor}
              errorMessage={getErrors(errorObject, "supervisor")}
            />
            {
              selectedSupervisor && selectedSupervisor.data?
                <>
                  <Row>
                    <Col md={11}>
                      {
                        selectedSupervisor.data.full_name?
                          <p className={style.accessFormUserData}>
                            {selectedSupervisor.data.full_name}
                          </p> :
                          <p className={style.accessFormUserData}>
                            {selectedSupervisor.data.first_name} {selectedSupervisor.data.last_name}
                          </p>
                      }
                    </Col>
                    <Col md={11}>
                      <p className={style.accessFormUserData}>{selectedSupervisor.data.email}</p>
                    </Col>
                    <Col md={11}>
                      <p className={style.accessFormUserData}>{selectedSupervisor.data.position_title}</p>
                    </Col>
                  </Row>
                </> : null
            }
          </Col>
        </Row>
      </Grid>
    </FormWrapper>
    <Loading isVisible={isLoading} />
  </Module>
);

// propTypes for the Module component
UserAccessForm.propTypes = {
  errorMessageUserDetail: PropTypes.string,
  errorMessageUserAccessRequest: PropTypes.string,
  errorObject: PropTypes.object.isRequired,
  onValidate: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  supervisors: PropTypes.func.isRequired,
  selectedSupervisor: PropTypes.object,
  // isLoadingSupervisors: PropTypes.bool.isRequired,
  userRoles: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
};

export default reduxForm({ form: "userAccessForm", enableReinitialize: true })(UserAccessForm);
