import React from "react";
import PropTypes from "prop-types";

// A wrapper of the CheckboxWrapper component form WFP UI Kit
const CheckboxWrapper = ({ title, children, isDisabled }) => (
  <fieldset className="wfp--fieldset" disabled={isDisabled}>
    <legend className="wfp--label">{title}</legend>
    {children}
  </fieldset>
);

// propTypes for the CheckboxWrapper component
CheckboxWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  isDisabled: PropTypes.bool
};

CheckboxWrapper.defaultProps = {
  isDisabled: false
};

export default CheckboxWrapper;
