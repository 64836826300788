import React, { Component } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Provider } from "react-redux";

import configureStore from "../../store";
import PrivateRoute from "@utils/PrivateRoute";
import Home from "../Home";
import Logout from "../Logout";
import IPFDetail from "../IPFDetail";
import IPFView from "../IPFView";
import CommodityPrices from "../CommodityPrices";
import ImportExportRestrictions from "../ImportExportRestrictions";
import ShippingRates from "../ShippingRates";
import NotFound from "../NotFound";
import ErrorMessage from "../ErrorMessage";
import Administration from "../Administration";
import AdministrationUserView from "../AdministrationUserView";
import UserAccess from "../UserAccess";
import AccessRequestView from "../AccessRequestView";

// Through the store we will share redux state to all component used in the application
const store = configureStore();

// The application root component is created, the Provider allow to share
// the redux store with all components used in the application
// and the BrowserRouter allow user to navigate through all the pages.
// We also render an Header and a Footer to be used in all the pages
class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <Switch>
            <Route exact path="/errorMessage" component={ErrorMessage} />
            <PrivateRoute exact path="/" component={Home} />
            <PrivateRoute exact path="/logout" component={Logout} />
            <PrivateRoute exact path="/IPFDetail" component={IPFDetail} />
            <PrivateRoute exact path="/IPFDetail/:id" component={IPFDetail} />
            <PrivateRoute exact path="/IPFView/:id" component={IPFView} />
            <PrivateRoute exact path="/commodityPrices" component={CommodityPrices} />
            <PrivateRoute exact path="/importExportRestrictions" component={ImportExportRestrictions} />
            <PrivateRoute exact path="/shippingRates" component={ShippingRates} />
            <PrivateRoute exact path="/administration" component={Administration} />
            <PrivateRoute exact path="/userAccess" component={UserAccess} />
            <PrivateRoute exact path="/userAccess/:id" component={UserAccess} />
            <PrivateRoute exact path="/userView/:id" component={AdministrationUserView} />
            <PrivateRoute exact path="/accessRequestView/:id" component={AccessRequestView} />
            <PrivateRoute component={NotFound} />
          </Switch>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;
