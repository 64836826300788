import React from "react";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";

import ModalWithComments from "../../../../components/ModalWithComments";
import Upload from "../../../../components/Upload";


// A component used tho show a confirmation message to delete a Commodity Price
const ConfirmActionModal = ({
  isOpen,
  onValidate,
  onCancel,
  errorMessage,
  isLoading,
  text,
  commentsError
}) => (
  <ModalWithComments
    isOpen={isOpen}
    onValidate={onValidate}
    onCancel={onCancel}
    validateText="Confirm"
    errorMessage={errorMessage}
    text={text}
    commentsError={commentsError}
    additionalComponent={<Upload name="attachments" label="Attachments" />}
    isLoading={isLoading}
  />
);

// propTypes for the Modal component
ConfirmActionModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onValidate: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  text: PropTypes.any.isRequired,
  commentsError: PropTypes.string
};

export default reduxForm({ form: "confirmActionForm", enableReinitialize: true })(ConfirmActionModal);
