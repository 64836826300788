// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_wrapper_lWgnaY5ALKbHdEYdzM2PAQ\\=\\= {\n  width: 100%;\n}\n\n.style_single_W0kL3VhBk3P3-VLbLPunGA\\=\\= {\n  margin-top: 0rem !important;\n}", "",{"version":3,"sources":["webpack://src/components/Checkbox/style.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;;AAEA;EACE,2BAAA;AACF","sourcesContent":[".wrapper {\n  width: 100%;\n}\n\n.single {\n  margin-top: 0rem !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "style_wrapper_lWgnaY5ALKbHdEYdzM2PAQ==",
	"single": "style_single_W0kL3VhBk3P3-VLbLPunGA=="
};
export default ___CSS_LOADER_EXPORT___;
