import React from "react";
import PropTypes from "prop-types";
import { tooltipStyle } from "@wfp/ui";
import { Tooltip } from "react-tippy";
import { iconInfoSolid } from "@wfp/icons";

import Icon from "../Icon";


// An icon with a tooltip
const IconTooltip = ({ html }) => (
  <Tooltip
    {...tooltipStyle}
    html={html}
  >
    <Icon
      icon={iconInfoSolid}
      width="16"
      height="16"
    />
  </Tooltip>
);

// propTypes for the IconTooltip component
IconTooltip.propTypes = {
  html: PropTypes.any.isRequired
};

export default IconTooltip;
