import React from "react";
import PropTypes from "prop-types";
import { StepNavigation as WFPStepNavigation, StepNavigationItem } from "@wfp/ui";


// A wrapper of the StepNavigation component form WFP UI Kit
const StepNavigation = ({ active, items }) => (
  <WFPStepNavigation selectedPage={active}>
    {
      items.map(item => (
        <StepNavigationItem key={item.page} label={item.label} page={item.page} status={item.status} />
      ))
    }
  </WFPStepNavigation>
);

// propTypes for the StepNavigation component
StepNavigation.propTypes = {
  active: PropTypes.number.isRequired,
  items: PropTypes.any.isRequired
};

export default StepNavigation;