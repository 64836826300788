import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { reset } from "redux-form";

import PageStandard from "../../components/PageStandard";
import Loading from "../../components/Loading";
import { adaptOptions, dateToString, openUrl } from "../../utils";
import ImportExportRestrictionsList from "./components/ImportExportRestrictionsList";
import ImportExportRestrictionModal from "./components/ImportExportRestrictionModal";
import ModalConfirm from "../../components/ModalConfirm";
import { DATE_FORMAT_INPUT, DATE_FORMAT_DEFAULT } from "../../constants";
import { hasPermission } from "../../utils/permissions";
import {
  countryList,
  importExportRestrictionList,
  importExportRestrictionCurrentPage,
  importExportRestrictionDetail,
  importExportRestrictionDetailReset,
  importExportRestrictionUpdate,
  importExportRestrictionUpdateReset,
  showImportExportRestrictionModal,
  hideImportExportRestrictionModal,
  importExportRestrictionDelete,
  showDeleteImportExportRestrictionModal,
  hideDeleteImportExportRestrictionModal,
  activeRecordImportExportRestriction,
  userDetail
} from "../../actions";

const API_URL_DOWNLOAD_XLS = `${process.env.API_BASE_URL}/api/import_export_restrictions/xls/`;

// A function to adapt form values to be compliant with the front-end expected format
export const adaptImportExportRestrictionInitialFormValues = values =>
  values && {
    ...values
  };

// A function to adapt form values to be compliant with the back-end expected format
export const adaptImportExportRestrictionFormValues = values =>
  values && {
    ...values
  };

// A function to adapt Import/Export Restrictions to a custom format
export const adaptImportExportRestrictions = importExportRestrictions =>
  importExportRestrictions.map(importExportRestriction => ({
    ...importExportRestriction,
    last_update_date: dateToString(importExportRestriction.last_update_date, DATE_FORMAT_INPUT, DATE_FORMAT_DEFAULT)
  }));

// A page to show the list of Import/Export Restrictions
export class ImportExportRestrictions extends Component {
  // Binding events to this
  constructor(props) {
    super(props);
    this.onPageChangeImportExportRestrictions = this.onPageChangeImportExportRestrictions.bind(this);
    this.onClickSearchImportExportRestrictions = this.onClickSearchImportExportRestrictions.bind(this);
    this.onClickEditImportExportRestriction = this.onClickEditImportExportRestriction.bind(this);
    this.onValidateImportExportRestrictionModal = this.onValidateImportExportRestrictionModal.bind(this);
    this.onCancelImportExportRestrictionModal = this.onCancelImportExportRestrictionModal.bind(this);
    this.onClickDeleteImportExportRestriction = this.onClickDeleteImportExportRestriction.bind(this);
    this.onValidateImportExportRestrictionDeleteModal = this.onValidateImportExportRestrictionDeleteModal.bind(this);
    this.onCancelImportExportRestrictionDeleteModal = this.onCancelImportExportRestrictionDeleteModal.bind(this);
    this.onClickExport = this.onClickExport.bind(this);
  }

  // On component mount, data are loaded
  componentDidMount() {
    this.props.countryList();
    this.props.userDetail();
    this.filterImportExportRestrictions(0);
  }

  // On component unmount store is reset where needed
  componentWillUnmount() {
    this.props.importExportRestrictionUpdateReset();
    this.props.importExportRestrictionDetailReset();
    this.props.activeRecordImportExportRestriction(0);
    this.props.importExportRestrictionCurrentPage(0);
    this.resetImportExportRestrictionDeleteModal();
    this.resetImportExportRestrictionModal();
  }

  // Search action on Import/Export Restriction tab
  onClickSearchImportExportRestrictions() {
    this.filterImportExportRestrictions(0);
  }

  // Shows the modal to edit an Import/Export Restriction
  onClickEditImportExportRestriction(id) {
    this.props.importExportRestrictionDetail(id).then(success => {
      if (success) {
        this.props.showImportExportRestrictionModal();
      }
    });
  }

  // Shows the modal to delete an Import/Export Restriction
  onClickDeleteImportExportRestriction(id) {
    this.props.activeRecordImportExportRestriction(id);
    this.props.showDeleteImportExportRestrictionModal();
  }

  // Close the modal to edit an Import/Export Restriction
  onCancelImportExportRestrictionModal() {
    this.resetImportExportRestrictionModal();
  }

  // On click cancel on delete modal
  onCancelImportExportRestrictionDeleteModal() {
    this.resetImportExportRestrictionDeleteModal();
  }

  // edit an Import/Export Restriction
  onValidateImportExportRestrictionModal() {
    this.props
      .importExportRestrictionUpdate(
        this.props.currentIdImportExportRestriction,
        adaptImportExportRestrictionFormValues(this.props.importExportRestrictionFormValues)
      )
      .then(success => {
        if (success) {
          this.resetImportExportRestrictionModal();
          this.filterImportExportRestrictions(0);
        }
      });
  }

  // Reset the modal to edit an Import/Export Restriction
  resetImportExportRestrictionModal() {
    this.props.hideImportExportRestrictionModal();
    this.props.resetImportExportRestrictionForm();
    this.props.importExportRestrictionUpdateReset();
  }

  // Reset the modal to delete an Import/Export Restriction
  resetImportExportRestrictionDeleteModal() {
    this.props.hideDeleteImportExportRestrictionModal();
    this.props.activeRecordImportExportRestriction(0);
  }

  // On click validate on delete Import/Export Restriction modal
  onValidateImportExportRestrictionDeleteModal() {
    this.props.importExportRestrictionDelete(this.props.idActiveRecordImportExportRestriction).then(success => {
      if (success) {
        this.resetImportExportRestrictionDeleteModal();
        this.filterImportExportRestrictions(0);
      }
    });
  }

  // A method to filter by props and and given filter
  filterImportExportRestrictions(page) {
    const { selectedCountries } = this.props;
    this.props.importExportRestrictionCurrentPage(page);
    this.props.importExportRestrictionList(page + 1, selectedCountries);
  }

  // When user select a page we have to perform a request with given parameters
  onPageChangeImportExportRestrictions(page) {
    this.filterImportExportRestrictions(page);
  }

  // Data are exported as Excel file based on filters selected by user
  onClickExport() {
    let parameters = [];
    parameters.push({ label: "country", value: this.props.selectedCountries });
    openUrl(API_URL_DOWNLOAD_XLS, parameters);
  }

  render() {
    const {
      isFetchingRelatedEntities,
      countries,
      isFetchingImportExportRestrictions,
      errorMessageImportExportRestrictions,
      importExportRestrictions,
      totalItemsImportExportRestrictions,
      pageImportExportRestrictions,
      isImportExportRestrictionModalOpen,
      errorImportExportRestrictionModal,
      initialFormValuesImportExportRestrictions,
      importExportRestrictionModalTitle,
      isLoadingImportExportRestrictionModal,
      isLoadingImportExportRestrictionDetail,
      isDeleteImportExportRestrictionModalOpen,
      errorMessageDeleteImportExportRestriction,
      isLoadingDeleteImportExportRestrictionModal,
      canChangeImportExportRestriction,
      canDeleteImportExportRestriction
    } = this.props;

    return (
      <>
        <PageStandard title="Import/Export Restrictions">
          <ImportExportRestrictionsList
            loadingImportExportRestrictions={isFetchingImportExportRestrictions}
            errorMessageImportExportRestrictions={errorMessageImportExportRestrictions}
            importExportRestrictions={importExportRestrictions}
            totalItems={totalItemsImportExportRestrictions}
            page={pageImportExportRestrictions}
            onPageChange={this.onPageChangeImportExportRestrictions}
            loadingFilters={isFetchingRelatedEntities}
            countries={countries}
            onClickSearch={this.onClickSearchImportExportRestrictions}
            onClickEdit={this.onClickEditImportExportRestriction}
            onClickDelete={this.onClickDeleteImportExportRestriction}
            canChangeImportExportRestriction={canChangeImportExportRestriction}
            canDeleteImportExportRestriction={canDeleteImportExportRestriction}
            onClickExport={this.onClickExport}
          />
        </PageStandard>
        <ImportExportRestrictionModal
          isOpen={isImportExportRestrictionModalOpen}
          onValidate={this.onValidateImportExportRestrictionModal}
          onCancel={this.onCancelImportExportRestrictionModal}
          errorObject={errorImportExportRestrictionModal}
          isLoading={isLoadingImportExportRestrictionModal}
          initialValues={initialFormValuesImportExportRestrictions}
          title={importExportRestrictionModalTitle}
        />
        <ModalConfirm
          isOpen={isDeleteImportExportRestrictionModalOpen}
          errorMessage={errorMessageDeleteImportExportRestriction}
          onValidate={this.onValidateImportExportRestrictionDeleteModal}
          onCancel={this.onCancelImportExportRestrictionDeleteModal}
          isLoading={isLoadingDeleteImportExportRestrictionModal}
          title="Delete Import/Export Restriction"
          text="Are you sure you want to delete this Import/Export Restriction?"
        />
        <Loading isVisible={isLoadingImportExportRestrictionDetail} />
      </>
    );
  }
}

// propTypes for the ImportExportRestrictions component
ImportExportRestrictions.propTypes = {
  isFetchingRelatedEntities: PropTypes.bool.isRequired,
  errorMessageFilters: PropTypes.string.isRequired,
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.any.isRequired
    })
  ),
  countryList: PropTypes.func.isRequired,
  importExportRestrictionCurrentPage: PropTypes.func.isRequired,
  importExportRestrictionList: PropTypes.func.isRequired,
  isFetchingImportExportRestrictions: PropTypes.bool.isRequired,
  errorMessageImportExportRestrictions: PropTypes.string.isRequired,
  importExportRestrictions: PropTypes.array.isRequired,
  totalItemsImportExportRestrictions: PropTypes.number.isRequired,
  pageImportExportRestrictions: PropTypes.number.isRequired,
  selectedCountries: PropTypes.array.isRequired,
  importExportRestrictionDetail: PropTypes.func.isRequired,
  importExportRestrictionDetailReset: PropTypes.func.isRequired,
  importExportRestrictionUpdate: PropTypes.func.isRequired,
  importExportRestrictionUpdateReset: PropTypes.func.isRequired,
  showImportExportRestrictionModal: PropTypes.func.isRequired,
  hideImportExportRestrictionModal: PropTypes.func.isRequired,
  resetImportExportRestrictionForm: PropTypes.func.isRequired,
  importExportRestrictionFormValues: PropTypes.any,
  currentIdImportExportRestriction: PropTypes.number,
  isImportExportRestrictionModalOpen: PropTypes.bool.isRequired,
  errorImportExportRestrictionModal: PropTypes.object.isRequired,
  initialFormValuesImportExportRestrictions: PropTypes.object.isRequired,
  importExportRestrictionModalTitle: PropTypes.string.isRequired,
  isLoadingImportExportRestrictionModal: PropTypes.bool.isRequired,
  isLoadingImportExportRestrictionDetail: PropTypes.bool.isRequired,
  activeRecordImportExportRestriction: PropTypes.func.isRequired,
  showDeleteImportExportRestrictionModal: PropTypes.func.isRequired,
  hideDeleteImportExportRestrictionModal: PropTypes.func.isRequired,
  importExportRestrictionDelete: PropTypes.func.isRequired,
  idActiveRecordImportExportRestriction: PropTypes.number.isRequired,
  isDeleteImportExportRestrictionModalOpen: PropTypes.bool.isRequired,
  errorMessageDeleteImportExportRestriction: PropTypes.string.isRequired,
  isLoadingDeleteImportExportRestrictionModal: PropTypes.bool.isRequired,
  userDetail: PropTypes.func.isRequired,
  canChangeImportExportRestriction: PropTypes.bool.isRequired,
  canDeleteImportExportRestriction: PropTypes.bool.isRequired
};

// defaultProps for the ImportExportRestrictions component
ImportExportRestrictions.defaultProps = {
  importExportRestrictionFormValues: undefined,
  currentIdImportExportRestriction: undefined
};

// Starting from the redux state it gets data related to Import/Export Restrictions list
export const mapStateToProps = state => {
  const { userDetail } = state;

  // A function to check if user has given permission
  const checkUserPermission = permission => {
    return (userDetail && userDetail.data && hasPermission(userDetail.data, permission)) || false;
  };
  return {
    // Related entities properties
    countries: adaptOptions(state.countryList.data.results, "iso_code", "name"),
    isFetchingRelatedEntities: state.countryList.isFetching,
    errorMessageFilters: state.countryList.errorMessage,
    // Import/Export restriction list properties and filters
    importExportRestrictions: adaptImportExportRestrictions(state.importExportRestrictionList.data.results),
    isFetchingImportExportRestrictions: state.importExportRestrictionList.isFetching,
    totalItemsImportExportRestrictions: state.importExportRestrictionList.data.count || 0,
    errorMessageImportExportRestrictions: state.importExportRestrictionList.errorMessage,
    pageImportExportRestrictions: state.importExportRestrictionCurrentPage.number,
    // Selected filters properties and initial filters form values for Import/Export Restrictions
    selectedCountries:
      (state.form.importExportRestrictionsFilters &&
        state.form.importExportRestrictionsFilters.values &&
        state.form.importExportRestrictionsFilters.values.countries &&
        state.form.importExportRestrictionsFilters.values.countries.map(element => element.value)) ||
      [],
    // Import/Export Restriction modal properties
    isImportExportRestrictionModalOpen: state.importExportRestrictionModal.isVisible,
    importExportRestrictionFormValues:
      state.form.importExportRestrictionForm && state.form.importExportRestrictionForm.values,
    isLoadingImportExportRestrictionModal: state.importExportRestrictionUpdate.isLoading,
    errorImportExportRestrictionModal: state.importExportRestrictionUpdate.error,
    initialFormValuesImportExportRestrictions: adaptImportExportRestrictionInitialFormValues(
      state.importExportRestrictionDetail.data
    ),
    currentIdImportExportRestriction: state.importExportRestrictionDetail.data.id,
    importExportRestrictionModalTitle:
      (state.importExportRestrictionDetail.data.country &&
        `Edit Import/Export Restriction for ${state.importExportRestrictionDetail.data.country.name}`) ||
      "",
    isLoadingImportExportRestrictionDetail: state.importExportRestrictionDetail.isFetching,
    // Delete Import/Export Restriction modal
    isDeleteImportExportRestrictionModalOpen: state.deleteImportExportRestrictionModal.isVisible,
    isLoadingDeleteImportExportRestrictionModal: state.importExportRestrictionDelete.isLoading,
    errorMessageDeleteImportExportRestriction: state.importExportRestrictionDelete.errorMessage,
    // Current record of Import/Export Restriction to delete
    idActiveRecordImportExportRestriction: state.activeRecordImportExportRestriction.id,
    // User permission management
    canChangeImportExportRestriction: checkUserPermission("restrictions.change_importexportrestriction"),
    canDeleteImportExportRestriction: checkUserPermission("restrictions.delete_importexportrestriction")
  };
};

// Maps functions to dispatch actions
export const mapDispatchToProps = dispatch => {
  return {
    countryList: () => dispatch(countryList()),
    importExportRestrictionList: (page, country) => dispatch(importExportRestrictionList({ page, country })),
    importExportRestrictionCurrentPage: number => dispatch(importExportRestrictionCurrentPage(number)),
    importExportRestrictionDetail: id => dispatch(importExportRestrictionDetail(id)),
    importExportRestrictionDetailReset: () => dispatch(importExportRestrictionDetailReset()),
    importExportRestrictionUpdate: (id, data) => dispatch(importExportRestrictionUpdate(id, data)),
    importExportRestrictionUpdateReset: () => dispatch(importExportRestrictionUpdateReset()),
    showImportExportRestrictionModal: () => dispatch(showImportExportRestrictionModal()),
    hideImportExportRestrictionModal: () => dispatch(hideImportExportRestrictionModal()),
    resetImportExportRestrictionForm: () => dispatch(reset("importExportRestrictionForm")),
    importExportRestrictionDelete: id => dispatch(importExportRestrictionDelete(id)),
    showDeleteImportExportRestrictionModal: () => dispatch(showDeleteImportExportRestrictionModal()),
    hideDeleteImportExportRestrictionModal: () => dispatch(hideDeleteImportExportRestrictionModal()),
    activeRecordImportExportRestriction: id => dispatch(activeRecordImportExportRestriction(id)),
    userDetail: () => dispatch(userDetail())
  };
};

// The form is added to the redux store with a given name
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImportExportRestrictions);
