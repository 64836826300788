import { ACTIVE_RECORD_ } from "../actions/activeRecord";


// A generic reducer used to store which is the current record on edit or to delete
const activeRecord = (element) => (state = { id: 0 }, action) => {
  switch (action.type) {
  case `${ACTIVE_RECORD_}${element}`:
    return { ...state, id: action.id };
  default:
    return state;
  }
};

export default activeRecord;