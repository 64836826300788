import { ACTIVE_ACTION_ } from "../actions/activeAction";


// A generic reducer used to store which is the current action to be confirmed
const activeAction = (element) => (state = { command: "" }, action) => {
  switch (action.type) {
  case `${ACTIVE_ACTION_}${element}`:
    return { ...state, command: action.command };
  default:
    return state;
  }
};

export default activeAction;