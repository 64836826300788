import React from "react";
import PropTypes from "prop-types";
import { reduxForm, Field } from "redux-form";
import { iconDownloadGlyph, iconDelete, iconEdit } from "@wfp/icons";

import { displayErrorMessage } from "../../../../utils";
import Module from "../../../../components/Module";
import Table from "../../../../components/Table";
import ShowHideArea from "../../../../components/ShowHideArea";
import FiltersWrapper from "../../../../components/FiltersWrapper";
import { Grid, Row, Col } from "../../../../components/Grid";
import Select from "../../../../components/Select";
import SelectAsync from "../../../../components/SelectAsync";
import Button from "../../../../components/Button";
import ButtonsWrapper from "../../../../components/ButtonsWrapper";
import { menu } from "../../../../utils/table";

import style from "./style.scss";


// The list of columns and their properties
export const columns = (onClickDelete, onClickEdit, canChangeIntakeContainer, canDeleteIntakeContainer) => [
  {
    Header: "Load port",
    accessor: "load_port"
  },
  {
    Header: "Commodity",
    accessor: "commodity.description"
  },
  {
    Header: "Container type",
    accessor: "container_type"
  },
  {
    Header: "Intake value (Mt)",
    accessor: "intake"
  },
  {
    Header: "Effective from",
    accessor: "as_of_date"
  },
  {
    Header: "Edit",
    Cell: props => menu(
      props.row?.original.id,
      [
        { key: props.row?.original.id, icon: iconEdit, label: "Edit intake", onClick: onClickEdit, visible: canChangeIntakeContainer },
        { key: props.row?.original.id + 1, icon: iconDelete, label: "Delete intake", onClick: onClickDelete, visible: canDeleteIntakeContainer }
      ]
    ),
    width: 40
  }
];


// The IntakeContainerList component is used to display a list of Shipping Rates
const IntakeContainerList = ({
  intakeContainers,
  errorMessageIntakeContainers,
  totalItems,
  page,
  onPageChange,
  loadingIntakeContainers,
  isVisible,
  loadPorts,
  containerTypes,
  specificCommodities,
  loadingFilters,
  onClickSearch,
  onClickExport,
  onClickDelete,
  canDeleteIntakeContainer,
  onClickEdit,
  canChangeIntakeContainer
}) => (
  <>
    <ShowHideArea isVisible={isVisible}>
      {displayErrorMessage(errorMessageIntakeContainers)}
      <Module
        title="Intake/Container at loading"
        filter={
          <ButtonsWrapper>
            <Button icon={iconDownloadGlyph} disabled={!intakeContainers.length} onClick={onClickExport}>Export</Button>
          </ButtonsWrapper>
        }
        table={
          <Table
            loading={loadingIntakeContainers}
            columns={columns(onClickDelete, onClickEdit, canChangeIntakeContainer, canDeleteIntakeContainer)}
            data={intakeContainers}
            totalItems={totalItems}
            page={page}
            onPageChange={onPageChange}
          />
        }
      >
        <FiltersWrapper>
          <Grid>
            <Row>
              <Col md={4}>
                <Field
                  isMulti
                  component={SelectAsync}
                  name="loadPorts"
                  id="loadPorts"
                  label="Load port"
                  loadOptions={loadPorts}
                />
              </Col>
              <Col md={4}>
                <Field
                  isMulti
                  component={Select}
                  name="specificCommodities"
                  id="specificCommodities"
                  label="Commodity"
                  options={specificCommodities}
                  loading={loadingFilters}
                />
              </Col>
              <Col md={3}>
                <Field
                  isMulti
                  component={Select}
                  name="containerTypes"
                  id="containerTypes"
                  label="Container type"
                  options={containerTypes}
                  loading={loadingFilters}
                />
              </Col>
              <Col md={1} className={style.searchButtonWrapper}>
                <Button onClick={onClickSearch}>Find</Button>
              </Col>
            </Row>
          </Grid>
        </FiltersWrapper>
      </Module>
    </ShowHideArea>
  </>
);

// propTypes for the List component
IntakeContainerList.propTypes = {
  intakeContainers: PropTypes.array.isRequired,
  errorMessageIntakeContainers: PropTypes.string.isRequired,
  totalItems: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  loadingIntakeContainers: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  loadPorts: PropTypes.func.isRequired,
  specificCommodities: PropTypes.array.isRequired,
  containerTypes: PropTypes.array.isRequired,
  loadingFilters: PropTypes.bool.isRequired,
  onClickSearch: PropTypes.func.isRequired,
  onClickExport: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  canDeleteIntakeContainer: PropTypes.bool.isRequired,
  onClickEdit: PropTypes.func.isRequired,
  canChangeIntakeContainer: PropTypes.bool.isRequired
};

export default reduxForm({ form: "intakeContainersFilters" })(IntakeContainerList);