import React from "react";
import { Link, withRouter } from "react-router-dom";
import { Footer as WFPFooter } from "@wfp/ui";
import PropTypes from "prop-types";

import { hasPermission } from "../../utils/permissions";


// A wrapper of the Footer component included in de WFP UI Kit
export const FooterComponent = ({ loading, user }) => (
  <WFPFooter pageWidth="lg" metaContent="2019 © World Food Programme">
    <div className="wfp--footer-info">
      <div className="wfp--footer__info">
        <div className="wfp--footer__info__item">
          <p className="wfp--footer__label">
            Home
          </p>
          <ul className="wfp--footer__list">
            <li>
              <Link to="/">
                Home
              </Link>
            </li>
          </ul>
        </div>
        {
          (hasPermission(user, "ipf.add_purchaserequest") || hasPermission(user, "ipf.create_gcmf")) && !loading
            ? <div className="wfp--footer__info__item">
              <p className="wfp--footer__label">
                New IPF
              </p>
              <ul className="wfp--footer__list">
                <li>
                  <Link to="/IPFDetail">
                    New IPF
                  </Link>
                </li>
              </ul>
            </div>
            : undefined
        }
        {
          hasPermission(user, "prices.view_commodityprice") && !loading
            ? <div className="wfp--footer__info__item">
              <p className="wfp--footer__label">
                Commodity Prices
              </p>
              <ul className="wfp--footer__list">
                <li>
                  <Link to="/commodityPrices">
                    Commodity Prices
                  </Link>
                </li>
              </ul>
            </div>
            : undefined
        }
        {
          hasPermission(user, "prices.view_shippingrate") && !loading
            ? <div className="wfp--footer__info__item">
              <p className="wfp--footer__label">
                Shipping Rates
              </p>
              <ul className="wfp--footer__list">
                <li>
                  <Link to="/shippingRates">
                    Shipping Rates
                  </Link>
                </li>
              </ul>
            </div>
            : undefined
        }
        {
          hasPermission(user, "restrictions.view_importexportrestriction") && !loading
            ? <div className="wfp--footer__info__item">
              <p className="wfp--footer__label">
                Commodity Prices
              </p>
              <ul className="wfp--footer__list">
                <li>
                  <Link to="/importExportRestrictions">
                    Imp/Exp Restrictions
                  </Link>
                </li>
              </ul>
            </div>
            : undefined
        }
      </div>
    </div>
  </WFPFooter>
);

// propTypes for the FooterComponent component
FooterComponent.propTypes = {
  loading: PropTypes.bool.isRequired,
  user: PropTypes.any
};

// defaultProps for the FooterComponent component
FooterComponent.defaultProps = {
  user: undefined
};

// withRouter is needed because the component need to use the Link component of react-router-dom
export default withRouter(FooterComponent);
