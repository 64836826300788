import React from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import {
  MainNavigation as WFPMainNavigation,
  SubNavigation,
  SubNavigationHeader,
  SubNavigationTitle,
  SubNavigationContent,
  SubNavigationList,
  SubNavigationGroup,
  SubNavigationItem,
  MainNavigationItem,
  Button as WFPButton,
  User
} from "@wfp/ui";

import Button from "../Button";
import { hasPermission } from "@utils/permissions";

// Logout URL
const API_URL_LOGOUT = `${process.env.API_BASE_URL}/oidc/logout/`;

// A function to get the name of the logged in user
export const getUserDescription = user => user.full_name || user.username;

// A function to decide if the new IPF button is disabled
// based on the current route
export const newIpfButtonDisabled = history =>
  history.location.pathname.includes("IPFDetail") || history.location.pathname.includes("IPFView");

// A wrapper of the MainNavigation component included in de WFP UI Kit
// which allows to create a menu with a defined set of items
const MainNavigation = ({ onClickNewIPF, loading, user, history }) => (
  <>
    {loading ? (
      <WFPMainNavigation logo={<Link to="/">SCIPS</Link>} pageWidth="lg">
        <MainNavigationItem>
          <a href={API_URL_LOGOUT}>Logout</a>
        </MainNavigationItem>
      </WFPMainNavigation>
    ) : (
      <WFPMainNavigation logo={<Link to="/">SCIPS</Link>} pageWidth="lg">
        {hasPermission(user, "ipf.add_purchaserequest") || hasPermission(user, "ipf.create_gcmf") ? (
          <MainNavigationItem>
            <Button onClick={onClickNewIPF} disabled={newIpfButtonDisabled(history)}>
              Create IPF
            </Button>
          </MainNavigationItem>
        ) : (
          undefined
        )}
        {hasPermission(user, "prices.view_commodityprice") ? (
          <MainNavigationItem>
            <Link to="/commodityPrices">Commodity Prices</Link>
          </MainNavigationItem>
        ) : (
          undefined
        )}
        {hasPermission(user, "prices.view_shippingrate") ? (
          <MainNavigationItem>
            <Link to="/shippingRates">Shipping Rates</Link>
          </MainNavigationItem>
        ) : (
          undefined
        )}
        {hasPermission(user, "restrictions.view_importexportrestriction") ? (
          <MainNavigationItem>
            <Link to="/importExportRestrictions">Imp/Exp Restrictions</Link>
          </MainNavigationItem>
        ) : (
          undefined
        )}
        {hasPermission(user, "auth.user_managment") ? (
          <MainNavigationItem>
            <Link to="/administration">Admin</Link>
          </MainNavigationItem>
        ) : (
          undefined
        )}
        <MainNavigationItem
          className="wfp--main-navigation__user"
          subNavigation={
            <SubNavigation>
              <SubNavigationHeader>
                <SubNavigationTitle>{`Welcome${user.first_name ? " " + user.first_name : ""}!`}</SubNavigationTitle>
                <WFPButton href={API_URL_LOGOUT}>Logout</WFPButton>
              </SubNavigationHeader>
              <SubNavigationContent>
                <SubNavigationList>
                  <SubNavigationGroup>
                    <SubNavigationItem>
                      {user.has_pending_user_request ? (
                        <span>User Access Form (In Progress)</span>
                      ) : (
                        <Link to={"/userAccess"}>User Access Form</Link>
                      )}
                    </SubNavigationItem>
                  </SubNavigationGroup>
                </SubNavigationList>
              </SubNavigationContent>
            </SubNavigation>
          }
        >
          <User title={user.username} name={getUserDescription(user)} ellipsis />
        </MainNavigationItem>
      </WFPMainNavigation>
    )}
  </>
);

// propTypes for the MainNavigation component
MainNavigation.propTypes = {
  onClickNewIPF: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  history: PropTypes.any.isRequired,
  user: PropTypes.any
};

// defaultProp for the MainNavigation component
MainNavigation.defaultProp = {
  user: undefined
};

// withRouter is needed because the component need to use the Link component of react-router-dom
export default withRouter(MainNavigation);
