import React from "react";
import { reduxForm } from "redux-form";
import PropTypes from "prop-types";

import Module, { SectionTitle } from "../../../../components/Module";
import ButtonsWrapper from "../../../../components/ButtonsWrapper";
import Button from "../../../../components/Button";
import ShowHideArea from "../../../../components/ShowHideArea";
import { displayErrorMessage, isEmpty } from "../../../../utils";
import { PurchaseOptionsTable } from "../../../../utils/purchaseOptions";
import {PurchaseOptionsFromIpfTable} from "../../../../utils/purchaseOptionsFromIpf";

// The SelectSource component allows user to select a specific commodity from a list
const SelectSource = ({
  isVisible,
  onClickNext,
  onClickPrevious,
  onClickDraft,
  selectedData,
  purchaseOptions,
  ipf,
  errorsSelectSource
}) => (
  <ShowHideArea isVisible={isVisible}>
    <Module
      title="Step 5/7 Select Source"
      footer={
        <ButtonsWrapper>
          <Button kind="secondary" onClick={onClickDraft}>Save Draft</Button>
          <Button kind="secondary" onClick={onClickPrevious}>
            Previous
          </Button>
          <Button onClick={onClickNext}>Continue</Button>
        </ButtonsWrapper>
      }
    >
      {selectedData}
      <SectionTitle title="Select a purchase option" withSeparator />
      {displayErrorMessage(errorsSelectSource)}
      {
        !isEmpty(purchaseOptions)?
          <PurchaseOptionsTable purchaseOptions={purchaseOptions} withRadio /> :
          ipf && ipf.purchase_options?
            <PurchaseOptionsFromIpfTable ipf={ipf} withRadio /> : null
      }

    </Module>
  </ShowHideArea>
);

// propTypes for the SelectSource component
SelectSource.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClickNext: PropTypes.func.isRequired,
  onClickDraft: PropTypes.func.isRequired,
  selectedData: PropTypes.node.isRequired,
  onClickPrevious: PropTypes.func.isRequired,
  purchaseOptions: PropTypes.array.isRequired,
  errorsSelectSource: PropTypes.string.isRequired,
  ipf: PropTypes.object
};

export default reduxForm({ form: "ipfDetailSelectSource", enableReinitialize: true })(SelectSource);
