import Cleave from "cleave.js";


// A normalizer to be used in redux-fomr to format numbers
export const normalizeNumber = (value, decimalsNumber=2) => {
  const input = document.createElement("input");
  input.value = value;
  var cleave = new Cleave(input, 
    {
      numeral: true,
      numeralThousandsGroupStyle: "thousand",
      numeralDecimalScale: decimalsNumber
    }
  );
  return cleave.getFormattedValue();
};
