import React from "react";
import PropTypes from "prop-types";
import { reduxForm, Field } from "redux-form";

import Select from "../../../../components/Select";
import Input from "../../../../components/Input";
import InputMultiline from "../../../../components/InputMultiline";
import { Grid, Row, Col } from "../../../../components/Grid";
import FormWrapper from "../../../../components/FormWrapper";
import Modal from "../../../../components/Modal";
import Loading from "../../../../components/Loading";
import { getErrors, displayErrorMessage } from "../../../../utils";
import { normalizeNumber } from "../../../../utils/reduxForm";

// The CommodityPriceModal component is used to add/edit a Commodity Price
const CommodityPriceModal = ({
  errorObject,
  loadingRelatedEntities,
  countries,
  specificCommodities,
  incoterms,
  isOpen,
  onValidate,
  onCancel,
  isLoading,
  locations,
  packagingTypes,
  isOnEdit,
  priceTypes,
  errorMessage,
  onChangeProjectCountry
}) => (
  <Modal
    isOpen={isOpen}
    title={isOnEdit ? "Edit Commodity Price" : "Create Commodity Price"}
    onValidate={onValidate}
    onCancel={onCancel}
    validateText="Save"
  >
    {displayErrorMessage(errorMessage)}
    <FormWrapper>
      <Grid>
        <Row>
          <Col md={6}>
            <Field
              component={Select}
              name="price_type"
              id="price_type"
              label="Source"
              options={priceTypes}
              loading={loadingRelatedEntities}
              smallMenu
              isDisabled={isOnEdit}
              errorMessage={getErrors(errorObject, "price_type")}
            />
          </Col>
          <Col md={6}>
            <Field
              component={Select}
              name="specific_commodity"
              id="specific_commodity"
              label="Commodity"
              options={specificCommodities}
              loading={loadingRelatedEntities}
              smallMenu
              isDisabled={isOnEdit}
              errorMessage={getErrors(errorObject, "specific_commodity")}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Field
              component={Select}
              name="origin_country"
              id="origin_country"
              label="Country"
              options={countries}
              loading={loadingRelatedEntities}
              smallMenu
              isDisabled={isOnEdit}
              onChange={onChangeProjectCountry}
              errorMessage={getErrors(errorObject, "origin_country")}
            />
          </Col>
          <Col md={6}>
            <Field
              component={Select}
              name="incoterm"
              id="incoterm"
              label="Incoterm"
              options={incoterms}
              loading={loadingRelatedEntities}
              smallMenu
              isDisabled={isOnEdit}
              errorMessage={getErrors(errorObject, "incoterm")}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Field
              component={Select}
              name="incoterm_place"
              id="incoterm_place"
              label="Named place"
              options={locations}
              loading={loadingRelatedEntities}
              smallMenu
              isDisabled={isOnEdit}
              errorMessage={getErrors(errorObject, "incoterm_place")}
            />
          </Col>
          <Col md={6}>
            <Field
              component={Select}
              name="packaging_type"
              id="packaging_type"
              label="Packaging"
              options={packagingTypes}
              loading={loadingRelatedEntities}
              smallMenu
              isDisabled={isOnEdit}
              errorMessage={getErrors(errorObject, "packaging_type")}
            />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Field
              component={Input}
              name="price"
              id="price"
              label="Price (US$/Mt)"
              normalize={(value) => normalizeNumber(value)}
              errorMessage={getErrors(errorObject, "price")}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Field
              component={InputMultiline}
              name="packaging_note"
              id="packaging_note"
              label="Note"
              errorMessage={getErrors(errorObject, "packaging_note")}
            />
          </Col>
        </Row>
      </Grid>
    </FormWrapper>
    <Loading isVisible={isLoading} />
  </Modal>
);

// propTypes for the Modal component
CommodityPriceModal.propTypes = {
  errorObject: PropTypes.object.isRequired,
  loadingRelatedEntities: PropTypes.bool.isRequired,
  specificCommodities: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.any.isRequired
    })
  ),
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.any.isRequired
    })
  ),
  incoterms: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.any.isRequired
    })
  ),
  packagingTypes: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.any.isRequired
    })
  ),
  priceTypes: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.any.isRequired
    })
  ),
  isOpen: PropTypes.bool.isRequired,
  onValidate: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  locations: PropTypes.array.isRequired,
  isOnEdit: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  onChangeProjectCountry: PropTypes.func.isRequired
};

export default reduxForm({ form: "commodityPriceForm", enableReinitialize: true })(CommodityPriceModal);
