import React from "react";
import PropTypes from "prop-types";
import { fieldInputPropTypes } from "redux-form";
import { Checkbox as WFPCheckbox } from "@wfp/ui";

import { displayErrorHelpText } from "../../utils";

import style from "./style.scss";


// A wrapper of the Checkbox component form WFP UI Kit
const Checkbox = ({ id, label, text, errorMessage, single, isDisabled, input: { value, onChange } }) => (
  <div className="wfp--form-item">
    {
      label
        ? <label htmlFor={id} className="wfp--label">{label}</label>
        : ""
    }
    <div className={style.wrapper}>
      <WFPCheckbox
        wrapperClassName={single ? style.single : undefined}
        checked={Boolean(value)}
        onChange={onChange}
        labelText={text}
        id={id}
        disabled={isDisabled}
      />
    </div>
    {displayErrorHelpText(errorMessage)}
  </div>
);

// propTypes for the Checkbox component
Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  input: PropTypes.shape(fieldInputPropTypes).isRequired,
  errorMessage: PropTypes.string,
  text: PropTypes.string.isRequired,
  single: PropTypes.bool,
  isDisabled: PropTypes.bool
};

// defaultProps for the Checkbox component
Checkbox.defaultProps = {
  label: "",
  errorMessage: "",
  single: false,
  isDisabled: false
};

export default Checkbox;
