import React from "react";
import PropTypes from "prop-types";
import { reduxForm, Field } from "redux-form";

import Module, { SectionTitle } from "../../../../components/Module";
import ButtonsWrapper from "../../../../components/ButtonsWrapper";
import FormWrapper from "../../../../components/FormWrapper";
import Select from "../../../../components/Select";
import Spacer from "../../../../components/Spacer";
import Button from "../../../../components/Button";
import InputMultiline from "../../../../components/InputMultiline";
import ShowHideArea from "../../../../components/ShowHideArea";
import { displayErrorMessage, getErrors } from "../../../../utils";
import { PurchaseOptionsTable } from "../../../../utils/purchaseOptions";

import style from "./style.scss";
import {PurchaseOptionsFromIpfTable} from "../../../../utils/purchaseOptionsFromIpf";


// A function used to render justifications and attachments
export const generateJustificationAndAttachments = justificationsAndAttachments => (
  <>
    <SectionTitle title="Justification" withSeparator />
    <div className={style.justificationsAndAttachments}>
      {justificationsAndAttachments.justification_0 ? <span>- Purchase restrictions</span> : undefined}
      {justificationsAndAttachments.justification_1 ? <span>- Tied contributors</span> : undefined}
      {justificationsAndAttachments.justification_2 ? <span>- Recipient country government restrictions</span> : undefined}
      {justificationsAndAttachments.justification_3 ? <span>- Small quantity less than truck/rail loads</span> : undefined}
      {justificationsAndAttachments.justification_4 ? <span>- Response at the onset of an emergency operation</span> : undefined}
      {justificationsAndAttachments.justification_5 ? <span>- Pipeline break</span> : undefined}
      {justificationsAndAttachments.justification_6 ? <span>- TOD/TDD expiring</span> : undefined}
      {justificationsAndAttachments.justification_7 ? <span>- Capacity building</span> : undefined}
      {justificationsAndAttachments.justification_8 ? <span>- Import/export restrictions</span> : undefined}
      {justificationsAndAttachments.justification_9 ? <span>- Other restrictions (requires additional comments)</span> : undefined}
      {justificationsAndAttachments.justification_10 ? <span>- Only two sources are available</span> : undefined}
      {justificationsAndAttachments.justification_11 ? <span>- Emergency</span> : undefined}
      {justificationsAndAttachments.justification_12 ? <span>- Not enough time for other sources</span> : undefined}
    </div>
  </>
);

// The SummaryStandard component allows user to select a specific commodity from a list
const SummaryStandard = ({
  isVisible,
  onClickNext,
  onClickPrevious,
  selectedData,
  selectedPurchaseOptions,
  justificationsAndAttachments,
  errorMessage,
  clearers,
  ipf,
  loadingClearers,
  errorObject
}) => (
  <ShowHideArea isVisible={isVisible}>
    <Module
      title="Step 7/7 Summary"
      footer={
        <ButtonsWrapper>
          <Button kind="secondary" onClick={onClickPrevious}>Previous</Button>
          <Button onClick={onClickNext}>Submit</Button>
        </ButtonsWrapper>
      }
    >
      {displayErrorMessage(errorMessage)}
      <SectionTitle title="Criteria" />
      {selectedData}
      <SectionTitle title="Source" withSeparator />
      {
        selectedPurchaseOptions.length > 0 && selectedPurchaseOptions[0]?.hasOwnProperty("tmp_id")?
          <PurchaseOptionsTable purchaseOptions={selectedPurchaseOptions} /> :
          selectedPurchaseOptions.length > 0 && selectedPurchaseOptions[0]?.hasOwnProperty("id")?
            <PurchaseOptionsFromIpfTable ipf={{...ipf, purchase_options: selectedPurchaseOptions}} /> :
            ipf && ipf.purchase_options && ipf.next_step > 4 ?
              <PurchaseOptionsFromIpfTable ipf={ipf} preferredOnly={true} /> : null
      }
      <SectionTitle title="Comments" withSeparator />
      <FormWrapper>
        <Field
          component={InputMultiline}
          name="comments"
          id="comments"
          maxLength={200}
        />
      </FormWrapper>
      <SectionTitle title="Clearance" withSeparator />
      <FormWrapper>
        <Field
          component={Select}
          name="clearer"
          id="clearer"
          label="Select clearer"
          options={clearers}
          loading={loadingClearers}
          errorMessage={getErrors(errorObject, "clearer")}
        />
      </FormWrapper>
      <Spacer height="1rem" />
      {generateJustificationAndAttachments(justificationsAndAttachments)}
    </Module>
  </ShowHideArea>
);

// propTypes for the SummaryStandard component
SummaryStandard.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClickNext: PropTypes.func.isRequired,
  selectedData: PropTypes.node.isRequired,
  onClickPrevious: PropTypes.func.isRequired,
  selectedPurchaseOptions: PropTypes.array.isRequired,
  justificationsAndAttachments: PropTypes.any,
  errorMessage: PropTypes.string.isRequired,
  clearers: PropTypes.array.isRequired,
  loadingClearers: PropTypes.bool.isRequired,
  errorObject: PropTypes.object.isRequired,
  ipf: PropTypes.object
};

// defaultProps for the SummaryStandard component
SummaryStandard.defaultProps = {
  justificationsAndAttachments: undefined
};

export default reduxForm({ form: "ipfDetailSummaryStandard", enableReinitialize: true })(SummaryStandard);