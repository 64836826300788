import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { reducer as formReducer } from "redux-form";

import resourceList from "./resourceList";
import resourceDetail from "./resourceDetail";
import resourceAdd from "./resourceAdd";
import resourceAddWithResult from "./resourceAddWithResult";
import resourceUpdate from "./resourceUpdate";
import resourceDelete from "./resourceDelete";
import showHide from "./showHide";
import currentPage from "./currentPage";
import activeRecord from "./activeRecord";
import activeAction from "./activeAction";
import errors from "./errors";
import resourceAddMultiple from "./resourceAddMultiple";
import resourceVersion from "./resourceVersion";

// Here we combine all reducers to create a root reducer for redux
export default history =>
  combineReducers({
    router: connectRouter(history),
    form: formReducer,
    specificCommodityList: resourceList("SPECIFIC_COMMODITY"),
    countryList: resourceList("COUNTRY"),
    countryZoneList: resourceList("COUNTRY_ZONE"),
    userList: resourceList("USER_LIST"),
    userRolesList: resourceList("USER_ROLES"),
    userDivisionList: resourceList("USER_DIVISIONS"),
    allIpfList: resourceList("ALL_IPF"),
    myIpfList: resourceList("MY_IPF"),
    commodityPriceList: resourceList("COMMODITY_PRICE"),
    commodityPriceAdd: resourceAdd("COMMODITY_PRICE_ADD"),
    commodityPriceConfirm: resourceAdd("COMMODITY_PRICE_CONFIRM"),
    commodityPriceDetail: resourceDetail("COMMODITY_PRICE"),
    commodityPriceUpdate: resourceUpdate("COMMODITY_PRICE"),
    commodityPriceDelete: resourceDelete("COMMODITY_PRICE"),
    incotermList: resourceList("INCOTERM"),
    locationList: resourceList("LOCATION"),
    grantList: resourceList("GRANT"),
    dischargePointList: resourceList("DISCHARGE_POINT"),
    deliveryPointList: resourceList("DELIVERY_POINT"),
    grantDetail: resourceDetail("GRANT"),
    wbsList: resourceList("WBS"),
    projectList: resourceList("PROJECT"),
    ipfViewIpfDetail: resourceDetail("IPF_VIEW_IPF"),
    userDetail: resourceDetail("USER"),
    userSearchDefaultDetail: resourceDetail("USER_SEARCH_DEFAULT"),
    userSearchDefaultAdd: resourceAdd("USER_SEARCH_DEFAULT"),
    commodityPriceModal: showHide("COMMODITY_PRICE_MODAL"),
    requestPriceModal: showHide("REQUEST_PRICE_MODAL"),
    successRequestPriceModal: showHide("SUCCESS_REQUEST_PRICE_MODAL"),
    deleteCommodityPriceModal: showHide("DELETE_COMMODITY_PRICE_MODAL"),
    confirmCommodityPriceModal: showHide("CONFIRM_COMMODITY_PRICE_MODAL"),
    userListCurrentPage: currentPage("USER_LIST"),
    ipfCurrentPage: currentPage("IPF"),
    allIpfCurrentPage: currentPage("ALL_IPF"),
    myIpfCurrentPage: currentPage("MY_IPF"),
    ipfDetailCurrentTab: currentPage("IPF_DETAIL_TAB"),
    commodityPriceCurrentPage: currentPage("COMMODITY_PRICE"),
    activeRecordCommodityPrice: activeRecord("COMMODITY_PRICE"),
    requestPriceAdd: resourceAdd("REQUEST_PRICE"),
    ipfDetailCriteriaStandardErrors: errors("IPF_DETAIL_CRITERIA_STANDARD"),
    ipfDetailCriteriaGcmfErrors: errors("IPF_DETAIL_CRITERIA_GCMF"),
    ipfDetailDestinationRtaStandardErrors: errors("IPF_DETAIL_DESTINATION_RTA_STANDARD"),
    ipfDetailDestinationRtaGcmfErrors: errors("IPF_DETAIL_DESTINATION_RTA_GCMF"),
    ipfDetailCompereSourcesErrors: errors("IPF_DETAIL_COMPARE_SOURCES"),
    ipfDetailCommodityPriceList: resourceList("IPF_DETAIL_COMMODITY_PRICE"),
    importExportRestrictionCurrentPage: currentPage("IMPORT_EXPORT_RESTRICTION"),
    importExportRestrictionList: resourceList("IMPORT_EXPORT_RESTRICTION"),
    importExportRestrictionDetail: resourceDetail("IMPORT_EXPORT_RESTRICTION"),
    importExportRestrictionModal: showHide("IMPORT_EXPORT_RESTRICTION_MODAL"),
    importExportRestrictionUpdate: resourceUpdate("IMPORT_EXPORT_RESTRICTION"),
    importExportRestrictionDelete: resourceDelete("IMPORT_EXPORT_RESTRICTION"),
    activeRecordImportExportRestriction: activeRecord("IMPORT_EXPORT_RESTRICTION"),
    deleteImportExportRestrictionModal: showHide("DELETE_IMPORT_EXPORT_RESTRICTION_MODAL"),
    dischargePointDefaultList: resourceList("DISCHARGE_POINT_DEFAULT"),
    ipfDetailCommodityPriceOptions: resourceAddWithResult("IPF_DETAIL_COMMODITY_PRICE_OPTIONS"),
    ipfDetailPurchaseOptionCurrentPage: currentPage("IPF_DETAIL_PURCHASE_OPTION"),
    ipfDetailSelectSourceErrors: errors("IPF_DETAIL_SELECT_SOURCE"),
    ipfDetailJustificationErrors: errors("IPF_DETAIL_JUSTIFICATION"),
    ipfAdd: resourceAddWithResult("IPF"),
    successIpfAddModal: showHide("SUCCESS_IPF_ADD_MODAL"),
    allIpfFiltersArea: showHide("ALL_IPF_FILTERS_AREA"),
    userFiltersArea: showHide("USER_LIST_FILTERS_AREA"),
    ipfViewImportExportRestrictionList: resourceList("IPF_VIEW_IMPORT_EXPORT_RESTRICTION"),
    ipfDetailImportExportRestrictionList: resourceList("IPF_DETAIL_IMPORT_EXPORT_RESTRICTION"),
    shippingRateList: resourceList("SHIPPING_RATE"),
    shippingRateCurrentPage: currentPage("SHIPPING_RATE"),
    shippingRateCurrentTab: currentPage("SHIPPING_RATE_TAB"),
    loadPortList: resourceList("LOAD_PORT"),
    dischargePortList: resourceList("DISCHARGE_PORT"),
    requestShippingRateModal: showHide("REQUEST_SHIPPING_RATE_MODAL"),
    requestShippingRateAdd: resourceAdd("REQUEST_SHIPPING_RATE"),
    successRequestShippingRateModal: showHide("SUCCESS_REQUEST_SHIPPING_RATE_MODAL"),
    rateContainerList: resourceList("RATE_CONTAINER"),
    rateContainerCurrentPage: currentPage("RATE_CONTAINER"),
    rateContainerModal: showHide("RATE_CONTAINER_MODAL"),
    rateContainerAdd: resourceAdd("RATE_CONTAINER"),
    gcmfZoneList: resourceList("GCMF_ZONE"),
    gcmfSubzoneList: resourceList("GCMF_SUBZONE"),
    intakeRestrictionList: resourceList("INTAKE_RESTRICTION"),
    intakeRestrictionCurrentPage: currentPage("INTAKE_RESTRICTION"),
    intakeRestrictionModal: showHide("INTAKE_RESTRICTION_MODAL"),
    intakeRestrictionAdd: resourceAdd("INTAKE_RESTRICTION"),
    ipfDetailCommodityPriceCount: resourceList("IPF_DETAIL_COMMODITY_PRICE_COUNT"),
    intakeContainerList: resourceList("INTAKE_CONTAINER"),
    intakeContainerCurrentPage: currentPage("INTAKE_CONTAINER"),
    intakeContainerModal: showHide("INTAKE_CONTAINER_MODAL"),
    intakeContainerAdd: resourceAdd("INTAKE_CONTAINER"),
    ipfViewIpfActionList: resourceList("IPF_VIEW_IPF_ACTION"),
    ipfViewIpfActionUpdate: resourceUpdate("IPF_VIEW_IPF_ACTION"),
    ipfViewConfirmIpfActionModal: showHide("IPF_VIEW_CONFIRM_IPF_ACTION_MODAL"),
    ipfViewActiveActionIpfAction: activeAction("IPF_VIEW_IPF_ACTION"),
    ipfDetailIpfDetail: resourceDetail("IPF_DETAIL_IPF"),
    ipfUpdate: resourceUpdate("IPF"),
    clearerList: resourceList("CLEARER"),
    ipfDetailSummaryStandardErrors: errors("IPF_DETAIL_SUMMARY_STANDARD"),
    ipfDetailIpfAttachmentAddMultiple: resourceAddMultiple("IPF_ATTACHMENT"),
    ipfDetailReviewCostsAndTimesErrors: errors("IPF_DETAIL_REVIEW_COST_TIMES"),
    applicationVersion: resourceVersion("APPLICATION"),
    updateApplicationBanner: showHide("UPDATE_APPLICATION_BANNER"),
    rateContainerDelete: resourceDelete("RATE_CONTAINER"),
    deleteRateContainerModal: showHide("DELETE_RATE_CONTAINER_MODAL"),
    intakeRestrictionDelete: resourceDelete("INTAKE_RESTRICTION"),
    deleteIntakeRestrictionModal: showHide("DELETE_INTAKE_RESTRICTION_MODAL"),
    intakeContainerDelete: resourceDelete("INTAKE_CONTAINER"),
    deleteIntakeContainerModal: showHide("DELETE_INTAKE_CONTAINER_MODAL"),
    activeRecordRateContainer: activeRecord("RATE_CONTAINER"),
    activeRecordIntakeRestriction: activeRecord("INTAKE_RESTRICTION"),
    activeRecordIntakeContainer: activeRecord("INTAKE_CONTAINER"),
    rateContainerDetail: resourceDetail("RATE_CONTAINER"),
    rateContainerUpdate: resourceUpdate("RATE_CONTAINER"),
    intakeRestrictionDetail: resourceDetail("INTAKE_RESTRICTION"),
    intakeRestrictionUpdate: resourceUpdate("INTAKE_RESTRICTION"),
    intakeContainerDetail: resourceDetail("INTAKE_CONTAINER"),
    intakeContainerUpdate: resourceUpdate("INTAKE_CONTAINER"),
    packagingTypeList: resourceList("PACKAGING_TYPE"),
    adminCurrentTab: currentPage("ADMIN_TAB"),
    adminUserDetail: resourceDetail("ADMIN_USER_VIEW"),
    adminUserDetailModal: showHide("ADMIN_USER_VIEW_MODAL"),
    successAdminUserDetailModal: showHide("SUCCESS_ADMIN_USER_VIEW_MODAL"),
    adminUserDetailUpdate: resourceUpdate("ADMIN_USER_DETAIL"),
    userAccessRequestAdd: resourceAdd("USER_ACCSESS_REQUEST"),
    successUserAccessRequestModal: showHide("SUCCESS_ADMIN_USER_VIEW_MODAL"),
    supervisorList: resourceList("SUPERVISOR"),
    accessRequestList: resourceList("ACCESS_REQUEST"),
    accessRequestDetail: resourceDetail("ACCESS_REQUEST"),
    accessRequestDetailUpdate: resourceUpdate("ACCESS_REQUEST"),
    accessRequestsCurrentPage: currentPage("ACCESS_REQUEST"),
    accessRequestDetailConfirmModal: showHide("ACCESS_REQUEST_UPDATE_MODAL"),
    accessRequestDetailActiveAction: activeAction("ACCESS_REQUEST_ACTION"),
    accessRequestActionsList: resourceList("ACCESS_REQUEST_ACTIONS"),
  });
