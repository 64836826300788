import React from "react";
import PropTypes from "prop-types";
import { Blockquote } from "@wfp/ui";

import style from "./style.scss";


// A wrapper of the Blockquote component form WFP UI Kit
const MessageError = ({ children }) => (
  <div className={style.wrapper}>
    <Blockquote error>
      {children}
    </Blockquote>
  </div>
);

// propTypes for the MessageError component
MessageError.propTypes = {
  children: PropTypes.node.isRequired
};

export default MessageError;
