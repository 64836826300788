import { dateToString } from "./";
import {  PRICE_TYPES } from "../constants";
import { formatDateDefault, formatNumber } from "../utils";


// A function to adapt Commodity Prices to a custom format
export const adaptCommodityPrices = commodityPrices => commodityPrices.map(commodityPrice => (
  {
    ...commodityPrice,
    price: formatNumber(commodityPrice.price),
    update_date: dateToString(commodityPrice.update_date, "DD-MM-YYYY hh:mm:ss UTC", "YYYY-MM-DD"),
    incoterm_place_name: commodityPrice.incoterm_place && commodityPrice.incoterm_place.name,
    incoterm_place_country: commodityPrice.incoterm_place && commodityPrice.incoterm_place.country && commodityPrice.incoterm_place.country.name,
    packaging_type: commodityPrice.packaging_type || { value: "", description: "" },
    price_type: PRICE_TYPES.find(price => commodityPrice.price_type === price.value) || { value: "", description: "" },
    as_of_date: commodityPrice.as_of_date && formatDateDefault(commodityPrice.as_of_date) || undefined,
    additional_information: {
      packaging_note: commodityPrice.packaging_note,
    }
  }
));