import React, { useState, Component } from "react";
import PropTypes from "prop-types";
import { tooltipStyle } from "@wfp/ui";
import { Tooltip } from "react-tippy";
import enhanceWithClickOutside from "react-click-outside";
import { iconOverflowMenu } from "@wfp/icons";

import Icon from "../Icon";

import style from "./style.scss";


// The  list of items is generated here
export class PopupMenuItems extends Component {

  handleClickOutside() {
    this.props.setOpen(false);
  }

  render() {
    const { id, items, setOpen } = this.props;
    return <ul className={style.items}>
      {
        items.filter(item => item.visible).map(item => (
          <li
            key={item.key}
            onClick={() => {
              setOpen(false);
              item.onClick(id);
            }}
          >
            <Icon
              icon={item.icon}
              width="16"
              height="16"
              fill="#031c2d"
            />
            <span>{item.label}</span>
          </li>
        ))
      }
    </ul>;
  }
}

// propTypes for the PopupMenuItems component
PopupMenuItems.propTypes = {
  id: PropTypes.number.isRequired,
  items: PropTypes.array.isRequired,
  setOpen: PropTypes.func.isRequired
};

export const PopupMenuItemsWithClickOutside = enhanceWithClickOutside(PopupMenuItems);

// A wrapper of the PopupMenu component form WFP UI Kit
// if no item is visible, the menu is hidden
const PopupMenu = ({ id, items }) => {

  const [open, setOpen] = useState(false);

  return items.filter(item => item.visible).length && <Tooltip
    {...tooltipStyle}
    interactive
    trigger="click"
    open={open}
    html={<PopupMenuItemsWithClickOutside id={id} items={items} setOpen={setOpen} />}
  >
    <div onClick={() => setOpen(true)}>
      <Icon
        icon={iconOverflowMenu}
        width="16"
        height="16"
      />
    </div>
  </Tooltip> || "";
};

// propTypes for the PopupMenu component
PopupMenu.propTypes = {
  id: PropTypes.number.isRequired,
  items: PropTypes.array.isRequired
};

export default PopupMenu;
