import React from "react";
import PropTypes from "prop-types";
import { Modal as WFPModal } from "@wfp/ui";


// A wrapper of the Modal component form WFP UI Kit
const Modal = ({ children, isOpen, title, validateText, cancelText, onValidate, onCancel, hideCancelButton }) => (
  <WFPModal
    inPortal={false}
    open={isOpen}
    modalHeading={title}
    primaryButtonText={validateText}
    secondaryButtonText={hideCancelButton ? undefined : cancelText}
    onRequestSubmit={onValidate}
    onSecondarySubmit={onCancel}
    hideClose
  >
    {children}
  </WFPModal>
);

// propTypes for the Modal component
Modal.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  validateText: PropTypes.string,
  cancelText: PropTypes.string,
  onValidate: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  children: PropTypes.node.isRequired,
  hideCancelButton: PropTypes.bool
};

// defaultProps for the Modal component
Modal.defaultProps = {
  isOpen: false,
  validateText: "Validate",
  cancelText: "Cancel",
  onCancel: undefined,
  hideCancelButton: false,
  title: undefined
};


export default Modal;
