import React from "react";
import PropTypes from "prop-types";
import { Button as WFPButton } from "@wfp/ui";

import Icon from "../Icon";

import style from "./style.scss";

// A wrapper of the Button component form WFP UI Kit
const Button = ({ children, onClick, kind, small, icon, disabled }) => (
  <WFPButton onClick={onClick} kind={kind} small={small} disabled={disabled}>
    {
      icon
        ? <div className={style.buttonWithIcon}>
          <span>{children}</span>
          <Icon icon={icon} fill="#ffffff" width="16" height="16" />
        </div>
        : <span>{children}</span>
    }
  </WFPButton>
);

// propTypes for the Button component
Button.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  kind: PropTypes.string,
  small: PropTypes.bool,
  icon: PropTypes.any,
  disabled: PropTypes.bool
};

// defaultProps for the Button component
Button.defaultProps = {
  kind: "primary",
  small: false,
  icon: undefined,
  disabled: false
};

export default Button;