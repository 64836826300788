import client from "../client";


//  Actions available for generic version resource reducer
export const RESOURCE_VERSION_REQUEST_ = "RESOURCE_VERSION_REQUEST_";
export const RESOURCE_VERSION_SUCCESS_ = "RESOURCE_VERSION_SUCCESS_";
export const RESOURCE_VERSION_ERROR_ = "RESOURCE_VERSION_ERROR_";

// Action creator for start fetching a resource
export const resourceVersionRequest = resource => () => {
  return {
    type: `${RESOURCE_VERSION_REQUEST_}${resource}`
  };
};

// Action creator for a successful version resource request
export const resourceVersionSuccess = resource => version => {
  return {
    type: `${RESOURCE_VERSION_SUCCESS_}${resource}`,
    version
  };
};

// Action creator for a successful version resource request
export const resourceVersionError = resource => errorMessage => {
  return {
    type: `${RESOURCE_VERSION_ERROR_}${resource}`,
    errorMessage
  };
};

// Here we dispatch the request action and then, after it is resolved, the success action
// or, if fails, the error action. The cache have to be ignored
export const resourceVersion = (resource) => () => {
  return (dispatch) => {
    dispatch(resourceVersionRequest(resource)());
    return client.get("/version.json", { headers: { "Cache-Control": "no-store" } })
      .then(data => {
        dispatch(resourceVersionSuccess(resource)(data.data.version));
        return data.data.version;
      })
      .catch(error => {
        dispatch(resourceVersionError(resource)(error.message));
        return "";
      });
  };
};
