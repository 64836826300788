import { show, hide } from "./showHide";
import { resourceList, resourceListReset } from "./resourceList";
import { resourceDetail, resourceDetailReset } from "./resourceDetail";
import { resourceAdd, resourceAddReset } from "./resourceAdd";
import { resourceAddWithResult, resourceAddWithResultReset } from "./resourceAddWithResult";
import { resourceUpdate, resourceUpdateReset } from "./resourceUpdate";
import { errors } from "./errors";
import { resourceDelete } from "./resourceDelete";
import { currentPage } from "./currentPage";
import { activeRecord } from "./activeRecord";
import { activeAction } from "./activeAction";
import { resourceAddMultiple } from "./resourceAddMultiple";
import { resourceVersion } from "./resourceVersion";

// List of URLs used to fetch resources
const API_URL_USER_DETAIL = "/api/accounts/user/";
const API_URL_USER_SEARCH_DEFAULT_DETAIL = "/api/accounts/user_search_default/";
const API_URL_USER_SEARCH_DEFAULT_ADD = "/api/accounts/user_search_default/";
const API_URL_SPECIFIC_COMMODITY_LIST = "/api/specific_commodities/";
const API_URL_COUNTRY_LIST = "/api/countries/";
const API_URL_COUNTRY_ZONE_LIST = "/api/countries_zones/";
const API_URL_WBS_LIST = "/api/wbs/";
const API_URL_ALL_IPF_LIST = "/api/purchase_requests/ready";
const API_URL_MY_IPF_LIST = "/api/purchase_requests/mine";
const API_URL_IPF_DETAIL = "/api/purchase_requests/";
const API_URL_IPF_ACTION_LIST = "/api/purchase_requests/";
const API_URL_IPF_ACTION_UPDATE = "/api/purchase_requests/";
const API_URL_IPF_ADD = "/api/purchase_requests/";
const API_URL_PROJECTS_LIST = "/api/projects/";
const API_URL_COMMODITY_PRICE_LIST = "/api/commodity_prices/";
const API_URL_COMMODITY_PRICE_LIST_N0_ZERO = "/api/commodity_prices/valorized";
const API_URL_COMMODITY_PRICE_ADD = "/api/commodity_prices/";
const API_URL_COMMODITY_PRICE_OPTIONS = "/api/commodity_prices/options/";
const API_URL_COMMODITY_PRICE_CONFITM = "/api/commodity_prices/";
const API_URL_REQUEST_PRICE_ADD = "/api/commodity_price_requests/";
const API_URL_COMMODITY_PRICE_UPDATE = "/api/commodity_prices/";
const API_URL_COMMODITY_PRICE_DETAIL = "/api/commodity_prices/";
const API_URL_COMMODITY_PRICE_DELETE = "/api/commodity_prices/";
const API_URL_INCOTERM_LIST = "/api/incoterms/";
const API_URL_LOCATION_LIST = "/api/locations/";
const API_URL_GRANT_LIST = "/api/grants/";
const API_URL_GRANT_DETAIL = "/api/grants/";
const API_URL_DISCHARGE_POINTS = "/api/discharge_points/";
const API_URL_DISCHARGE_POINTS_DEFAULT = "/api/discharge_points/";
const API_URL_DELIVERY_POINTS = "/api/delivery_points/";
const API_URL_IMPORT_EXPORT_RESTRICTION_LIST = "/api/import_export_restrictions/";
const API_URL_IMPORT_EXPORT_RESTRICTION_DETAIL = "/api/import_export_restrictions/";
const API_URL_IMPORT_EXPORT_RESTRICTION_UPDATE = "/api/import_export_restrictions/";
const API_URL_IMPORT_EXPORT_RESTRICTION_DELETE = "/api/import_export_restrictions/";
const API_URL_SHIPPING_RATE_LIST = "/api/shipping_rates/";
const API_URL_RATE_CONTAINER_LIST = "/api/container_rates/";
const API_URL_RATE_CONTAINER_DETAIL = "/api/container_rates/";
const API_URL_INTAKE_RESTRICTION_LIST = "/api/intake_restrictions/";
const API_URL_INTAKE_RESTRICTION_DETAIL = "/api/intake_restrictions/";
const API_URL_PORT_LIST = "/api/ports/";
const API_URL_REQUEST_SHIPPING_RATE_ADD = "/api/shipping_rate_requests/";
const API_URL_RATE_CONTAINER_ADD = "/api/container_rates/";
const API_URL_RATE_CONTAINER_UPDATE = "/api/container_rates/";
const API_URL_RATE_CONTAINER_DELETE = "/api/container_rates/";
const API_URL_INTAKE_RESTRICTION_ADD = "/api/intake_restrictions/";
const API_URL_INTAKE_RESTRICTION_UPDATE = "/api/intake_restrictions/";
const API_URL_INTAKE_RESTRICTION_DELETE = "/api/intake_restrictions/";
const API_URL_GCMF_ZONE_LIST = "/api/gcmf_zones/";
const API_URL_GCMF_SUBZONE_LIST = "/api/gcmf_subzones/";
const API_URL_INTAKE_CONTAINER_LIST = "/api/intake_containers/";
const API_URL_INTAKE_CONTAINER_DETAIL = "/api/intake_containers/";
const API_URL_INTAKE_CONTAINER_ADD = "/api/intake_containers/";
const API_URL_INTAKE_CONTAINER_UPDATE = "/api/intake_containers/";
const API_URL_INTAKE_CONTAINER_DELETE = "/api/intake_containers/";
const API_URL_CLEARER_LIST = "/api/accounts/clearers/";
const API_URL_SUPERVISOR_LIST = "/api/accounts/user_requests/supervisors/";
const API_URL_IPF_ATTACHMENT = "/api/purchase_requests/";
const API_URL_PACKAGING_TYPE = "/api/packaging_types/";
const API_URL_USER_LIST = "/api/accounts/users/";
const API_URL_USER_ROLES_LIST = "/api/accounts/available_roles/";
const API_URL_USER_DIVISIONS_LIST = "/api/accounts/list_divisions/";
const API_URL_ADMIN_USER_DETAIL = "/api/accounts/";
const API_URL_ADMIN_USER_DETAIL_UPDATE = "/api/accounts/";
const API_URL_USER_ACCSESS_REQUEST = "/api/accounts/user_requests/";
const API_URL_USER_ACCSESS_REQUEST_DETAIL = "/api/accounts/user_requests/";
const API_URL_USER_ACCSESS_REQUEST_LIST = "/api/accounts/user_requests/";
const API_URL_USER_ACCESS_REQUEST_LIST = "/api/accounts/user_requests/";

// Action available for application version
export const applicationVersion = resourceVersion("APPLICATION");
export const showUpdateApplicationBanner = show("UPDATE_APPLICATION_BANNER");

// Actions available for related entities resources
export const specificCommodityList = resourceList("SPECIFIC_COMMODITY", API_URL_SPECIFIC_COMMODITY_LIST, {
  page_size: "9999"
});
export const countryList = resourceList("COUNTRY", API_URL_COUNTRY_LIST, { page_size: "9999" });
export const countryZoneList = resourceList("COUNTRY_ZONE", API_URL_COUNTRY_ZONE_LIST, { page_size: "9999" });
export const wbsList = resourceList("WBS", API_URL_WBS_LIST, { page_size: "9999" });
export const wbsListReset = resourceListReset("WBS");
export const projectList = resourceList("PROJECT", API_URL_PROJECTS_LIST, { page_size: "9999" });
export const projectListReset = resourceListReset("PROJECT");
export const incotermList = resourceList("INCOTERM", API_URL_INCOTERM_LIST);
export const locationList = resourceList("LOCATION", API_URL_LOCATION_LIST, { page_size: "9999" });
export const locationListReset = resourceListReset("LOCATION");
export const grantList = resourceList("GRANT", API_URL_GRANT_LIST);
export const grantDetail = resourceDetail("GRANT", API_URL_GRANT_DETAIL);
export const grantDetailReset = resourceDetailReset("GRANT");
export const dischargePointList = resourceList("DISCHARGE_POINT", API_URL_DISCHARGE_POINTS);
export const dischargePointDefaultList = resourceList("DISCHARGE_POINT_DEFAULT", API_URL_DISCHARGE_POINTS_DEFAULT);
export const dischargePointDefaultListReset = resourceListReset("DISCHARGE_POINT_DEFAULT");
export const deliveryPointList = resourceList("DELIVERY_POINT", API_URL_DELIVERY_POINTS, { page_size: "9999" });
export const deliveryPointListReset = resourceListReset("DELIVERY_POINT");
export const loadPortList = resourceList("LOAD_PORT", API_URL_PORT_LIST);
export const dischargePortList = resourceList("DISCHARGE_PORT", API_URL_PORT_LIST);
export const gcmfZoneList = resourceList("GCMF_ZONE", API_URL_GCMF_ZONE_LIST);
export const gcmfSubzoneList = resourceList("GCMF_SUBZONE", API_URL_GCMF_SUBZONE_LIST);
export const gcmfSubzoneListReset = resourceListReset("GCMF_SUBZONE");
export const clearerList = resourceList("CLEARER", API_URL_CLEARER_LIST, { page_size: "9999" });
export const clearerListReset = resourceListReset("CLEARER");
export const packagingTypeList = resourceList("PACKAGING_TYPE", API_URL_PACKAGING_TYPE, { page_size: "9999" });
export const userRolesList = resourceList("USER_ROLES", API_URL_USER_ROLES_LIST);
export const userDivisionList = resourceList("USER_DIVISIONS", API_URL_USER_DIVISIONS_LIST);

// Actions available for Home page
export const allIpfList = resourceList("ALL_IPF", API_URL_ALL_IPF_LIST);
export const myIpfList = resourceList("MY_IPF", API_URL_MY_IPF_LIST);
export const allIpfCurrentPage = currentPage("ALL_IPF");
export const myIpfCurrentPage = currentPage("MY_IPF");
export const showAllIpfFiltersArea = show("ALL_IPF_FILTERS_AREA");
export const hideAllIpfFiltersArea = hide("ALL_IPF_FILTERS_AREA");

// Actions available for IPF view
export const ipfViewImportExportRestrictionList = resourceList(
  "IPF_VIEW_IMPORT_EXPORT_RESTRICTION",
  API_URL_IMPORT_EXPORT_RESTRICTION_LIST
);
export const ipfViewImportExportRestrictionListReset = resourceListReset("IPF_VIEW_IMPORT_EXPORT_RESTRICTION");
export const ipfViewIpfDetail = resourceDetail("IPF_VIEW_IPF", API_URL_IPF_DETAIL);
export const ipfViewIpfActionList = resourceList("IPF_VIEW_IPF_ACTION", API_URL_IPF_ACTION_LIST);
export const ipfViewIpfActionUpdate = resourceUpdate("IPF_VIEW_IPF_ACTION", API_URL_IPF_ACTION_UPDATE);
export const ipfViewShowConfirmIpfActionModal = show("IPF_VIEW_CONFIRM_IPF_ACTION_MODAL");
export const ipfViewHideConfirmIpfActionModal = hide("IPF_VIEW_CONFIRM_IPF_ACTION_MODAL");
export const ipfViewActiveActionIpfAction = activeAction("IPF_VIEW_IPF_ACTION");

// Actions available for Commodity Price page
export const commodityPriceList = resourceList("COMMODITY_PRICE", API_URL_COMMODITY_PRICE_LIST);
export const commodityPriceDetail = resourceDetail("COMMODITY_PRICE", API_URL_COMMODITY_PRICE_DETAIL);
export const commodityPriceDetailReset = resourceDetailReset("COMMODITY_PRICE");
export const commodityPriceAdd = resourceAdd("COMMODITY_PRICE_ADD", API_URL_COMMODITY_PRICE_ADD);
export const commodityPriceConfirm = resourceAdd("COMMODITY_PRICE_CONFIRM", API_URL_COMMODITY_PRICE_CONFITM);
export const commodityPriceAddReset = resourceAddReset("COMMODITY_PRICE_ADD");
export const commodityPriceUpdate = resourceUpdate("COMMODITY_PRICE", API_URL_COMMODITY_PRICE_UPDATE);
export const commodityPriceUpdateReset = resourceUpdateReset("COMMODITY_PRICE");
export const commodityPriceDelete = resourceDelete("COMMODITY_PRICE", API_URL_COMMODITY_PRICE_DELETE);
export const showCommodityPriceModal = show("COMMODITY_PRICE_MODAL");
export const hideCommodityPriceModal = hide("COMMODITY_PRICE_MODAL");
export const showRequestPriceModal = show("REQUEST_PRICE_MODAL");
export const hideRequestPriceModal = hide("REQUEST_PRICE_MODAL");
export const requestPriceAdd = resourceAdd("REQUEST_PRICE", API_URL_REQUEST_PRICE_ADD);
export const requestPriceAddReset = resourceAddReset("REQUEST_PRICE");
export const showSuccessRequestPriceModal = show("SUCCESS_REQUEST_PRICE_MODAL");
export const hideSuccessRequestPriceModal = hide("SUCCESS_REQUEST_PRICE_MODAL");
export const showDeleteCommodityPriceModal = show("DELETE_COMMODITY_PRICE_MODAL");
export const hideDeleteCommodityPriceModal = hide("DELETE_COMMODITY_PRICE_MODAL");
export const showConfirmCommodityPriceModal = show("CONFIRM_COMMODITY_PRICE_MODAL");
export const hideConfirmCommodityPriceModal = hide("CONFIRM_COMMODITY_PRICE_MODAL");
export const activeRecordCommodityPrice = activeRecord("COMMODITY_PRICE");
export const commodityPriceCurrentPage = currentPage("COMMODITY_PRICE");

// Action available for Import/Export Restriction page
export const importExportRestrictionList = resourceList(
  "IMPORT_EXPORT_RESTRICTION",
  API_URL_IMPORT_EXPORT_RESTRICTION_LIST
);
export const importExportRestrictionDetail = resourceDetail(
  "IMPORT_EXPORT_RESTRICTION",
  API_URL_IMPORT_EXPORT_RESTRICTION_DETAIL
);
export const importExportRestrictionDetailReset = resourceDetailReset("IMPORT_EXPORT_RESTRICTION");
export const importExportRestrictionUpdate = resourceUpdate(
  "IMPORT_EXPORT_RESTRICTION",
  API_URL_IMPORT_EXPORT_RESTRICTION_UPDATE
);
export const importExportRestrictionUpdateReset = resourceUpdateReset("IMPORT_EXPORT_RESTRICTION");
export const importExportRestrictionCurrentPage = currentPage("IMPORT_EXPORT_RESTRICTION");
export const showImportExportRestrictionModal = show("IMPORT_EXPORT_RESTRICTION_MODAL");
export const hideImportExportRestrictionModal = hide("IMPORT_EXPORT_RESTRICTION_MODAL");
export const importExportRestrictionDelete = resourceDelete(
  "IMPORT_EXPORT_RESTRICTION",
  API_URL_IMPORT_EXPORT_RESTRICTION_DELETE
);
export const activeRecordImportExportRestriction = activeRecord("IMPORT_EXPORT_RESTRICTION");
export const showDeleteImportExportRestrictionModal = show("DELETE_IMPORT_EXPORT_RESTRICTION_MODAL");
export const hideDeleteImportExportRestrictionModal = hide("DELETE_IMPORT_EXPORT_RESTRICTION_MODAL");

// Actions available for User and User Search Default resources
export const userDetail = resourceDetail("USER", API_URL_USER_DETAIL, "userDetail");
export const userDetailReset = resourceDetailReset("USER");
export const userSearchDefaultDetail = resourceDetail("USER_SEARCH_DEFAULT", API_URL_USER_SEARCH_DEFAULT_DETAIL);
export const userSearchDefaultDetailReset = resourceDetailReset("USER_SEARCH_DEFAULT");
export const userSearchDefaultAdd = resourceAdd("USER_SEARCH_DEFAULT", API_URL_USER_SEARCH_DEFAULT_ADD);

// Actions available for new IPF detail
export const ipfDetailCurrentTab = currentPage("IPF_DETAIL_TAB");
export const ipfDetailCriteriaStandardErrors = errors("IPF_DETAIL_CRITERIA_STANDARD");
export const ipfDetailCriteriaGcmfErrors = errors("IPF_DETAIL_CRITERIA_GCMF");
export const ipfDetailDestinationRtaStandardErrors = errors("IPF_DETAIL_DESTINATION_RTA_STANDARD");
export const ipfDetailDestinationRtaGcmfErrors = errors("IPF_DETAIL_DESTINATION_RTA_GCMF");
export const ipfDetailCompereSourcesErrors = errors("IPF_DETAIL_COMPARE_SOURCES");
export const ipfDetailSelectSourceErrors = errors("IPF_DETAIL_SELECT_SOURCE");
export const ipfDetailJustificationErrors = errors("IPF_DETAIL_JUSTIFICATION");
export const ipfDetailCommodityPriceList = resourceList(
  "IPF_DETAIL_COMMODITY_PRICE",
  API_URL_COMMODITY_PRICE_LIST_N0_ZERO,
  {
    page_size: "9999",
    ordering: ["price"]
  }
);
export const ipfDetailCommodityPriceListReset = resourceListReset("IPF_DETAIL_COMMODITY_PRICE");
export const ipfDetailCommodityPriceCount = resourceList(
  "IPF_DETAIL_COMMODITY_PRICE_COUNT",
  API_URL_COMMODITY_PRICE_LIST,
  {
    page_size: "1"
  }
);
export const ipfDetailCommodityPriceCountReset = resourceListReset("IPF_DETAIL_COMMODITY_PRICE_COUNT");
export const ipfDetailCommodityPriceOptions = resourceAddWithResult(
  "IPF_DETAIL_COMMODITY_PRICE_OPTIONS",
  API_URL_COMMODITY_PRICE_OPTIONS
);
export const ipfDetailCommodityPriceOptionsReset = resourceAddWithResultReset(
  "IPF_DETAIL_COMMODITY_PRICE_OPTIONS",
  API_URL_COMMODITY_PRICE_OPTIONS
);
export const ipfDetailPurchaseOptionCurrentPage = currentPage("IPF_DETAIL_PURCHASE_OPTION");
export const ipfAdd = resourceAddWithResult("IPF", API_URL_IPF_ADD);
export const ipfUpdate = resourceUpdate("IPF", API_URL_IPF_ADD);
export const ipfUpdateReset = resourceUpdateReset("IPF", API_URL_IPF_ADD);
export const ipfAddReset = resourceAddWithResultReset("IPF");
export const showSuccessIpfAddModal = show("SUCCESS_IPF_ADD_MODAL");
export const hideSuccessIpfAddModal = hide("SUCCESS_IPF_ADD_MODAL");
export const ipfDetailImportExportRestrictionList = resourceList(
  "IPF_DETAIL_IMPORT_EXPORT_RESTRICTION",
  API_URL_IMPORT_EXPORT_RESTRICTION_LIST
);
export const ipfDetailImportExportRestrictionListReset = resourceListReset("IPF_DETAIL_IMPORT_EXPORT_RESTRICTION");
export const ipfDetailIpfDetail = resourceDetail("IPF_DETAIL_IPF", API_URL_IPF_DETAIL);
export const ipfDetailIpfDetailReset = resourceDetailReset("IPF_DETAIL_IPF");
export const ipfDetailSummaryStandardErrors = errors("IPF_DETAIL_SUMMARY_STANDARD");
export const ipfDetailIpfAttachmentAddMultiple = resourceAddMultiple("IPF_ATTACHMENT", API_URL_IPF_ATTACHMENT);
export const ipfDetailReviewCostsAndTimesErrors = errors("IPF_DETAIL_REVIEW_COST_TIMES");

// Actions available for Shipping Rates page
export const shippingRateCurrentPage = currentPage("SHIPPING_RATE");

// Actions available for Rate/Mt section
export const shippingRateList = resourceList("SHIPPING_RATE", API_URL_SHIPPING_RATE_LIST);
export const shippingRateCurrentTab = currentPage("SHIPPING_RATE_TAB");
export const showRequestShippingRateModal = show("REQUEST_SHIPPING_RATE_MODAL");
export const hideRequestShippingRateModal = hide("REQUEST_SHIPPING_RATE_MODAL");
export const requestShippingRateAdd = resourceAdd("REQUEST_SHIPPING_RATE", API_URL_REQUEST_SHIPPING_RATE_ADD);
export const requestShippingRateAddReset = resourceAddReset("REQUEST_SHIPPING_RATE");
export const showSuccessRequestShippingRateModal = show("SUCCESS_REQUEST_SHIPPING_RATE_MODAL");
export const hideSuccessRequestShippingRateModal = hide("SUCCESS_REQUEST_SHIPPING_RATE_MODAL");

// Actions available for Rate/Container section
export const rateContainerList = resourceList("RATE_CONTAINER", API_URL_RATE_CONTAINER_LIST);
export const rateContainerCurrentPage = currentPage("RATE_CONTAINER");
export const showRateContainerModal = show("RATE_CONTAINER_MODAL");
export const hideRateContainerModal = hide("RATE_CONTAINER_MODAL");
export const rateContainerAdd = resourceAdd("RATE_CONTAINER", API_URL_RATE_CONTAINER_ADD);
export const rateContainerAddReset = resourceAddReset("RATE_CONTAINER");
export const activeRecordRateContainer = activeRecord("RATE_CONTAINER");
export const rateContainerDelete = resourceDelete("RATE_CONTAINER", API_URL_RATE_CONTAINER_DELETE);
export const showDeleteRateContainerModal = show("DELETE_RATE_CONTAINER_MODAL");
export const hideDeleteRateContainerModal = hide("DELETE_RATE_CONTAINER_MODAL");
export const rateContainerDetail = resourceDetail("RATE_CONTAINER", API_URL_RATE_CONTAINER_DETAIL);
export const rateContainerDetailReset = resourceDetailReset("RATE_CONTAINER");
export const rateContainerUpdate = resourceUpdate("RATE_CONTAINER", API_URL_RATE_CONTAINER_UPDATE);
export const rateContainerUpdateReset = resourceUpdateReset("RATE_CONTAINER");

// Actions available for Intake/Restriction section
export const intakeRestrictionList = resourceList("INTAKE_RESTRICTION", API_URL_INTAKE_RESTRICTION_LIST);
export const intakeRestrictionCurrentPage = currentPage("INTAKE_RESTRICTION");
export const showIntakeRestrictionModal = show("INTAKE_RESTRICTION_MODAL");
export const hideIntakeRestrictionModal = hide("INTAKE_RESTRICTION_MODAL");
export const intakeRestrictionAdd = resourceAdd("INTAKE_RESTRICTION", API_URL_INTAKE_RESTRICTION_ADD);
export const intakeRestrictionAddReset = resourceAddReset("INTAKE_RESTRICTION");
export const activeRecordIntakeRestriction = activeRecord("INTAKE_RESTRICTION");
export const intakeRestrictionDelete = resourceDelete("INTAKE_RESTRICTION", API_URL_INTAKE_RESTRICTION_DELETE);
export const showDeleteIntakeRestrictionModal = show("DELETE_INTAKE_RESTRICTION_MODAL");
export const hideDeleteIntakeRestrictionModal = hide("DELETE_INTAKE_RESTRICTION_MODAL");
export const intakeRestrictionDetail = resourceDetail("INTAKE_RESTRICTION", API_URL_INTAKE_RESTRICTION_DETAIL);
export const intakeRestrictionDetailReset = resourceDetailReset("INTAKE_RESTRICTION");
export const intakeRestrictionUpdate = resourceUpdate("INTAKE_RESTRICTION", API_URL_INTAKE_RESTRICTION_UPDATE);
export const intakeRestrictionUpdateReset = resourceUpdateReset("INTAKE_RESTRICTION");

// Actions available for Intake/Container section
export const intakeContainerList = resourceList("INTAKE_CONTAINER", API_URL_INTAKE_CONTAINER_LIST);
export const intakeContainerCurrentPage = currentPage("INTAKE_CONTAINER");
export const showIntakeContainerModal = show("INTAKE_CONTAINER_MODAL");
export const hideIntakeContainerModal = hide("INTAKE_CONTAINER_MODAL");
export const intakeContainerAdd = resourceAdd("INTAKE_CONTAINER", API_URL_INTAKE_CONTAINER_ADD);
export const intakeContainerAddReset = resourceAddReset("INTAKE_CONTAINER");
export const activeRecordIntakeContainer = activeRecord("INTAKE_CONTAINER");
export const intakeContainerDelete = resourceDelete("INTAKE_CONTAINER", API_URL_INTAKE_CONTAINER_DELETE);
export const showDeleteIntakeContainerModal = show("DELETE_INTAKE_CONTAINER_MODAL");
export const hideDeleteIntakeContainerModal = hide("DELETE_INTAKE_CONTAINER_MODAL");
export const intakeContainerDetail = resourceDetail("INTAKE_CONTAINER", API_URL_INTAKE_CONTAINER_DETAIL);
export const intakeContainerDetailReset = resourceDetailReset("INTAKE_CONTAINER");
export const intakeContainerUpdate = resourceUpdate("INTAKE_CONTAINER", API_URL_INTAKE_CONTAINER_UPDATE);
export const intakeContainerUpdateReset = resourceUpdateReset("INTAKE_CONTAINER");

// Acitons available for User Administration
export const userList = resourceList("USER_LIST", API_URL_USER_LIST);
export const userListCurrentPage = currentPage("USER_LIST");
export const showUserListFiltersArea = show("USER_LIST_FILTERS_AREA");
export const hideUserListFiltersArea = hide("USER_LIST_FILTERS_AREA");

// Actions available for User Admin view
export const adminCurrentTab = currentPage("ADMIN_TAB");
export const adminUserDetail = resourceDetail("ADMIN_USER_VIEW", API_URL_ADMIN_USER_DETAIL);
export const showAdminUserDetailModal = show("ADMIN_USER_VIEW_MODAL");
export const hideAdminUserDetailModal = hide("ADMIN_USER_VIEW_MODAL");
export const showSuccessAdminUserDetailModal = show("SUCCESS_ADMIN_USER_VIEW_MODAL");
export const hideSuccessAdminUserDetailModal = hide("SUCCESS_ADMIN_USER_VIEW_MODAL");
export const adminUserDetailUpdate = resourceUpdate("ADMIN_USER_DETAIL", API_URL_ADMIN_USER_DETAIL_UPDATE);
export const adminUserDetailUpdateReset = resourceUpdateReset("ADMIN_USER_DETAIL");

// Actions availble for User Access Form
export const supervisorList = resourceList("SUPERVISOR", API_URL_SUPERVISOR_LIST, { page_size: "9999" });
export const supervisorListReset = resourceListReset("SUPERVISOR");
export const userAccessRequestAdd = resourceAdd("USER_ACCSESS_REQUEST", API_URL_USER_ACCSESS_REQUEST);
export const userAccessRequestAddReset = resourceAddReset("USER_ACCSESS_REQUEST");
export const showSuccessUserAccessRequestModal = show("SUCCESS_ADMIN_USER_VIEW_MODAL");
export const hideSuccessUserAccessRequestModal = hide("SUCCESS_ADMIN_USER_VIEW_MODAL");

//Actions availble for User Access Requests List
export const accessRequestList = resourceList("ACCESS_REQUEST", API_URL_USER_ACCSESS_REQUEST_LIST);
export const accessRequestsCurrentPage = currentPage("ACCESS_REQUEST");
export const accessRequestDetail = resourceDetail("ACCESS_REQUEST", API_URL_USER_ACCSESS_REQUEST_DETAIL);
export const accessRequestDetailReset = resourceDetailReset("ACCESS_REQUEST");
export const accessRequestDetailUpdate = resourceUpdate("ACCESS_REQUEST", API_URL_USER_ACCSESS_REQUEST_DETAIL);
export const accessRequestDetailUpdateReset = resourceUpdateReset("ACCESS_REQUEST");
export const accessRequestDetailActiveAction = activeAction("ACCESS_REQUEST_ACTION");
export const showAccessRequestDetailConfirmModal = show("ACCESS_REQUEST_UPDATE_MODAL");
export const hideAccessRequestDetailConfirmModal = hide("ACCESS_REQUEST_UPDATE_MODAL");
export const accessRequestActionsList = resourceList("ACCESS_REQUEST_ACTIONS", API_URL_USER_ACCESS_REQUEST_LIST);
