import React from "react";
import PropTypes from "prop-types";
import { Link as WFPLink } from "@wfp/ui";

// A wrapper of the Link component form WFP UI Kit
const Link = ({ children, onClick }) => (
  <WFPLink onClick={onClick} >
    {children}
  </WFPLink>
);

// propTypes for the Link component
Link.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired
};

export default Link;