// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_visible_JWaTT2QXPuN0vT2tyai6wg\\=\\= {\n  display: block;\n}\n\n.style_hidden_QA03\\+3ewnWeynNnEAPYupA\\=\\= {\n  display: none;\n}", "",{"version":3,"sources":["webpack://src/components/ShowHideArea/style.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;AACJ;;AAEA;EACI,aAAA;AACJ","sourcesContent":[".visible {\n    display: block;\n}\n\n.hidden {\n    display: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"visible": "style_visible_JWaTT2QXPuN0vT2tyai6wg==",
	"hidden": "style_hidden_QA03+3ewnWeynNnEAPYupA=="
};
export default ___CSS_LOADER_EXPORT___;
