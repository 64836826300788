import { ERRORS_ } from "../actions/errors";


// A generic reducer used to store some errors to be shown in a page under fields
const errors = element => (state = { errors: { data: {} } }, action) => {
  switch (action.type) {
  case `${ERRORS_}${element}`:
    return { ...state, errors: { data: action.data } };
  default:
    return state;
  }
};

export default errors;