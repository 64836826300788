// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_wrapper_rd0\\+3B5Gs7gTxTcphD7xaQ\\=\\= {\n  position: fixed;\n  top: 0px;\n  left: 0px;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background-color: rgba(255, 255, 255, 0.8);\n  z-index: 9999;\n  text-align: center;\n}\n\n.style_wrapper_rd0\\+3B5Gs7gTxTcphD7xaQ\\=\\= h4 {\n  margin-bottom: 1rem;\n}", "",{"version":3,"sources":["webpack://src/components/Loading/style.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,QAAA;EACA,SAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,0CAAA;EACA,aAAA;EACA,kBAAA;AACF;;AAEA;EACE,mBAAA;AACF","sourcesContent":[".wrapper {\n  position: fixed;\n  top: 0px;\n  left: 0px;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background-color: rgba($color: #ffffff, $alpha: 0.8);\n  z-index: 9999;\n  text-align: center;\n}\n\n.wrapper h4 {\n  margin-bottom: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "style_wrapper_rd0+3B5Gs7gTxTcphD7xaQ=="
};
export default ___CSS_LOADER_EXPORT___;
