import Axios from "axios";

// Creates the default axios client with some defaults
const client = Axios.create({
  baseURL: process.env.API_BASE_URL,
  withXSRFToken: true,
  responseType: "json",
  withCredentials: true,
  xsrfCookieName: `${process.env.CSRFTOKEN}`,
  xsrfHeaderName: "X-CSRFTOKEN",
  headers: {
    "Accept": "application/json",
    "Content-Type": "application/json",
  },
});

export default client;