// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_grantWrapper_L5tkxmV-JcSU6Pksghy\\+DQ\\=\\= {\n  padding: 1rem 0rem;\n}\n\n.style_grantTitle_mpOqDsGHfz6fZN8Un\\+x-og\\=\\= {\n  margin-bottom: 1.2rem;\n  display: block;\n  font-weight: bold;\n  font-size: 0.9rem;\n}\n\n.style_grantLabel_yo3DMKSFnSHsMPOqpmlwtQ\\=\\= {\n  margin: 0.5rem 0rem;\n  display: block;\n  font-weight: bold;\n  font-size: 0.8rem;\n}\n\n.style_grantDescription_u-fde\\+fWnsj\\+co\\+ExPQ6LQ\\=\\= {\n  display: block;\n  font-size: 0.9rem;\n}", "",{"version":3,"sources":["webpack://src/containers/IPFDetail/style.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;;AAEA;EACI,qBAAA;EACA,cAAA;EACA,iBAAA;EACA,iBAAA;AACJ;;AAEA;EACI,mBAAA;EACA,cAAA;EACA,iBAAA;EACA,iBAAA;AACJ;;AAEA;EACI,cAAA;EACA,iBAAA;AACJ","sourcesContent":[".grantWrapper {\n    padding: 1rem 0rem;\n}\n\n.grantTitle {\n    margin-bottom: 1.2rem;\n    display: block;\n    font-weight: bold;\n    font-size: 0.9rem;\n}\n\n.grantLabel {\n    margin: 0.5rem 0rem;\n    display: block;\n    font-weight: bold;\n    font-size: 0.8rem;\n}\n\n.grantDescription {\n    display: block;\n    font-size: 0.9rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grantWrapper": "style_grantWrapper_L5tkxmV-JcSU6Pksghy+DQ==",
	"grantTitle": "style_grantTitle_mpOqDsGHfz6fZN8Un+x-og==",
	"grantLabel": "style_grantLabel_yo3DMKSFnSHsMPOqpmlwtQ==",
	"grantDescription": "style_grantDescription_u-fde+fWnsj+co+ExPQ6LQ=="
};
export default ___CSS_LOADER_EXPORT___;
