import * as Sentry from "@sentry/browser";
import { useEffect } from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

// Configure sentry to log errors in production
export const configureSentry = (force = false) => {
  if (process.env.SENTRY_ENV === "production" || force) {
    Sentry.init({ 
      dsn: "https://41c7cefb9625432393969ccb52445183@o274918.ingest.us.sentry.io/5289021", 
      environment: "production",
      release: process.env.npm_package_version,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
      ],
      tracesSampleRate: 1.0,
      tracePropagationTargets: ["https://api.scips.wfp.org"],
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });
  }
  if (process.env.SENTRY_ENV === "quality" ) {
    Sentry.init({ 
      dsn: "https://41c7cefb9625432393969ccb52445183@o274918.ingest.us.sentry.io/5289021", 
      environment: "quality",
      release: process.env.npm_package_version,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
      ],
      tracesSampleRate: 1.0,
      tracePropagationTargets: ["https://qa.api.scips.wfp.org"],
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });
  }
  if (process.env.SENTRY_ENV === "develop") {
    Sentry.init({ 
      dsn: "https://41c7cefb9625432393969ccb52445183@o274918.ingest.us.sentry.io/5289021", 
      environment: "develop",
      release: process.env.npm_package_version,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
      ],
      tracesSampleRate: 1.0,
      tracePropagationTargets: ["https://dev.scips.wfp.org", /^https:\/\/dev\.scips\.wfp\.org\/api/],
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });
  }
};

// Configure sentry to log errors in production
export const captureException = (error) => {
    Sentry.captureException(error);
};