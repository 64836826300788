import React from "react";
import PropTypes from "prop-types";

import style from "./style.scss";

// A wrappers that align items on right
const AlignRight = ({ children }) => (
  <div className={style.wrapper}>{children}</div>
);

// propTypes for the AlignRight component
AlignRight.propTypes = {
  children: PropTypes.node.isRequired
};

export default AlignRight;
