import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { iconWfpHumEmergencyResponsePos } from "@wfp/icons";

import Icon from "../../components/Icon";
import PageNoTitle from "../../components/PageNoTitle";

import style from "./style.scss";


// A page displayed when user navigate to unknown route
export class NotFound extends Component {
  render() {
    return (
      <PageNoTitle>
        <div className={style.wrapper}>
          <Icon
            icon={iconWfpHumEmergencyResponsePos}
            width="200"
            height="200"
            style={{ marginBottom: "3rem", transform: "rotate(10deg)" }}
          />
          <h1 className="wfp--story__title">Sorry, we couldn&apos;t find that page</h1>
          <p>
            Go back to <Link to="/">Homepage</Link>
          </p>
        </div>
      </PageNoTitle>
    );
  }
}

// The router is used to allow user to navigate to home page
export default withRouter(NotFound);
