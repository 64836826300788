import React from "react";
import PropTypes from "prop-types";

import { displayErrorMessage, formatDateDefault } from "@utils";
import Tag from "@components/Tag";
import Module from "@components/Module";
import { Grid, Row, Col } from "@components/Grid";

import style from "./style.scss";
import {History} from "../../IPFView/components/Detail";

const generateRoleTags = roles => roles.map(role => <Tag key={role.replace(/ /g, "")}>{role}</Tag>);

const AccessRequestDetail = ({ accessRequest, errorMessage }) => {
  return (
    <Module>
      {displayErrorMessage(errorMessage)}
      <div className={style.state}>
        <span className={style.stateLabel}>Current status</span>
        <span className={style.stateTag}>
          <Tag>{accessRequest.state}</Tag>
        </span>
      </div>
      <Grid>
        <Row>
          <Col md={7}>
            <table className={style.details}>
              <tbody>
                <tr>
                  <td>First Name</td>
                  <td>{accessRequest.first_name || "–"}</td>
                </tr>
                <tr>
                  <td>Last Name</td>
                  <td>{accessRequest.last_name || "–"}</td>
                </tr>
                <tr>
                  <td>Username/Email Address</td>
                  <td>{accessRequest.email}</td>
                </tr>
                <tr>
                  <td>Role</td>
                  <td>{accessRequest.role || "–"}</td>
                </tr>
                <tr>
                  <td>Country</td>
                  <td>{accessRequest.country || "–"}</td>
                </tr>
                <tr>
                  <td>Division</td>
                  <td className={style.wrap}>
                    {
                      accessRequest.org_unit_description?
                        accessRequest.org_unit_description.split(",").map((item, idx) => <Tag key={idx}>{item}</Tag>) :
                        accessRequest.divisions &&
                        accessRequest.divisions.divisions &&
                        accessRequest.divisions.divisions.length > 0
                          ? accessRequest.divisions.divisions.map(division => <Tag key={division.id}>{division.name}</Tag>)
                          : "–"}
                  </td>
                </tr>
                <tr>
                  <td>Requested Roles</td>
                  <td className={style.wrap}>
                    {accessRequest.roles_to_grant &&
                    accessRequest.roles_to_grant.roles &&
                    accessRequest.roles_to_grant.roles.length > 0
                      ? generateRoleTags(accessRequest.roles_to_grant.roles)
                      : "–"}
                  </td>
                </tr>
                <tr>
                  <td>Supervisor</td>
                  <td className={style.supervisorDisplay}>
                    {accessRequest.supervisor_name || "-"} <br/>
                    {accessRequest.supervisor} <br/>
                    {accessRequest.supervisor_role || "-"}
                  </td>
                </tr>
                <tr>
                  <td>Submitted</td>
                  <td>{formatDateDefault(accessRequest.submission_date)}</td>
                </tr>
              </tbody>
            </table>
          </Col>
          <Col md={5}>
            <History items={accessRequest.history} />
          </Col>
        </Row>
      </Grid>
    </Module>
  );
};

AccessRequestDetail.propTypes = {
  accessRequest: PropTypes.PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    country: PropTypes.string,
    roles_to_grant: PropTypes.shape({
      roles: PropTypes.arrayOf(PropTypes.string)
    }),
    divisions: PropTypes.shape({
      divisions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string
        })
      )
    }),
    submission_date: PropTypes.string,
    supervisor: PropTypes.string,
    state: PropTypes.string
  }).isRequired,
  errorMessage: PropTypes.string.isRequired
};

export default AccessRequestDetail;
