// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_icon_CU27kcV\\+yHG8guJ5iqaC7Q\\=\\= {\n  margin-bottom: 0.5rem;\n  font-size: 1rem;\n  display: flex;\n  align-items: center;\n}\n\n.style_radio_2OUjGdtaWY-AsTpI0Y\\+Cvw\\=\\= {\n  margin-left: 4px;\n  width: 18px;\n}\n\n.style_totals_BgP9hV3fNovaP\\+iR2VzWQw\\=\\= {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  height: 100%;\n  justify-content: space-between;\n}\n\n.style_bestOption_aevJ57gNjug9x6mfTp4vfw\\=\\= {\n  align-self: flex-start;\n}\n\n.style_totalCost_goRsitEsEuTiDkK\\+Bh\\+0dg\\=\\= {\n  align-self: flex-end;\n  display: block;\n  font-size: 1rem;\n  font-weight: bold;\n}", "",{"version":3,"sources":["webpack://src/containers/IPFDetail/components/SummaryGcmf/style.scss"],"names":[],"mappings":"AAAA;EACI,qBAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,WAAA;AACJ;;AAEA;EACI,aAAA;EACA,sBAAA;EACA,WAAA;EACA,YAAA;EACA,8BAAA;AACJ;;AAEA;EACI,sBAAA;AACJ;;AAEA;EACI,oBAAA;EACA,cAAA;EACA,eAAA;EACA,iBAAA;AACJ","sourcesContent":[".icon {\n    margin-bottom: 0.5rem;\n    font-size: 1rem;\n    display: flex;\n    align-items: center;\n}\n\n.radio {\n    margin-left: 4px;\n    width: 18px;\n}\n\n.totals {\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n    height: 100%;\n    justify-content: space-between;\n}\n\n.bestOption {\n    align-self: flex-start\n}\n\n.totalCost {\n    align-self: flex-end;\n    display: block;\n    font-size: 1rem;\n    font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "style_icon_CU27kcV+yHG8guJ5iqaC7Q==",
	"radio": "style_radio_2OUjGdtaWY-AsTpI0Y+Cvw==",
	"totals": "style_totals_BgP9hV3fNovaP+iR2VzWQw==",
	"bestOption": "style_bestOption_aevJ57gNjug9x6mfTp4vfw==",
	"totalCost": "style_totalCost_goRsitEsEuTiDkK+Bh+0dg=="
};
export default ___CSS_LOADER_EXPORT___;
