import React, { Component, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { reset } from "redux-form";

import PageStandard from "@components/PageStandard";
import Button from "@components/Button";
import ButtonsWrapper from "@components/ButtonsWrapper";
import ConfirmActionModal from "./components/ConfirmActionModal";
import Detail from "./components/Detail";
import Loading from "@components/Loading";
import { formatNumber, openUrl, getStatusErrorMessage, isEmpty } from "@utils";
import { PRICE_TYPES } from "@constants";
import {
  ipfViewIpfDetail,
  ipfViewIpfActionList,
  ipfViewIpfActionUpdate,
  ipfViewActiveActionIpfAction,
  ipfViewShowConfirmIpfActionModal,
  ipfViewHideConfirmIpfActionModal,
  ipfDetailIpfAttachmentAddMultiple,
  ipfViewImportExportRestrictionList,
  ipfViewImportExportRestrictionListReset
} from "@actions";

const API_URL_DOWNLOAD_PDF = `${process.env.API_BASE_URL}/api/purchase_requests/id/pdf`;

// A function to adapt Purchase options to a custom format
export const adaptPurchaseOptions = (purchaseOptions, bestTimeOptions, bestCostOptions) =>
  purchaseOptions.map(purchaseOption => ({
    ...purchaseOption,
    origin_country: purchaseOption.origin_country && purchaseOption.origin_country.name,
    source_type: PRICE_TYPES.find(source => purchaseOption.source_of_supply === source.value) || {
      value: "",
      description: ""
    },
    packaging_type: purchaseOption.packaging_type || { value: "", description: "" },
    incoterm_place_name: purchaseOption.incoterm_place && purchaseOption.incoterm_place.name,
    incoterm_place_country:
      purchaseOption.incoterm_place &&
      purchaseOption.incoterm_place.country &&
      purchaseOption.incoterm_place.country.name,
    incoterm_code: purchaseOption.incoterm && purchaseOption.incoterm.code,
    incoterm_description: purchaseOption.incoterm && purchaseOption.incoterm.description,
    commodity_cost: formatNumber(purchaseOption.commodity_cost),
    ocean_transportation_cost: formatNumber(purchaseOption.ocean_transportation_cost),
    port_handling_other_cost: formatNumber(purchaseOption.port_and_handling_cost),
    overland_transportation_cost: formatNumber(purchaseOption.overland_transportation_cost),
    quality_and_quantity: formatNumber(purchaseOption.quality_and_quantity),
    processing_time: formatNumber(purchaseOption.processing_time),
    sailing_time: formatNumber(purchaseOption.sailing_time),
    port_time: formatNumber(purchaseOption.port_time),
    overland_time: formatNumber(purchaseOption.overland_time),
    total_cost: formatNumber(purchaseOption.cost_per_mt),
    total_time: formatNumber(purchaseOption.total_time),
    additional_information: {
      total_ipf_cost: formatNumber(purchaseOption.total_costs),
      best_time_option: bestTimeOptions.find(option => option === purchaseOption.id) !== undefined,
      best_cost_option: bestCostOptions.find(option => option === purchaseOption.id) !== undefined,
      packaging_note: purchaseOption.packaging_note,
    }
  }));

// A function to generate the list of actions buttons
// when user clicks an action a confirm modal is displayed
export const generateActionsButtons = (actions, onClickAction) => (
  <>
    {actions.map(action => (
      <Button key={action.command} onClick={() => onClickAction(action.command)}>
        {action.label}
      </Button>
    ))}
  </>
);

// A function to generate the message of the confirm action model
export const generateConfirmActionMessage = (actions, currentActionCommand) => {
  const action = actions.find(action => action.command === currentActionCommand);
  return (action && `${action.label}?`) || "";
};

// A function to calculate the percentage above the best purchase option,
// if the non best cost-effective one was selected
export const calculateValuesAboveIpp = (purchaseOptions, bestCostOptions) => {
  const selectedOption = purchaseOptions.find(purchaseOption => purchaseOption.preferred);
  const bestOption =
    bestCostOptions && purchaseOptions.find(purchaseOption => bestCostOptions.find(id => purchaseOption.id === id));
  return (
    (selectedOption &&
      bestOption && {
      percentage: formatNumber((selectedOption.total_costs * 100) / bestOption.total_costs - 100),
      value: formatNumber(selectedOption.total_costs - bestOption.total_costs)
    }) || {
      percentage: "0",
      value: "0"
    }
  );
};

// A component that shows the IPF detail page
export class IPFView extends Component {

  constructor(props) {
    super(props);
    this.state = {
      commentsError: ""
    };
  }
  // On component mount, IPF detail is loaded
  componentDidMount() {
    this.onClickAction = this.onClickAction.bind(this);
    this.onCancelConfirmActionModal = this.onCancelConfirmActionModal.bind(this);
    this.onValidateConfirmActionModal = this.onValidateConfirmActionModal.bind(this);
    this.props.ipfViewIpfDetail(this.props.id);
    this.props.ipfViewIpfActionList(this.props.id);
    this.props.ipfViewImportExportRestrictionList(this.props.projectCountry);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.projectCountry !== this.props.projectCountry) {
      this.props.ipfViewImportExportRestrictionList(this.props.projectCountry);
    }
  }

  // On component unmount store is reset where needed
  componentWillUnmount() {
    this.resetConfirmActionModal();
    this.props.ipfViewImportExportRestrictionListReset();
  }

  // A method used to download PDF related to the IPF
  downloadPdf() {
    openUrl(API_URL_DOWNLOAD_PDF.replace("id", this.props.id));
  }

  // When user clicks an action a confirm model is shown
  onClickAction(action) {
    const editingActions = ["loc_edit", "loc_amend", "int_edit", "int_amend", "gcmf_edit"];
    this.props.ipfViewActiveActionIpfAction(action);
    if (editingActions.indexOf(action) === -1) {
      this.props.ipfViewShowConfirmIpfActionModal();
    } else {
      this.props.ipfViewIpfActionUpdate(this.props.id, action, this.props.comments).then(success => {
        if (success) {
          this.props.history.push(`/IPFDetail/${this.props.id}`);
        }
      });
    }
  }

  // Close the modal to confirm the action
  onCancelConfirmActionModal() {
    this.resetConfirmActionModal();
  }

  // Confirm the action, save attachments, and redirect to edit page
  // it action is edit
  onValidateConfirmActionModal() {
    const action = this.props.activeIpfAction;
    const withdrawActions = ["int_withdraw", "loc_withdraw", "gcmf_withdraw"];
    if (withdrawActions.includes(action) && !this.props.comments) {
      this.setState({
        commentsError: "This field is required"
      });
    }
    else {
      this.props.ipfViewIpfActionUpdate(this.props.id, this.props.activeIpfAction, this.props.comments).then(success => {
        if (success) {
          let requests = [];
          const attachments = document.getElementById("attachments");
          const files = attachments ? attachments.files : {};
          Object.keys(files).map(key => {
            const data = new FormData();
            data.append("file", files[key]);
            requests.push({url: `${this.props.id}/add_attachment/${files[key].name}/`, data});
          });

          this.props.ipfDetailIpfAttachmentAddMultiple(requests).then(() => {
            this.resetConfirmActionModal();
            this.props.ipfViewIpfDetail(this.props.id);
            this.props.ipfViewIpfActionList(this.props.id);
          });
        }
      });
    }
  }

  resetConfirmActionModal() {
    this.props.ipfViewHideConfirmIpfActionModal();
    this.props.ipfViewActiveActionIpfAction("");
    this.props.resetConfirmActionForm();
  }

  render() {
    const {
      isFetching,
      errorMessage,
      ipf,
      importExportRestriction,
      chosenPurchaseOptions,
      otherPurchaseOptions,
      actions,
      isConfirmActionModalOpen,
      errorMessageIpfActionUpdate,
      messageConfirmAction,
      valuesAboveIpp
    } = this.props;
    return (
      <>
        {isFetching ? (
          <Loading isVisible={isFetching}>Loading IPF details...</Loading>
        ) : (
          <PageStandard
            title={`Request ID ${ipf.ipf_id || "-"}`}
            additional={
              <ButtonsWrapper>
                {generateActionsButtons(actions, this.onClickAction)}
                <Button onClick={this.downloadPdf.bind(this)}>Download PDF</Button>
              </ButtonsWrapper>
            }
          >
            <Detail
              errorMessage={errorMessage}
              ipf={ipf}
              importExportRestriction={importExportRestriction}
              chosenPurchaseOptions={chosenPurchaseOptions}
              otherPurchaseOptions={otherPurchaseOptions}
              valuesAboveIpp={valuesAboveIpp}
            />
            <ConfirmActionModal
              isOpen={isConfirmActionModalOpen}
              errorMessage={errorMessageIpfActionUpdate}
              onValidate={this.onValidateConfirmActionModal}
              onCancel={this.onCancelConfirmActionModal}
              commentsError={this.state.commentsError}
              text={messageConfirmAction}
              isLoading={false}
            />
          </PageStandard>
        )}
      </>
    );
  }
}

// propTypes for the IPFView component
IPFView.propTypes = {
  id: PropTypes.string.isRequired,
  isFetching: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  projectCountry: PropTypes.string.isRequired,
  ipf: PropTypes.any.isRequired,
  importExportRestriction: PropTypes.object.isRequired,
  chosenPurchaseOptions: PropTypes.array.isRequired,
  otherPurchaseOptions: PropTypes.array.isRequired,
  ipfViewIpfDetail: PropTypes.func.isRequired,
  ipfViewIpfActionList: PropTypes.func.isRequired,
  ipfViewActiveActionIpfAction: PropTypes.func.isRequired,
  ipfDetailIpfAttachmentAddMultiple: PropTypes.func.isRequired,
  ipfViewImportExportRestrictionListReset: PropTypes.func.isRequired,
  ipfViewImportExportRestrictionList: PropTypes.func.isRequired,
  ipfViewShowConfirmIpfActionModal: PropTypes.func.isRequired,
  ipfViewHideConfirmIpfActionModal: PropTypes.func.isRequired,
  ipfViewIpfActionUpdate: PropTypes.func.isRequired,
  activeIpfAction: PropTypes.string.isRequired,
  actions: PropTypes.array.isRequired,
  isConfirmActionModalOpen: PropTypes.bool.isRequired,
  errorMessageIpfActionUpdate: PropTypes.string.isRequired,
  messageConfirmAction: PropTypes.string.isRequired,
  history: PropTypes.any.isRequired,
  resetConfirmActionForm: PropTypes.func.isRequired,
  comments: PropTypes.string.isRequired,
  valuesAboveIpp: PropTypes.object.isRequired
};

// Starting from the redux state it gets data related to an IPF detail
export const mapStateToProps = (state, ownProps) => {
  return {
    // Properties related to IPF detail
    id: ownProps.match.params.id,
    ipf: state.ipfViewIpfDetail.data,
    errorMessage: state.ipfViewIpfDetail.errorMessage,
    // Properties related to actions user can perform and current action
    // comments are enabled only if rejected or endorse
    actions:
      (state.ipfViewIpfActionList.data &&
        state.ipfViewIpfActionList.data.actions &&
        state.ipfViewIpfActionList.data.actions.filter(
          action => action.command !== "submit_to_clearer" && action.command !== "submit_to_approver"
        )) ||
      [],
    activeIpfAction: state.ipfViewActiveActionIpfAction.command,
    isConfirmActionModalOpen: state.ipfViewConfirmIpfActionModal.isVisible,
    errorMessageIpfActionUpdate:
      (!isEmpty(state.ipfViewIpfActionUpdate.error) && getStatusErrorMessage(state.ipfViewIpfActionUpdate.error)) || "",
    messageConfirmAction:
      (state.ipfViewIpfActionList.data &&
        state.ipfViewIpfActionList.data.actions &&
        generateConfirmActionMessage(
          state.ipfViewIpfActionList.data.actions,
          state.ipfViewActiveActionIpfAction.command
        )) ||
      "",
    comments:
      (state.form.confirmActionForm &&
        state.form.confirmActionForm.values &&
        state.form.confirmActionForm.values.comments) ||
      "",
    // Properties related to loading status
    isFetchingIPFView: state.ipfViewIpfDetail.isFetching,
    // Purchase options properties
    isFetching:
      state.ipfViewIpfDetail.isFetching ||
      !state.ipfViewIpfDetail.data.id ||
      state.ipfViewIpfActionList.isFetching ||
      state.ipfViewImportExportRestrictionList.isFetching,
    // Purchase options properties
    chosenPurchaseOptions:
      (state.ipfViewIpfDetail.data &&
        state.ipfViewIpfDetail.data.purchase_options &&
        state.ipfViewIpfDetail.data.purchase_options.length &&
        adaptPurchaseOptions(
          state.ipfViewIpfDetail.data.purchase_options.filter(purchaseOption => purchaseOption.preferred),
          state.ipfViewIpfDetail.data.best_time_options,
          state.ipfViewIpfDetail.data.best_costs_options
        )) ||
      [],
    otherPurchaseOptions:
      (state.ipfViewIpfDetail.data &&
        state.ipfViewIpfDetail.data.purchase_options &&
        state.ipfViewIpfDetail.data.purchase_options.length &&
        adaptPurchaseOptions(
          state.ipfViewIpfDetail.data.purchase_options.filter(purchaseOption => !purchaseOption.preferred),
          state.ipfViewIpfDetail.data.best_time_options,
          state.ipfViewIpfDetail.data.best_costs_options
        )) ||
      [],
    valuesAboveIpp: calculateValuesAboveIpp(
      (state.ipfViewIpfDetail.data &&
        state.ipfViewIpfDetail.data.purchase_options &&
        state.ipfViewIpfDetail.data.purchase_options.length &&
        state.ipfViewIpfDetail.data.purchase_options) ||
        [],
      state.ipfViewIpfDetail.data.best_costs_options || []
    ),
    projectCountry:
      (state.ipfViewIpfDetail.data &&
        state.ipfViewIpfDetail.data.project_country &&
        state.ipfViewIpfDetail.data.project_country.iso_code) ||
      "",
    // Import/Export Restrictions related data
    importExportRestriction:
      (state.ipfViewImportExportRestrictionList &&
        state.ipfViewImportExportRestrictionList.data &&
        state.ipfViewImportExportRestrictionList.data.count &&
        state.ipfViewImportExportRestrictionList.data.results[0]) ||
      {},
  };
};

// Maps functions to dispatch actions
export const mapDispatchToProps = dispatch => {
  return {
    ipfViewIpfDetail: id => dispatch(ipfViewIpfDetail(id)),
    ipfViewIpfActionList: id => dispatch(ipfViewIpfActionList({}, `${id}/actions/`)),
    ipfViewIpfActionUpdate: (id, action, comments) =>
      dispatch(ipfViewIpfActionUpdate(id, { comments }, `perform/${action}/`)),
    ipfViewShowConfirmIpfActionModal: () => dispatch(ipfViewShowConfirmIpfActionModal()),
    ipfViewHideConfirmIpfActionModal: () => dispatch(ipfViewHideConfirmIpfActionModal()),
    ipfViewActiveActionIpfAction: action => dispatch(ipfViewActiveActionIpfAction(action)),
    ipfDetailIpfAttachmentAddMultiple: requests => dispatch(ipfDetailIpfAttachmentAddMultiple(requests)),
    resetConfirmActionForm: () => dispatch(reset("confirmActionForm")),
    ipfViewImportExportRestrictionList: country => dispatch(ipfViewImportExportRestrictionList({ country: [country] })),
    ipfViewImportExportRestrictionListReset: () => dispatch(ipfViewImportExportRestrictionListReset())
  };
};

// The form is added to the redux store with a given name
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(IPFView));
