// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_wrapper_eTtODinBd8h\\+DFJ62HdAfA\\=\\= {\n  position: fixed;\n  height: 100%;\n  width: 100%;\n  top: 0px;\n  left: 0px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n}\n\n.style_icon_62JGQ6f\\+PCkQMSeRBkjF\\+g\\=\\= {\n  margin-bottom: 1em;\n}", "",{"version":3,"sources":["webpack://src/containers/ErrorMessage/style.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,YAAA;EACA,WAAA;EACA,QAAA;EACA,SAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;AACF;;AAEA;EACE,kBAAA;AACF","sourcesContent":[".wrapper {\n  position: fixed;\n  height: 100%;\n  width: 100%;\n  top: 0px;\n  left: 0px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  text-align: center\n}\n\n.icon {\n  margin-bottom: 1em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "style_wrapper_eTtODinBd8h+DFJ62HdAfA==",
	"icon": "style_icon_62JGQ6f+PCkQMSeRBkjF+g=="
};
export default ___CSS_LOADER_EXPORT___;
