// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_searchButtonWrapper_-vKIC5bNT5DXN5f2cLduqg\\=\\= {\n  align-self: flex-end;\n}", "",{"version":3,"sources":["webpack://src/containers/ImportExportRestrictions/components/ImportExportRestrictionsList/style.scss"],"names":[],"mappings":"AAAA;EACI,oBAAA;AACJ","sourcesContent":[".searchButtonWrapper {\n    align-self: flex-end;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchButtonWrapper": "style_searchButtonWrapper_-vKIC5bNT5DXN5f2cLduqg=="
};
export default ___CSS_LOADER_EXPORT___;
