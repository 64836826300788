// Actions available to be used by the generic current page reducer
export const ACTIVE_ACTION_ = "ACTIVE_ACTION_";


// Action creator to set the active action that user is performing
// usually, when user select an action to perform
// he he have to confirm the action
// so it have to be stored in the store
// to be used when user confirm the action
export const activeAction = (element) => command => {
  return {
    type: `${ACTIVE_ACTION_}${element}`,
    command: command
  };
};