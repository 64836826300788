import client from "../client";


//  Actions available for generic list resource reducer
export const RESOURCE_LIST_REQUEST_ = "RESOURCE_LIST_REQUEST_";
export const RESOURCE_LIST_SUCCESS_ = "RESOURCE_LIST_SUCCESS_";
export const RESOURCE_LIST_ERROR_ = "RESOURCE_LIST_ERROR_";
export const RESOURCE_LIST_RESET_ = "RESOURCE_LIST_RESET_";

// Action creator for start fetching a resource
export const resourceListRequest = resource => () => {
  return {
    type: `${RESOURCE_LIST_REQUEST_}${resource}`
  };
};

// Action creator for a successful list resource request
export const resourceListSuccess = resource => data => {
  return {
    type: `${RESOURCE_LIST_SUCCESS_}${resource}`,
    data
  };
};

// Action creator for a successful list resource request
export const resourceListError = resource => errorMessage => {
  return {
    type: `${RESOURCE_LIST_ERROR_}${resource}`,
    errorMessage
  };
};

// Action creator for resetting the data
export const resourceListReset = resource => () => {
  return {
    type: `${RESOURCE_LIST_RESET_}${resource}`,
  };
};

// Here we dispatch the request action and then, after it is resolved, the success action
// or, if fails, the error action
export const resourceList = (resource, url, defaultParams = {}) => (params = {}, appendUrl = "") => {
  return dispatch => {
    dispatch(resourceListRequest(resource)());
    return client.get(`${url}${appendUrl}`, { params: {...defaultParams, ...params} })
      .then(result => {
        dispatch(resourceListSuccess(resource)(result.data));
        return result.data;
      })
      .catch(error => {
        dispatch(resourceListError(resource)(error.message));
        return {};
      });
  };
};
