import client from "../client";
import { isEmpty } from "../utils";

//  Actions available for generic detail resource reducer
export const RESOURCE_DETAIL_REQUEST_ = "RESOURCE_DETAIL_REQUEST_";
export const RESOURCE_DETAIL_SUCCESS_ = "RESOURCE_DETAIL_SUCCESS_";
export const RESOURCE_DETAIL_ERROR_ = "RESOURCE_DETAIL_ERROR_";
export const RESOURCE_DETAIL_RESET_ = "RESOURCE_DETAIL_RESET_";

// Action creator for start fetching a resource
export const resourceDetailRequest = resource => () => {
  return {
    type: `${RESOURCE_DETAIL_REQUEST_}${resource}`
  };
};

// Action creator for a successful detail resource request
export const resourceDetailSuccess = resource => data => {
  return {
    type: `${RESOURCE_DETAIL_SUCCESS_}${resource}`,
    data
  };
};

// Action creator for a successful detail resource request
export const resourceDetailError = resource => errorMessage => {
  return {
    type: `${RESOURCE_DETAIL_ERROR_}${resource}`,
    errorMessage
  };
};

// Action creator for resetting the data
export const resourceDetailReset = resource => () => {
  return {
    type: `${RESOURCE_DETAIL_RESET_}${resource}`
  };
};

// Here we dispatch the request action and then, after it is resolved, the success action
// or, if fails, the error action
export const resourceDetail = (resource, url, cacheName) => (id = "", suffix = "") => {
  return (dispatch, getState) => {
    if (cacheName && getState()[cacheName] && !isEmpty(getState()[cacheName].data)) {
      dispatch(resourceDetailSuccess(resource)(getState()[cacheName].data));
      return true;
    } else {
      dispatch(resourceDetailRequest(resource)());
      return client
        .get(`${url}${id}${suffix}`)
        .then(data => {
          dispatch(resourceDetailSuccess(resource)(data.data));
          return true;
        })
        .catch(error => {
          dispatch(resourceDetailError(resource)(error.message));
          return false;
        });
    }
  };
};
