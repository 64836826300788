import React from "react";
import PropTypes from "prop-types";
import { reduxForm, Field } from "redux-form";
import { iconEdit, iconDelete, iconCheckmarkOutline, iconDownloadGlyph } from "@wfp/icons";

import { displayErrorMessage } from "../../../../utils";
import { twoLineCell, headerWithInformation, menu, tagCell } from "../../../../utils/table";
import Table from "../../../../components/Table";
import Button from "../../../../components/Button";
import Link from "../../../../components/Link";
import AlignRight from "../../../../components/AlignRight";
import Select from "../../../../components/Select";
import Checkbox from "../../../../components/Checkbox";
import { Grid, Row, Col } from "../../../../components/Grid";
import Module from "../../../../components/Module";
import FiltersWrapper from "../../../../components/FiltersWrapper";
import ButtonsWrapper from "../../../../components/ButtonsWrapper";
import { alignRightCell } from "@utils/table";

import style from "./style.scss";

// The list of columns and their properties
export const columns = (
  onClickEdit,
  onClickDelete,
  onClickConfirm,
  canChangeCommodityPrice,
  canDeleteCommodityPrice
) => [
  {
    Header: () => null,
    id: 'expander',
    Cell: props => 
      alignRightCell(<div id="expander" className={props.arrowBox} {...props.row?.getToggleRowExpandedProps()}>
        <div className={props.arrowStyle}></div>
      </div>)
  },
  {
    Header: "Named place",
    Cell: props =>
      twoLineCell(props.row?.original.incoterm_place_name, props.row?.original.origin_country && props.row?.original.origin_country.name),
    width: 180
  },
  {
    Header: "Incoterm",
    accessor: "incoterm.code",
    width: 70
  },
  {
    Header: "Source",
    Cell: props => tagCell(props.row?.original.price_type.description),
    width: 110
  },
  {
    Header: "Commodity",
    accessor: "specific_commodity.description",
    width: 180,
    className: style.multilineCell
  },
  {
    Header: "Packaging",
    accessor: "packaging_type.description",
    width: 250,
    className: style.multilineCell
  },
  {
    Header: () => headerWithInformation("Price", "US$ / Mt"),
    accessor: "price",
    width: 90
  },
  {
    Header: "Effective from",
    accessor: "as_of_date",
    width: 100
  },
  {
    Header: "Created by",
    accessor: "update_user.full_name",
    width: 100
  },
  {
    Header: "Action",
    Cell: props =>
      menu(props.row?.original.id, [
        {
          key: props.row?.original.id,
          icon: iconEdit,
          label: "Edit price",
          onClick: onClickEdit,
          visible: canChangeCommodityPrice
        },
        {
          key: props.row?.original.id + 1,
          icon: iconCheckmarkOutline,
          label: "Confirm price",
          onClick: onClickConfirm,
          visible: canChangeCommodityPrice
        },
        {
          key: props.row?.original.id + 2,
          icon: iconDelete,
          label: "Delete price",
          onClick: onClickDelete,
          visible: canDeleteCommodityPrice
        }
      ]),
    width: 30
  }
];

// A component used to display additional information in a expand section
export const AdditionalInformation = ({ packaging_note }) => (
  <div className={style.additionalInformation}>
    <table>
      <tbody>
        <tr>
          <td>Note</td>
          <td>{packaging_note}</td>
        </tr>
      </tbody>
    </table>
  </div>
);

// propTypes for the AdditionalInformation component
AdditionalInformation.propTypes = {
  packaging_note: PropTypes.string
};

// defaultProps for the AdditionalInformation component
AdditionalInformation.defaultProps = {
  packaging_note: ""
};

// The CommodityPriceList component is used to display a list of Commodity Prices
const CommodityPriceList = ({
  commodityPrices,
  errorMessageCommodityPrices,
  errorMessageFilters,
  loadingFilters,
  countries,
  specificCommodities,
  incoterms,
  totalItems,
  page,
  onPageChange,
  loadingCommodityPrices,
  onClickSearch,
  onClickEdit,
  onClickDelete,
  onClickReset,
  onClickResetPreset,
  loadingPresets,
  onClickConfirm,
  canChangeCommodityPrice,
  canDeleteCommodityPrice,
  onClickExport
}) => (
  <>
    {displayErrorMessage(errorMessageCommodityPrices)}
    {displayErrorMessage(errorMessageFilters)}
    <Module
      title="Commodity Prices"
      filter={
        <ButtonsWrapper>
          <Button icon={iconDownloadGlyph} disabled={!commodityPrices.length} onClick={onClickExport}>
            Export
          </Button>
        </ButtonsWrapper>
      }
      table={
        <Table
          loading={loadingCommodityPrices}
          columns={columns(
            onClickEdit,
            onClickDelete,
            onClickConfirm,
            canChangeCommodityPrice,
            canDeleteCommodityPrice
          )}
          data={commodityPrices.map(d => {
            return {
            ...d,
            subRows: undefined
          }})}
          totalItems={totalItems}
          page={page}
          onPageChange={onPageChange}
          subComponent={{ component: AdditionalInformation, dataKey: "additional_information" }}
        />
      }
    >
      <FiltersWrapper>
        <Grid>
          <Row>
            <Col md={4}>
              <Field
                isMulti
                component={Select}
                name="countries"
                id="countries"
                label="Country"
                options={countries}
                loading={loadingFilters}
              />
            </Col>
            <Col md={3}>
              <Field
                isMulti
                component={Select}
                name="commodities"
                id="commodities"
                label="Commodity"
                options={specificCommodities}
                loading={loadingFilters}
              />
            </Col>
            <Col md={4}>
              <Field
                isMulti
                component={Select}
                name="incoterms"
                id="incoterms"
                label="Incoterm"
                options={incoterms}
                loading={loadingFilters}
              />
            </Col>
            <Col md={1} className={style.searchButtonWrapper}>
              <Button onClick={onClickSearch}>Find</Button>
            </Col>
          </Row>
        </Grid>
      </FiltersWrapper>
      <FiltersWrapper>
        <Grid>
          <Row>
            <Col md={3}>
              {loadingPresets ? (
                <span>Loading...</span>
              ) : (
                <Field
                  component={Checkbox}
                  name="savePreset"
                  id="savePreset"
                  text="Save this as your default preset"
                  single
                />
              )}
            </Col>
            <Col md={3}>{loadingPresets ? " " : <Link onClick={onClickResetPreset}>Remove default presets</Link>}</Col>
            <Col md={6}>
              <AlignRight>
                <Link onClick={onClickReset}>Clear form</Link>
              </AlignRight>
            </Col>
          </Row>
        </Grid>
      </FiltersWrapper>
    </Module>
  </>
);

// propTypes for the List component
CommodityPriceList.propTypes = {
  commodityPrices: PropTypes.array.isRequired,
  errorMessageCommodityPrices: PropTypes.string.isRequired,
  loadingFilters: PropTypes.bool.isRequired,
  errorMessageFilters: PropTypes.string.isRequired,
  totalItems: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  loadingCommodityPrices: PropTypes.bool.isRequired,
  specificCommodities: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.any.isRequired
    })
  ),
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.any.isRequired
    })
  ),
  incoterms: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.any.isRequired
    })
  ),
  onClickSearch: PropTypes.func.isRequired,
  onClickEdit: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  onClickReset: PropTypes.func.isRequired,
  onClickResetPreset: PropTypes.func.isRequired,
  loadingPresets: PropTypes.bool.isRequired,
  onClickConfirm: PropTypes.func.isRequired,
  canChangeCommodityPrice: PropTypes.bool.isRequired,
  canDeleteCommodityPrice: PropTypes.bool.isRequired,
  onClickExport: PropTypes.func.isRequired
};

export default reduxForm({ form: "commodityPricesFilters", enableReinitialize: true })(CommodityPriceList);
