import React from "react";
import PropTypes from "prop-types";

import { DATE_FORMAT_DEFAULT, DATE_FORMAT_DATE_PICKER } from "../../../../constants";
import { dateToString } from "../../../../utils";

import style from "./style.scss";


// The SelectedDataGcmf shows a summary of selected data
const SelectedDataGcmf = ({ values, destination }) => (
  <table className={style.summary}>
    <tbody>
      <tr>
        <td>
          Commodity
        </td>
        <td>
          {values.commodity && values.commodity.label}
        </td>
        <td>
          Quantity
        </td>
        <td>
          {values.quantity}
        </td>
      </tr>
      <tr>
        <td>
          GCMF supply line
        </td>
        <td>
          {values.gcmf_zone && values.gcmf_zone.label}
        </td>
        <td>
          GCMF corridor
        </td>
        <td>
          {values.gcmf_subzone && values.gcmf_subzone.label}
        </td>
      </tr>
      <tr>
        <td>
          WBS element
        </td>
        <td>
          {values.gcmf_subzone && values.gcmf_subzone.data && values.gcmf_subzone.data.wbs}
        </td>
        <td>
          &nbsp;
        </td>
        <td>
          &nbsp;
        </td>
      </tr>
      <tr>
        <td>
          Discharge port
        </td>
        <td>
          {destination.discharge_port && destination.discharge_port.label}
        </td>
        <td>
          RTA
        </td>
        <td>
          {destination.rta_discharge_port && dateToString(destination.rta_discharge_port, DATE_FORMAT_DATE_PICKER, DATE_FORMAT_DEFAULT)}
        </td>
      </tr>
      <tr>
        <td>
          Final destination point
        </td>
        <td>
          {destination.discharge_port && destination.discharge_port.label}
        </td>
        <td>
          RTA
        </td>
        <td>
          {destination.rta_destination_point && dateToString(destination.rta_destination_point, DATE_FORMAT_DATE_PICKER, DATE_FORMAT_DEFAULT)}
        </td>
      </tr>
    </tbody>
  </table>
);

// propTypes for the SelectedDataGcmf component
SelectedDataGcmf.propTypes = {
  values: PropTypes.object.isRequired,
  destination: PropTypes.any
};

// defaultProps for the SelectedDataGcmf component
SelectedDataGcmf.defaultProps = {
  destination: undefined
};

export default SelectedDataGcmf;