// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_grid_MutYybbfLcIC5Z\\+fjfYudA\\=\\= {\n  margin: 0px -8px;\n}", "",{"version":3,"sources":["webpack://src/components/Grid/style.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF","sourcesContent":[".grid {\n  margin: 0px -8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grid": "style_grid_MutYybbfLcIC5Z+fjfYudA=="
};
export default ___CSS_LOADER_EXPORT___;
