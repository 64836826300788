import React from "react";
import { tagCell } from "./table";
import Button from "../components/Button";
import style from "./style.scss";

// A component to render a button inside the list to view the IPF Detail
export const generateViewButton = (id, onClick) => (
  <Button small onClick={() => onClick(id)}>
    View
  </Button>
);

export const generateEditButton = (id, onClick) => (
  <Button small onClick={() => onClick(id)}>
    Edit
  </Button>
);

// The list of columns and their properties
export const columns = onClickButton => [
  {
    Header: "First Name",
    accessor: "first_name",
    width: 130
  },
  {
    Header: "Last Name",
    accessor: "last_name",
    width: 130
  },
  {
    Header: "User Name/Email Address",
    accessor: "email",
    width: 240
  },
  {
    Header: "Country",
    accessor: "country",
    width: 100
  },
  {
    Header: "Division",
    Cell: props =>
      props.row?.original && props.row?.original.org_unit_description?
        props.row?.original.org_unit_description.split(",").map((item) => tagCell(item)) :
        props.row?.original && props.row?.original.divisions && props.row?.original.divisions.divisions && props.row?.original.divisions.divisions.length > 0
          ? props.row?.original.divisions.divisions.map(division => tagCell(division.name, division.id))
          : "–",
    className: style.multilineColumn
  },
  {
    Header: "Roles Requested",
    Cell: props => props.row?.original && props.row?.original.roles_to_grant ?
    props.row?.original.roles_to_grant.roles.filter(
      (role) => role.toLowerCase() !== "public"
    ).map(role => tagCell(role, role.replace(/ /g, ""))) : "-",
    className: style.multilineColumn
  },
  {
    Header: "Status",
    Cell: props => props.row?.original && props.row?.original.state? 
    props.row?.original.state.charAt(0).toUpperCase() + props.row?.original.state.slice(1) : "-",
    width: 100
  },
  {
    Header: "Edit",
    width: 70,
    Cell: props => props.row?.original && props.row?.original.state ?
    props.row?.original.state === "draft"? generateEditButton(props.row?.original.id, onClickButton):
      generateViewButton(props.row?.original.id, onClickButton) : "-"
  }
];
