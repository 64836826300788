import { RESOURCE_DELETE_REQUEST_, RESOURCE_DELETE_SUCCESS_, RESOURCE_DELETE_ERROR_ } from "../actions/resourceDelete";


// A generic reducer used to delete a resource
const resourceDelete = resource => (state = { isLoading: false, errorMessage: "" }, action) => {
  switch (action.type) {
  case `${RESOURCE_DELETE_REQUEST_}${resource}`:
    return { ...state, isLoading: true, errorMessage: "" };
  case `${RESOURCE_DELETE_SUCCESS_}${resource}`:
    return { ...state, isLoading: false, errorMessage: "" };
  case `${RESOURCE_DELETE_ERROR_}${resource}`:
    return { ...state, isLoading: false, errorMessage: action.errorMessage };
  default:
    return state;
  }
};

export default resourceDelete;