import client from "../client";

// Actions available for generic update resource reducer
export const RESOURCE_UPDATE_REQUEST_ = "RESOURCE_UPDATE_REQUEST_";
export const RESOURCE_UPDATE_SUCCESS_ = "RESOURCE_UPDATE_SUCCESS_";
export const RESOURCE_UPDATE_ERROR_ = "RESOURCE_UPDATE_ERROR_";
export const RESOURCE_UPDATE_RESET_ = "RESOURCE_UPDATE_RESET_";

// Action creator for start updating a resource
export const resourceUpdateRequest = resource => () => {
  return {
    type: `${RESOURCE_UPDATE_REQUEST_}${resource}`
  };
};

// Action creator for a successful update resource request
export const resourceUpdateSuccess = resource => data => {
  return {
    type: `${RESOURCE_UPDATE_SUCCESS_}${resource}`,
    data
  };
};

// Action creator for a successful list resource request
export const resourceUpdateError = resource => error => {
  return {
    type: `${RESOURCE_UPDATE_ERROR_}${resource}`,
    error
  };
};

// Action creator for resetting the data
export const resourceUpdateReset = resource => () => {
  return {
    type: `${RESOURCE_UPDATE_RESET_}${resource}`,
  };
};

// Here we dispatch the request action and then, after it is resolved, the success action
// or, if fails, the error action
export const resourceUpdate = (resource, url) => (id, data, appendUrl = "") => {
  return dispatch => {
    dispatch(resourceUpdateRequest(resource)());
    return client.put(`${url}${id}/${appendUrl}`, data)
      .then(result => {
        dispatch(resourceUpdateSuccess(resource)(result.data));
        return true;
      })
      .catch(error => {
        dispatch(resourceUpdateError(resource)(error.response));
        return false;
      });
  };
};
