import React from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { Breadcrumb as WFPBreadcrumb, BreadcrumbItem, BreadcrumbHome } from "@wfp/ui";

// Builds a list of links starting from a given location
const routes = [
  {
    value: "",
    title: "Home"
  },
  {
    value: "IPFDetail",
    title: "IPF Detail"
  },
  {
    value: "IPFView",
    title: "View IPF"
  },
  {
    value: "commodityPrices",
    title: "Commodity Prices"
  },
  {
    value: "importExportRestrictions",
    title: "Import/Export Restrictions"
  },
  {
    value: "shippingRates",
    title: "Shipping Rates"
  },
  {
    value: "administration",
    title: "Admin"
  },
  {
    value: "userAccess",
    title: "User Access Form"
  },
  {
    value: "userView",
    title: "View User"
  },
  {
    value: "accessRequestView",
    title: "User Access Request"
  }
];

// Gets the current route starting from the current location
export const getLink = pathname => {
  const link = routes.find(route => route.value === pathname.split("/")[1]);
  if (link) {
    return (
      <BreadcrumbItem key={pathname}>
        <Link to={pathname}>{link.title}</Link>
      </BreadcrumbItem>
    );
  }
  return (
    <BreadcrumbItem key={pathname}>
      <Link to={pathname}>Not Found</Link>
    </BreadcrumbItem>
  );
};

// A breadcrumb component that allows user to navigate through application's pages
const Breadcrumb = ({ pathname }) => (
  <WFPBreadcrumb>
    <BreadcrumbItem>
      <Link to="/">
        <BreadcrumbHome />
      </Link>
    </BreadcrumbItem>
    {getLink(pathname)}
  </WFPBreadcrumb>
);

// propTypes for the breadcrumb component
Breadcrumb.propTypes = {
  pathname: PropTypes.string.isRequired
};

// Here we pass the pathname to the Breadcrumb component to allow it to render the right trail
export default withRouter(props => <Breadcrumb pathname={props.location.pathname} />);
