import React from "react";
import PropTypes from "prop-types";
import { fieldInputPropTypes } from "redux-form";

import { displayErrorHelpText } from "../../utils";

import style from "./style.scss";

// A wrapper of the TextInput component form WFP UI Kit
const Input = ({
  id,
  type,
  label,
  placeholder,
  errorMessage,
  isDisabled,
  autoCompleteOff,
  input: { value, onFocus, onChange }
}) => (
  <div className="wfp--form-item">
    {label ? (
      <label htmlFor={id} className="wfp--label">
        {label}
      </label>
    ) : (
      ""
    )}
    <div className={style.wrapper}>
      <input
        value={value}
        onFocus={onFocus}
        onChange={onChange}
        id={id}
        type={type}
        placeholder={placeholder}
        className="wfp--input"
        disabled={isDisabled}
        autoComplete={autoCompleteOff ? "off" : undefined}
      />
    </div>
    {displayErrorHelpText(errorMessage)}
  </div>
);

// propTypes for the Input component
Input.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  input: PropTypes.shape(fieldInputPropTypes).isRequired,
  errorMessage: PropTypes.string,
  isDisabled: PropTypes.bool,
  autoCompleteOff: PropTypes.bool
};

// defaultProps for the Input component
Input.defaultProps = {
  type: "text",
  label: "",
  placeholder: "",
  errorMessage: "",
  isDisabled: false,
  autoCompleteOff: undefined
};

export default Input;
