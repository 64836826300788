// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_wrapper_K7TdjodgFDiaX5KLjaa3ow\\=\\= {\n  margin: 4rem 0rem 6rem 0rem;\n}", "",{"version":3,"sources":["webpack://src/components/PageStandard/style.scss"],"names":[],"mappings":"AAAA;EACE,2BAAA;AACF","sourcesContent":[".wrapper {\n  margin: 4rem 0rem 6rem 0rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "style_wrapper_K7TdjodgFDiaX5KLjaa3ow=="
};
export default ___CSS_LOADER_EXPORT___;
