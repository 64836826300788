import "react-dates/initialize";

import React, { useState } from "react";
import PropTypes from "prop-types";
import { DateRangePicker as RDDateRangePicker } from "react-dates";

import { displayErrorHelpText } from "../../utils";
import { DATE_FORMAT_DEFAULT } from "../../constants";


// This function returns a value or undefined
export const valueOrUndefined = value => value || undefined;

// A DateRangePicker component used to select a range of dates
const DateRangePicker = ({
  id,
  label,
  placeholderStartDate,
  placeholderEndDate,
  withPortal,
  isOutsideRange,
  errorMessage,
  input: { value: { startDate, endDate }, onChange }
}) => {

  const [focusedInput, setFocusedInput] = useState(null);

  return (
    <div className="wfp--form-item">
      <label htmlFor={id} className="wfp--label">{label}</label>
      <RDDateRangePicker
        startDateId={`start_date_${id}`}
        endDateId={`end_date_${id}`}
        startDate={startDate}
        endDate={endDate}
        onDatesChange={onChange}
        focusedInput={focusedInput}
        onFocusChange={setFocusedInput}
        hideKeyboardShortcutsPanel
        readOnly
        showClearDates
        startDatePlaceholderText={placeholderStartDate}
        endDatePlaceholderText={placeholderEndDate}
        isOutsideRange={isOutsideRange}
        withPortal={valueOrUndefined(withPortal)}
        displayFormat={DATE_FORMAT_DEFAULT}
      />
      {displayErrorHelpText(errorMessage)}
    </div>
  );
};

// propTypes for the Input component
DateRangePicker.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  input: PropTypes.any.isRequired,
  placeholderStartDate: PropTypes.string,
  placeholderEndDate: PropTypes.string,
  withPortal: PropTypes.bool,
  isOutsideRange: PropTypes.func,
  errorMessage: PropTypes.string,
};

// defaultProps for the DateRangePicker component
DateRangePicker.defaultProps = {
  placeholderStartDate: "From",
  placeholderEndDate: "To",
  withPortal: false,
  errorMessage: "",
  isOutsideRange: () => false
};

export default DateRangePicker;
