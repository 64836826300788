import React, { Component, useMemo, useEffect, useState, useCallback,  } from "react";
import { Table as TableWFP, Pagination, TablePagination } from "@wfp/ui";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useFilters,
  useExpanded,
} from 'react-table';

import { calculateMinimumRows } from "../../utils/table";

import style from "./style.scss";


// A function to calculate the naumber of pages
export const calculatePages = (totalItems, pageSize) => totalItems ? Math.ceil(totalItems / pageSize) : 0;

// A sub component generator used to show additional data when expanding a row
export const generateSubComponent = (row, subComponent, expandAll) => {
  const SubComponent = subComponent.component;
  return <div className={expandAll ? style.subComponentNoExpand : style.subComponent}><SubComponent {...row.original[subComponent.dataKey]} /></div>;
};

// A ReactTable wrapper with pagination
function Table(props) {
//class Table extends React.Component {
  // A function that create a sub component and properties to it
  const generateSubComponent2 = (row, subComponent, expandAll) => {
    return generateSubComponent(row, subComponent, expandAll);
  }

  // A function to show all rows expanded
  const expandAll = () => {
    return this.props.expandAll ? this.props.data.map(() => true) : undefined;
  }
  
  
  //render() {
    const columns = useMemo(() => props.columns, [props.columns]);
    const data = useMemo(() => props.data, [props.data]);
    const subComponent = useMemo(() => props.subComponent, [props.subComponent]);
    //const dispatch = useDispatch();
    
    //const columns = props.columns;
    //const data = props.data;
    
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      canPreviousPage,
      canNextPage,
      page,
      gotoPage,
      nextPage,
      previousPage,
      prepareRow,
      setPageSize,
      state: { pageIndex, pageSize, expanded },
      setGlobalFilter,
      setAllFilters,
    } = useTable(
      {
        columns,
        data,
      },
      useGlobalFilter,
      useFilters,
      useExpanded,
      usePagination,
    );
    
    useEffect(() => {
      setGlobalFilter(undefined);
      setAllFilters([]);
      setPageSize(20);
    },[]);
  
    /*const changePage = (page) => {
      gotoPage(page.page - 1);
  
      // Update PageSize
      if (pageSize !== page.pageSize) {
        setPageSize(page.pageSize);
      }
    };*/
    
    /*const loadData = useCallback(
      (page, pageSize, ordering) => {
        let params = { page, page_size: pageSize };
        //dispatch(params.loadRecipes({ params }));
      },
      //[dispatch]
    );
    
    const onChange = useCallback(
      (e) => {
        if (e.pageSize !== pageSize) {
          setPageSize(e.pageSize);
          !props.manual && gotoPage(0);
        } else {
          if (props.manual && loadData) {
            loadData(e.page, e.pageSize);
          } else {
            gotoPage(e.page - 1);
          }
        }
      },
      [pageSize, setPageSize, props.manual, loadData, gotoPage]
    );*/
    
    const renderRowSubComponent = React.useCallback(
    ({ row, subComponent, expandAll }) => (
      <pre
        style={{
          fontSize: '10px',
        }}
      >
        {generateSubComponent2(row, subComponent, expandAll)}
      </pre>
    ),
    []
    )
    
    return (
    <>
      <TableWFP {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.length > 0 && !props.loading ? (
            page.map((row, i) => {
              prepareRow(row);
              return (
                <React.Fragment key= {row.getRowProps().key}>
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render('Cell', {arrowStyle: row.isExpanded ? style.arrowDown : style.arrowRight, arrowBox: style.arrowBox})}</td>
                      );
                    })}
                  </tr>
                  {row.isExpanded ? (
                      <tr>
                        <td colSpan={columns.length}>
                          {/*
                              Inside it, call our renderRowSubComponent function. In reality,
                              you could pass whatever you want as props to
                              a component like this, including the entire
                              table instance. But for this example, we'll just
                              pass the row
                            */}
                          {renderRowSubComponent({ row, subComponent, expandAll })}
                        </td>
                      </tr>
                    ) : null}
                </React.Fragment>
              );
            })
          ) : !props.loading ? (
            <div className={style.loading}>No Data found</div>
          ) : (
            <div className={style.loading}>Loading data...</div>
          )
        } 
        </tbody>
      </TableWFP>
      {props.showPagination != false ? (
      <TablePagination
        pageSize="20"
        pageSizeOptions={[20]}
        showPageSizeOptions="false"
        backwardText="Backward"
        forwardText="Forward"
        pageNumberText="Page Number"
        
        // Pagination properties, if pagination is handled
        showPagination={props.showPagination}
        //PaginationComponent={TablePagination}
        totalItems={props.totalItems}
        page={pageIndex + 1}
        onPageChange={props.onPageChange}
        minRows={calculateMinimumRows(data)}
        noDataText="No record exists"
      />
      ) : null}
    </>
  );
  //}
}

// propTypes for the Table component
Table.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  pageSize: PropTypes.number.isRequired,
  subComponent: PropTypes.shape({
    // The component can be a React component or a native element, like a div
    component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
    dataKey: PropTypes.string.isRequired
  }),
  expandAll: PropTypes.bool,
  // Pagination properties, if pagination is handled
  showPagination: PropTypes.bool,
  totalItems: PropTypes.number,
  pageIndex: PropTypes.number,
};

// defaultProps for Table component
Table.defaultProps = {
  subComponent: undefined,
  pageSize: 20,
  showPagination: true,
  totalItems: undefined,
  expandAll: false
};

export default Table;
