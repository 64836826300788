import React from "react";
import { reduxForm, Field } from "redux-form";
import PropTypes from "prop-types";

import Module, { SectionTitle } from "../../../../components/Module";
import Spacer from "../../../../components/Spacer";
import SelectAsync from "../../../../components/SelectAsync";
import DatePicker from "../../../../components/DatePicker";
import { Grid, Row, Col } from "../../../../components/Grid";
import FormWrapper from "../../../../components/FormWrapper";
import ButtonsWrapper from "../../../../components/ButtonsWrapper";
import ShowHideArea from "../../../../components/ShowHideArea";
import Button from "../../../../components/Button";
import { getErrors, isPastDate } from "../../../../utils";

// The DestinationAndRtaGcmf component allows user to select a specific commodity from a list
const DestinationAndRtaGcmf = ({
  isVisible,
  onClickNext,
  onClickPrevious,
  onClickDraft,
  errorObject,
  dischargePoints,
  isOutsideRangeDestinationPointDate,
  onChangeDischargePortDate
}) => (
  <ShowHideArea isVisible={isVisible}>
    <Module
      title="Step 2/7 Destination And RTA"
      footer={
        <ButtonsWrapper>
          <Button kind="secondary" onClick={onClickDraft}>Save Draft</Button>
          <Button kind="secondary" onClick={onClickPrevious}>
            Previous
          </Button>
          <Button onClick={onClickNext}>Continue</Button>
        </ButtonsWrapper>
      }
    >
      <SectionTitle title="Destination" />
      <FormWrapper>
        <Grid>
          <Row>
            <Col md={6}>
              <Field
                component={SelectAsync}
                name="discharge_port"
                id="discharge_port"
                label="Discharge port"
                loadOptions={dischargePoints}
                errorMessage={getErrors(errorObject, "discharge_port")}
              />
            </Col>
          </Row>
        </Grid>
        <SectionTitle title="RTA" withSeparator />
        <Grid>
          <Row>
            <Col md={4}>
              <Field
                component={DatePicker}
                name="rta_discharge_port"
                id="rta_discharge_port"
                label="Discharge port"
                isOutsideRange={isPastDate}
                onChange={onChangeDischargePortDate}
                errorMessage={getErrors(errorObject, "rta_discharge_port")}
              />
            </Col>
            <Col md={4}>
              <Field
                component={DatePicker}
                name="rta_destination_point"
                id="rta_destination_point"
                label="Final destination point"
                isOutsideRange={isOutsideRangeDestinationPointDate}
                errorMessage={getErrors(errorObject, "rta_destination_point")}
              />
            </Col>
          </Row>
        </Grid>
      </FormWrapper>
      <Spacer height="22rem" />
    </Module>
  </ShowHideArea>
);

// propTypes for the DestinationAndRtaGcmf component
DestinationAndRtaGcmf.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClickNext: PropTypes.func.isRequired,
  onClickDraft: PropTypes.func.isRequired,
  errorObject: PropTypes.object.isRequired,
  dischargePoints: PropTypes.func.isRequired,
  isOutsideRangeDestinationPointDate: PropTypes.func.isRequired,
  onChangeDischargePortDate: PropTypes.func.isRequired,
  onClickPrevious: PropTypes.func.isRequired
};

export default reduxForm({ form: "ipfDetailDestinationRtaGcmf", enableReinitialize: true })(DestinationAndRtaGcmf);
