import React from "react";

import PopupMenu from "@components/PopupMenu";
import Tag from "@components/Tag";
import style from "./style.scss";

// The component used to render a cell in two lines with some styles
export const twoLineCell = (firstLine, secondLine) => (
  <div>
    <span className={style.firstLine}>{firstLine}</span>
    <span className={style.secondLine}>{secondLine}</span>
  </div>
);

// The component used to render a cell aligned to right
export const alignRightCell = text => (
  <div className={style.alignRight}>
    <span>{text}</span>
  </div>
);

// The component used to render a cell in two lines with some styles
export const tagCell = (text, key) => (
  <div key={key}>
    <Tag key={key}>{text}</Tag>
  </div>
);

// The component used to render a header with additional information
export const headerWithInformation = (title, information) => (
  <div>
    <span className={style.title}>{title}</span>
    <span className={style.information}>{information}</span>
  </div>
);

// The component used to render a small
export const smallHeader = (title, information) => (
  <div>
    <span className={style.smallHeader}>{title}</span>
    {information ? <span className={style.informationSmall}>{information}</span> : ""}
  </div>
);

// The component used to render a centered header
export const centeredHeader = (title, information = "") => (
  <div className={style.centeredHeader}>
    <span>{title}</span>
    {information ? <span className={style.information}>{information}</span> : ""}
  </div>
);

// The component used to render a popup menu in a table
export const menu = (id, items) => <PopupMenu id={id} items={items} />;

// Calculate minimum rows to show in a table
// if table is empty, five rows are displayed
// to make "No rows found" message visible inside the table
// if there are some rows minimum rows is set to zero
// to hide empty rows
export const calculateMinimumRows = data => (data.length ? 0 : 5);
