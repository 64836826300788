import React from "react";
import PropTypes from "prop-types";
import { Icon as WFPIcon } from "@wfp/ui";

export const ClockIcon = ({ fill, width, height, style }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 20"
    fill={fill}
    width={width}
    height={height}
    style={style}
  >
    <path d="M14.5 7l.9-.8c.2-.2.2-.5 0-.7l-.7-.6a.5.5 0 0 0-.6 0l-.8.8a8 8 0 0 0-4.4-2V1.8H10c.3 0 .5-.2.5-.4v-1c0-.2-.2-.4-.5-.4H6c-.3 0-.5.2-.5.5v.9c0 .2.2.4.5.4h1v2a8 8 0 0 0-7 7.8 8 8 0 0 0 8 8A8 8 0 0 0 14.5 7zM8 17.7a6.1 6.1 0 0 1-6.2-6c0-3.5 2.8-6.2 6.2-6.2 3.4 0 6.2 2.7 6.2 6.1 0 3.4-2.8 6.1-6.2 6.1zm.5-4.3h-1a.5.5 0 0 1-.4-.4V7.8c0-.3.2-.5.4-.5h1c.2 0 .4.2.4.5V13c0 .2-.2.4-.4.4z" />
  </svg>
);

// propTypes for the ClockIcon component
ClockIcon.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  style: PropTypes.object,
  fill: PropTypes.string
};

// defaultProps for the ClockIcon component
ClockIcon.defaultProps = {
  style: {},
  fill: "#0a6eb4"
};

// A wrapper of the Icon component form WFP UI Kit
const Icon = ({ icon, fill, width, height, style, description }) => (
  <WFPIcon
    icon={icon}
    fill={fill}
    width={width}
    height={height}
    style={style}
    description={description}
  />
);

// propTypes for the Icon component
Icon.propTypes = {
  icon: PropTypes.any.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  style: PropTypes.object,
  fill: PropTypes.string,
  description: PropTypes.string
};

// defaultProps for the Icon component
Icon.defaultProps = {
  style: {},
  fill: "#0a6eb4",
  description: ""
};

export default Icon;
