import { RESOURCE_ADD_REQUEST_, RESOURCE_ADD_SUCCESS_, RESOURCE_ADD_ERROR_, RESOURCE_ADD_RESET_ } from "../actions/resourceAdd";


// A generic reducer used to add a resource
const resourceAdd = resource => (state = { isLoading: false, data: {}, error: {} }, action) => {
  switch (action.type) {
  case `${RESOURCE_ADD_REQUEST_}${resource}`:
    return { ...state, isLoading: true, data: {}, error: {} };
  case `${RESOURCE_ADD_SUCCESS_}${resource}`:
    return { ...state, isLoading: false, data: action.data, error:{} };
  case `${RESOURCE_ADD_ERROR_}${resource}`:
    return { ...state, isLoading: false, data: {}, error: action.error };
  case `${RESOURCE_ADD_RESET_}${resource}`:
    return { ...state, isLoading: false, data: {}, error: {} };
  default:
    return state;
  }
};

export default resourceAdd;