// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_wrapper_Iy-FAxN1oFItBdr7z1FTaQ\\=\\= {\n  width: 100%;\n}", "",{"version":3,"sources":["webpack://src/components/Upload/style.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;AACJ","sourcesContent":[".wrapper {\n    width: 100%;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "style_wrapper_Iy-FAxN1oFItBdr7z1FTaQ=="
};
export default ___CSS_LOADER_EXPORT___;
