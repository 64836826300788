// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_summary_2NxRe\\+i4E8FAVBCB4bc2tQ\\=\\= {\n  width: 100%;\n}\n\n.style_summary_2NxRe\\+i4E8FAVBCB4bc2tQ\\=\\= td {\n  padding: 0rem 0rem 1rem 0rem;\n  font-size: 0.8rem;\n}\n\n.style_summary_2NxRe\\+i4E8FAVBCB4bc2tQ\\=\\= td:nth-child(1) {\n  width: 22%;\n  font-weight: bold;\n}\n\n.style_summary_2NxRe\\+i4E8FAVBCB4bc2tQ\\=\\= td:nth-child(2) {\n  width: 30%;\n}\n\n.style_summary_2NxRe\\+i4E8FAVBCB4bc2tQ\\=\\= td:nth-child(3) {\n  width: 18%;\n  font-weight: bold;\n}\n\n.style_summary_2NxRe\\+i4E8FAVBCB4bc2tQ\\=\\= td:nth-child(4) {\n  width: 30%;\n}", "",{"version":3,"sources":["webpack://src/containers/IPFDetail/components/SelectedDataGcmf/style.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;;AAEA;EACE,4BAAA;EACA,iBAAA;AACF;;AAEA;EACE,UAAA;EACA,iBAAA;AACF;;AAEA;EACE,UAAA;AACF;;AAEA;EACE,UAAA;EACA,iBAAA;AACF;;AAEA;EACE,UAAA;AACF","sourcesContent":[".summary {\n  width: 100%;\n}\n\n.summary td {\n  padding: 0rem 0rem 1rem 0rem;\n  font-size: 0.8rem;\n}\n\n.summary td:nth-child(1) {\n  width: 22%;\n  font-weight: bold;\n}\n\n.summary td:nth-child(2) {\n  width: 30%;\n}\n\n.summary td:nth-child(3) {\n  width: 18%;\n  font-weight: bold;\n}\n\n.summary td:nth-child(4) {\n  width: 30%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"summary": "style_summary_2NxRe+i4E8FAVBCB4bc2tQ=="
};
export default ___CSS_LOADER_EXPORT___;
