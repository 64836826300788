import React from "react";
import PropTypes from "prop-types";
import { reduxForm, Field } from "redux-form";

import InputMultiline from "../../../../components/InputMultiline";
import { Grid, Row, Col } from "../../../../components/Grid";
import FormWrapper from "../../../../components/FormWrapper";
import Modal from "../../../../components/Modal";
import Loading from "../../../../components/Loading";
import { getErrors } from "../../../../utils";


// The ImportExportRestrictionModal component is used to edit an Import/Export Restriction
const ImportExportRestrictionModal = ({
  errorObject,
  isOpen,
  onValidate,
  onCancel,
  isLoading,
  title
}) => (
  <Modal
    isOpen={isOpen}
    title={title}
    onValidate={onValidate}
    onCancel={onCancel}
    validateText="Update restriction"
  >
    <FormWrapper>
      <Grid>
        <Row>
          <Col md={6}>
            <Field
              component={InputMultiline}
              name="import_restrictions"
              id="import_restrictions"
              label="Import restrictions"
              errorMessage={getErrors(errorObject, "import_restrictions")}
            />
          </Col>
          <Col md={6}>
            <Field
              component={InputMultiline}
              name="export_restrictions"
              id="export_restrictions"
              label="Export restrictions"
              errorMessage={getErrors(errorObject, "export_restrictions")}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Field
              component={InputMultiline}
              name="additional_information"
              id="additional_information"
              label="Additional information"
              errorMessage={getErrors(errorObject, "additional_information")}
            />
          </Col>
        </Row>
      </Grid>
    </FormWrapper>
    <Loading isVisible={isLoading} />
  </Modal>
);

// propTypes for the ImportExportRestrictionModal component
ImportExportRestrictionModal.propTypes = {
  errorObject: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onValidate: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired
};

export default reduxForm({ form: "importExportRestrictionForm", enableReinitialize: true })(ImportExportRestrictionModal);