import React from "react";
import { reduxForm, Field } from "redux-form";
import PropTypes from "prop-types";

import Module, { SectionTitle } from "../../../../components/Module";
import ButtonsWrapper from "../../../../components/ButtonsWrapper";
import Checkbox from "../../../../components/Checkbox";
import Button from "../../../../components/Button";
import TableInMemory from "../../../../components/TableInMemory";
import ShowHideArea from "../../../../components/ShowHideArea";
import { displayErrorMessage } from "../../../../utils";
import { twoLineCell, tagCell } from "../../../../utils/table";
import { alignRightCell } from "@utils/table";
import { captureException } from "../../../../sentry";

import style from "./style.scss";

// A component used to select a source
export const generateSourceCheckbox = props => (
  <Field component={Checkbox} name={`source_${props.row?.original.id}`} id={`source_${props.row?.original.id}`} text="" single />
);

// The list of columns and their properties
export const columns = () => [
  {
    Header: "Button",
    width: 35,
    Cell: generateSourceCheckbox
  },
  {
    Header: () => null,
    id: 'expander',
    Cell: props => 
      alignRightCell(<div id="expander" className={props.arrowBox} {...props.row?.getToggleRowExpandedProps()}>
        <div className={props.arrowStyle}></div>
      </div>)
  },
  {
    Header: "Named place",
    Cell: props => twoLineCell(props.row?.original.incoterm_place_name, props.row?.original.incoterm_place_country),
    width: 190
  },
  {
    Header: "Incoterm",
    accessor: "incoterm.code",
    width: 70
  },
  {
    Header: "Source",
    Cell: props => tagCell(props.row?.original.price_type.description),
    width: 105
  },
  {
    Header: "Packaging",
    accessor: "packaging_type.description",
    width: 290,
    className: style.multilineCell
  },
  {
    Header: "Price",
    accessor: "price",
    width: 80
  }
];

// A component used to display additional information in a expand section
export const AdditionalInformation = ({ packaging_note }) => (
  <div className={style.additionalInformation}>
    <table>
      <tbody>
        <tr>
          <td>Note</td>
          <td>{packaging_note}</td>
        </tr>
      </tbody>
    </table>
  </div>
);

// propTypes for the AdditionalInformation component
AdditionalInformation.propTypes = {
  packaging_note: PropTypes.string
};

// defaultProps for the AdditionalInformation component
AdditionalInformation.defaultProps = {
  packaging_note: ""
};


// The CompareSources component allows user to select a specific commodity from a list
const CompareSources = ({
  isVisible,
  onClickNext,
  onClickPrevious,
  onClickDraft,
  selectedData,
  loadingCommodityPrices,
  errorMessageCommodityPrices,
  errorMessageCompareSources,
  commodityPrices
}) => (
  <ShowHideArea isVisible={isVisible}>
    <Module
      title="Step 3/7 Compare Sources"
      footer={
        <ButtonsWrapper>
          <Button kind="secondary" onClick={onClickDraft}>Save Draft</Button>
          <Button kind="secondary" onClick={onClickPrevious}>
            Previous
          </Button>
          <Button onClick={onClickNext}>Continue</Button>
        </ButtonsWrapper>
      }
    >
      {selectedData}
      <SectionTitle title="Select sources to compare" withSeparator />
      {displayErrorMessage(errorMessageCommodityPrices)}
      {displayErrorMessage(errorMessageCompareSources)}
      {captureException(errorMessageCommodityPrices)}
      {captureException(errorMessageCompareSources)}
      <TableInMemory
        loading={loadingCommodityPrices}
        columns={columns()}
        data={commodityPrices.map(d => {
          return {
          ...d,
          subRows: undefined
        }})}        
        pageSize={commodityPrices.length}
        showPagination={false}
        subComponent={{ component: AdditionalInformation, dataKey: "additional_information" }}
      />
    </Module>
  </ShowHideArea>
);

// propTypes for the CompareSources component
CompareSources.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClickNext: PropTypes.func.isRequired,
  onClickDraft: PropTypes.func.isRequired,
  selectedData: PropTypes.node.isRequired,
  loadingCommodityPrices: PropTypes.bool.isRequired,
  errorMessageCommodityPrices: PropTypes.string.isRequired,
  commodityPrices: PropTypes.array.isRequired,
  errorMessageCompareSources: PropTypes.string.isRequired,
  onClickPrevious: PropTypes.func.isRequired
};

export default reduxForm({ form: "ipfDetailCompareSources", enableReinitialize: true })(CompareSources);
