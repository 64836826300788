import React from "react";
import moment from "moment";

import MessageError from "../components/MessageError";
import MessageWarning from "../components/MessageWarning";
import ErrorHelpText from "../components/ErrorHelpText";
import { DATE_FORMAT_DEFAULT, DATE_FORMAT_INPUT } from "../constants";

// A function to clear query paramter and decoded string.
// Input: "?test%20test"
// Output: "test test"
export const clearQueryParamter = queryParamter => decodeURIComponent(queryParamter.substring(1));

// Decide if to display an error message or not
export const displayErrorMessage = errorMessage => errorMessage && <MessageError>{errorMessage}</MessageError>;

// Decide if to display a warning message
export const displayWarningMessage = warningMessage =>
  warningMessage && <MessageWarning>{warningMessage}</MessageWarning>;

// Decide if display an error message or not
export const displayErrorHelpText = errorMessage => errorMessage && <ErrorHelpText>{errorMessage}</ErrorHelpText>;

// A function to adapt a list of object to the format { value: x, label: y }
// starting from a given pair ok keys
// it is possible to add an additional field to te label
// it is also possible to attach received data in an additional key
// to make them available using the key "data"
export const adaptOptions = (data, valueKey, labelKey, additionalLabelKey = "", withData = false) => {
  return data.map(item => {
    let result = { value: item[valueKey] };
    result = {
      ...result,
      label: additionalLabelKey ? `${item[labelKey]} (${item[additionalLabelKey]})` : item[labelKey]
    };
    if (withData) {
      result = { ...result, data: item };
    }
    return result;
  });
};

// A function to adapt a single object to the format { value: x, label: y }
// starting from a given pair ok keys
export const adaptOption = (data, valueKey, labelKey) =>
  !isEmpty(data) && { value: data[valueKey], label: data[labelKey] };

// Function to redirect user to a given url
export const redirect = location => window.location.assign(location);

// Function to gat a date in a given format
export const dateToString = (date, fromFormat, toFormat) => (date && moment(date, fromFormat).format(toFormat)) || "";

// Function to get a date from a date time string
export const stringToDate = (date, format) => (date && moment(date, format)) || "";

// A function to get a string representation of a date inn the default format
export const formatDateDefault = date => dateToString(date, DATE_FORMAT_INPUT, DATE_FORMAT_DEFAULT);

// Check if picked date is in the past
export const isPastDate = (date, currentDate = new Date()) => {
  return date.startOf("day") < moment(currentDate).startOf("day");
};

// Function to format a number
// if number has decimal parts, the number have to show to decimal points
// if the number has not decimal parts, no decimal part have to be shown
export const formatNumber = number => {
  if (isNaN(number) || !number) {
    return "0";
  } else if (number % 1 === 0) {
    return parseFloat(number).toLocaleString("en-GB", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      useGrouping: true
    });
  } else {
    return parseFloat(number).toLocaleString("en-GB", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      useGrouping: true
    });
  }
};

// Function to format a number
// return number with three decimal points
export const formatNumberWithDecimalParts = number => {
  if (isNaN(number) || !number) {
    return "0";
  } else {
    return parseFloat(number).toLocaleString("en-GB", {
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
      useGrouping: true
    });
  }
};

// A function to get a comma separated strings from an array
export const arrayToString = array =>
  array.reduce((message, element) => (message ? `${message}, ${element}` : element), "");

// A function to extract errors related to a field from an error object
export const getErrors = (object, key) => 
  object && object.data && object.data[key] && !isEmpty(object.data[key]) ? arrayToString(object.data[key]) : "" || "";

// A function to check if an object is empty
export const isEmpty = data =>
  (typeof data === "boolean" && data === false) ||
  data === undefined ||
  data === null ||
  String(data).trim() === "" ||
  (typeof data !== "boolean" && Object.keys(data).length === 0);
  
export const isMinor = data =>
  (typeof data !== "boolean" && Object.keys(data).length <= 2 && Object.keys(data).length !== 0);

// A function to get an error object starting from given form values
export const validateData = (values, fields) => {
  const errors = {};
  fields.map(field => {
    if (isEmpty(values) || isEmpty(values[field])) {
      errors[field] = ["This field is required"];
    }
  });
  return errors;
};

// A function to remove commas from a number
export const clearNumber = value => value.replace(/,/g, "");

// A function to retrieve the error message from a status code and status text
// it only get the message if body does not contain any data
export const getStatusErrorMessage = data => data && isEmpty(data.data) && `${data.status} ${data.statusText}`;

// A function to display a string or a dash if string is empty
export const stringOrDash = string => string || "-";

// A function to display a string or a zero if string is empty
export const stringOrZero = string => string || "0";

// A function to generate an URL with parameters
// starting from a base URL and a list of values
export const openUrl = (baseUrl, parameters = undefined) => {
  let query = "";
  if (!isEmpty(parameters)) {
    parameters.map(parameter => {
      if (!isEmpty(parameter.value)) {
        if (Array.isArray(parameter.value)) {
          parameter.value.map(value =>
            query ? (query += `&${parameter.label}[]=${value}`) : (query += `${parameter.label}[]=${value}`)
          );
        } else {
          query
            ? (query += `&${parameter.label}=${parameter.value}`)
            : (query += `${parameter.label}=${parameter.value}`);
        }
      }
    });
    query = query && `?${query}`;
  }
  window.open(`${baseUrl}${query}`);
};
