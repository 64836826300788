import React from "react";
import PropTypes from "prop-types";
import Async from "react-select/async";
import { fieldInputPropTypes } from "redux-form";

import { displayErrorHelpText } from "../../utils";

import style from "./style.scss";

// A function used to retrieve the pair of value/label selected in the component
export const getValue = (value, isMulti, displayKey) => {
  if (isMulti) {
    return value && value.map(item => ({ value: item.value, label: item[displayKey] }));
  }
  return value && { value: value.value, label: value[displayKey] };
};

// A wrapper of the AsyncSelect with WFP UI Kit styles
const SelectAsync = ({
  id,
  label,
  loadOptions,
  placeholder,
  smallLabel,
  smallMenu,
  errorMessage,
  isDisabled,
  noOptionsMessage,
  defaultOptions,
  isMulti,
  displayKey,
  input: { value, onFocus, onChange, onInputChange }
}) => (
  <div className="wfp--form-item">
    {smallLabel
      ? <label htmlFor={id} className={style.smallLabel}>{label}</label>
      : <label htmlFor={id} className="wfp--label">{label}</label>
    }
    <div className={style.wrapper}>
      <Async
        isMulti={isMulti}
        cacheOptions
        value={getValue(value, isMulti, displayKey)}
        onFocus={onFocus}
        onChange={onChange}
        onInputChange={onInputChange}
        className="wfp--react-select-container"
        classNamePrefix="wfp--react-select"
        id={id}
        loadOptions={loadOptions}
        placeholder={placeholder}
        maxMenuHeight={smallMenu ? "140" : undefined}
        isDisabled={isDisabled}
        noOptionsMessage={noOptionsMessage}
        isClearable
        defaultOptions={defaultOptions}
      />
    </div>
    {displayErrorHelpText(errorMessage)}
  </div>
);

// propTypes for the SelectAsync component
SelectAsync.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  input: PropTypes.shape(fieldInputPropTypes).isRequired,
  placeholder: PropTypes.string,
  smallLabel: PropTypes.bool,
  smallMenu: PropTypes.bool,
  errorMessage: PropTypes.string,
  loadOptions: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  noOptionsMessage: PropTypes.func,
  defaultOptions: PropTypes.array,
  isMulti: PropTypes.bool,
  displayKey: PropTypes.string
};

// defaultProps for the SelectAsync component
SelectAsync.defaultProps = {
  label: "",
  isMulti: false,
  smallLabel: false,
  loading: false,
  placeholder: "Start typing to search",
  smallMenu: false,
  errorMessage: "",
  isDisabled: false,
  noOptionsMessage: undefined,
  defaultOptions: undefined,
  displayKey: "label"
};

export default SelectAsync;
