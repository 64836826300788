import React from "react";
import PropTypes from "prop-types";

import style from "./style.scss";


// A wrappers that show or hide and area based on a paramter
const ShowHideArea = ({ children, isVisible }) => (
  <div className={isVisible ? style.visible : style.hidden}>
    {children}
  </div>
);

// propTypes for the ShowHideArea component
ShowHideArea.propTypes = {
  children: PropTypes.node.isRequired,
  isVisible: PropTypes.bool.isRequired
};

export default ShowHideArea;
