import React from "react";
import PropTypes from "prop-types";
import { reduxForm, Field } from "redux-form";
import { iconDownloadGlyph } from "@wfp/icons";

import { displayErrorMessage } from "../../../../utils";
import Module from "../../../../components/Module";
import Table from "../../../../components/Table";
import ShowHideArea from "../../../../components/ShowHideArea";
import FiltersWrapper from "../../../../components/FiltersWrapper";
import { Grid, Row, Col } from "../../../../components/Grid";
import Select from "../../../../components/Select";
import SelectAsync from "../../../../components/SelectAsync";
import Button from "../../../../components/Button";
import ButtonsWrapper from "../../../../components/ButtonsWrapper";

import style from "./style.scss";


// The list of columns and their properties
export const columns = () => [
  {
    Header: "Load port",
    accessor: "load_port"
  },
  {
    Header: "Discharge port",
    accessor: "discharge_port"
  },
  {
    Header: "Container type",
    accessor: "container_type"
  },
  {
    Header: "Commodity",
    accessor: "specific_commodity.description"
  },
  {
    Header: "Price US$/Mt",
    accessor: "price"
  },
  {
    Header: "Valid until",
    accessor: "valid_until"
  }
];

// The ShippingRatesList component is used to display a list of Shipping Rates
const ShippingRatesList = ({
  shippingRates,
  errorMessageShippingRates,
  totalItems,
  page,
  onPageChange,
  loadingShippingRates,
  isVisible,
  loadPorts,
  dischargePorts,
  specificCommodities,
  containerTypes,
  loadingFilters,
  onClickSearch,
  onClickExport
}) => (
  <>
    <ShowHideArea isVisible={isVisible}>
      {displayErrorMessage(errorMessageShippingRates)}
      <Module
        title="Shipping Rates"
        filter={
          <ButtonsWrapper>
            <Button icon={iconDownloadGlyph} disabled={!shippingRates.length} onClick={onClickExport}>Export</Button>
          </ButtonsWrapper>
        }
        table={
          <Table
            loading={loadingShippingRates}
            columns={columns()}
            data={shippingRates}
            totalItems={totalItems}
            page={page}
            onPageChange={onPageChange}
          />
        }
      >
        <FiltersWrapper>
          <Grid>
            <Row>
              <Col md={3}>
                <Field
                  isMulti
                  component={SelectAsync}
                  name="loadPorts"
                  id="loadPorts"
                  label="Load port"
                  loadOptions={loadPorts}
                />
              </Col>
              <Col md={3}>
                <Field
                  isMulti
                  component={SelectAsync}
                  name="dischargePorts"
                  id="dischargePorts"
                  label="Discharge port"
                  loadOptions={dischargePorts}
                />
              </Col>
              <Col md={3}>
                <Field
                  isMulti
                  component={Select}
                  name="specificCommodities"
                  id="specificCommodities"
                  label="Commodity"
                  options={specificCommodities}
                  loading={loadingFilters}
                />
              </Col>
              <Col md={2}>
                <Field
                  isMulti
                  component={Select}
                  name="containerTypes"
                  id="containerTypes"
                  label="Container type"
                  options={containerTypes}
                  loading={loadingFilters}
                />
              </Col>
              <Col md={1} className={style.searchButtonWrapper}>
                <Button onClick={onClickSearch}>Find</Button>
              </Col>
            </Row>
          </Grid>
        </FiltersWrapper>
      </Module>
    </ShowHideArea>
  </>
);

// propTypes for the List component
ShippingRatesList.propTypes = {
  shippingRates: PropTypes.array.isRequired,
  errorMessageShippingRates: PropTypes.string.isRequired,
  totalItems: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  loadingShippingRates: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  loadPorts: PropTypes.func.isRequired,
  dischargePorts: PropTypes.func.isRequired,
  specificCommodities: PropTypes.array.isRequired,
  containerTypes: PropTypes.array.isRequired,
  loadingFilters: PropTypes.bool.isRequired,
  onClickSearch: PropTypes.func.isRequired,
  onClickExport: PropTypes.func.isRequired
};

export default reduxForm({ form: "shippingRatesFilters" })(ShippingRatesList);