import React from "react";
import PropTypes from "prop-types";

import MessageInformation from "../../../../components/MessageInformation";
import { DATE_FORMAT_DEFAULT, DATE_FORMAT_DATE_PICKER, DATE_FORMAT_STANDARD } from "../../../../constants";
import { dateToString, stringOrDash, isEmpty } from "../../../../utils";

import style from "./style.scss";


// The SelectedDataStandard shows a summary of selected data
const SelectedDataStandard = ({ values, destination, importExportRestriction }) => (
  <>
    <table className={style.summary}>
      <tbody>
        <tr>
          <td>
            Commodity
          </td>
          <td>
            {values.commodity && values.commodity.label}
          </td>
          <td>
            Quantity
          </td>
          <td>
            {values.quantity}
          </td>
        </tr>
        <tr>
          <td>
            Project country
          </td>
          <td>
            {values.project_country && values.project_country.label}
          </td>
          <td>
            Project/CPB
          </td>
          <td>
            {values.project && values.project.label}
          </td>
        </tr>
        <tr>
          <td>
            WBS element
          </td>
          <td>
            {values.wbs && values.wbs.label}
          </td>
          <td>
            Final destination country
          </td>
          <td>
            {values.recipient_country && values.recipient_country.label}
          </td>
        </tr>
        {
          !isEmpty(destination) &&
          <>
            <tr>
              <td>
                Discharge port
              </td>
              <td>
                {destination.discharge_port && destination.discharge_port.label}
              </td>
              <td>
                RTA
              </td>
              <td>
                {destination.rta_discharge_port && dateToString(destination.rta_discharge_port, DATE_FORMAT_DATE_PICKER, DATE_FORMAT_DEFAULT)}
              </td>
            </tr>
            <tr>
              <td>
                Final destination point
              </td>
              <td>
                {destination.destination_point && destination.destination_point.label}
              </td>
              <td>
                RTA
              </td>
              <td>
                {destination.rta_destination_point && dateToString(destination.rta_destination_point, DATE_FORMAT_DATE_PICKER, DATE_FORMAT_DEFAULT)}
              </td>
            </tr>
          </>
        }
      </tbody>
    </table>
    <MessageInformation>
      <table className={style.grant}>
        <tbody>
          <tr>
            <td>
              Grant number
            </td>
            <td>
              {`${values.grant && values.grant.value || ""} - ${values.grant && values.grant.data && values.grant.data.description || ""}`}
            </td>
          </tr>
          <tr>
            <td>
              Remarks
            </td>
            <td>
              {stringOrDash(values.grant && values.grant.data && values.grant.data.general_remark)}
            </td>
          </tr>
          <tr>
            <td>
              Purchase restrictions
            </td>
            <td>
              {stringOrDash(values.grant && values.grant.data && values.grant.data.purchase_restriction)}
            </td>
          </tr>
          <tr>
            <td>
              Purchase restrictions remarks
            </td>
            <td>
              {stringOrDash(values.grant && values.grant.data && values.grant.data.purchase_restriction_remarks)}
            </td>
          </tr>
          <tr>
            <td>
              TOD
            </td>
            <td>
              {stringOrDash(values.grant && values.grant.data && dateToString(values.grant.data.tod, DATE_FORMAT_STANDARD, DATE_FORMAT_DEFAULT))}
            </td>
          </tr>
        </tbody>
      </table>
    </MessageInformation>
    <MessageInformation>
      {
        importExportRestriction.import_restrictions || importExportRestriction.export_restrictions || importExportRestriction.additional_information
          ? <table className={style.importExportRestriction}>
            <tbody>
              <tr>
                <td>
                  Imp/exp restriction country
                </td>
                <td>
                  {importExportRestriction.country && importExportRestriction.country.name}
                </td>
              </tr>
              <tr>
                <td>
                  Import restrictions
                </td>
                <td>
                  {importExportRestriction.import_restrictions}
                </td>
              </tr>
              <tr>
                <td>
                  Export restrictions
                </td>
                <td>
                  {importExportRestriction.export_restrictions}
                </td>
              </tr>
              <tr>
                <td>
                  Additional information
                </td>
                <td>
                  {importExportRestriction.additional_information}
                </td>
              </tr>
            </tbody>
          </table>
          : <span className={style.importExportRestriction}>No Import/export restrictions found for this country</span>
      }
    </MessageInformation>
  </>
);

// propTypes for the SelectedDataStandard component
SelectedDataStandard.propTypes = {
  values: PropTypes.object.isRequired,
  importExportRestriction: PropTypes.object.isRequired,
  destination: PropTypes.any
};

// defaultProps for the SelectedDataStandard component
SelectedDataStandard.defaultProps = {
  destination: undefined
};
export default SelectedDataStandard;