import React from "react";
import ReactDOM from "react-dom";

import App from "./containers/App";
import { configureSentry } from "./sentry"; // TODO: Restore it, removed for IE 11 compatibility

// Import of global styles of the application
import "./assets/scss/base.scss";

// Sentry setup
configureSentry(); // TODO: Restore it, removed for IE 11 compatibility

// It renders the root component in the DOM
ReactDOM.render(<App />, document.getElementById("index"));
