import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { reset } from "redux-form";
import moment from "moment";

import PageTabs from "@components/PageTabs";
import ModalConfirm from "@components/ModalConfirm";
import ShowHideArea from "@components/ShowHideArea";
import Button from "@components/Button";
import ModalSuccess from "@components/ModalSuccess";
import ShippingRatesList from "./components/ShippingRatesList";
import RateContainerList from "./components/RateContainerList";
import RequestShippingRateModal from "./components/RequestShippingRateModal";
import RateContainerModal from "./components/RateContainerModal";
import IntakeRestrictionModal from "./components/IntakeRestrictionModal";
import IntakeContainerModal from "./components/IntakeContainerModal";
import IntakeRestrictionList from "./components/IntakeRestrictionList";
import IntakeContainerList from "./components/IntakeContainerList";
import { CONTAINER_TYPES, DATE_FORMAT_INPUT, DATE_FORMAT_DEFAULT, DATE_FORMAT_DATE_PICKER } from "@constants";
import {
  dateToString,
  adaptOptions,
  isEmpty,
  getStatusErrorMessage,
  clearNumber,
  openUrl,
  adaptOption,
  stringToDate
} from "@utils";
import { hasPermission } from "@utils/permissions";
import {
  shippingRateList,
  shippingRateCurrentPage,
  shippingRateCurrentTab,
  loadPortList,
  dischargePortList,
  specificCommodityList,
  showRequestShippingRateModal,
  hideRequestShippingRateModal,
  requestShippingRateAdd,
  requestShippingRateAddReset,
  showSuccessRequestShippingRateModal,
  hideSuccessRequestShippingRateModal,
  rateContainerList,
  rateContainerCurrentPage,
  showRateContainerModal,
  hideRateContainerModal,
  rateContainerAdd,
  rateContainerAddReset,
  intakeRestrictionList,
  intakeRestrictionCurrentPage,
  intakeRestrictionAdd,
  intakeRestrictionAddReset,
  showIntakeRestrictionModal,
  hideIntakeRestrictionModal,
  intakeContainerList,
  intakeContainerCurrentPage,
  intakeContainerAdd,
  intakeContainerAddReset,
  showIntakeContainerModal,
  hideIntakeContainerModal,
  rateContainerDelete,
  showDeleteRateContainerModal,
  hideDeleteRateContainerModal,
  intakeRestrictionDelete,
  showDeleteIntakeRestrictionModal,
  hideDeleteIntakeRestrictionModal,
  intakeContainerDelete,
  showDeleteIntakeContainerModal,
  hideDeleteIntakeContainerModal,
  activeRecordRateContainer,
  activeRecordIntakeRestriction,
  activeRecordIntakeContainer,
  userDetail,
  rateContainerDetail,
  rateContainerDetailReset,
  rateContainerUpdate,
  rateContainerUpdateReset,
  intakeRestrictionDetail,
  intakeRestrictionDetailReset,
  intakeRestrictionUpdate,
  intakeRestrictionUpdateReset,
  intakeContainerDetail,
  intakeContainerDetailReset,
  intakeContainerUpdate,
  intakeContainerUpdateReset
} from "@actions";

const API_URL_DOWNLOAD_XLS_SHIPPING_RATES = `${process.env.API_BASE_URL}/api/shipping_rates/xls/`;
const API_URL_DOWNLOAD_XLS_RATE_CONTAINERS = `${process.env.API_BASE_URL}/api/container_rates/xls/`;
const API_URL_DOWNLOAD_XLS_INTAKE_RESTRICTIONS = `${process.env.API_BASE_URL}/api/intake_restrictions/xls/`;
const API_URL_DOWNLOAD_XLS_INTAKE_CONTAINERS = `${process.env.API_BASE_URL}/api/intake_containers/xls/`;

// A function to adapt Shipping Rates to a custom format,
// data may be in two different format due to data
// coming from the old version of the application
export const adaptShippingRates = shippingRates =>
  shippingRates.map(shippingRate => ({
    ...shippingRate,
    load_port:
      (shippingRate.load_port && shippingRate.load_port.name) ||
      (shippingRate.load_port && shippingRate.load_port.location && shippingRate.load_port.location.name) ||
      "",
    discharge_port:
      (shippingRate.discharge_port && shippingRate.discharge_port.name) ||
      (shippingRate.discharge_port &&
        shippingRate.discharge_port.location &&
        shippingRate.discharge_port.location.name) ||
      "",
    container_type:
      (shippingRate.container_type &&
        CONTAINER_TYPES.find(container => shippingRate.container_type === container.value) &&
        CONTAINER_TYPES.find(container => shippingRate.container_type === container.value).description) ||
      "",
    valid_until:
      (shippingRate.valid_until && dateToString(shippingRate.valid_until, DATE_FORMAT_INPUT, DATE_FORMAT_DEFAULT)) || ""
  }));

// A function to adapt Rate/Container to a custom format,
// data may be in two different format due to data
// coming from the old version of the application
export const adaptRateContainers = rateContainers =>
  rateContainers.map(rateContainer => ({
    ...rateContainer,
    load_port:
      (rateContainer.load_port && rateContainer.load_port.name) ||
      (rateContainer.load_port && rateContainer.load_port.location && rateContainer.load_port.location.name) ||
      "",
    discharge_port:
      (rateContainer.discharge_port && rateContainer.discharge_port.name) ||
      (rateContainer.discharge_port &&
        rateContainer.discharge_port.location &&
        rateContainer.discharge_port.location.name) ||
      "",
    container_type:
      (rateContainer.container_type &&
        CONTAINER_TYPES.find(container => rateContainer.container_type === container.value) &&
        CONTAINER_TYPES.find(container => rateContainer.container_type === container.value).description) ||
      "",
    valid_until:
      (rateContainer.valid_until && dateToString(rateContainer.valid_until, DATE_FORMAT_INPUT, DATE_FORMAT_DEFAULT)) ||
      ""
  }));

// A function to adapt Intake/Restriction to a custom format,
// data may be in two different format due to data
// coming from the old version of the application
export const adaptIntakeRestrictions = intakeRestrictions =>
  intakeRestrictions.map(intakeRestriction => ({
    ...intakeRestriction,
    discharge_port:
      (intakeRestriction.discharge_port && intakeRestriction.discharge_port.name) ||
      (intakeRestriction.discharge_port &&
        intakeRestriction.discharge_port.location &&
        intakeRestriction.discharge_port.location.name) ||
      "",
    container_type:
      (intakeRestriction.container_type &&
        CONTAINER_TYPES.find(container => intakeRestriction.container_type === container.value) &&
        CONTAINER_TYPES.find(container => intakeRestriction.container_type === container.value).description) ||
      "",
    as_of_date:
      (intakeRestriction.as_of_date &&
        dateToString(intakeRestriction.as_of_date, DATE_FORMAT_INPUT, DATE_FORMAT_DEFAULT)) ||
      ""
  }));

// A function to adapt Intake/Container to a custom format,
// data may be in two different format due to data
// coming from the old version of the application
export const adaptIntakeContainers = intakeContainers =>
  intakeContainers.map(intakeContainer => ({
    ...intakeContainer,
    load_port:
      (intakeContainer.load_port && intakeContainer.load_port.name) ||
      (intakeContainer.load_port && intakeContainer.load_port.location && intakeContainer.load_port.location.name) ||
      "",
    container_type:
      (intakeContainer.container_type &&
        CONTAINER_TYPES.find(container => intakeContainer.container_type === container.value) &&
        CONTAINER_TYPES.find(container => intakeContainer.container_type === container.value).description) ||
      "",
    as_of_date:
      (intakeContainer.as_of_date &&
        dateToString(intakeContainer.as_of_date, DATE_FORMAT_INPUT, DATE_FORMAT_DEFAULT)) ||
      ""
  }));

// A function to adapt form values to be compliant with the back-end expected format
export const adaptRequestShippingRateFormValues = values =>
  values && {
    ...values,
    commodity: values.commodity && values.commodity.value,
    load_port: values.load_port && values.load_port.value,
    discharge_port: values.discharge_port && values.discharge_port.value,
    quantity: values.quantity && clearNumber(values.quantity),
    shipment_period_from:
      values.shipment_period &&
      values.shipment_period.startDate &&
      dateToString(values.shipment_period.startDate, DATE_FORMAT_DATE_PICKER, DATE_FORMAT_INPUT),
    shipment_period_to:
      values.shipment_period &&
      values.shipment_period.endDate &&
      dateToString(values.shipment_period.endDate, DATE_FORMAT_DATE_PICKER, DATE_FORMAT_INPUT)
  };

// A function to adapt form values to be compliant with the back-end expected format
export const adaptRateContainerFormValues = values =>
  values && {
    ...values,
    load_port: values.load_port && values.load_port.value,
    discharge_port: values.discharge_port && values.discharge_port.value,
    container_type: values.container_type && values.container_type.value,
    price: values.price && clearNumber(values.price),
    valid_until: values.valid_until && dateToString(values.valid_until, DATE_FORMAT_DATE_PICKER, DATE_FORMAT_INPUT)
  };

// A function to adapt form values to be compliant with the back-end expected format
export const adaptIntakeRestrictionFormValues = values =>
  values && {
    ...values,
    discharge_port: values.discharge_port && values.discharge_port.value,
    container_type: values.container_type && values.container_type.value,
    intake: values.intake && clearNumber(values.intake)
  };

// A function to adapt form values to be compliant with the back-end expected format
export const adaptIntakeContainerFormValues = values =>
  values && {
    ...values,
    commodity: values.commodity && values.commodity.value,
    load_port: values.load_port && values.load_port.value,
    container_type: values.container_type && values.container_type.value,
    intake: values.intake && clearNumber(values.intake)
  };

// A function to adapt form values to be compliant with the front-end expected format
export const adaptRateContainerInitialFormValues = values =>
  values && {
    ...values,
    load_port:
      (values.load_port && {
        value: values.load_port.code,
        label: values.load_port.name
      }) ||
      undefined,
    discharge_port:
      (values.discharge_port && {
        value: values.discharge_port.code,
        label: values.discharge_port.name
      }) ||
      undefined,
    price: values.price || undefined,
    container_type:
      (values.container_type &&
        adaptOption(
          CONTAINER_TYPES.find(containerType => values.container_type === containerType.value),
          "value",
          "description"
        )) ||
      undefined,
    valid_until: (values.valid_until && stringToDate(values.valid_until, DATE_FORMAT_INPUT)) || undefined
  };

// A function to adapt form values to be compliant with the front-end expected format
export const adaptIntakeContainerInitialFormValues = values =>
  values && {
    ...values,
    commodity: (values.commodity && { value: values.commodity.code, label: values.commodity.description }) || undefined,
    load_port:
      (values.load_port && {
        value: values.load_port.code,
        label: values.load_port.name
      }) ||
      undefined,
    intake: values.intake || undefined,
    container_type:
      (values.container_type &&
        adaptOption(
          CONTAINER_TYPES.find(containerType => values.container_type === containerType.value),
          "value",
          "description"
        )) ||
      undefined
  };

// A function to adapt form values to be compliant with the front-end expected format
export const adaptIntakeRestrictionInitialFormValues = values =>
  values && {
    ...values,
    discharge_port:
      (values.discharge_port && {
        value: values.discharge_port.code,
        label: values.discharge_port.name
      }) ||
      undefined,
    intake: values.intake || undefined,
    container_type:
      (values.container_type &&
        adaptOption(
          CONTAINER_TYPES.find(containerType => values.container_type === containerType.value),
          "value",
          "description"
        )) ||
      undefined
  };

// A page to show the list of Shipping Rates
export class ShippingRates extends Component {
  // Binding events to this
  constructor(props) {
    super(props);
    this.onPageChangeShippingRates = this.onPageChangeShippingRates.bind(this);
    this.onClickRatesMtTab = this.onClickRatesMtTab.bind(this);
    this.onClickRateContainerTab = this.onClickRateContainerTab.bind(this);
    this.onClickIntakeRestrictionTab = this.onClickIntakeRestrictionTab.bind(this);
    this.onClickIntakeContainerTab = this.onClickIntakeContainerTab.bind(this);
    this.onClickSearchShippingRate = this.onClickSearchShippingRate.bind(this);
    this.loadPorts = this.loadPorts.bind(this);
    this.dischargePorts = this.dischargePorts.bind(this);
    this.onClickRequestShippingRate = this.onClickRequestShippingRate.bind(this);
    this.onCancelRequestShippingRateModal = this.onCancelRequestShippingRateModal.bind(this);
    this.onValidateRequestShippingRateModal = this.onValidateRequestShippingRateModal.bind(this);
    this.onValidateSuccessModal = this.onValidateSuccessModal.bind(this);
    this.isOutsideRangeShipmentPeriodDate = this.isOutsideRangeShipmentPeriodDate.bind(this);
    this.onPageChangeRateContainers = this.onPageChangeRateContainers.bind(this);
    this.onClickSearchRateContainer = this.onClickSearchRateContainer.bind(this);
    this.onClickAddRateContainer = this.onClickAddRateContainer.bind(this);
    this.onCancelRateContainerModal = this.onCancelRateContainerModal.bind(this);
    this.onValidateRateContainerModal = this.onValidateRateContainerModal.bind(this);
    this.onPageChangeIntakeRestrictions = this.onPageChangeIntakeRestrictions.bind(this);
    this.onClickSearchIntakeRestriction = this.onClickSearchIntakeRestriction.bind(this);
    this.onClickAddIntakeRestriction = this.onClickAddIntakeRestriction.bind(this);
    this.onCancelIntakeRestrictionModal = this.onCancelIntakeRestrictionModal.bind(this);
    this.onValidateIntakeRestrictionModal = this.onValidateIntakeRestrictionModal.bind(this);
    this.onPageChangeIntakeContainers = this.onPageChangeIntakeContainers.bind(this);
    this.onClickSearchIntakeContainer = this.onClickSearchIntakeContainer.bind(this);
    this.onClickAddIntakeContainer = this.onClickAddIntakeContainer.bind(this);
    this.onCancelIntakeContainerModal = this.onCancelIntakeContainerModal.bind(this);
    this.onValidateIntakeContainerModal = this.onValidateIntakeContainerModal.bind(this);
    this.onClickExportShippingRates = this.onClickExportShippingRates.bind(this);
    this.onClickExportRateContainers = this.onClickExportRateContainers.bind(this);
    this.onClickExportIntakeRestrictions = this.onClickExportIntakeRestrictions.bind(this);
    this.onClickExportIntakeContainers = this.onClickExportIntakeContainers.bind(this);
    this.onClickDeleteRateContainer = this.onClickDeleteRateContainer.bind(this);
    this.onValidateRateContainerDeleteModal = this.onValidateRateContainerDeleteModal.bind(this);
    this.onCancelRateContainerDeleteModal = this.onCancelRateContainerDeleteModal.bind(this);
    this.onClickDeleteIntakeContainer = this.onClickDeleteIntakeContainer.bind(this);
    this.onValidateIntakeContainerDeleteModal = this.onValidateIntakeContainerDeleteModal.bind(this);
    this.onCancelIntakeContainerDeleteModal = this.onCancelIntakeContainerDeleteModal.bind(this);
    this.onClickDeleteIntakeRestriction = this.onClickDeleteIntakeRestriction.bind(this);
    this.onValidateIntakeRestrictionDeleteModal = this.onValidateIntakeRestrictionDeleteModal.bind(this);
    this.onCancelIntakeRestrictionDeleteModal = this.onCancelIntakeRestrictionDeleteModal.bind(this);
    this.onClickEditRateContainer = this.onClickEditRateContainer.bind(this);
    this.onClickEditIntakeContainer = this.onClickEditIntakeContainer.bind(this);
    this.onClickEditIntakeRestriction = this.onClickEditIntakeRestriction.bind(this);
  }

  // On component mount, data are loaded
  componentDidMount() {
    this.props.specificCommodityList();
    this.filterShippingRates(0);
    this.filterRateContainers(0);
    this.filterIntakeRestrictions(0);
    this.filterIntakeContainers(0);
    this.props.userDetail();
  }

  // On component unmount store is reset where needed
  componentWillUnmount() {
    this.props.shippingRateCurrentTab(0);
    this.props.shippingRateCurrentPage(0);
    this.props.rateContainerCurrentPage(0);
    this.props.intakeRestrictionCurrentPage(0);
    this.props.intakeContainerCurrentPage(0);
    this.resetRequestShippingRateModal();
    this.resetRateContainerModal();
    this.resetIntakeRestrictionModal();
    this.resetIntakeContainerModal();
    this.resetRateContainerDeleteModal();
    this.resetIntakeContainerDeleteModal();
    this.resetIntakeRestrictionDeleteModal();
  }

  // A method to filter by props and and given filter
  filterShippingRates(page) {
    const { selectedLoadPorts, selectedDischargePorts, selectedCommodities, selectedContainersTypes } = this.props;
    this.props.shippingRateCurrentPage(page);
    this.props.shippingRateList(
      page + 1,
      selectedLoadPorts,
      selectedDischargePorts,
      selectedCommodities,
      selectedContainersTypes
    );
  }

  // A method to filter by props and and given filter
  filterRateContainers(page) {
    const {
      selectedLoadPortsRateContainer,
      selectedDischargePortsRateContainer,
      selectedContainersTypesRateContainer
    } = this.props;
    this.props.rateContainerCurrentPage(page);
    this.props.rateContainerList(
      page + 1,
      selectedLoadPortsRateContainer,
      selectedDischargePortsRateContainer,
      selectedContainersTypesRateContainer
    );
  }

  // A method to filter by props and and given filter
  filterIntakeRestrictions(page) {
    const {
      selectedDischargePortsIntakeRestriction,
      selectedCommoditiesIntakeRestriction,
      selectedContainersTypesIntakeRestriction
    } = this.props;
    this.props.intakeRestrictionCurrentPage(page);
    this.props.intakeRestrictionList(
      page + 1,
      selectedDischargePortsIntakeRestriction,
      selectedCommoditiesIntakeRestriction,
      selectedContainersTypesIntakeRestriction
    );
  }

  // A method to filter by props and and given filter
  filterIntakeContainers(page) {
    const {
      selectedLoadPortsIntakeContainer,
      selectedCommoditiesIntakeContainer,
      selectedContainersTypesIntakeContainer
    } = this.props;
    this.props.intakeContainerCurrentPage(page);
    this.props.intakeContainerList(
      page + 1,
      selectedLoadPortsIntakeContainer,
      selectedCommoditiesIntakeContainer,
      selectedContainersTypesIntakeContainer
    );
  }

  // When user select a page we have to perform a request with given parameters
  onPageChangeShippingRates(page) {
    this.filterShippingRates(page);
  }

  // When user select a page we have to perform a request with given parameters
  onPageChangeRateContainers(page) {
    this.filterRateContainers(page);
  }

  // When user select a page we have to perform a request with given parameters
  onPageChangeIntakeRestrictions(page) {
    this.filterIntakeRestrictions(page);
  }

  // When user select a page we have to perform a request with given parameters
  onPageChangeIntakeContainers(page) {
    this.filterIntakeContainers(page);
  }

  // Set the current tab to Rates/Mt
  onClickRatesMtTab() {
    this.changeCurrentTab(0);
  }

  // Set the current tab to Rate/ Container
  onClickRateContainerTab() {
    this.changeCurrentTab(1);
  }

  // Set the current tab to Intake/Restriction
  onClickIntakeRestrictionTab() {
    this.changeCurrentTab(2);
  }

  // Set the current tab to Intake/Container
  onClickIntakeContainerTab() {
    this.changeCurrentTab(3);
  }

  // A method used to change the current tab
  changeCurrentTab(tab) {
    this.props.shippingRateCurrentTab(tab);
  }

  // Getting and adapting load port list
  loadPorts(value) {
    return this.props
      .loadPortList(value)
      .then(data => data.results.map(result => ({ value: result.code, label: result.name })));
  }

  // Getting and adapting discharge port list
  dischargePorts(value) {
    return this.props
      .dischargePortList(value)
      .then(data => data.results.map(result => ({ value: result.code, label: result.name })));
  }

  // Search action on Rate/Mt tab
  onClickSearchShippingRate() {
    this.filterShippingRates(0);
  }

  // Search action on Rate/Container tab
  onClickSearchRateContainer() {
    this.filterRateContainers(0);
  }

  // Search action on Intake/Restriction tab
  onClickSearchIntakeRestriction() {
    this.filterIntakeRestrictions(0);
  }

  // Search action on Intake/Restriction tab
  onClickSearchIntakeContainer() {
    this.filterIntakeContainers(0);
  }

  // Shows the modal to request a Shipping Rate
  onClickRequestShippingRate() {
    this.props.showRequestShippingRateModal();
  }

  // Shows the modal to add a Rate/Container
  onClickAddRateContainer() {
    this.props.showRateContainerModal();
  }

  // Shows the modal to add a Intake/Restriction
  onClickAddIntakeRestriction() {
    this.props.showIntakeRestrictionModal();
  }

  // Shows the modal to add a Intake/Container
  onClickAddIntakeContainer() {
    this.props.showIntakeContainerModal();
  }

  // Close the modal to add a Request Shipping Rate
  onCancelRequestShippingRateModal() {
    this.resetRequestShippingRateModal();
  }

  // Close the modal to add a Rate/Container
  onCancelRateContainerModal() {
    this.resetRateContainerModal();
  }

  // Close the modal to add a Intake/Restriction
  onCancelIntakeRestrictionModal() {
    this.resetIntakeRestrictionModal();
  }

  // Close the modal to add a Intake/Container
  onCancelIntakeContainerModal() {
    this.resetIntakeContainerModal();
  }

  // Adds a Shipping Rate Request
  onValidateRequestShippingRateModal() {
    this.props
      .requestShippingRateAdd(adaptRequestShippingRateFormValues(this.props.requestShippingRateFormValues))
      .then(success => {
        if (success) {
          this.resetRequestShippingRateModal();
          this.props.showSuccessModal();
        }
      });
  }

  // Adds/edit a Rate/Container
  onValidateRateContainerModal() {
    if (this.props.isOnEditRateContainer) {
      this.props
        .rateContainerUpdate(
          this.props.currentIdRateContainer,
          adaptRateContainerFormValues(this.props.rateContainerFormValues)
        )
        .then(success => {
          if (success) {
            this.resetRateContainerModal();
            this.filterRateContainers(0);
          }
        });
    } else {
      this.props.rateContainerAdd(adaptRateContainerFormValues(this.props.rateContainerFormValues)).then(success => {
        if (success) {
          this.filterRateContainers(0);
          this.resetRateContainerModal();
        }
      });
    }
  }

  // Adds/edit a Intake/Restriction
  onValidateIntakeRestrictionModal() {
    if (this.props.isOnEditIntakeRestriction) {
      this.props
        .intakeRestrictionUpdate(
          this.props.currentIdIntakeRestriction,
          adaptIntakeRestrictionFormValues(this.props.intakeRestrictionFormValues)
        )
        .then(success => {
          if (success) {
            this.resetIntakeRestrictionModal();
            this.filterIntakeRestrictions(0);
          }
        });
    } else {
      this.props
        .intakeRestrictionAdd(adaptIntakeRestrictionFormValues(this.props.intakeRestrictionFormValues))
        .then(success => {
          if (success) {
            this.filterIntakeRestrictions(0);
            this.resetIntakeRestrictionModal();
          }
        });
    }
  }

  // Adds/edit a Intake/Container
  onValidateIntakeContainerModal() {
    if (this.props.isOnEditIntakeContainer) {
      this.props
        .intakeContainerUpdate(
          this.props.currentIdIntakeContainer,
          adaptIntakeContainerFormValues(this.props.intakeContainerFormValues)
        )
        .then(success => {
          if (success) {
            this.resetIntakeContainerModal();
            this.filterIntakeContainers(0);
          }
        });
    } else {
      this.props
        .intakeContainerAdd(adaptIntakeContainerFormValues(this.props.intakeContainerFormValues))
        .then(success => {
          if (success) {
            this.filterIntakeContainers(0);
            this.resetIntakeContainerModal();
          }
        });
    }
  }

  // Reset the modal to add a Request Shipping Rate
  resetRequestShippingRateModal() {
    this.props.hideRequestShippingRateModal();
    this.props.resetRequestShippingRateForm();
    this.props.requestShippingRateAddReset();
  }

  // Reset the modal to add a Rate/Container
  resetRateContainerModal() {
    this.props.hideRateContainerModal();
    this.props.resetRateContainerForm();
    this.props.rateContainerAddReset();
    this.props.rateContainerUpdateReset();
    this.props.rateContainerDetailReset();
  }

  // Reset the modal to add a Intake/Restriction
  resetIntakeRestrictionModal() {
    this.props.hideIntakeRestrictionModal();
    this.props.resetIntakeRestrictionForm();
    this.props.intakeRestrictionAddReset();
    this.props.intakeRestrictionUpdateReset();
    this.props.intakeRestrictionDetailReset();
  }

  // Reset the modal to add a Intake/Restriction
  resetIntakeContainerModal() {
    this.props.hideIntakeContainerModal();
    this.props.resetIntakeContainerForm();
    this.props.intakeContainerAddReset();
    this.props.intakeContainerUpdateReset();
    this.props.intakeContainerDetailReset();
  }

  // Close the success modal
  onValidateSuccessModal() {
    this.props.hideSuccessModal();
  }

  // Check if shipment period date is in future
  isOutsideRangeShipmentPeriodDate(date, currentDate = new Date()) {
    return date.startOf("day") <= moment(currentDate).startOf("day");
  }

  // Data are exported as Excel file based on filters selected by user
  onClickExportShippingRates() {
    let parameters = [];
    parameters.push({ label: "load_port", value: this.props.selectedLoadPorts });
    parameters.push({ label: "discharge_port", value: this.props.selectedDischargePorts });
    parameters.push({ label: "commodity", value: this.props.selectedCommodities });
    parameters.push({ label: "container_type", value: this.props.selectedContainersTypes });
    openUrl(API_URL_DOWNLOAD_XLS_SHIPPING_RATES, parameters);
  }

  // Data are exported as Excel file based on filters selected by user
  onClickExportRateContainers() {
    let parameters = [];
    parameters.push({ label: "load_port", value: this.props.selectedLoadPortsRateContainer });
    parameters.push({ label: "discharge_port", value: this.props.selectedDischargePortsRateContainer });
    parameters.push({ label: "container_type", value: this.props.selectedContainersTypesRateContainer });
    openUrl(API_URL_DOWNLOAD_XLS_RATE_CONTAINERS, parameters);
  }

  // Data are exported as Excel file based on filters selected by user
  onClickExportIntakeRestrictions() {
    let parameters = [];
    parameters.push({ label: "discharge_port", value: this.props.selectedDischargePortsIntakeRestriction });
    parameters.push({ label: "container_type", value: this.props.selectedContainersTypesIntakeRestriction });
    openUrl(API_URL_DOWNLOAD_XLS_INTAKE_RESTRICTIONS, parameters);
  }

  // Data are exported as Excel file based on filters selected by user
  onClickExportIntakeContainers() {
    let parameters = [];
    parameters.push({ label: "load_port", value: this.props.selectedLoadPortsIntakeContainer });
    parameters.push({ label: "commodity", value: this.props.selectedCommoditiesIntakeContainer });
    parameters.push({ label: "container_type", value: this.props.selectedContainersTypesIntakeContainer });
    openUrl(API_URL_DOWNLOAD_XLS_INTAKE_CONTAINERS, parameters);
  }

  // Shows the modal to delete a Rate Container
  onClickDeleteRateContainer(id) {
    this.props.activeRecordRateContainer(id);
    this.props.showDeleteRateContainerModal();
  }

  // Shows the modal to delete a Intake Container
  onClickDeleteIntakeContainer(id) {
    this.props.activeRecordIntakeContainer(id);
    this.props.showDeleteIntakeContainerModal();
  }

  // Shows the modal to delete a Intake Restriction
  onClickDeleteIntakeRestriction(id) {
    this.props.activeRecordIntakeRestriction(id);
    this.props.showDeleteIntakeRestrictionModal();
  }

  // On click cancel on delete Rate Container modal
  onCancelRateContainerDeleteModal() {
    this.resetRateContainerDeleteModal();
  }

  // On click cancel on delete Intake Container modal
  onCancelIntakeContainerDeleteModal() {
    this.resetIntakeContainerDeleteModal();
  }

  // On click cancel on delete Intake Restriction modal
  onCancelIntakeRestrictionDeleteModal() {
    this.resetIntakeRestrictionDeleteModal();
  }

  // Reset the modal to delete a Rate Container
  resetRateContainerDeleteModal() {
    this.props.hideDeleteRateContainerModal();
    this.props.activeRecordRateContainer(0);
  }

  // Reset the modal to delete a Intake Container
  resetIntakeContainerDeleteModal() {
    this.props.hideDeleteIntakeContainerModal();
    this.props.activeRecordIntakeContainer(0);
  }

  // Reset the modal to delete a Intake Restriction
  resetIntakeRestrictionDeleteModal() {
    this.props.hideDeleteIntakeRestrictionModal();
    this.props.activeRecordIntakeRestriction(0);
  }

  // On click validate on delete Rate Container modal
  onValidateRateContainerDeleteModal() {
    this.props.rateContainerDelete(this.props.idActiveRecordRateContainer).then(success => {
      if (success) {
        this.resetRateContainerDeleteModal();
        this.filterRateContainers(0);
      }
    });
  }

  // On click validate on delete Intake Container modal
  onValidateIntakeContainerDeleteModal() {
    this.props.intakeContainerDelete(this.props.idActiveRecordIntakeContainer).then(success => {
      if (success) {
        this.resetIntakeContainerDeleteModal();
        this.filterIntakeContainers(0);
      }
    });
  }

  // On click validate on delete Intake Restriction modal
  onValidateIntakeRestrictionDeleteModal() {
    this.props.intakeRestrictionDelete(this.props.idActiveRecordIntakeRestriction).then(success => {
      if (success) {
        this.resetIntakeRestrictionDeleteModal();
        this.filterIntakeRestrictions(0);
      }
    });
  }

  // Shows the modal to edit a Rate Container
  onClickEditRateContainer(id) {
    this.props.rateContainerDetail(id).then(success => {
      if (success) {
        this.props.showRateContainerModal();
      }
    });
  }

  // Shows the modal to edit a Intake Container
  onClickEditIntakeContainer(id) {
    this.props.intakeContainerDetail(id).then(success => {
      if (success) {
        this.props.showIntakeContainerModal();
      }
    });
  }

  // Shows the modal to edit a Intake
  onClickEditIntakeRestriction(id) {
    this.props.intakeRestrictionDetail(id).then(success => {
      if (success) {
        this.props.showIntakeRestrictionModal();
      }
    });
  }

  render() {
    const {
      isFetchingShippingRates,
      errorMessageShippingRates,
      shippingRates,
      totalItemsShippingRates,
      pageShippingRates,
      currentTab,
      specificCommodities,
      containerTypes,
      isFetchingRelatedEntities,
      isRequestShippingRateModalOpen,
      errorObjectRequestShippingRateModal,
      isSuccessModalOpen,
      isLoadingRequestShippingRateModal,
      errorMessageRequestShippingRateModal,
      isFetchingRateContainers,
      errorMessageRateContainers,
      rateContainers,
      totalItemsRateContainers,
      pageRateContainers,
      isRateContainerModalOpen,
      errorObjectRateContainerModal,
      isLoadingRateContainerModal,
      errorMessageRateContainerModal,
      isIntakeRestrictionModalOpen,
      errorObjectIntakeRestrictionModal,
      isLoadingIntakeRestrictionModal,
      errorMessageIntakeRestrictionModal,
      intakeRestrictions,
      errorMessageIntakeRestrictions,
      totalItemsIntakeRestrictions,
      pageIntakeRestrictions,
      isFetchingIntakeRestrictions,
      intakeContainers,
      errorMessageIntakeContainers,
      totalItemsIntakeContainers,
      pageIntakeContainers,
      isFetchingIntakeContainers,
      isIntakeContainerModalOpen,
      errorObjectIntakeContainerModal,
      isLoadingIntakeContainerModal,
      errorMessageIntakeContainerModal,
      isDeleteRateContainerModalOpen,
      errorMessageDeleteRateContainer,
      isLoadingDeleteRateContainerModal,
      canDeleteRateContainer,
      isDeleteIntakeContainerModalOpen,
      errorMessageDeleteIntakeContainer,
      isLoadingDeleteIntakeContainerModal,
      canDeleteIntakeContainer,
      isDeleteIntakeRestrictionModalOpen,
      errorMessageDeleteIntakeRestriction,
      isLoadingDeleteIntakeRestrictionModal,
      canDeleteIntakeRestriction,
      isOnEditRateContainer,
      isOnEditIntakeContainer,
      isOnEditIntakeRestriction,
      initialFormValuesRateContainer,
      initialFormValuesIntakeContainer,
      initialFormValuesIntakeRestriction,
      canChangeRateContainer,
      canChangeIntakeContainer,
      canChangeIntakeRestriction,
      canRequestShippingRate,
      canAddNewRate,
      canAddNewRestriction,
      canAddNewIntake
    } = this.props;
    return (
      <>
        <PageTabs
          title="Shipping Rates"
          tabs={[
            {
              id: "rate_mt",
              label: "Rates/Mt",
              onClick: this.onClickRatesMtTab
            },
            {
              id: "rate_container",
              label: "Rate/Container",
              onClick: this.onClickRateContainerTab
            },
            {
              id: "intake_restriction",
              label: "Intake/Restriction",
              onClick: this.onClickIntakeRestrictionTab
            },
            {
              id: "intake_container",
              label: "Intake/Container",
              onClick: this.onClickIntakeContainerTab
            }
          ]}
          additional={
            <>
              <ShowHideArea isVisible={currentTab === 0 && canRequestShippingRate}>
                <Button onClick={this.onClickRequestShippingRate}>Request shipping rate</Button>
              </ShowHideArea>
              <ShowHideArea isVisible={currentTab === 1 && canAddNewRate}>
                <Button onClick={this.onClickAddRateContainer}>Add new rate</Button>
              </ShowHideArea>
              <ShowHideArea isVisible={currentTab === 2 && canAddNewRestriction}>
                <Button onClick={this.onClickAddIntakeRestriction}>Add new restriction</Button>
              </ShowHideArea>
              <ShowHideArea isVisible={currentTab === 3 && canAddNewIntake}>
                <Button onClick={this.onClickAddIntakeContainer}>Add new intake</Button>
              </ShowHideArea>
            </>
          }
        >
          <ShippingRatesList
            isVisible={currentTab === 0}
            loadingShippingRates={isFetchingShippingRates}
            errorMessageShippingRates={errorMessageShippingRates}
            shippingRates={shippingRates}
            totalItems={totalItemsShippingRates}
            page={pageShippingRates}
            onPageChange={this.onPageChangeShippingRates}
            loadPorts={this.loadPorts}
            dischargePorts={this.dischargePorts}
            specificCommodities={specificCommodities}
            containerTypes={containerTypes}
            loadingFilters={isFetchingRelatedEntities}
            onClickSearch={this.onClickSearchShippingRate}
            onClickExport={this.onClickExportShippingRates}
            onClickDelete={this.onClickDeleteShippingRate}
          />
          <RequestShippingRateModal
            isOpen={isRequestShippingRateModalOpen}
            onValidate={this.onValidateRequestShippingRateModal}
            onCancel={this.onCancelRequestShippingRateModal}
            errorObject={errorObjectRequestShippingRateModal}
            errorMessage={errorMessageRequestShippingRateModal}
            loadingRelatedEntities={isFetchingRelatedEntities}
            specificCommodities={specificCommodities}
            loadPorts={this.loadPorts}
            dischargePorts={this.dischargePorts}
            isOutsideRangeShipmentPeriodDate={this.isOutsideRangeShipmentPeriodDate}
            isLoading={isLoadingRequestShippingRateModal}
          />
          <RateContainerModal
            isOpen={isRateContainerModalOpen}
            onValidate={this.onValidateRateContainerModal}
            onCancel={this.onCancelRateContainerModal}
            errorObject={errorObjectRateContainerModal}
            errorMessage={errorMessageRateContainerModal}
            loadPorts={this.loadPorts}
            dischargePorts={this.dischargePorts}
            containerTypes={containerTypes}
            isLoading={isLoadingRateContainerModal}
            isOnEdit={isOnEditRateContainer}
            initialValues={initialFormValuesRateContainer}
          />
          <ModalSuccess
            isOpen={isSuccessModalOpen}
            onValidate={this.onValidateSuccessModal}
            text="Your request has been sent"
          />
          <RateContainerList
            isVisible={currentTab === 1}
            loadingRateContainers={isFetchingRateContainers}
            errorMessageRateContainers={errorMessageRateContainers}
            rateContainers={rateContainers}
            totalItems={totalItemsRateContainers}
            page={pageRateContainers}
            onPageChange={this.onPageChangeRateContainers}
            loadPorts={this.loadPorts}
            dischargePorts={this.dischargePorts}
            containerTypes={containerTypes}
            loadingFilters={isFetchingRelatedEntities}
            onClickSearch={this.onClickSearchRateContainer}
            onClickExport={this.onClickExportRateContainers}
            onClickDelete={this.onClickDeleteRateContainer}
            onClickEdit={this.onClickEditRateContainer}
            canDeleteRateContainer={canDeleteRateContainer}
            canChangeRateContainer={canChangeRateContainer}
          />
          <IntakeRestrictionList
            isVisible={currentTab === 2}
            intakeRestrictions={intakeRestrictions}
            errorMessageIntakeRestrictions={errorMessageIntakeRestrictions}
            totalItems={totalItemsIntakeRestrictions}
            page={pageIntakeRestrictions}
            onPageChange={this.onPageChangeIntakeRestrictions}
            loadingIntakeRestrictions={isFetchingIntakeRestrictions}
            dischargePorts={this.dischargePorts}
            containerTypes={containerTypes}
            loadingFilters={isFetchingRelatedEntities}
            onClickSearch={this.onClickSearchIntakeRestriction}
            onClickExport={this.onClickExportIntakeRestrictions}
            onClickDelete={this.onClickDeleteIntakeRestriction}
            onClickEdit={this.onClickEditIntakeRestriction}
            canDeleteIntakeRestriction={canDeleteIntakeRestriction}
            canChangeIntakeRestriction={canChangeIntakeRestriction}
          />
          <IntakeRestrictionModal
            isOpen={isIntakeRestrictionModalOpen}
            onValidate={this.onValidateIntakeRestrictionModal}
            onCancel={this.onCancelIntakeRestrictionModal}
            errorObject={errorObjectIntakeRestrictionModal}
            errorMessage={errorMessageIntakeRestrictionModal}
            loadingRelatedEntities={isFetchingRelatedEntities}
            dischargePorts={this.dischargePorts}
            containerTypes={containerTypes}
            isLoading={isLoadingIntakeRestrictionModal}
            isOnEdit={isOnEditIntakeRestriction}
            initialValues={initialFormValuesIntakeRestriction}
          />
          <IntakeContainerList
            isVisible={currentTab === 3}
            intakeContainers={intakeContainers}
            errorMessageIntakeContainers={errorMessageIntakeContainers}
            totalItems={totalItemsIntakeContainers}
            page={pageIntakeContainers}
            onPageChange={this.onPageChangeIntakeContainers}
            loadingIntakeContainers={isFetchingIntakeContainers}
            loadPorts={this.loadPorts}
            specificCommodities={specificCommodities}
            containerTypes={containerTypes}
            loadingFilters={isFetchingRelatedEntities}
            onClickSearch={this.onClickSearchIntakeContainer}
            onClickExport={this.onClickExportIntakeContainers}
            onClickDelete={this.onClickDeleteIntakeContainer}
            onClickEdit={this.onClickEditIntakeContainer}
            canDeleteIntakeContainer={canDeleteIntakeContainer}
            canChangeIntakeContainer={canChangeIntakeContainer}
          />
          <IntakeContainerModal
            isOpen={isIntakeContainerModalOpen}
            onValidate={this.onValidateIntakeContainerModal}
            onCancel={this.onCancelIntakeContainerModal}
            errorObject={errorObjectIntakeContainerModal}
            errorMessage={errorMessageIntakeContainerModal}
            loadingRelatedEntities={isFetchingRelatedEntities}
            specificCommodities={specificCommodities}
            loadPorts={this.loadPorts}
            containerTypes={containerTypes}
            isLoading={isLoadingIntakeContainerModal}
            isOnEdit={isOnEditIntakeContainer}
            initialValues={initialFormValuesIntakeContainer}
          />
          <ModalConfirm
            isOpen={isDeleteRateContainerModalOpen}
            errorMessage={errorMessageDeleteRateContainer}
            onValidate={this.onValidateRateContainerDeleteModal}
            onCancel={this.onCancelRateContainerDeleteModal}
            isLoading={isLoadingDeleteRateContainerModal}
            title="Delete Rate/Container?"
            text="Are you sure you want to delete this Rate/Container?"
          />
          <ModalConfirm
            isOpen={isDeleteIntakeRestrictionModalOpen}
            errorMessage={errorMessageDeleteIntakeRestriction}
            onValidate={this.onValidateIntakeRestrictionDeleteModal}
            onCancel={this.onCancelIntakeRestrictionDeleteModal}
            isLoading={isLoadingDeleteIntakeRestrictionModal}
            title="Delete Intake/Restriction"
            text="Are you sure you want to delete this Intake/Restriction?"
          />
          <ModalConfirm
            isOpen={isDeleteIntakeContainerModalOpen}
            errorMessage={errorMessageDeleteIntakeContainer}
            onValidate={this.onValidateIntakeContainerDeleteModal}
            onCancel={this.onCancelIntakeContainerDeleteModal}
            isLoading={isLoadingDeleteIntakeContainerModal}
            title="Delete Intake/Container"
            text="Are you sure you want to delete this Intake/Container?"
          />
        </PageTabs>
      </>
    );
  }
}

// propTypes for the ShippingRates component
ShippingRates.propTypes = {
  shippingRateCurrentPage: PropTypes.func.isRequired,
  shippingRateList: PropTypes.func.isRequired,
  isFetchingShippingRates: PropTypes.bool.isRequired,
  errorMessageShippingRates: PropTypes.string.isRequired,
  shippingRates: PropTypes.array.isRequired,
  totalItemsShippingRates: PropTypes.number.isRequired,
  pageShippingRates: PropTypes.number.isRequired,
  currentTab: PropTypes.number.isRequired,
  shippingRateCurrentTab: PropTypes.func.isRequired,
  specificCommodityList: PropTypes.func.isRequired,
  selectedLoadPorts: PropTypes.array.isRequired,
  selectedDischargePorts: PropTypes.array.isRequired,
  selectedCommodities: PropTypes.array.isRequired,
  selectedContainersTypes: PropTypes.array.isRequired,
  loadPortList: PropTypes.func.isRequired,
  dischargePortList: PropTypes.func.isRequired,
  specificCommodities: PropTypes.array.isRequired,
  containerTypes: PropTypes.array.isRequired,
  isFetchingRelatedEntities: PropTypes.bool.isRequired,
  showRequestShippingRateModal: PropTypes.func.isRequired,
  requestShippingRateAdd: PropTypes.func.isRequired,
  showSuccessModal: PropTypes.func.isRequired,
  hideRequestShippingRateModal: PropTypes.func.isRequired,
  resetRequestShippingRateForm: PropTypes.func.isRequired,
  requestShippingRateAddReset: PropTypes.func.isRequired,
  isRequestShippingRateModalOpen: PropTypes.bool.isRequired,
  errorObjectRequestShippingRateModal: PropTypes.object.isRequired,
  isSuccessModalOpen: PropTypes.bool.isRequired,
  hideSuccessModal: PropTypes.func.isRequired,
  requestShippingRateFormValues: PropTypes.any,
  isLoadingRequestShippingRateModal: PropTypes.bool.isRequired,
  errorMessageRequestShippingRateModal: PropTypes.string.isRequired,
  rateContainerCurrentPage: PropTypes.func.isRequired,
  selectedLoadPortsRateContainer: PropTypes.array.isRequired,
  selectedDischargePortsRateContainer: PropTypes.array.isRequired,
  selectedContainersTypesRateContainer: PropTypes.array.isRequired,
  rateContainerList: PropTypes.func.isRequired,
  isFetchingRateContainers: PropTypes.bool.isRequired,
  errorMessageRateContainers: PropTypes.string.isRequired,
  rateContainers: PropTypes.array.isRequired,
  totalItemsRateContainers: PropTypes.number.isRequired,
  pageRateContainers: PropTypes.number.isRequired,
  showRateContainerModal: PropTypes.func.isRequired,
  rateContainerAdd: PropTypes.func.isRequired,
  rateContainerFormValues: PropTypes.any,
  hideRateContainerModal: PropTypes.func.isRequired,
  resetRateContainerForm: PropTypes.func.isRequired,
  rateContainerAddReset: PropTypes.func.isRequired,
  isRateContainerModalOpen: PropTypes.bool.isRequired,
  errorObjectRateContainerModal: PropTypes.object.isRequired,
  isLoadingRateContainerModal: PropTypes.bool.isRequired,
  errorMessageRateContainerModal: PropTypes.string.isRequired,
  intakeRestrictionCurrentPage: PropTypes.func.isRequired,
  selectedDischargePortsIntakeRestriction: PropTypes.array.isRequired,
  selectedCommoditiesIntakeRestriction: PropTypes.array.isRequired,
  selectedContainersTypesIntakeRestriction: PropTypes.array.isRequired,
  intakeRestrictionList: PropTypes.func.isRequired,
  intakeRestrictions: PropTypes.array.isRequired,
  errorMessageIntakeRestrictions: PropTypes.string.isRequired,
  totalItemsIntakeRestrictions: PropTypes.number.isRequired,
  pageIntakeRestrictions: PropTypes.number.isRequired,
  isFetchingIntakeRestrictions: PropTypes.bool.isRequired,
  showIntakeRestrictionModal: PropTypes.func.isRequired,
  intakeRestrictionAdd: PropTypes.func.isRequired,
  isIntakeRestrictionModalOpen: PropTypes.bool.isRequired,
  errorObjectIntakeRestrictionModal: PropTypes.object.isRequired,
  isLoadingIntakeRestrictionModal: PropTypes.bool.isRequired,
  errorMessageIntakeRestrictionModal: PropTypes.string.isRequired,
  hideIntakeRestrictionModal: PropTypes.func.isRequired,
  resetIntakeRestrictionForm: PropTypes.func.isRequired,
  intakeRestrictionAddReset: PropTypes.func.isRequired,
  intakeRestrictionFormValues: PropTypes.any,
  intakeContainerCurrentPage: PropTypes.func.isRequired,
  selectedLoadPortsIntakeContainer: PropTypes.array.isRequired,
  selectedCommoditiesIntakeContainer: PropTypes.array.isRequired,
  selectedContainersTypesIntakeContainer: PropTypes.array.isRequired,
  intakeContainerList: PropTypes.func.isRequired,
  intakeContainers: PropTypes.array.isRequired,
  errorMessageIntakeContainers: PropTypes.string.isRequired,
  totalItemsIntakeContainers: PropTypes.number.isRequired,
  pageIntakeContainers: PropTypes.number.isRequired,
  isFetchingIntakeContainers: PropTypes.bool.isRequired,
  showIntakeContainerModal: PropTypes.func.isRequired,
  intakeContainerAdd: PropTypes.func.isRequired,
  isIntakeContainerModalOpen: PropTypes.bool.isRequired,
  errorObjectIntakeContainerModal: PropTypes.object.isRequired,
  isLoadingIntakeContainerModal: PropTypes.bool.isRequired,
  errorMessageIntakeContainerModal: PropTypes.string.isRequired,
  hideIntakeContainerModal: PropTypes.func.isRequired,
  resetIntakeContainerForm: PropTypes.func.isRequired,
  intakeContainerAddReset: PropTypes.func.isRequired,
  intakeContainerFormValues: PropTypes.any,
  userDetail: PropTypes.func.isRequired,
  isOnEditRateContainer: PropTypes.bool.isRequired,
  rateContainerUpdate: PropTypes.func.isRequired,
  currentIdRateContainer: PropTypes.number,
  isOnEditIntakeRestriction: PropTypes.bool.isRequired,
  intakeRestrictionUpdate: PropTypes.func.isRequired,
  currentIdIntakeRestriction: PropTypes.number,
  isOnEditIntakeContainer: PropTypes.bool.isRequired,
  intakeContainerUpdate: PropTypes.func.isRequired,
  currentIdIntakeContainer: PropTypes.number,
  rateContainerUpdateReset: PropTypes.func.isRequired,
  rateContainerDetailReset: PropTypes.func.isRequired,
  intakeRestrictionUpdateReset: PropTypes.func.isRequired,
  intakeRestrictionDetailReset: PropTypes.func.isRequired,
  intakeContainerUpdateReset: PropTypes.func.isRequired,
  intakeContainerDetailReset: PropTypes.func.isRequired,
  activeRecordRateContainer: PropTypes.func.isRequired,
  showDeleteRateContainerModal: PropTypes.func.isRequired,
  activeRecordIntakeContainer: PropTypes.func.isRequired,
  showDeleteIntakeContainerModal: PropTypes.func.isRequired,
  activeRecordIntakeRestriction: PropTypes.func.isRequired,
  showDeleteIntakeRestrictionModal: PropTypes.func.isRequired,
  hideDeleteRateContainerModal: PropTypes.func.isRequired,
  hideDeleteIntakeContainerModal: PropTypes.func.isRequired,
  hideDeleteIntakeRestrictionModal: PropTypes.func.isRequired,
  rateContainerDelete: PropTypes.func.isRequired,
  idActiveRecordRateContainer: PropTypes.number.isRequired,
  intakeContainerDelete: PropTypes.func.isRequired,
  idActiveRecordIntakeContainer: PropTypes.number.isRequired,
  intakeRestrictionDelete: PropTypes.func.isRequired,
  idActiveRecordIntakeRestriction: PropTypes.number.isRequired,
  rateContainerDetail: PropTypes.func.isRequired,
  intakeContainerDetail: PropTypes.func.isRequired,
  intakeRestrictionDetail: PropTypes.func.isRequired,
  isDeleteRateContainerModalOpen: PropTypes.bool.isRequired,
  errorMessageDeleteRateContainer: PropTypes.string.isRequired,
  isLoadingDeleteRateContainerModal: PropTypes.bool.isRequired,
  canDeleteRateContainer: PropTypes.bool.isRequired,
  isDeleteIntakeContainerModalOpen: PropTypes.bool.isRequired,
  errorMessageDeleteIntakeContainer: PropTypes.string.isRequired,
  isLoadingDeleteIntakeContainerModal: PropTypes.bool.isRequired,
  canDeleteIntakeContainer: PropTypes.bool.isRequired,
  isDeleteIntakeRestrictionModalOpen: PropTypes.bool.isRequired,
  errorMessageDeleteIntakeRestriction: PropTypes.string.isRequired,
  isLoadingDeleteIntakeRestrictionModal: PropTypes.bool.isRequired,
  canDeleteIntakeRestriction: PropTypes.bool.isRequired,
  initialFormValuesRateContainer: PropTypes.object,
  initialFormValuesIntakeContainer: PropTypes.object,
  initialFormValuesIntakeRestriction: PropTypes.object,
  canChangeRateContainer: PropTypes.bool.isRequired,
  canChangeIntakeContainer: PropTypes.bool.isRequired,
  canChangeIntakeRestriction: PropTypes.bool.isRequired,
  canRequestShippingRate: PropTypes.bool.isRequired,
  canAddNewRate: PropTypes.bool.isRequired,
  canAddNewRestriction: PropTypes.bool.isRequired,
  canAddNewIntake: PropTypes.bool.isRequired
};

// defaultProps for the ShippingRates component
ShippingRates.defaultProps = {
  requestShippingRateFormValues: undefined,
  rateContainerFormValues: undefined,
  intakeRestrictionFormValues: undefined,
  intakeContainerFormValues: undefined,
  currentIdRateContainer: undefined,
  currentIdIntakeRestriction: undefined,
  currentIdIntakeContainer: undefined,
  initialFormValuesRateContainer: undefined,
  initialFormValuesIntakeContainer: undefined,
  initialFormValuesIntakeRestriction: undefined
};

// Starting from the redux state it gets data related to Shipping Rates list
export const mapStateToProps = state => {
  const { userDetail, form } = state;
  const {
    shippingRatesFilters,
    rateContainersFilters,
    intakeContainersFilters,
    intakeRestrictionsFilters,
    intakeRestrictionForm,
    requestShippingRateForm,
    intakeContainerForm,
    rateContainerForm
  } = form;

  // A function to check if user has given permission
  const checkUserPermission = permission => {
    return (userDetail && userDetail.data && hasPermission(userDetail.data, permission)) || false;
  };

  // A funcion to create an array of applied filters
  const createFiltersArray = (filters, property) => {
    const hasProperty = filters && filters.values && filters.values[property];
    let filterArray = [];
    if (hasProperty) filterArray = filters.values[property].map(element => element.value);
    return filterArray;
  };

  // Function that returns error message for modal
  const getModalErrorMessage = error => {
    let modalErrorMessage = "";
    if (!isEmpty(error) && getStatusErrorMessage(error)) {
      modalErrorMessage = getStatusErrorMessage(error);
    } else if (
      !isEmpty(error) &&
      !isEmpty(error.data) &&
      error.data.non_field_errors &&
      error.data.non_field_errors.length
    ) {
      modalErrorMessage = error.data.non_field_errors[0];
    }
    return modalErrorMessage;
  };

  return {
    // Related entities properties
    specificCommodities: adaptOptions(state.specificCommodityList.data.results, "code", "description"),
    containerTypes: adaptOptions(CONTAINER_TYPES, "value", "description"),
    isFetchingRelatedEntities: state.specificCommodityList.isFetching,
    errorMessageFilters: state.specificCommodityList.errorMessage,
    // Selected filters properties
    selectedLoadPorts: createFiltersArray(shippingRatesFilters, "loadPorts"),
    selectedDischargePorts: createFiltersArray(shippingRatesFilters, "dischargePorts"),
    selectedCommodities: createFiltersArray(shippingRatesFilters, "specificCommodities"),
    selectedContainersTypes: createFiltersArray(shippingRatesFilters, "containerTypes"),
    selectedLoadPortsRateContainer: createFiltersArray(rateContainersFilters, "loadPorts"),
    selectedDischargePortsRateContainer: createFiltersArray(rateContainersFilters, "dischargePorts"),
    selectedContainersTypesRateContainer: createFiltersArray(rateContainersFilters, "containerTypes"),
    selectedCommoditiesIntakeRestriction: createFiltersArray(intakeRestrictionsFilters, "specificCommodities"),
    selectedDischargePortsIntakeRestriction: createFiltersArray(intakeRestrictionsFilters, "dischargePorts"),
    selectedContainersTypesIntakeRestriction: createFiltersArray(intakeRestrictionsFilters, "containerTypes"),
    selectedCommoditiesIntakeContainer: createFiltersArray(intakeContainersFilters, "specificCommodities"),
    selectedLoadPortsIntakeContainer: createFiltersArray(intakeContainersFilters, "loadPorts"),
    selectedContainersTypesIntakeContainer: createFiltersArray(intakeContainersFilters, "containerTypes"),
    // Shipping Rate list properties and filters
    shippingRates: adaptShippingRates(state.shippingRateList.data.results),
    isFetchingShippingRates: state.shippingRateList.isFetching,
    totalItemsShippingRates: state.shippingRateList.data.count || 0,
    errorMessageShippingRates: state.shippingRateList.errorMessage,
    pageShippingRates: state.shippingRateCurrentPage.number,
    // Rate/Container list properties and filters
    rateContainers: adaptRateContainers(state.rateContainerList.data.results),
    isFetchingRateContainers: state.rateContainerList.isFetching,
    totalItemsRateContainers: state.rateContainerList.data.count || 0,
    errorMessageRateContainers: state.rateContainerList.errorMessage,
    pageRateContainers: state.rateContainerCurrentPage.number,
    // Intake/Restriction list properties and filters
    intakeRestrictions: adaptIntakeRestrictions(state.intakeRestrictionList.data.results),
    isFetchingIntakeRestrictions: state.intakeRestrictionList.isFetching,
    totalItemsIntakeRestrictions: state.intakeRestrictionList.data.count || 0,
    errorMessageIntakeRestrictions: state.intakeRestrictionList.errorMessage,
    pageIntakeRestrictions: state.intakeRestrictionCurrentPage.number,
    // Intake/Container list properties and filters
    intakeContainers: adaptIntakeContainers(state.intakeContainerList.data.results),
    isFetchingIntakeContainers: state.intakeContainerList.isFetching,
    totalItemsIntakeContainers: state.intakeContainerList.data.count || 0,
    errorMessageIntakeContainers: state.intakeContainerList.errorMessage,
    pageIntakeContainers: state.intakeContainerCurrentPage.number,
    // Current active tab
    currentTab: state.shippingRateCurrentTab.number,
    // Request Shipping Rate modal
    isRequestShippingRateModalOpen: state.requestShippingRateModal.isVisible,
    requestShippingRateFormValues: requestShippingRateForm && requestShippingRateForm.values,
    errorObjectRequestShippingRateModal: state.requestShippingRateAdd.error,
    errorMessageRequestShippingRateModal:
      (!isEmpty(state.requestShippingRateAdd.error) && getStatusErrorMessage(state.requestShippingRateAdd.error)) || "",
    isSuccessModalOpen: state.successRequestShippingRateModal.isVisible,
    isLoadingRequestShippingRateModal: state.requestShippingRateAdd.isLoading,
    // Rate/Container modal
    // if a record with same data already exists, a message will be shown
    isRateContainerModalOpen: state.rateContainerModal.isVisible,
    rateContainerFormValues: rateContainerForm && rateContainerForm.values,
    errorObjectRateContainerModal: { ...state.rateContainerAdd.error, ...state.rateContainerUpdate.error },
    errorMessageRateContainerModal:
      getModalErrorMessage(state.rateContainerAdd.error) || getModalErrorMessage(state.rateContainerUpdate.error),
    isLoadingRateContainerModal: state.rateContainerAdd.isLoading || state.rateContainerUpdate.isLoading,
    //Intake/Restriction modal
    // if a record with same data already exists, a message will be shown
    isIntakeRestrictionModalOpen: state.intakeRestrictionModal.isVisible,
    intakeRestrictionFormValues: intakeRestrictionForm && intakeRestrictionForm.values,
    errorObjectIntakeRestrictionModal: state.intakeRestrictionAdd.error,
    errorMessageIntakeRestrictionModal: getModalErrorMessage(state.intakeRestrictionAdd.error),
    isLoadingIntakeRestrictionModal: state.intakeContainerAdd.isLoading,
    //Intake/Container modal
    // if a record with same data already exists, a message will be shown
    isIntakeContainerModalOpen: state.intakeContainerModal.isVisible,
    intakeContainerFormValues: intakeContainerForm && intakeContainerForm.values,
    errorObjectIntakeContainerModal: state.intakeContainerAdd.error,
    errorMessageIntakeContainerModal: getModalErrorMessage(state.intakeContainerAdd.error),
    isLoadingIntakeContainerModal: state.intakeContainerAdd.isLoading,
    // Delete a Rate Container
    isDeleteRateContainerModalOpen: state.deleteRateContainerModal.isVisible,
    isLoadingDeleteRateContainerModal: state.rateContainerDelete.isLoading,
    errorMessageDeleteRateContainer: state.rateContainerDelete.errorMessage,
    idActiveRecordRateContainer: state.activeRecordRateContainer.id,
    canDeleteRateContainer: checkUserPermission("prices.delete_containerrate"),
    // Delete a Intake Container
    isDeleteIntakeContainerModalOpen: state.deleteIntakeContainerModal.isVisible,
    isLoadingDeleteIntakeContainerModal: state.intakeContainerDelete.isLoading,
    errorMessageDeleteIntakeContainer: state.intakeContainerDelete.errorMessage,
    idActiveRecordIntakeContainer: state.activeRecordIntakeContainer.id,
    canDeleteIntakeContainer: checkUserPermission("prices.delete_intakecontainer"),
    // Delete a Intake Restriction
    isDeleteIntakeRestrictionModalOpen: state.deleteIntakeRestrictionModal.isVisible,
    isLoadingDeleteIntakeRestrictionModal: state.intakeRestrictionDelete.isLoading,
    errorMessageDeleteIntakeRestriction: state.intakeRestrictionDelete.errorMessage,
    idActiveRecordIntakeRestriction: state.activeRecordIntakeRestriction.id,
    canDeleteIntakeRestriction: checkUserPermission("prices.delete_intakerestriction"),
    // Edit a Rate Container
    initialFormValuesRateContainer: adaptRateContainerInitialFormValues(state.rateContainerDetail.data),
    isOnEditRateContainer: !isEmpty(state.rateContainerDetail.data),
    currentIdRateContainer: state.rateContainerDetail.data.id,
    canChangeRateContainer: checkUserPermission("prices.change_containerrate"),
    // Edit a Intake Container
    initialFormValuesIntakeContainer: adaptIntakeContainerInitialFormValues(state.intakeContainerDetail.data),
    isOnEditIntakeContainer: !isEmpty(state.intakeContainerDetail.data),
    currentIdIntakeContainer: state.intakeContainerDetail.data.id,
    canChangeIntakeContainer: checkUserPermission("prices.change_intakecontainer"),
    // Edit a Intake Restriction
    initialFormValuesIntakeRestriction: adaptIntakeRestrictionInitialFormValues(state.intakeRestrictionDetail.data),
    isOnEditIntakeRestriction: !isEmpty(state.intakeRestrictionDetail.data),
    currentIdIntakeRestriction: state.intakeRestrictionDetail.data.id,
    canChangeIntakeRestriction: checkUserPermission("prices.change_intakerestriction"),
    // Permission to see "Add" buttons within tabs
    canRequestShippingRate: checkUserPermission("prices.add_shippingraterequest"),
    canAddNewRate: checkUserPermission("prices.add_shippingrate"),
    canAddNewRestriction: checkUserPermission("prices.add_intakerestriction"),
    canAddNewIntake: checkUserPermission("prices.add_intakecontainer")
  };
};

// Maps functions to dispatch actions
export const mapDispatchToProps = dispatch => {
  return {
    shippingRateList: (page, load_port, discharge_port, commodity, container_type) =>
      dispatch(shippingRateList({ page, load_port, discharge_port, commodity, container_type })),
    shippingRateCurrentPage: number => dispatch(shippingRateCurrentPage(number)),
    shippingRateCurrentTab: number => dispatch(shippingRateCurrentTab(number)),
    specificCommodityList: () => dispatch(specificCommodityList()),
    loadPortList: name => dispatch(loadPortList({ name })),
    dischargePortList: name => dispatch(dischargePortList({ name })),
    requestShippingRateAdd: data => dispatch(requestShippingRateAdd(data)),
    requestShippingRateAddReset: () => dispatch(requestShippingRateAddReset()),
    showRequestShippingRateModal: () => dispatch(showRequestShippingRateModal()),
    hideRequestShippingRateModal: () => dispatch(hideRequestShippingRateModal()),
    resetRequestShippingRateForm: () => dispatch(reset("requestShippingRateForm")),
    showSuccessModal: () => dispatch(showSuccessRequestShippingRateModal()),
    hideSuccessModal: () => dispatch(hideSuccessRequestShippingRateModal()),
    rateContainerList: (page, load_port, discharge_port, container_type) =>
      dispatch(rateContainerList({ page, load_port, discharge_port, container_type })),
    rateContainerCurrentPage: number => dispatch(rateContainerCurrentPage(number)),
    showRateContainerModal: () => dispatch(showRateContainerModal()),
    hideRateContainerModal: () => dispatch(hideRateContainerModal()),
    rateContainerAdd: data => dispatch(rateContainerAdd(data)),
    rateContainerAddReset: () => dispatch(rateContainerAddReset()),
    resetRateContainerForm: () => dispatch(reset("rateContainerForm")),
    intakeRestrictionList: (page, discharge_port, container_type) =>
      dispatch(intakeRestrictionList({ page, discharge_port, container_type })),
    intakeRestrictionCurrentPage: number => dispatch(intakeRestrictionCurrentPage(number)),
    showIntakeRestrictionModal: () => dispatch(showIntakeRestrictionModal()),
    hideIntakeRestrictionModal: () => dispatch(hideIntakeRestrictionModal()),
    intakeRestrictionAdd: data => dispatch(intakeRestrictionAdd(data)),
    intakeRestrictionAddReset: () => dispatch(intakeRestrictionAddReset()),
    resetIntakeRestrictionForm: () => dispatch(reset("intakeRestrictionForm")),
    intakeContainerList: (page, load_port, commodity, container_type) =>
      dispatch(intakeContainerList({ page, load_port, commodity, container_type })),
    intakeContainerCurrentPage: number => dispatch(intakeContainerCurrentPage(number)),
    showIntakeContainerModal: () => dispatch(showIntakeContainerModal()),
    hideIntakeContainerModal: () => dispatch(hideIntakeContainerModal()),
    intakeContainerAdd: data => dispatch(intakeContainerAdd(data)),
    intakeContainerAddReset: () => dispatch(intakeContainerAddReset()),
    resetIntakeContainerForm: () => dispatch(reset("intakeContainerForm")),
    rateContainerDelete: id => dispatch(rateContainerDelete(id)),
    showDeleteRateContainerModal: () => dispatch(showDeleteRateContainerModal()),
    hideDeleteRateContainerModal: () => dispatch(hideDeleteRateContainerModal()),
    intakeRestrictionDelete: id => dispatch(intakeRestrictionDelete(id)),
    showDeleteIntakeRestrictionModal: () => dispatch(showDeleteIntakeRestrictionModal()),
    hideDeleteIntakeRestrictionModal: () => dispatch(hideDeleteIntakeRestrictionModal()),
    intakeContainerDelete: id => dispatch(intakeContainerDelete(id)),
    showDeleteIntakeContainerModal: () => dispatch(showDeleteIntakeContainerModal()),
    hideDeleteIntakeContainerModal: () => dispatch(hideDeleteIntakeContainerModal()),
    activeRecordRateContainer: id => dispatch(activeRecordRateContainer(id)),
    activeRecordIntakeRestriction: id => dispatch(activeRecordIntakeRestriction(id)),
    activeRecordIntakeContainer: id => dispatch(activeRecordIntakeContainer(id)),
    userDetail: () => dispatch(userDetail()),
    rateContainerDetail: id => dispatch(rateContainerDetail(id)),
    rateContainerDetailReset: () => dispatch(rateContainerDetailReset()),
    rateContainerUpdate: (id, data) => dispatch(rateContainerUpdate(id, data)),
    rateContainerUpdateReset: () => dispatch(rateContainerUpdateReset()),
    intakeRestrictionDetail: id => dispatch(intakeRestrictionDetail(id)),
    intakeRestrictionDetailReset: () => dispatch(intakeRestrictionDetailReset()),
    intakeRestrictionUpdate: (id, data) => dispatch(intakeRestrictionUpdate(id, data)),
    intakeRestrictionUpdateReset: () => dispatch(intakeRestrictionUpdateReset()),
    intakeContainerDetail: id => dispatch(intakeContainerDetail(id)),
    intakeContainerDetailReset: () => dispatch(intakeContainerDetailReset()),
    intakeContainerUpdate: (id, data) => dispatch(intakeContainerUpdate(id, data)),
    intakeContainerUpdateReset: () => dispatch(intakeContainerUpdateReset())
  };
};

// The form is added to the redux store with a given name
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShippingRates);
