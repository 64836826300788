// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_ackMessage_99E1N13eGPBZZSGirv4HXA\\=\\= {\n  margin-top: 1.5rem;\n}", "",{"version":3,"sources":["webpack://src/containers/IPFDetail/components/CriteriaStandard/style.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF","sourcesContent":[".ackMessage {\n  margin-top: 1.5rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ackMessage": "style_ackMessage_99E1N13eGPBZZSGirv4HXA=="
};
export default ___CSS_LOADER_EXPORT___;
