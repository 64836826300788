import React from "react";
import PropTypes from "prop-types";
import { reduxForm, Field } from "redux-form";
import { iconCloseGlyph } from "@wfp/icons";
import { iconFilterAlt, iconDownloadGlyph } from "@wfp/icons";

import { displayErrorMessage } from "../../../../utils";
import { columns, AdditionalInformation } from "../../../../utils/ipfs";
import Table from "../../../../components/Table";
import Button from "../../../../components/Button";
import Link from "../../../../components/Link";
import Select from "../../../../components/Select";
import Module from "../../../../components/Module";
import { Grid, Row, Col } from "../../../../components/Grid";
import DateRangePicker from "../../../../components/DateRangePicker";
import Input from "../../../../components/Input";
import FiltersWrapper from "../../../../components/FiltersWrapper";
import AlignRight from "../../../../components/AlignRight";
import AlignCenterHorizontal from "../../../../components/AlignCenterHorizontal";
import ButtonsWrapper from "../../../../components/ButtonsWrapper";
import Checkbox from "../../../../components/Checkbox";

import style from "./style.scss";

// A function to display show/hide filters button
const generateShowHideFiltersButton = (isVisible, onClick) => (
  <Button onClick={onClick} icon={isVisible ? iconCloseGlyph : iconFilterAlt}>
    {isVisible ? "Hide filters" : "Show filters"}
  </Button>
);

// The AllIpfList component is used to display a list of IPFs
const AllIpfList = ({
  ipfs,
  errorMessageIpfs,
  errorMessageFilters,
  totalItems,
  page,
  onPageChange,
  loadingIpfs,
  loadingFilters,
  onClickDetail,
  specificCommodities,
  countriesZones,
  statuses,
  smallholderFarmer,
  aboveIpp,
  onClickSearch,
  onClickReset,
  loadingPresets,
  onClickResetPreset,
  isVisibleFiltersArea,
  onClickShowHideFiltersArea,
  onClickExport
}) => (
  <>
    {displayErrorMessage(errorMessageIpfs)}
    {displayErrorMessage(errorMessageFilters)}
    <Module
      title="All IPFs"
      filter={
        <ButtonsWrapper>
          <Button icon={iconDownloadGlyph} disabled={!ipfs.length} onClick={onClickExport}>
            Export
          </Button>
          {generateShowHideFiltersButton(isVisibleFiltersArea, onClickShowHideFiltersArea)}
        </ButtonsWrapper>
      }
      table={
        <Table
          loading={loadingIpfs}
          columns={columns(onClickDetail, false)}
          data={ipfs}
          totalItems={totalItems}
          page={page}
          onPageChange={onPageChange}
          subComponent={{ component: AdditionalInformation, dataKey: "additional_information" }}
        />
      }
    >
      {isVisibleFiltersArea ? (
        <>
          <FiltersWrapper>
            <Grid>
              <Row>
                <Col md={4}>
                  <Field
                    isMulti
                    component={Select}
                    name="countriesZones"
                    id="countriesZones"
                    label="Project country/GCMF supply line"
                    options={countriesZones}
                    loading={loadingFilters}
                  />
                </Col>
                <Col md={4}>
                  <Field
                    isMulti
                    component={Select}
                    name="commodities"
                    id="commodities"
                    label="Commodity"
                    options={specificCommodities}
                    loading={loadingFilters}
                  />
                </Col>
                <Col md={4}>
                  <Field component={Select} name="status" id="status" label="Status" options={statuses} />
                </Col>
              </Row>
            </Grid>
          </FiltersWrapper>
          <FiltersWrapper>
            <Grid>
              <Row>
                <Col md={2}>
                  <Field component={Select} name="aboveIpp" id="aboveIpp" label="Above IPP" options={aboveIpp} />
                </Col>
                <Col md={2}>
                  <Field
                    component={Select}
                    name="smallholderFarmer"
                    id="smallholderFarmer"
                    label="Smallholder Farmer"
                    options={smallholderFarmer}
                  />
                </Col>
                <Col md={4}>
                  <Field component={DateRangePicker} name="dates" id="dates" label="Date" />
                </Col>
                <Col md={4}>
                  <Field component={Input} name="ipfId" id="ipfId" label="IPF ID" />
                </Col>
              </Row>
            </Grid>
          </FiltersWrapper>
          <FiltersWrapper>
            <Grid>
              <Row>
                <Col md={3}>
                  <AlignCenterHorizontal>
                    {loadingPresets ? (
                      <span>Loading...</span>
                    ) : (
                      <Field
                        component={Checkbox}
                        name="savePreset"
                        id="savePreset"
                        text="Save this as your default preset"
                        single
                      />
                    )}
                  </AlignCenterHorizontal>
                </Col>
                <Col md={3}>
                  <AlignCenterHorizontal>
                    {loadingPresets ? " " : <Link onClick={onClickResetPreset}>Remove default presets</Link>}
                  </AlignCenterHorizontal>
                </Col>
                <Col md={6} className={style.searchButtonWrapper}>
                  <AlignRight>
                    <ButtonsWrapper>
                      <Link onClick={onClickReset}>Clear filters</Link>
                      <Button onClick={onClickSearch}>Find</Button>
                    </ButtonsWrapper>
                  </AlignRight>
                </Col>
              </Row>
            </Grid>
          </FiltersWrapper>
        </>
      ) : (
        undefined
      )}
    </Module>
  </>
);

// propTypes for the AllIpfList component
AllIpfList.propTypes = {
  ipfs: PropTypes.array.isRequired,
  errorMessageIpfs: PropTypes.string.isRequired,
  errorMessageFilters: PropTypes.string.isRequired,
  totalItems: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  loadingIpfs: PropTypes.bool.isRequired,
  loadingFilters: PropTypes.bool.isRequired,
  onClickDetail: PropTypes.func.isRequired,
  specificCommodities: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.any.isRequired
    })
  ),
  countriesZones: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.any.isRequired
    })
  ),
  statuses: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.any.isRequired
    })
  ),
  smallholderFarmer: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.any.isRequired
    })
  ),
  aboveIpp: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.any.isRequired
    })
  ),
  onClickSearch: PropTypes.func.isRequired,
  onClickReset: PropTypes.func.isRequired,
  loadingPresets: PropTypes.bool.isRequired,
  onClickResetPreset: PropTypes.func.isRequired,
  isVisibleFiltersArea: PropTypes.bool.isRequired,
  onClickShowHideFiltersArea: PropTypes.func.isRequired,
  onClickExport: PropTypes.func.isRequired
};

export default reduxForm({ form: "homeFilters", enableReinitialize: true })(AllIpfList);
