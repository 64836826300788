import React from "react";
import { tagCell } from "./table";
import Button from "../components/Button";
import style from "./style.scss";

// A component to render a button inside the list to view the IPF Detail
export const generateViewButton = (id, onClick) => (
  <Button small onClick={() => onClick(id)}>
    View
  </Button>
);

// A function to get the name of the logged/viewed in user
export const getUserDescription = user =>
  user.first_name && user.first_name ? `${user.first_name} ${user.last_name}` : user.username;

// The list of columns and their properties
export const columns = onClickButton => [
  {
    Header: "First Name",
    accessor: "first_name",
    width: 130
  },
  {
    Header: "Last Name",
    accessor: "last_name",
    width: 130
  },
  {
    Header: "User Name/Email Address",
    accessor: "email",
    width: 240
  },
  {
    Header: "Country",
    accessor: "country.name",
    width: 100
  },
  {
    Header: "Division",
    Cell: props =>
      props.row?.original && props.row?.original.org_unit_description ? props.row?.original.org_unit_description.split(",").map((item) => tagCell(item)) :
        props.row?.original && props.row?.original.divisions && props.row?.original.divisions.length > 0
          ? props.row?.original.divisions.map(division => tagCell(division.name, division.id))
          : "–",
    className: style.multilineColumn
  },
  {
    Header: "Roles",
    Cell: props => props.row?.original && props.row?.original.roles ? 
    props.row?.original.roles.filter((role) => role.toLowerCase() !== "public").map(role => tagCell(role, role.replace(/ /g, ""))): "-",
    className: style.multilineColumn
  },
  {
    Header: "Button",
    width: 70,
    Cell: props => generateViewButton(props.row?.original.id, onClickButton)
  }
];
