import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";

import client from "./client";
import { redirect } from "./";
import Header from "../containers/Header";
import Footer from "../containers/Footer";
import PageInformation from "../components/PageInformation";


// List of URLs used for login and user check
const API_URL_USER = "/api/accounts/user/";
const API_URL_LOGIN = `${process.env.API_BASE_URL}/oidc/`;

// A class to render a route protected by login
class PrivateRoute extends Component {
  // By default the content is loading and the user is considered as not logged in
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isLoggedIn: false,
      error: false
    };
  }

  // Here we try to get the username by calling the endpoint of user
  // if user is not logged in, we redirect the user to login page
  // if the redirect doesn't work (may be because the server is offline), we render an error
  componentDidMount() {
    client.get(API_URL_USER).then(data => {
      if (data.data.username === "") {
        this.setState({ isLoading: false });
        redirect(API_URL_LOGIN);
      } else {
        this.setState({ isLoading: false, isLoggedIn: true, });
      }
    }).catch(() => {
      this.setState({ isLoading: false, error: true });
    }
    );
  }

  // The component is rendered based on state
  // if there is a network error or the page is loading, a message is displayed
  // if the user is logged in, the component is displayed
  // if there are no errors but the user is not logged in, a redirecting message is displayed
  render() {
    if (this.state.error) {
      return <Redirect to="/errorMessage" />;
    }

    if (this.state.isLoading) {
      return <PageInformation title="Wait while the user is checked" text="Checking the user..."/>;
    }

    if (this.state.isLoggedIn) {
      return (
        <>
          <Header />
          <Route {...this.props} />
          <Footer />
        </>
      );
    }

    return <PageInformation title="You will be redirected to login" text="Redirecting to login..." />;
  }
}

// PropTypes for the PrivateRoute component
PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired
};

export default PrivateRoute;