import React from "react";
import PropTypes from "prop-types";
import { iconWfpHumEmergencyResponsePos } from "@wfp/icons";

import Icon from "../Icon";

import style from "./style.scss";


// A message informing user is be redirected to another page
const PageInformation = ({ title, text }) => (
  <div className={style.wrapper}>
    <div>
      <Icon
        icon={iconWfpHumEmergencyResponsePos}
        width="200"
        height="200"
        style={{ marginBottom: "3rem", transform: "rotate(10deg)" }}
      />
      <h2 className={style.title}>{title}</h2>
      <p className={style.text}>{text}</p>
    </div>
  </div>
);

// propTypes for the PageInformation component
PageInformation.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
};

// defaultProps for the PageInformation component
PageInformation.defaultProps = {
  title: "Application is loading",
  text: "Please wait..."
};

export default PageInformation;
