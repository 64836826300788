// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_buttonWithIcon_ba5\\+Je\\+KZ-JRPZVC0rWuhA\\=\\= {\n  display: flex;\n  justify-content: center;\n}\n\n.style_buttonWithIcon_ba5\\+Je\\+KZ-JRPZVC0rWuhA\\=\\= span {\n  margin-right: 0.5rem;\n}", "",{"version":3,"sources":["webpack://src/components/Button/style.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;AACF;;AAEA;EACE,oBAAA;AACF","sourcesContent":[".buttonWithIcon {\n  display: flex;\n  justify-content: center;\n}\n\n.buttonWithIcon span {\n  margin-right: 0.5rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonWithIcon": "style_buttonWithIcon_ba5+Je+KZ-JRPZVC0rWuhA=="
};
export default ___CSS_LOADER_EXPORT___;
