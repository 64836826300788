import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { iconWarningSolid } from "@wfp/icons";

import Icon from "../../components/Icon";

import style from "./style.scss";

// A page displayed when user navigate to unknown route
export class ErrorMessage extends Component {
  render() {
    return (
      <div className={style.wrapper}>
        <div>
          <div className={style.icon}>
            <Icon icon={iconWarningSolid} width="200" height="200" />
          </div>
          <h1 className="wfp--story__title">Sorry, an error has occurred</h1>
          <p>
            Try to go back to <Link to="/">Homepage</Link>
          </p>
        </div>
      </div>
    );
  }
}

// The router is used to allow user to navigate to home page
export default withRouter(ErrorMessage);
