import React from "react";
import PropTypes from "prop-types";

import Module, { SectionTitle } from "../../../../components/Module";
import ButtonsWrapper from "../../../../components/ButtonsWrapper";
import Button from "../../../../components/Button";
import ShowHideArea from "../../../../components/ShowHideArea";
import { displayErrorMessage } from "../../../../utils";
import { PurchaseOptionsTable } from "../../../../utils/purchaseOptions";

import style from "./style.scss";
import FormWrapper from "../../../../components/FormWrapper";
import {Field, reduxForm} from "redux-form";
import InputMultiline from "../../../../components/InputMultiline";
import {PurchaseOptionsFromIpfTable} from "../../../../utils/purchaseOptionsFromIpf";


// A function used to render justifications and attachments
export const generateJustificationAndAttachments = justificationsAndAttachments => (
  <>
    <SectionTitle title="Justification" withSeparator />
    <div className={style.justificationsAndAttachments}>
      {justificationsAndAttachments.justification_0 ? <span>- Purchase restrictions</span> : undefined}
      {justificationsAndAttachments.justification_1 ? <span>- Tied contributors</span> : undefined}
      {justificationsAndAttachments.justification_2 ? <span>- Recipient country government restrictions</span> : undefined}
      {justificationsAndAttachments.justification_3 ? <span>- Small quantity less than truck/rail loads</span> : undefined}
      {justificationsAndAttachments.justification_4 ? <span>- Response at the onset of an emergency operation</span> : undefined}
      {justificationsAndAttachments.justification_5 ? <span>- Pipeline break</span> : undefined}
      {justificationsAndAttachments.justification_6 ? <span>- TOD/TDD expiring</span> : undefined}
      {justificationsAndAttachments.justification_7 ? <span>- Capacity building</span> : undefined}
      {justificationsAndAttachments.justification_8 ? <span>- Import/export restrictions</span> : undefined}
      {justificationsAndAttachments.justification_9 ? <span>- Other restrictions (requires additional comments)</span> : undefined}
    </div>
  </>
);

// The SummaryGcmf component allows user to select a specific commodity from a list
const SummaryGcmf = ({
  isVisible,
  onClickNext,
  onClickPrevious,
  selectedData,
  selectedPurchaseOptions,
  justificationsAndAttachments,
  errorMessage,
  ipf
}) => (
  <ShowHideArea isVisible={isVisible}>
    <Module
      title="Step 7/7 Summary"
      footer={
        <ButtonsWrapper>
          <Button kind="secondary" onClick={onClickPrevious}>Previous</Button>
          <Button onClick={onClickNext}>Submit</Button>
        </ButtonsWrapper>
      }
    >
      {displayErrorMessage(errorMessage)}
      <SectionTitle title="Criteria" />
      {selectedData}
      <SectionTitle title="Source" withSeparator />
      {
        selectedPurchaseOptions.length > 0 && Object.prototype.hasOwnProperty.call(selectedPurchaseOptions[0], "tmp_id")?
          <PurchaseOptionsTable purchaseOptions={selectedPurchaseOptions} /> :
          selectedPurchaseOptions.length > 0 && selectedPurchaseOptions[0].hasOwnProperty("id")?
            <PurchaseOptionsFromIpfTable ipf={{...ipf, purchase_options: selectedPurchaseOptions}} /> :
            ipf && ipf.purchase_options && ipf.next_step > 4 ?
              <PurchaseOptionsFromIpfTable ipf={ipf} preferredOnly={true} /> : null
      }      <SectionTitle title="Comments" withSeparator />
      <FormWrapper>
        <Field
          component={InputMultiline}
          name="comments"
          id="comments"
          maxLength={200}
        />
      </FormWrapper>
      {generateJustificationAndAttachments(justificationsAndAttachments)}
    </Module>
  </ShowHideArea>
);

// propTypes for the SummaryGcmf component
SummaryGcmf.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClickNext: PropTypes.func.isRequired,
  selectedData: PropTypes.node.isRequired,
  onClickPrevious: PropTypes.func.isRequired,
  selectedPurchaseOptions: PropTypes.array.isRequired,
  justificationsAndAttachments: PropTypes.any,
  errorMessage: PropTypes.string.isRequired,
  ipf: PropTypes.object
};

// defaultProps for the SummaryGcmf component
SummaryGcmf.defaultProps = {
  justificationsAndAttachments: undefined
};

export default reduxForm({ form: "ipfDetailSummaryGcmf", enableReinitialize: true })(SummaryGcmf);