import { createBrowserHistory } from "history";
import { applyMiddleware, compose, createStore } from "redux";
import { routerMiddleware } from "connected-react-router";
import thunkMiddleware from "redux-thunk";

import createRootReducer from "../reducers";


// Here we add support for the redux dev tools extension
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Creating an history to allow user browse through the pages
const history = createBrowserHistory();

// Here we create the store, createRootReducer creates the root reducer
// used by redux to manage the state of the application.
const configureStore = preloadedState => (
  createStore(
    createRootReducer(history),
    preloadedState,
    composeEnhancers(applyMiddleware(routerMiddleware(history), thunkMiddleware))
  )
);

export default configureStore;
