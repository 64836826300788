import client from "../client";

// Actions available for generic add resource reducer
export const RESOURCE_ADD_REQUEST_ = "RESOURCE_ADD_REQUEST_";
export const RESOURCE_ADD_SUCCESS_ = "RESOURCE_ADD_SUCCESS_";
export const RESOURCE_ADD_ERROR_ = "RESOURCE_ADD_ERROR_";
export const RESOURCE_ADD_RESET_ = "RESOURCE_ADD_RESET_";

// Action creator for start adding a resource
export const resourceAddRequest = resource => () => {
  return {
    type: `${RESOURCE_ADD_REQUEST_}${resource}`
  };
};

// Action creator for a successful add resource request
export const resourceAddSuccess = resource => data => {
  return {
    type: `${RESOURCE_ADD_SUCCESS_}${resource}`,
    data
  };
};

// Action creator for a successful list resource request
export const resourceAddError = resource => error => {
  return {
    type: `${RESOURCE_ADD_ERROR_}${resource}`,
    error
  };
};

// Action creator for resetting the data
export const resourceAddReset = resource => () => {
  return {
    type: `${RESOURCE_ADD_RESET_}${resource}`,
  };
};

// Here we dispatch the request action and then, after it is resolved, the success action
// or, if fails, the error action
export const resourceAdd = (resource, url) => (data, appendUrl = "") => {
  return dispatch => {
    dispatch(resourceAddRequest(resource)());
    return client.post(`${url}${appendUrl}`, data)
      .then(result => {
        dispatch(resourceAddSuccess(resource)(result.data));
        return true;
      })
      .catch(error => {
        dispatch(resourceAddError(resource)(error.response));
        return false;
      });
  };
};
