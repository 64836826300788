import client from "../client";


//  Actions available for generic delete resource reducer
export const RESOURCE_DELETE_REQUEST_ = "RESOURCE_DELETE_REQUEST_";
export const RESOURCE_DELETE_SUCCESS_ = "RESOURCE_DELETE_SUCCESS_";
export const RESOURCE_DELETE_ERROR_ = "RESOURCE_DELETE_ERROR_";

// Action creator for start fetching a resource
export const resourceDeleteRequest = resource => () => {
  return {
    type: `${RESOURCE_DELETE_REQUEST_}${resource}`
  };
};

// Action creator for a successful delete resource request 
export const resourceDeleteSuccess = resource => () => {
  return {
    type: `${RESOURCE_DELETE_SUCCESS_}${resource}`
  };
};

// Action creator for a successful delete resource request 
export const resourceDeleteError = resource => errorMessage => {
  return {
    type: `${RESOURCE_DELETE_ERROR_}${resource}`,
    errorMessage
  };
};

// Here we dispatch the request action and then, after it is resolved, the success action
// or, if fails, the error action
export const resourceDelete = (resource, url) => id => {
  return dispatch => {
    dispatch(resourceDeleteRequest(resource)());
    return client.delete(`${url}${id}/`)
      .then(() => {
        dispatch(resourceDeleteSuccess(resource)());
        return true;
      })
      .catch(error => {
        dispatch(resourceDeleteError(resource)(error.message));
        return false;
      });
  };
};
