import React from "react";
import PropTypes from "prop-types";
import { reduxForm, Field } from "redux-form";

import Select from "../../../../components/Select";
import DateRangePicker from "../../../../components/DateRangePicker";
import Input from "../../../../components/Input";
import SelectAsync from "../../../../components/SelectAsync";
import { Grid, Row, Col } from "../../../../components/Grid";
import FormWrapper from "../../../../components/FormWrapper";
import Modal from "../../../../components/Modal";
import Loading from "../../../../components/Loading";
import { getErrors, displayErrorMessage } from "../../../../utils";
import { normalizeNumber } from "../../../../utils/reduxForm";

// The RequestShippingRateModal component is used to add a Request Shipping Rate
const RequestShippingRateModal = ({
  errorObject,
  loadingRelatedEntities,
  specificCommodities,
  loadPorts,
  dischargePorts,
  isOpen,
  onValidate,
  onCancel,
  isOutsideRangeShipmentPeriodDate,
  isLoading,
  errorMessage
}) => (
  <Modal
    isOpen={isOpen}
    title="Request Shipping Rate"
    onValidate={onValidate}
    onCancel={onCancel}
    validateText="Send email"
  >
    {displayErrorMessage(errorMessage)}
    <FormWrapper>
      <Grid>
        <Row>
          <Col md={6}>
            <Field
              component={SelectAsync}
              name="load_port"
              id="load_port"
              label="Load port"
              loadOptions={loadPorts}
              smallMenu
              errorMessage={getErrors(errorObject, "load_port")}
            />
          </Col>
          <Col md={6}>
            <Field
              component={SelectAsync}
              name="discharge_port"
              id="discharge_port"
              label="Discharge port"
              loadOptions={dischargePorts}
              smallMenu
              errorMessage={getErrors(errorObject, "discharge_port")}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Field
              component={Select}
              name="commodity"
              id="commodity"
              label="Commodity"
              options={specificCommodities}
              loading={loadingRelatedEntities}
              errorMessage={getErrors(errorObject, "commodity")}
            />
          </Col>
          <Col md={3}>
            <Field
              component={Input}
              name="quantity"
              id="quantity"
              label="Quantity"
              normalize={(value) => normalizeNumber(value)}
              errorMessage={getErrors(errorObject, "quantity")}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Field
              component={DateRangePicker}
              name="shipment_period"
              id="shipment_period"
              label="Shipment period"
              withPortal
              isOutsideRange={isOutsideRangeShipmentPeriodDate}
              errorMessage={
                getErrors(errorObject, "shipment_period_from") || getErrors(errorObject, "shipment_period_to")
              }
            />
          </Col>
        </Row>
      </Grid>
    </FormWrapper>
    <Loading isVisible={isLoading} />
  </Modal>
);

// propTypes for the Modal component
RequestShippingRateModal.propTypes = {
  errorObject: PropTypes.object.isRequired,
  loadingRelatedEntities: PropTypes.bool.isRequired,
  specificCommodities: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.any.isRequired
    })
  ),
  isOpen: PropTypes.bool.isRequired,
  onValidate: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  loadPorts: PropTypes.func.isRequired,
  dischargePorts: PropTypes.func.isRequired,
  isOutsideRangeShipmentPeriodDate: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired
};

export default reduxForm({ form: "requestShippingRateForm", enableReinitialize: true })(RequestShippingRateModal);
