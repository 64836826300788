import React from "react";
import PropTypes from "prop-types";

import style from "./style.scss";

// An upload component
const Upload = ({ name, label }) => (
  <div className="wfp--form-item">
    <label htmlFor={name} className="wfp--label">{label}</label>
    <div className={style.wrapper}>
      <input
        autoComplete="off"
        multiple
        name={name}
        id={name}
        type={"file"}
      />
    </div>
  </div>
);

// propTypes for the Upload component
Upload.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

export default Upload;
