import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { userDetail } from "@actions";
import FooterComponent from "@components/FooterComponent";

// The footer used in all pages to allow user access to utils and links
export class Footer extends Component {
  // When component mount the user is fetched
  componentDidMount() {
    this.props.userDetail();
  }

  render() {
    return <FooterComponent loading={this.props.isFetching} user={this.props.currentUser} />;
  }
}

// propTypes for the FormGroup component
Footer.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  userDetail: PropTypes.func.isRequired,
  currentUser: PropTypes.any
};

// defaultProps for the FormGroup component
Footer.defaultProps = {
  currentUser: undefined
};

// Starting from the redux state it gets data related to logged in user
export const mapStateToProps = state => {
  return {
    currentUser: state.userDetail && state.userDetail.data,
    isFetching: !state.userDetail || state.userDetail.isFetching
  };
};

// Maps functions to dispatch actions
export const mapDispatchToProps = dispatch => {
  return {
    userDetail: () => dispatch(userDetail())
  };
};

// The component needs to use the router to redirect user to the search page
// after entering some text in the search box
export default connect(mapStateToProps, mapDispatchToProps)(Footer);
