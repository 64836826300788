import React from "react";
import PropTypes from "prop-types";


// A simple wrapper that helps to align fields of a form
const FormWrapper = ({ children }) => (
  <div className="wfp--form-long">
    {children}
  </div>
);

// propTypes for the FormWrapper component
FormWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FormWrapper;
