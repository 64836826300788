import Axios from "axios";

import { redirect } from "../utils";


// List of URLs used for login and user check
const API_URL_USER = "/api/accounts/user/";
const API_URL_LOGIN = `${process.env.API_BASE_URL}/oidc/`;

// Default headers to be used for request
const headers = {
  "Accept": "application/json",
  "Content-Type": "application/json",
};

// Creates the default axios client with some defaults
const client = Axios.create({
  baseURL: process.env.API_BASE_URL,
  withXSRFToken: true,
  responseType: "json",
  withCredentials: true,
  xsrfCookieName: `${process.env.CSRFTOKEN}`,
  xsrfHeaderName: "X-CSRFTOKEN",
  headers
});

// Function to redirect to login if user is not logged in
export const redirectIfNotLoggedIn = data => {
  if (data.data.username === "") {
    redirect(API_URL_LOGIN);
  }
};

// Function to redirect to login if user is not logged in
export const redirectToError = () => {
  redirect("/errorMessage");
};


// An interceptor used to redirect user to login page or error page
// if it is not logged in or the server is not reachable
// request headers have to be ignored to make this request
// for example, the request to get the current version of the application
// adds an header for the "Cache-Control" that have to be ignored
export const redirectInterceptor = config => {
  Axios.get(API_URL_USER, { ...config, headers}).then(redirectIfNotLoggedIn).catch(redirectToError);
  return config;
};

// Setup interceptors to check if user is logged in before each request
client.interceptors.request.use(redirectInterceptor);

export default client;