// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_wrapper_43\\+Dx7DoK1e3PIEd3Rx5hQ\\=\\= {\n  margin-bottom: 2rem;\n}", "",{"version":3,"sources":["webpack://src/components/MessageError/style.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF","sourcesContent":[".wrapper {\n  margin-bottom: 2rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "style_wrapper_43+Dx7DoK1e3PIEd3Rx5hQ=="
};
export default ___CSS_LOADER_EXPORT___;
