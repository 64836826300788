import React from "react";
import { reduxForm, Field } from "redux-form";
import PropTypes from "prop-types";

import Module, { SectionTitle } from "../../../../components/Module";
import Select from "../../../../components/Select";
import Checkbox from "../../../../components/Checkbox";
import SelectAsync from "../../../../components/SelectAsync";
import Input from "../../../../components/Input";
import { Grid, Row, Col } from "../../../../components/Grid";
import FormWrapper from "../../../../components/FormWrapper";
import ButtonsWrapper from "../../../../components/ButtonsWrapper";
import ShowHideArea from "../../../../components/ShowHideArea";
import Button from "../../../../components/Button";
import Spacer from "../../../../components/Spacer";
import { displayErrorMessage, getErrors, displayWarningMessage } from "../../../../utils";
import { normalizeNumber } from "../../../../utils/reduxForm";

import style from "./style.scss";

// A function used to display a message when no options are found
export const noOptionsMessageGrants = input => {
  const filter = input.inputValue.replace(/^[0]+/g, "");
  return filter.length > 3 ? "This grant number doesn't exist" : "Start typing...";
};

const SHFOptions = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];

// The CriteriaStandard component allows user to select a specific commodity from a list
const CriteriaStandard = ({
  countries,
  wbs,
  errorMessage,
  specificCommodities,
  onClickNext,
  onClickDraft,
  isVisible,
  projects,
  onSelectProjectCountry,
  onSelectProject,
  errorObject,
  grants,
  loadingCountries,
  loadingWbs,
  loadingSpecificCommodities,
  loadingProjects,
  onSelectFinalDestinationCountry,
  onSelectCommodityPrice,
  warningMessageCommodityPrice,
  isOnEdit
}) => (
  <ShowHideArea isVisible={isVisible}>
    <Module
      title="Step 1/7 Criteria"
      footer={
        <ButtonsWrapper>
          <Button kind="secondary" onClick={onClickDraft}>Save Draft</Button>
          <Button onClick={onClickNext}>Continue</Button>
        </ButtonsWrapper>
      }
    >
      {displayErrorMessage(errorMessage)}
      {displayWarningMessage(warningMessageCommodityPrice)}
      <FormWrapper>
        <div className={style.ackMessage}>
          <Field
            component={Checkbox}
            name="ackMessage"
            id="ackMessage"
            text="I am aware that the Global Commodity Management Facility (GCMF) is the preferred sourcing option"
            single
            errorMessage={getErrors(errorObject, "ackMessage")}
          />
        </div>
        <SectionTitle title="Commodity" />
        <Grid>
          <Row>
            <Col md={6}>
              <Field
                isDisabled={isOnEdit}
                component={Select}
                name="commodity"
                id="commodity"
                label="Commodity"
                options={specificCommodities}
                loading={loadingSpecificCommodities}
                onChange={onSelectCommodityPrice}
                errorMessage={getErrors(errorObject, "commodity")}
              />
            </Col>
            <Col md={4}>
              <Field
                component={Input}
                name="quantity"
                id="quantity"
                label="Quantity (Mt)"
                normalize={(value) => normalizeNumber(value,3)}
                errorMessage={getErrors(errorObject, "quantity")}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Field
                component={Select}
                name="small_holder_farmer_standard"
                id="small_holder_farmer_standard"
                label="Smallholder farmer"
                options={SHFOptions}
                errorMessage={getErrors(errorObject, "small_holder_farmer_standard")}
              />
              <Field
                component={Checkbox}
                name="gmo_commodity_standard"
                id="gmo_commodity_standard"
                text="GMO"
                single
                errorMessage={getErrors(errorObject, "gmo_commodity_standard")}
              />
            </Col>
          </Row>
        </Grid>
        <SectionTitle title="Project details" withSeparator />
        <Grid>
          <Row>
            <Col md={6}>
              <Field
                component={Select}
                name="project_country"
                id="project_country"
                label="Project country"
                options={countries}
                loading={loadingCountries}
                onChange={onSelectProjectCountry}
                isDisabled={isOnEdit || loadingProjects}
                errorMessage={getErrors(errorObject, "project_country")}
              />
            </Col>
            <Col md={6}>
              <Field
                component={Select}
                name="project"
                id="project"
                label="Project/CPB"
                options={projects}
                loading={loadingProjects}
                onChange={onSelectProject}
                isDisabled={loadingWbs}
                errorMessage={getErrors(errorObject, "project")}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Field
                component={Select}
                name="wbs"
                id="wbs"
                label="WBS element"
                options={wbs}
                loading={loadingWbs}
                errorMessage={getErrors(errorObject, "wbs")}
              />
            </Col>
            <Col md={6}>
              <Field
                isDisabled={isOnEdit}
                component={Select}
                name="recipient_country"
                id="recipient_country"
                label="Final destination country"
                options={countries}
                loading={loadingCountries}
                onChange={onSelectFinalDestinationCountry}
                errorMessage={getErrors(errorObject, "recipient_country")}
              />
            </Col>
          </Row>
        </Grid>
        <SectionTitle title="Grant" withSeparator />
        <Grid>
          <Row>
            <Col md={12}>
              <Field
                component={SelectAsync}
                name="grant"
                id="grant"
                label="Grant number"
                loadOptions={grants}
                displayKey="value"
                placeholder="Type at least four numbers after zeroes"
                noOptionsMessage={noOptionsMessageGrants}
                errorMessage={getErrors(errorObject, "grant")}
              />
            </Col>
          </Row>
        </Grid>
        <Spacer height="8rem" />
      </FormWrapper>
    </Module>
  </ShowHideArea>
);

// propTypes for the CriteriaStandard component
CriteriaStandard.propTypes = {
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.any.isRequired
    })
  ),
  wbs: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.any
    })
  ),
  specificCommodities: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.any.isRequired
    })
  ),
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.any.isRequired
    })
  ),
  errorMessage: PropTypes.string.isRequired,
  onClickNext: PropTypes.func.isRequired,
  onClickDraft: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onSelectProjectCountry: PropTypes.func.isRequired,
  onSelectProject: PropTypes.func.isRequired,
  errorObject: PropTypes.object.isRequired,
  grants: PropTypes.func.isRequired,
  loadingCountries: PropTypes.bool.isRequired,
  loadingWbs: PropTypes.bool.isRequired,
  loadingSpecificCommodities: PropTypes.bool.isRequired,
  loadingProjects: PropTypes.bool.isRequired,
  onSelectFinalDestinationCountry: PropTypes.func.isRequired,
  onSelectCommodityPrice: PropTypes.func.isRequired,
  warningMessageCommodityPrice: PropTypes.string.isRequired,
  isOnEdit: PropTypes.bool.isRequired
};

export default reduxForm({ form: "ipfDetailCriteriaStandard", enableReinitialize: true })(CriteriaStandard);
