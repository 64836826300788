import React from "react";
import { reduxForm, Field } from "redux-form";
import PropTypes from "prop-types";

import { Grid, Row, Col } from "../../../../components/Grid";
import Module, { SectionTitle } from "../../../../components/Module";
import ButtonsWrapper from "../../../../components/ButtonsWrapper";
import Tag from "../../../../components/Tag";
import Button from "../../../../components/Button";
import Input from "../../../../components/Input";
import ShowHideArea from "../../../../components/ShowHideArea";
import { displayErrorMessage, stringOrZero, getErrors } from "../../../../utils";
import { normalizeNumber } from "../../../../utils/reduxForm";

import style from "./style.scss";

// The ReviewCostsAndTimes component allows user to select insert const and times for each Purchase Oprion
const ReviewCostsAndTimes = ({
  isVisible,
  onClickNext,
  onClickPrevious,
  onClickDraft,
  selectedData,
  purchaseOptions,
  loadingPurchaseOptions,
  errorMessagePurchaseOptions,
  errorObject
}) => (
  <ShowHideArea isVisible={isVisible}>
    <Module
      title="Step 4/7 Review costs and times"
      footer={
        <ButtonsWrapper>
          <Button kind="secondary" onClick={onClickDraft}>Save Draft</Button>
          <Button kind="secondary" onClick={onClickPrevious}>
            Previous
          </Button>
          <Button onClick={onClickNext}>Continue</Button>
        </ButtonsWrapper>
      }
    >
      {selectedData}
      <SectionTitle title="Review and enter costs and lead times" withSeparator />
      {displayErrorMessage(errorMessagePurchaseOptions)}
      {loadingPurchaseOptions && <span>Loading...</span>}
      {purchaseOptions.map(purchaseOption => {

        return (
          <div key={purchaseOption.tmp_id} className={style.purchaseOption}>
            <h4 className={style.title}>Delivery</h4>
            <div className={style.header}>
              <div>
                <p>Named place</p>
                <span>{`${purchaseOption.incoterm_place_name} (${purchaseOption.incoterm_place_country})`}</span>
              </div>
              <div>
                <p>Incoterm</p>
                <span>{purchaseOption.commodity_price? purchaseOption.commodity_price.incoterm.code : purchaseOption.incoterm.code}</span>
              </div>
              <div>
                <p>Source</p>
                <span>
                  <Tag>{purchaseOption.source_of_supply.description}</Tag>
                </span>
              </div>
              <div>
                <p>Packaging</p>
                <span>{purchaseOption.packaging_type.description}</span>
              </div>
            </div>
            <Grid>
              <Row>
                <Col md={4}>
                  <h5 className={style.title}>Lead Times (days)</h5>
                  <span className={style.smallLabel}>Technical</span>
                  <span className={style.readonly}>
                    {stringOrZero(purchaseOption.times? purchaseOption.times.technical_time : purchaseOption.processing_time)}
                  </span>
                  <span className={style.smallLabel}>Shipping/ Discharge</span>
                  <Field
                    component={Input}
                    name={`discharge_shipping_time_${purchaseOption.tmp_id}`}
                    id={`discharge_shipping_time_${purchaseOption.tmp_id}`}
                    normalize={(value) => normalizeNumber(value)}
                    errorMessage={getErrors(errorObject, `discharge_shipping_time_${purchaseOption.tmp_id}`)}
                  />
                  <span className={style.smallLabel}>Landside</span>
                  <Field
                    component={Input}
                    name={`landside_time_${purchaseOption.tmp_id}`}
                    id={`landside_time_${purchaseOption.tmp_id}`}
                    normalize={(value) => normalizeNumber(value)}
                    errorMessage={getErrors(errorObject, `landside_time_${purchaseOption.tmp_id}`)}
                  />
                </Col>
                <Col md={1}>&nbsp;</Col>
                <Col md={4}>
                  <h5 className={style.title}>Costs (US$/Mt)</h5>
                  <span className={style.smallLabel}>Commodity</span>
                  <span className={style.readonly}>
                    {stringOrZero(purchaseOption.costs? purchaseOption.costs.commodity_price : purchaseOption.commodity_cost)}
                  </span>
                  <span className={style.smallLabel}>Shipping rate</span>
                  <span className={style.readonly}>{stringOrZero(purchaseOption.costs.shipping_rate)}</span>
                  <span className={style.smallLabel}>Port/ Handling/ Other</span>
                  <Field
                    component={Input}
                    name={`port_handling_other_cost_${purchaseOption.tmp_id}`}
                    id={`port_handling_other_cost_${purchaseOption.tmp_id}`}
                    value={purchaseOption.port_and_handling_cost}
                    normalize={(value) => normalizeNumber(value)}
                    errorMessage={getErrors(errorObject, `port_handling_other_cost_${purchaseOption.tmp_id}`)}
                  />
                  <span className={style.smallLabel}>Landside rate</span>
                  <Field
                    component={Input}
                    name={`landside_cost_${purchaseOption.tmp_id}`}
                    id={`landside_cost_${purchaseOption.tmp_id}`}
                    normalize={(value) => normalizeNumber(value)}
                    errorMessage={getErrors(errorObject, `landside_cost_${purchaseOption.tmp_id}`)}
                  />
                  <span className={style.smallLabel}>Quantity & Quality</span>
                  <Field
                    component={Input}
                    name={`quantity_and_quality_cost_${purchaseOption.tmp_id}`}
                    id={`quantity_and_quality_cost_${purchaseOption.tmp_id}`}
                    normalize={(value) => normalizeNumber(value)}
                    errorMessage={getErrors(errorObject, `quantity_and_quality_cost_${purchaseOption.tmp_id}`)}
                  />
                </Col>
              </Row>
            </Grid>
          </div>
        );}
      )}
    </Module>
  </ShowHideArea>
);

// propTypes for the ReviewCostsAndTimes component
ReviewCostsAndTimes.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClickNext: PropTypes.func.isRequired,
  onClickDraft: PropTypes.func.isRequired,
  selectedData: PropTypes.node.isRequired,
  onClickPrevious: PropTypes.func.isRequired,
  purchaseOptions: PropTypes.array.isRequired,
  loadingPurchaseOptions: PropTypes.bool.isRequired,
  errorMessagePurchaseOptions: PropTypes.string.isRequired,
  errorObject: PropTypes.object.isRequired
};

export default reduxForm({ form: "ipfDetailReviewCostsAndTimes", enableReinitialize: true })(ReviewCostsAndTimes);
