import React from "react";
import PropTypes from "prop-types";
import { Grid as RFGGrid, Row as RFGRow, Col as RFGCol } from "react-flexbox-grid";

import style from "./style.scss";

// Grid component wrapper
export const Grid = ({ children, ...props }) => <RFGGrid className={style.grid} {...props}>{children}</RFGGrid>; 

// propTypes for the grid component
Grid.propTypes = {
  children: PropTypes.any.isRequired
};

// Grid component wrapper
export const Row = ({ children, ...props }) => <RFGRow {...props}>{children}</RFGRow>; 

// propTypes for the grid component
Row.propTypes = {
  children: PropTypes.any.isRequired
};

// Grid component wrapper
export const Col = ({ children, ...props }) => <RFGCol {...props}>{children}</RFGCol>; 

// propTypes for the grid component
Col.propTypes = {
  children: PropTypes.any.isRequired
};