import React from "react";
import PropTypes from "prop-types";
import { reduxForm, Field } from "redux-form";

import Select from "../../../../components/Select";
import Input from "../../../../components/Input";
import { Grid, Row, Col } from "../../../../components/Grid";
import Spacer from "../../../../components/Spacer";
import FormWrapper from "../../../../components/FormWrapper";
import Modal from "../../../../components/Modal";
import Loading from "../../../../components/Loading";
import { getErrors, displayErrorMessage } from "../../../../utils";

// The RequestPriceModal component is used to add a Request Price
const RequestPriceModal = ({
  errorObject,
  loadingRelatedEntities,
  countries,
  specificCommodities,
  incoterms,
  isOpen,
  onValidate,
  onCancel,
  isLoading,
  locations,
  packagingTypes,
  priceTypes,
  title,
  errorMessage,
  onChangeProjectCountry
}) => (
  <Modal isOpen={isOpen} title={title} onValidate={onValidate} onCancel={onCancel} validateText="Send email">
    {displayErrorMessage(errorMessage)}
    <FormWrapper>
      <Grid>
        <Row>
          <Col md={6}>
            <Field
              component={Select}
              name="price_type"
              id="price_type"
              label="Source"
              options={priceTypes}
              loading={loadingRelatedEntities}
              smallMenu
              errorMessage={getErrors(errorObject, "price_type")}
            />
          </Col>
          <Col md={6}>
            <Field
              component={Select}
              name="commodity"
              id="commodity"
              label="Commodity"
              options={specificCommodities}
              loading={loadingRelatedEntities}
              smallMenu
              errorMessage={getErrors(errorObject, "commodity")}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Field
              component={Select}
              name="origin_country"
              id="origin_country"
              label="Country"
              options={countries}
              loading={loadingRelatedEntities}
              smallMenu
              onChange={onChangeProjectCountry}
              errorMessage={getErrors(errorObject, "origin_country")}
            />
          </Col>
          <Col md={6}>
            <Field
              component={Select}
              name="incoterm"
              id="incoterm"
              label="Incoterm"
              options={incoterms}
              loading={loadingRelatedEntities}
              smallMenu
              errorMessage={getErrors(errorObject, "incoterm")}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Field
              component={Select}
              name="incoterm_place"
              id="incoterm_place"
              label="Named place"
              options={locations}
              loading={loadingRelatedEntities}
              smallMenu
              errorMessage={getErrors(errorObject, "incoterm_place")}
            />
          </Col>
          <Col md={6}>
            <Field
              component={Select}
              name="packaging_type"
              id="packaging_type"
              label="Packaging"
              options={packagingTypes}
              loading={loadingRelatedEntities}
              smallMenu
              errorMessage={getErrors(errorObject, "packaging_type")}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Field
              component={Input}
              name="email_recipient"
              id="email_recipient"
              label="Send to"
              errorMessage={getErrors(errorObject, "email_recipient")}
            />
          </Col>
          <Col md={6}>
            <Field
              component={Input}
              name="email_cc"
              id="email_cc"
              label="Cc"
              errorMessage={getErrors(errorObject, "email_cc")}
            />
          </Col>
        </Row>
      </Grid>
    </FormWrapper>
    <Spacer height={"8rem"} />
    <Loading isVisible={isLoading} />
  </Modal>
);

// propTypes for the Modal component
RequestPriceModal.propTypes = {
  errorObject: PropTypes.object.isRequired,
  loadingRelatedEntities: PropTypes.bool.isRequired,
  specificCommodities: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.any.isRequired
    })
  ),
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.any.isRequired
    })
  ),
  incoterms: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.any.isRequired
    })
  ),
  packagingTypes: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.any.isRequired
    })
  ),
  priceTypes: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.any.isRequired
    })
  ),
  isOpen: PropTypes.bool.isRequired,
  onValidate: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  locations: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  errorMessage: PropTypes.string.isRequired,
  onChangeProjectCountry: PropTypes.func.isRequired
};

export default reduxForm({ form: "requestPriceForm", enableReinitialize: true })(RequestPriceModal);
