import React from "react";
import PropTypes from "prop-types";
import { reduxForm, Field } from "redux-form";

import Select from "@components/Select";
import Input from "@components/Input";
import DatePicker from "@components/DatePicker";
import SelectAsync from "@components/SelectAsync";
import { Grid, Row, Col } from "@components/Grid";
import FormWrapper from "@components/FormWrapper";
import Modal from "@components/Modal";
import Loading from "@components/Loading";
import { getErrors, displayErrorMessage, isPastDate } from "@utils";
import { normalizeNumber } from "@utils/reduxForm";

// The RateContainerModal component is used to add a Rate/Container
const RateContainerModal = ({
  errorObject,
  containerTypes,
  loadPorts,
  dischargePorts,
  isOpen,
  onValidate,
  onCancel,
  errorMessage,
  isLoading,
  isOnEdit
}) => (
  <Modal isOpen={isOpen} title="New Rate/Container" onValidate={onValidate} onCancel={onCancel} validateText="Save">
    {displayErrorMessage(errorMessage)}
    <FormWrapper>
      <Grid>
        <Row>
          <Col md={6}>
            <Field
              component={SelectAsync}
              name="load_port"
              id="load_port"
              label="Load port"
              loadOptions={loadPorts}
              smallMenu
              isDisabled={isOnEdit}
              errorMessage={getErrors(errorObject, "load_port")}
            />
          </Col>
          <Col md={6}>
            <Field
              component={SelectAsync}
              name="discharge_port"
              id="discharge_port"
              label="Discharge port"
              loadOptions={dischargePorts}
              smallMenu
              isDisabled={isOnEdit}
              errorMessage={getErrors(errorObject, "discharge_port")}
            />
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <Field
              component={Select}
              name="container_type"
              id="container_type"
              label="Container type"
              isDisabled={isOnEdit}
              options={containerTypes}
            />
          </Col>
          <Col md={3}>
            <Field
              component={Input}
              name="price"
              id="price"
              label="Price (US$)"
              normalize={(value) => normalizeNumber(value)}
              errorMessage={getErrors(errorObject, "price")}
            />
          </Col>
          <Col md={3}>
            <Field
              component={DatePicker}
              name="valid_until"
              id="valid_until"
              label="Valid until"
              withPortal
              errorMessage={getErrors(errorObject, "valid_until")}
              isOutsideRange={isPastDate}
            />
          </Col>
        </Row>
      </Grid>
    </FormWrapper>
    <Loading isVisible={isLoading} />
  </Modal>
);

// propTypes for the Modal component
RateContainerModal.propTypes = {
  errorObject: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onValidate: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  loadPorts: PropTypes.func.isRequired,
  dischargePorts: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
  containerTypes: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isOnEdit: PropTypes.bool.isRequired
};

export default reduxForm({ form: "rateContainerForm", enableReinitialize: true })(RateContainerModal);
