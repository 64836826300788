import { SHOW_, HIDE_ } from "../actions/showHide";


// A generic reducer used to hide or show an element
const showHide = (element) => (state = { isVisible: false }, action) => {
  switch (action.type) {
  case `${SHOW_}${element}`:
    return { ...state, isVisible: true };
  case `${HIDE_}${element}`:
    return { ...state, isVisible: false };
  default:
    return state;
  }
};

export default showHide;