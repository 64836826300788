import React, { Component } from "react";

import PageInformation from "../../components/PageInformation";


// Login URL
const API_URL_LOGIN = `${process.env.API_BASE_URL}/oidc/`;

// The logout page, it redirects to login
class Logout extends Component {
  componentDidMount(){
    // When ready it redirects to login page to allow user login again
    window.location.assign(API_URL_LOGIN);
  }

  render() {
    return (
      <PageInformation title="You will be redirected to login" text="Redirecting to login..."/>
    );
  }
}

export default Logout;
