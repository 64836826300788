// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_additionalInformation_KKbu0aCmHyQmdPO7\\+17uyg\\=\\= table tr td {\n  padding: 0.2rem 0.5rem 0.2rem 0rem;\n}\n\n.style_additionalInformation_KKbu0aCmHyQmdPO7\\+17uyg\\=\\= table tr td:nth-child(odd) {\n  font-weight: bold;\n}\n\n.style_additionalInformation_KKbu0aCmHyQmdPO7\\+17uyg\\=\\= table tr td:first-child {\n  width: 8rem;\n}\n\n.style_searchButtonWrapper_mJY2XYu1kQ4HkifCzrJwzg\\=\\= {\n  align-self: flex-end;\n}\n\n.style_multilineCell_VDpu1KmCtc2qOueJ0hrKNw\\=\\= {\n  line-height: 1.35;\n}", "",{"version":3,"sources":["webpack://src/containers/CommodityPrices/components/CommodityPriceList/style.scss"],"names":[],"mappings":"AAAA;EACE,kCAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,oBAAA;AACF;;AAEA;EACE,iBAAA;AACF","sourcesContent":[".additionalInformation table tr td {\n  padding: 0.2rem 0.5rem 0.2rem 0rem;\n}\n\n.additionalInformation table tr td:nth-child(odd) {\n  font-weight: bold;\n}\n\n.additionalInformation table tr td:first-child {\n  width: 8rem;\n}\n\n.searchButtonWrapper {\n  align-self: flex-end;\n}\n\n.multilineCell {\n  line-height: 1.35;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"additionalInformation": "style_additionalInformation_KKbu0aCmHyQmdPO7+17uyg==",
	"searchButtonWrapper": "style_searchButtonWrapper_mJY2XYu1kQ4HkifCzrJwzg==",
	"multilineCell": "style_multilineCell_VDpu1KmCtc2qOueJ0hrKNw=="
};
export default ___CSS_LOADER_EXPORT___;
