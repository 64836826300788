import client from "../client";

// Actions available for generic add multiple resource reducer
export const RESOURCE_ADD_MULTIPLE_REQUEST_ = "RESOURCE_ADD_MULTIPLE_REQUEST_";
export const RESOURCE_ADD_MULTIPLE_SUCCESS_ = "RESOURCE_ADD_MULTIPLE_SUCCESS_";
export const RESOURCE_ADD_MULTIPLE_ERROR_ = "RESOURCE_ADD_MULTIPLE_ERROR_";
export const RESOURCE_ADD_MULTIPLE_RESET_ = "RESOURCE_ADD_MULTIPLE_RESET_";

// A function to make multiple promises responses flat
export const flattenResults = results => results.map(result => result.data);

// Action creator for start adding a list of resources
export const resourceAddMultipleRequest = resource => () => {
  return {
    type: `${RESOURCE_ADD_MULTIPLE_REQUEST_}${resource}`
  };
};

// Action creator for a successful add multiple resource request
export const resourceAddMultipleSuccess = resource => data => {
  return {
    type: `${RESOURCE_ADD_MULTIPLE_SUCCESS_}${resource}`,
    data
  };
};

// Action creator for a successful add multiple resource request
export const resourceAddMultipleError = resource => error => {
  return {
    type: `${RESOURCE_ADD_MULTIPLE_ERROR_}${resource}`,
    error
  };
};

// Action creator for resetting the data
export const resourceAddMultipleReset = resource => () => {
  return {
    type: `${RESOURCE_ADD_MULTIPLE_RESET_}${resource}`,
  };
};

// Here we dispatch a list of requests action and then, after they are resolved, the success action
// or, if fails, the error action
export const resourceAddMultiple = (resource, url) => (requests) => {
  return dispatch => {
    dispatch(resourceAddMultipleRequest(resource)());
    const promises = requests.map(request => client.post(`${url}${request.url}`, request.data));
    return Promise.all(promises)
      .then(result => {
        dispatch(resourceAddMultipleSuccess(resource)(flattenResults(result)));
        return result;
      })
      .catch(error => {
        dispatch(resourceAddMultipleError(resource)(error));
        return {};
      });
  };
};
