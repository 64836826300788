import React from "react";
import { Field } from "redux-form";
import PropTypes from "prop-types";
import { iconWfpHumResourcingPos } from "@wfp/icons";

import Icon, { ClockIcon } from "../components/Icon";
import Radio from "../components/Radio";
import Tag from "../components/Tag";

import style from "./style.scss";

// The sources list table used by many components
export const PurchaseOptionsTable = ({ purchaseOptions, withRadio }) => (
  <>
    {purchaseOptions.map(purchaseOption => !purchaseOption.hasOwnProperty("tmp_id") ? null : (
      <div key={purchaseOption.tmp_id} className={style.purchaseOption}>
        {withRadio ? (
          <div>
            <Field
              component={Radio}
              name="options"
              id={String(purchaseOption.tmp_id)}
              value={String(purchaseOption.tmp_id)}
              text=""
            />
          </div>
        ) : (
          <div />
        )}
        <div className={style.purchaseOptionRight}>
          <h4 className={style.title}>Delivery</h4>
          <div className={style.header}>
            <div>
              <p>Named place</p>
              <span>{`${purchaseOption.incoterm_place_name} (${purchaseOption.incoterm_place_country})`}</span>
            </div>
            <div>
              <p>Incoterm</p>
              <span>{purchaseOption.commodity_price.incoterm.code}</span>
            </div>
            <div>
              <p>Source</p>
              <span>
                <Tag>{purchaseOption.source_of_supply.description}</Tag>
              </span>
            </div>
            <div>
              <p>Packaging</p>
              <span>{purchaseOption.packaging_type.description}</span>
            </div>
          </div>
          <div className={style.table}>
            <div>
              <div className={style.column}>
                <div>
                  <h6 className={style.title}>Lead Times (days)</h6>
                  <table>
                    <tbody>
                      <tr>
                        <td>Technical</td>
                        <td>{purchaseOption.technical_time}</td>
                      </tr>
                      <tr>
                        <td>Shipping/ Discharge</td>
                        <td>{purchaseOption.discharge_shipping_time}</td>
                      </tr>
                      <tr>
                        <td>Landside</td>
                        <td>{purchaseOption.landside_time}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div>
                  <table>
                    <tbody>
                      <tr>
                        <td>Total time</td>
                        <td>{purchaseOption.total_time}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div>
              <div className={style.column}>
                <div>
                  <h6 className={style.title}>Costs (US$/Mt)</h6>
                  <table>
                    <tbody>
                      <tr>
                        <td>Commodity</td>
                        <td>{purchaseOption.commodity_cost}</td>
                      </tr>
                      <tr>
                        <td>Shipping rate</td>
                        <td>{purchaseOption.shipping_cost}</td>
                      </tr>
                      <tr>
                        <td>Port/ Handling/ Other</td>
                        <td>{purchaseOption.port_handling_other_cost}</td>
                      </tr>
                      <tr>
                        <td>Landside rate</td>
                        <td>{purchaseOption.landside_cost}</td>
                      </tr>
                      <tr>
                        <td>Quantity & Quality</td>
                        <td>{purchaseOption.quantity_and_quality_cost}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div>
                  <table>
                    <tbody>
                      <tr>
                        <td>Total Cost/Mt</td>
                        <td>{purchaseOption.total_cost_mt}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div>
              <div className={style.column}>
                <div>
                  <h6 className={style.titleRight}>Total IPF Cost (US$)</h6>
                </div>
                <div>
                  {purchaseOption.is_best_cost ? (
                    <div className={style.icon}>
                      Cheapest option
                      <Icon
                        icon={iconWfpHumResourcingPos}
                        width="18"
                        height="18"
                        style={{ padding: "0rem 0rem 0rem 0.4rem" }}
                      />
                    </div>
                  ) : (
                    undefined
                  )}
                  {purchaseOption.is_best_time ? (
                    <div className={style.icon}>
                      Fastest option
                      <ClockIcon name="light" width="18" height="18" style={{ padding: "0rem 0rem 0rem 0.4rem" }} />
                    </div>
                  ) : (
                    undefined
                  )}
                  <span className={style.totalCost}>{purchaseOption.total_cost}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ))}
  </>
);

// propTypes for the PurchaseOptionsTable component
PurchaseOptionsTable.propTypes = {
  purchaseOptions: PropTypes.array.isRequired,
  withRadio: PropTypes.bool
};

// defaultProps for the PurchaseOptionsTable component
PurchaseOptionsTable.defaultProps = {
  withRadio: false
};
