// Actions available to be used by the generic current page reducer
export const CURRENT_PAGE_ = "CURRENT_PAGE_";


// Action creator to set a current page number
export const currentPage = element => number => {
  return {
    type: `${CURRENT_PAGE_}${element}`,
    number: number
  };
};