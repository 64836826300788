import React from "react";
import PropTypes from "prop-types";

import { displayErrorMessage } from "@utils";
import { columns } from "@utils/accessRequests";
import Module from "@components/Module";
import Table from "@components/Table";

// The UserAccessRequestList component is used to display a list of IPFs
const UserAccessRequestList = ({
  accessRequests,
  errorMessageAccessRequests,
  totalItems,
  page,
  onPageChange,
  loadingAccessRequests,
  onClickDetail
}) => (
  <>
    {displayErrorMessage(errorMessageAccessRequests)}
    <Module
      title="Your Access Requests"
      table={
        <Table
          loading={loadingAccessRequests}
          columns={columns(onClickDetail, true)}
          data={accessRequests}
          totalItems={totalItems}
          page={page}
          onPageChange={onPageChange}
          pageSize={5}
        />
      }
    ></Module>
  </>
);

// propTypes for the UserAccessRequestList component
UserAccessRequestList.propTypes = {
  accessRequests: PropTypes.array.isRequired,
  errorMessageAccessRequests: PropTypes.string.isRequired,
  totalItems: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  loadingAccessRequests: PropTypes.bool.isRequired,
  onClickDetail: PropTypes.func.isRequired
};

export default UserAccessRequestList;
