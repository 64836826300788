import React from "react";
import PropTypes from "prop-types";

import style from "./style.scss";


// A simple wrapper that helps to align fields of a form
const FiltersWrapper = ({ children }) => (
  <div className={style.wrapper}>
    {children}
  </div>
);

// propTypes for the FiltersWrapper component
FiltersWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FiltersWrapper;
