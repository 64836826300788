import React from "react";
import { Wrapper } from "@wfp/ui";
import PropTypes from "prop-types";

import Breadcrumb from "../../components/Breadcrumb";
import style from "./style.scss";

// A page component with a title and a children as prop
const PageNoTitle = ({ children }) => (
  <>
    <Wrapper pageWidth="lg" spacing="md" background="lighter">
      <Breadcrumb />
    </Wrapper>
    <Wrapper pageWidth="lg" spacing="md" background="lighter">
      <div className={style.wrapper}>
        {children}
      </div>
    </Wrapper>
  </>
);

// propTypes for the PageNoTitle component
PageNoTitle.propTypes = {
  children: PropTypes.node.isRequired
};

export default PageNoTitle;
