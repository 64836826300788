import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { reset } from "redux-form";

import PageStandard from "@components/PageStandard";
import ModalSuccess from "@components/ModalSuccess";
import Loading from "@components/Loading";

import { adaptOptions, adaptOption, isEmpty, getStatusErrorMessage } from "@utils";
import { getUserDescription } from "@utils/users";

import {
  userDetail,
  userDetailReset,
  userRolesList,
  userDivisionList,
  countryList,
  userAccessRequestAdd,
  userAccessRequestAddReset,
  accessRequestDetailUpdate,
  accessRequestDetailUpdateReset,
  showSuccessUserAccessRequestModal,
  hideSuccessUserAccessRequestModal,
  accessRequestDetail,
  accessRequestDetailActiveAction,
  supervisorList,
} from "@actions";
import UserAccessForm from "./UserAccessForm";

export const adaptRoles = roles => {
  const roleList = [];
  roles.filter(role => ["administrator", "public"].indexOf(role.toLowerCase()) === -1).map(role => roleList.push({ label: role, value: role }));
  return roleList;
};

export const adaptUserInitialValues = user => {
  return {
    ...user,
    divisionItems: user.divisions,
    divisions: user.divisions && user.divisions.hasOwnProperty("id")? user.divisions.map(division => division.id) : [],
    country: user.country && adaptOption(user.country, "name", "name")
  };
};

export const adaptUser = user => ({ ...user, country: user.country && adaptOption(user.country, "id", "name") });

export class UserAccess extends Component {
  componentDidMount() {
    this.onValidate = this.onValidate.bind(this);
    this.onValidateSuccessModal = this.onValidateSuccessModal.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.supervisors = this.supervisors.bind(this);
    this.props.userRolesList();
    this.props.countryList();
    this.props.userDivisionList();
    if (this.props.isOnEdit) {
      this.props.accessRequestDetail(this.props.id);
    }
  }

  // On component unmount store is reset where needed
  componentWillUnmount() {
    this.props.userAccessRequestAddReset();
    this.props.resetUserAccessForm();
    // this.props.userDetailReset();
    this.props.userDetail();
  }

  resetUserAccessForm() {
    // this.props.supervisorListReset();
    this.props.userAccessRequestAddReset();
    this.props.accessRequestDetailUpdateReset();
    this.props.resetUserAccessForm();
    this.props.history.push("/");
    window.scrollTo(0, 0);
  }

  onValidate() {
    const { supervisor, roles } = this.props.userAccessFormValues;
    const {currentUser} = this.props;
    let supervisor_email = "";
    let supervisor_full_name = "";
    let supervisor_position_title = "";
    let org_unit_description = currentUser? currentUser.org_unit_description : "";
    let country = currentUser && currentUser.country? currentUser.country.value : "";

    if (supervisor && supervisor.data) {
      let {email, first_name, last_name, position_title, full_name} = supervisor.data;
      supervisor_full_name = full_name? full_name : `${first_name} ${last_name}`;
      supervisor_email = email;
      supervisor_position_title = position_title;
    }
    if(this.props.isOnEdit) {
      this.props.accessRequestDetailUpdate(
        this.props.id,
        supervisor_email,
        supervisor_full_name,
        supervisor_position_title,
        roles,
        country,
        org_unit_description,
      ).then(success => {
        if (success) {
          this.props.accessRequestStateUpdate(this.props.id, "resubmit").then(success => {
            if (success) {
              this.props.showSuccessUserAccessRequestModal();
              this.props.userDetailReset();
            }
          });
        }
      });
    }
    else {
      this.props.userAccessRequestAdd(
        supervisor_email,
        supervisor_full_name,
        supervisor_position_title,
        roles,
        country,
        org_unit_description,
      ).then(success => {
        if (success) {
          this.props.showSuccessUserAccessRequestModal();
          this.props.userDetailReset();
        }
      });
    }
  }

  // When user click ok on success modal, it is redirected to Admin list page
  // and the page is scrolled to top
  onValidateSuccessModal() {
    this.props.hideSuccessUserAccessRequestModal();
    this.resetUserAccessForm();
  }

  onCancel() {
    this.resetUserAccessForm();
  }

  supervisors(value) {
    const filter = value.replace(/^[0]+/g, "");
    const { supervisorList } = this.props;
    if (filter.length > 3) {
      return supervisorList(filter).then(data =>
        data.supervisors.map(result => ({
          value: `${result.first_name} ${result.last_name}`,
          label: `${result.first_name} ${result.last_name}`,
          data: result
        }))
      );
    } else {
      return Promise.resolve(undefined);
    }
  }

  render() {
    const {
      isFetching,
      errorMessageUserDetail,
      errorMessageUserAccessRequest,
      currentUser,
      errorObject,
      userRoles,
      isSuccessModalOpen,
      isFetchingDivisions,
      isFetchingUserRoles,
      isFetchingCountries,
      isOnEdit,
      accessRequest,
    } = this.props;

    const isLoading = isFetching || isFetchingCountries || isFetchingDivisions || isFetchingUserRoles;
    let initialValues = currentUser;
    if(isOnEdit && accessRequest) {
      initialValues.roles = accessRequest.roles_to_grant? accessRequest.roles_to_grant.roles : [];
      initialValues.supervisor = {
        data: {
          email: accessRequest.supervisor,
          full_name: accessRequest.supervisor_name,
          position_title: accessRequest.supervisor_role
        },
        label: accessRequest.supervisor_name,
        value: accessRequest.supervisor_name
      };
    }
    let selectedSupervisor = this.props.userAccessFormValues? this.props.userAccessFormValues.supervisor : null;
    return (
      <>
        {isLoading || (currentUser && currentUser.has_pending_user_request &&
            (!isOnEdit || accessRequest.state !== "draft" || currentUser.email !== accessRequest.username)) ? (
            <Loading isVisible={isFetching || currentUser.has_pending_user_request}>
              {isOnEdit? "You can not edit this access request." : currentUser.has_pending_user_request
                ? "Your access request is in progress. Check again later or contact your supervisor."
                : "Loading..."}
            </Loading>
          ) : (
            <PageStandard title={`${getUserDescription(currentUser)}`}>
              <UserAccessForm
                user={currentUser}
                isLoading={isFetching}
                isOnEdit
                selectedSupervisor={selectedSupervisor}
                errorObject={errorObject}
                onValidate={this.onValidate}
                onCancel={this.onCancel}
                userRoles={userRoles}
                supervisors={this.supervisors}
                initialValues={initialValues}
                errorMessageUserDetail={errorMessageUserDetail}
                errorMessageUserAccessRequest={errorMessageUserAccessRequest}
              />
              <ModalSuccess
                isOpen={isSuccessModalOpen}
                onValidate={this.onValidateSuccessModal}
                text="Your Access Request has been submitted."
              />
            </PageStandard>
          )}
      </>
    );
  }
}

// propTypes for the UserAccess component
UserAccess.propTypes = {
  id: PropTypes.any,
  isFetching: PropTypes.bool.isRequired,
  isOnEdit: PropTypes.bool.isRequired,
  errorMessageUserDetail: PropTypes.string.isRequired,
  errorMessageUserAccessRequest: PropTypes.string.isRequired,
  currentUser: PropTypes.any.isRequired,
  errorObject: PropTypes.object.isRequired,
  history: PropTypes.any.isRequired,
  userDetail: PropTypes.func.isRequired,
  userDetailReset: PropTypes.func.isRequired,
  userAccessRequestAdd: PropTypes.func.isRequired,
  userAccessRequestAddReset: PropTypes.func.isRequired,
  accessRequestDetailUpdate: PropTypes.func.isRequired,
  accessRequestDetailUpdateReset: PropTypes.func.isRequired,
  countryList: PropTypes.func.isRequired,
  userDivisionList: PropTypes.func.isRequired,
  userRolesList: PropTypes.func.isRequired,
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.any.isRequired
    })
  ),

  userRoles: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.any.isRequired
    })
  ),
  divisions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.any.isRequired
    })
  ),
  divisionList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any.isRequired,
      name: PropTypes.any.isRequired
    })
  ),
  userAccessFormValues: PropTypes.any,
  isFetchingCountries: PropTypes.bool.isRequired,
  isFetchingUserRoles: PropTypes.bool.isRequired,
  isFetchingDivisions: PropTypes.bool.isRequired,
  isSuccessModalOpen: PropTypes.bool.isRequired,
  resetUserAccessForm: PropTypes.func.isRequired,
  showSuccessUserAccessRequestModal: PropTypes.func.isRequired,
  hideSuccessUserAccessRequestModal: PropTypes.func.isRequired,
  userAccessDetail: PropTypes.func.isRequired,
  accessRequestStateUpdate: PropTypes.func.isRequired,
  accessRequestStateUpdateReset: PropTypes.func.isRequired,
  supervisorList: PropTypes.func.isRequired
};

UserAccess.defaultProps = {
  userAccessFormValues: undefined,
  currentUser: undefined
};

export const mapStateToProps = (state, ownProps) => {
  const {
    form,
    userDetail,
    userRolesList,
    countryList,
    successUserAccessRequestModal,
    accessRequestDetail,
    userAccessRequestAdd,
    accessRequestDetailUpdate,
    userDivisionList
  } = state;
  const { userAccessForm } = form;
  const isOnEdit = ownProps.match.params.id !== undefined;


  return {
    errorMessageUserDetail: userDetail && userDetail.errorMessage,
    errorMessageUserAccessRequest:
      (!isEmpty(userAccessRequestAdd.error) && getStatusErrorMessage(userAccessRequestAdd.error)) ||
        (!isEmpty(accessRequestDetailUpdate.error) && getStatusErrorMessage(accessRequestDetailUpdate.error)) ||  "",
    currentUser: userDetail && adaptUserInitialValues(userDetail.data),
    isFetching: !userDetail || userDetail.isFetching,
    isFetchingCountries: countryList.isFetching,
    isFetchingDivisions: userDivisionList.isFetching,
    isFetchingUserRoles: userRolesList.isFetching,
    isOnEdit: isOnEdit,
    id: ownProps.match.params.id,
    accessRequest: accessRequestDetail.data,
    divisionList: userDivisionList.data.divisions,
    divisions: userDivisionList.data.divisions,
    countries: adaptOptions(countryList.data.results, "name", "name"),
    userRoles: adaptRoles(userRolesList.data.results),
    isSuccessModalOpen: successUserAccessRequestModal.isVisible,
    errorObject: userAccessRequestAdd.error || accessRequestDetailUpdate.error,
    userAccessFormValues: userAccessForm && userAccessForm.values
  };
};

// Maps functions to dispatch actions
export const mapDispatchToProps = dispatch => {
  return {
    userDetail: () => dispatch(userDetail()),
    userDetailReset: () => dispatch(userDetailReset()),
    userAccessRequestAdd: (supervisor, supervisor_name, supervisor_title, roles, country, org_unit_description) =>
      dispatch(
        userAccessRequestAdd({
          roles_to_grant: {
            roles
          },
          supervisor,
          supervisor_name,
          country,
          org_unit_description,
          supervisor_role: supervisor_title,
        })
      ),
    accessRequestDetailUpdate: (id, supervisor, supervisor_name, supervisor_title, roles, country, org_unit_description) =>
      dispatch(
        accessRequestDetailUpdate(id, {
          roles_to_grant: {
            roles
          },
          supervisor,
          supervisor_name,
          country,
          org_unit_description,
          supervisor_role: supervisor_title,
        })
      ),
    userAccessRequestAddReset: () => dispatch(userAccessRequestAddReset()),
    accessRequestDetailUpdateReset: () => dispatch(accessRequestDetailUpdateReset()),
    countryList: country => dispatch(countryList({ country })),
    userRolesList: () => dispatch(userRolesList()),
    userDivisionList: () => dispatch(userDivisionList()),
    resetUserAccessForm: () => dispatch(reset("userAccessForm")),
    showSuccessUserAccessRequestModal: () => dispatch(showSuccessUserAccessRequestModal()),
    hideSuccessUserAccessRequestModal: () => dispatch(hideSuccessUserAccessRequestModal()),
    accessRequestDetail: id => dispatch(accessRequestDetail(id)),
    accessRequestDetailActiveAction: action => dispatch(accessRequestDetailActiveAction(action)),
    accessRequestStateUpdate: (id, action) => dispatch(accessRequestDetailUpdate(id, {}, `perform/${action}/`)),
    accessRequestStateUpdateReset: () => dispatch(accessRequestDetailUpdateReset()),
    supervisorList: code => dispatch(supervisorList({ code })),



  };
};

// The form is added to the redux store with a given name
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserAccess));
